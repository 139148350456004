import React, { useCallback, useEffect, useState } from 'react';
import { styled } from 'react-free-style';
import { Helmet } from 'react-helmet';

import MergeProfileTitle from '../profiles/MergeProfileTitle';
import CompanyProfileView from './mergeCompaniesProfile';
import { cardTypes } from '../../cardTypes';

import { getGroup, updateGroup } from '../../../../api/groups.js';
import { Column, Columns, InnerPageContent, PageBody, PageContainer } from '../../../../styles/components/components';

import { FinanceView, WebsiteView } from '../../../profile/views';
import CardPageContainer from '../../../../components/card/CardPageContainer';

import _ from 'lodash';
import BrandingWrapped from '../../../../components/branding-wrapped/BrandingWrapped';

function MergeCompanies({ computedMatch, user, styles, _getGroup = getGroup }) {
  // next lines are relevant only until link to merge page is implemented
  // after that, we don't want to use query params to determine merging profiles
  const testCompanyIds =
    '5cca1e2fd06586001247f239,' +
    '5d24d8411cea980010c6565d,' +
    '5d30dfc0f86a370011078bf4,' +
    '5bd09f1f948b13000fdcb21c';
  const initialCompanyIds = (computedMatch.params.companyIds || testCompanyIds).split(',');

  const [companyIds, setCompanyIds] = useState(initialCompanyIds);
  const [companies, setCompanies] = useState([]);
  const [cardHeights, setCardHeights] = useState({});
  const [financeInfo, setFinanceInfo] = useState({});
  const [teamInfo, setTeamInfo] = useState({});

  function getCardHeights() {
    let maxHeights = {};
    const maxHeight = (x, y) => {
      return x.clientHeight > y.clientHeight ? x : y;
    };
    for (let cardType of cardTypes) {
      let cards = document.getElementsByName(cardType);
      if (cards.length) {
        cards = Array.from(cards);
        const max = cards.reduce(maxHeight).clientHeight;
        maxHeights[cardType] = max;
      }
    }
    return maxHeights;
  }

  useEffect(() => {
    const heights = getCardHeights();
    if (!_.isEqual(heights, cardHeights)) {
      setCardHeights(heights);
    }
  }, [cardHeights, companyIds, _getGroup, companies, teamInfo]);

  const retrieveCompanies = useCallback(async () => {
    let companiesInfo = [];
    for (let id of companyIds) {
      const company = await _getGroup(id);
      companiesInfo.push(company);
    }
    return companiesInfo;
  }, [_getGroup, companyIds]);

  useEffect(() => {
    const currentIds = companies.map((x) => x._id);
    if (!_.isEqual(currentIds, companyIds)) {
      retrieveCompanies(companyIds, _getGroup).then((companiesInfo) => {
        companiesInfo && setCompanies(companiesInfo);
      });
    }
  }, [companyIds, _getGroup, companies, retrieveCompanies]);

  const refreshProfiles = () => {
    retrieveCompanies(companyIds, _getGroup).then((companiesInfo) => {
      companiesInfo && setCompanies(companiesInfo);
    });
  };

  const saveWebsite = async ({ updates }, companyId) => {
    await updateGroup(companyId, updates[companyId]);
    refreshProfiles();
  };

  const setPrimary = (primary) => {
    // move new primary profile to the front
    const newIdList = [...companyIds];
    const newPrimaryId = newIdList.splice(primary, 1)[0];
    newIdList.unshift(newPrimaryId);
    setCompanyIds(newIdList);

    // instead of re-retrieving, move new primary person to the front
    const newCompanyList = [...companies];
    const newPrimaryCompany = newCompanyList.splice(primary, 1)[0];
    newCompanyList.unshift(newPrimaryCompany);
    setCompanies(newCompanyList);
  };

  const cardData = cardTypes.reduce((x, t) => {
    const height = _.get(cardHeights, t);
    return {
      ...x,
      [t]: {
        primary: false,
        cardHeight: height,
        isEmpty: !height || height === 40,
        name: t,
      },
    };
  }, {});

  const isFinanceSectionEmpty =
    cardData.netsuite.isEmpty &&
    cardData.loanouts.isEmpty &&
    cardData.gstHst.isEmpty &&
    cardData.taxIds.isEmpty &&
    cardData.commission.isEmpty &&
    cardData.vat.isEmpty;

  return (
    <PageContainer>
      <Helmet>
        <title>MERGE</title>
      </Helmet>
      <BrandingWrapped />
      <div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        SUBMIT MERGE CONFIRMATION
      </div>
      <div
        style={{ color: '#6B6A69', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        Select the primary profile that you want to keep and add the data from the secondary profiles that you would
        like to keep
      </div>
      <PageBody>
        <InnerPageContent style={{ maxWidth: '95%' }}>
          <div className={styles.title}>Profile Info</div>
          <div style={{ overflow: 'auto' }}>
            <Columns className={styles.scrollableCols}>
              {companies &&
                companies.map((company, index) => {
                  const isPrimary = index === 0;
                  return (
                    <Column key={`company-col-${index}`} className={isPrimary ? styles.stickyCol : styles.scrollCol}>
                      <CardPageContainer onSave={refreshProfiles}>
                        <MergeProfileTitle person={company} index={index} setPrimary={setPrimary} />
                        <CompanyProfileView
                          companyInfo={company}
                          user={user}
                          cardData={{ ...cardData, primary: isPrimary }}
                          styles={styles}
                          setTeamInfo={(data) => setTeamInfo({ ...teamInfo, ...data })}
                          refreshProfiles={() => refreshProfiles()}
                        />
                        {!isFinanceSectionEmpty && (
                          <div className={styles.sectionTitle}>{isPrimary ? 'Finance' : ''}</div>
                        )}
                        <FinanceView
                          company={company}
                          mergeCardData={{ ...cardData, primary: isPrimary }}
                          setFinance={(data) => setFinanceInfo({ ...financeInfo, ...data })}
                          refreshProfile={() => refreshProfiles()}
                        />
                        {!cardData.website.isEmpty && (
                          <div className={styles.sectionTitle}>{isPrimary ? 'Website' : ''}</div>
                        )}
                        <WebsiteView
                          company={company}
                          mergeCardData={{ ...cardData.website, primary: isPrimary }}
                          saveChanges={(changes) => saveWebsite(changes, company._id)}
                        />
                        <div className={styles.sectionTitle}>{isPrimary ? 'Touring Info' : ''}</div>
                      </CardPageContainer>
                    </Column>
                  );
                })}
            </Columns>
          </div>
        </InnerPageContent>
      </PageBody>
    </PageContainer>
  );
}

const colStyle = {
  height: '100%',
  maxWidth: '50%',
  minWidth: '388px',
  margin: '0px !important',
  backgroundColor: '#F5F5F5',
};

const withStyles = styled({
  scrollCol: {
    ...colStyle,
    zIndex: '1',
    marginRight: '2px !important',
  },
  stickyCol: {
    ...colStyle,
    position: 'sticky',
    left: '0',
    zIndex: '2',
    boxShadow: '10px 0px 5px -7px rgba(0,0,0,.1)',
  },
  title: {
    fontSize: '24px',
    marginBottom: '5px',
  },
  sectionTitle: {
    fontSize: '24px',
    marginBottom: '5px',
    height: '36px',
    marginTop: '20px',
  },
  scrollableCols: {
    whiteSpace: 'nowrap',
  },
});

export default withStyles(MergeCompanies);
