import React, { useState, useCallback, useEffect } from 'react';
import { notify } from 'react-notify-toast';

import { searchPersonByDepartment } from './../../api/person';

import AccountingRepEditor from './uta-touring-agent-editor';
import AccountingCard from '../accounting-card/AccountingCard';
import PersonRelationshipViewer from './uta-employee-relationship-viewer';

import { getPersonToAccountingRepRelations, updateEntityToAccountingRepRelationships } from '../../api/get-enum-types';

import cypressTags from '../../support/cypressTags';
import CommonTooltip from '../common/tooltip';
import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

const AccountingRep = ({ onSave, user, onChange, entity, entityLabel, mergeCardData, blockEdit }) => {
  const [relationships, setRelationships] = useState([]);

  const id = entity?._id;
  const loadTouringAgents = async () => {
    if (!id) {
      return [];
    }
    if (entityLabel === 'person') {
      getPersonToAccountingRepRelations(entity._id).then((result) => {
        let relations = Array.isArray(result) ? result : [];
        setRelationships(relations);
      });
    }
  };

  const loadTouringAgentsWrapped = useCallback(loadTouringAgents, [entity, entityLabel, id]);

  useEffect(() => {
    loadTouringAgentsWrapped();
  }, [id, loadTouringAgentsWrapped]);

  const handleChange = (id) => {
    const relationship = relationships.filter((ds) => ds._id !== id);
    setRelationships(relationship);
  };

  const saveChanges = async ({ creates, updates, deletes, mergedData }) => {
    const externalChanges = {};
    if (creates && creates.length > 0) {
      externalChanges.creates = creates.map((relationship) => ({
        _id: relationship._id,
        subsidairyType: relationship.subsidairyType,
        personId: (relationship.personId || {})._id,
      }));
    }

    if (deletes && Object.keys(deletes).length > 0) {
      // Just need an array of ids
      externalChanges.deletes = Object.keys(deletes);
    }

    if (updates && Object.keys(updates).length > 0) {
      externalChanges.updates = mergedData
        .filter(({ _id }) => updates[_id])
        .map((relationship) => {
          return {
            _id: relationship._id,
            subsidairyType: relationship.subsidairyType,
            personId: relationship.personId._id,
          };
        });
    }

    if (['venue', 'company', 'person'].includes(entityLabel)) {
      updateEntityToAccountingRepRelationships({
        [`${entityLabel}Id`]: id,
        creates: externalChanges.creates,
        updates: externalChanges.updates,
        deletes: externalChanges.deletes,
      })
        .then((result) => {
          if (result.status !== 200 && result.status !== 201) {
            return Promise.reject({ message: `Status ${result.status}` });
          }
          notify.show('Accounting Rep saved successfully', 'success');
          loadTouringAgents();
          onSave && onSave(externalChanges);
          return Promise.resolve();
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log(`Error Saving: ${errorMessage}`);
          notify.show('Accounting Rep did not save successfully. Please try again.', 'error');
        });
    }

    const resultStatusValidator = (result) => {
      if (result.status !== 200 && result.status !== 201) {
        return Promise.reject({ message: `Status ${result.status}` });
      }
      return Promise.resolve();
    };

    onSave && onSave(externalChanges).then(resultStatusValidator);
  };

  const showAccountingReps = !mergeCardData || !mergeCardData.isEmpty || relationships.length > 0;

  const onSearchPerson = async (searchText) => {
    return searchPersonByDepartment(searchText, ['Employee'], ['Finance %26 Accounting']).then((result) => {
      return result;
    });
  };

  return !showAccountingReps ? (
    <></>
  ) : (
    <div data-cy={cypressTags.COMMON.GUARANTEE_TEAM_CARD} style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <AccountingCard
        title="Accounting Rep"
        mergeCardData={mergeCardData}
        data={relationships}
        readView={readView}
        editView={!blockEdit && ((props) => editView({ onSearchPerson, relationships }, props))}
        onSave={saveChanges}
        onChange={onChange}
        createNew={() => ({ _random: Math.random() })}
        onValidateItem={(params) => validateItems(params)}
        blockEdit={blockEdit}
        canDelete
        blockCreateMultiple={false}
        handleChange={handleChange}
      />
    </div>
  );
};

function readView({ item: relationship, idx }) {
  return <PersonRelationshipViewer key={idx} relationship={relationship} />;
}

function editView({ onSearchPerson, relationships }, { item: relationship, setState, idx }) {
  return (
    <AccountingRepEditor
      key={idx}
      relationship={relationship}
      onSearch={onSearchPerson}
      onPersonChanged={(personId) => setState({ personId })}
      onAgentTypeChanged={(subsidairyType) => setState({ subsidairyType })}
      onRegionTypeChanged={(regions) => setState({ regions })}
    />
  );
}

const validateItems = (saveableData) => {
  if (saveableData.length > 1) {
    if (saveableData[0].subsidairyType === saveableData[1].subsidairyType) {
      return 'Each Subsidiary can only be added once per client.';
    } else if (!saveableData[0].personId?.name || !saveableData[1].personId?.name) {
      return 'Require subsidiary agent name';
    } else {
      return '';
    }
  } else if (saveableData.length === 1) {
    if (!saveableData[0].personId?.name) {
      return 'Require subsidiary agent name';
    } else {
      return '';
    }
  }
};

export default AccountingRep;
