import React from 'react';
import Card from '../card/Card';

function TerritoriesCardWrapper({ title, conditional, mergeData, children }) {
  if (conditional) {
    return children;
  } else if (mergeData && mergeData.cardHeight) {
    return <Card title={title} mergeCardData={mergeData} data={[]} canEdit />;
  } else {
    return <></>;
  }
}

export default TerritoriesCardWrapper;
