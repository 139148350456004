import { get, put } from './index.js';
import { notify } from 'react-notify-toast';

const getIdKey = (entityType) => `${entityType}Id`;

const createTerritoryDataApis = (subPath) => {
  const getPath = (entityType) => `/touring/territory-data/${entityType}/${subPath}`;

  const getTerritoryData = async (entityType, id) => {
    try {
      const response = await get(getPath(entityType), { [getIdKey(entityType)]: id });
      return response.body;
    } catch (e) {
      notify.show('Error getting territory data', 'error');
      throw e;
    }
  };

  const updateTerritoryData = async (entityType, id, territoryData) => {
    const request = { [getIdKey(entityType)]: id, territoryData };
    try {
      const response = await put(getPath(entityType), request);
      return response.body;
    } catch (e) {
      switch (subPath) {
        case 'touring-agents':
          notify.show('Touring Agents did not save successfully. Please try again.', 'error');
          break;
        case 'business-names':
          notify.show('Business Names did not save successfully. Please try again.', 'error');
          break;
        case 'touring-territories':
          notify.show('Territories did not save successfully. Please try again.', 'error');
          break;
        case 'guarantee-names':
          notify.show('Guarantee did not save successfully. Please try again.', 'error');
          break;
        case 'contract-signer-blocks':
          notify.show('Contract Signer Block did not save successfully. Please try again.', 'error');
          break;
        default:
          notify.show('Error saving territory data', 'error');
      }
      throw e;
    }
  };

  return [getTerritoryData, updateTerritoryData];
};

export const [getTaxIds, updateTaxIds] = createTerritoryDataApis('tax-ids');
export const [getVatNumbers, updateVatNumbers] = createTerritoryDataApis('vat-numbers');
export const [getGstHstNumbers, updateGstHstNumbers] = createTerritoryDataApis('gst-hst-numbers');
export const [getLoanouts, updateLoanouts] = createTerritoryDataApis('loanouts');
export const [getAgencyCommissionRates, updateAgencyCommissionRates] =
  createTerritoryDataApis('agency-commission-rates');

export const [getProvisions, updateProvisions] = createTerritoryDataApis('provisions');
export const [getContractSignerBlocks, updateContractSignerBlocks] = createTerritoryDataApis('contract-signer-blocks');
export const [getBalanceRemittance, updateBalanceRemittance] = createTerritoryDataApis('balance-remittances');
export const [getAdditionalClauses, updateAdditionalClauses] = createTerritoryDataApis('additional-clauses');
export const [getBusinessNames, updateBusinessNames] = createTerritoryDataApis('business-names');
export const [getTouringTerritories, updateTouringTerritories] = createTerritoryDataApis('touring-territories');
export const [getGuaranteeNames, updateGuaranteeNames] = createTerritoryDataApis('guarantee-names');
export const [getTouringAgents, updateTouringAgents] = createTerritoryDataApis('touring-agents');
