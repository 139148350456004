import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { buttonTypes } from '@united-talent-agency/julius-frontend-components';

import { CardBody, CardButton, CardHeader, CardPane, CardTitle } from '../card/Card';
import cypressTags from '../../support/cypressTags';
import { TouringAgentTable } from './TouringAgentTable';
import { TouringAgentEditor } from './TouringAgentEditor';
import CommonTooltip from '../common/tooltip';

import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export const UtaTouringAgent = ({
  territoriesData,
  title,
  individualItemTitle,
  textFieldTitle,
  agentTypes,
  regionTypes,
  saveData,
  id,
  mergeCardData,
  touringAgentBlocks,
  isTouring,
  blockEdit,
  vocations,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const isClientComedy = vocations.includes('Comedy (Live)');

  return isEditing && !blockEdit ? (
    <div style={{ position: 'relative' }} data-cy={cypressTags.PERSON.TOURING_AGENT.EDIT_MODE_CONTAINER}>
      <TouringAgentEditor
        isClientComedy={isClientComedy}
        territoriesData={territoriesData}
        title={title}
        individualItemTitle={individualItemTitle}
        textFieldTitle={textFieldTitle}
        setReadMode={() => setIsEditing(false)}
        agentTypes={agentTypes}
        regionTypes={regionTypes}
        saveData={saveData}
        id={id}
        isTouring={isTouring}
      />
    </div>
  ) : (
    <div style={{ position: 'relative' }} data-cy={cypressTags.PERSON.TOURING_AGENT.VIEW_MODE_CONTAINER}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <ReadView
        id={id}
        mergeCardData={mergeCardData}
        territoriesData={territoriesData}
        title={title}
        textFieldTitle={textFieldTitle}
        setEditMode={() => setIsEditing(true)}
        isTouring={isTouring}
        blockEdit={blockEdit}
        touringAgentBlocks={touringAgentBlocks}
        isClientComedy={isClientComedy}
      />{' '}
    </div>
  );
};

const ReadView = ({
  territoriesData,
  title,
  setEditMode,
  mergeCardData,
  blockEdit,
  touringAgentBlocks,
  isClientComedy,
}) => {
  const style = mergeCardData ? { background: '#DFDFDB', height: '30px', padding: '15px' } : {};
  const cardTitle = (!mergeCardData || mergeCardData?.primary) && title;
  const canEdit = (!blockEdit && !mergeCardData) || mergeCardData?.primary;

  return (
    <CardPane name={mergeCardData?.name} mergeCardData={mergeCardData}>
      <CardHeader isEditing={false} style={style}>
        <CardTitle>{cardTitle}</CardTitle>
        {canEdit && <CardButton type={buttonTypes.edit} onClick={setEditMode} />}
      </CardHeader>
      <CardBody>
        {touringAgentBlocks && (
          <TouringAgentTable territoriesData={territoriesData} textFieldTitle={title} isClientComedy={isClientComedy} />
        )}
      </CardBody>
    </CardPane>
  );
};

export const HeaderButtonsContainer = styled.div`
  float: right;
`;

export const TerritoriesSectionContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
  padding: 20px 28px 28px 28px;
`;
