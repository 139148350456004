import React from 'react';
import CreatableSelectDropdown from '../creatable-select-dropdown/CreatableSelectDropdown';

const NEW_COMPANY_ID = `NEW-ITEM-${Date.now()}`;

export const handleOnChangeAgency = (selectedValue, actionType, onAgencyChanged) => {
  switch (actionType.action) {
    case 'select-option':
      onAgencyChanged(selectedValue);
      break;
    case 'create-option':
      onAgencyChanged({ company: { _id: NEW_COMPANY_ID, name: selectedValue.value } });
      break;
    case 'clear':
      onAgencyChanged({});
      break;
    default:
      break;
  }
};

const AgencyEditor = (props) => {
  const { agency = {}, searchGroups, onAgencyChanged, readOnly, cyTag = '', dropdownCyTag = '' } = props;

  const getAgencies = (inputValue, callback) => {
    if (!inputValue) {
      return callback([]);
    }
    searchGroups(inputValue).then((results) => {
      const menuOptions = (results.data || []).map((agency) => ({
        value: agency.name,
        label: agency.name,
        company: agency,
      }));
      return callback(menuOptions);
    });
  };

  return (
    <div id="creatable-select" style={{ marginBottom: 10, width: '100%', marginTop: '4px' }} data-cy={cyTag}>
      <CreatableSelectDropdown
        title={'Company'}
        loadable
        loadOptions={getAgencies}
        defaultValue={
          agency.company && agency.company.name && agency.company.name !== 'Invalid Data'
            ? { value: agency.company.name, label: agency.company.name }
            : null
        }
        onChange={(selectedValue, actionType) => {
          handleOnChangeAgency(selectedValue, actionType, onAgencyChanged);
        }}
        readOnly={readOnly}
        matchLegacyStyle
        cyTag={dropdownCyTag}
      />
      <span
        className="invalid-company-warning"
        style={{
          color: 'red',
          fontSize: '12px',
          display: agency.company && agency.company.name === 'Invalid Data' ? 'block' : 'none',
        }}
      >
        Invalid Company
      </span>
    </div>
  );
};

export default AgencyEditor;
