import React, { useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import {
  getInternalTeamCompanyRelationships,
  updateInternalTeamCompanyRelationships,
} from '@united-talent-agency/julius-frontend-store';

import InternalTeamUTAEmployeeRelationships from '../../../../components/internal-team-uta-employee-relationships/uta-employee-relationships';

import search from '../../../../support/algolia';

const loadInternalTeamPeople = (dispatch, companyId) => {
  return dispatch(getInternalTeamCompanyRelationships({ companyId })).then((result) => {
    let relationships = Array.isArray(result?.body) ? result.body : [];
    return relationships
      .filter((relation) => {
        return relation && relation.utaEmployee;
      })
      .sort((a, b) => a?.utaEmployee.name.localeCompare(b.utaEmployee.name));
  });
};

const TeamView = ({
  dispatch,
  entity,
  onSave,
  mergeCardData,
  setTeamInfo,
  returnRelationsList,
  blockEdit,
  onChange,
  refreshProfile,
  cyTag,
}) => {
  const [personRelationships, setPersonRelationships] = useState([]);
  const companyId = entity._id;
  useEffect(() => {
    if (!companyId) {
      return;
    }
    loadInternalTeamPeople(dispatch, companyId).then((relationships) => {
      setPersonRelationships(relationships);
      (mergeCardData || returnRelationsList) && setTeamInfo({ rels: relationships });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, dispatch]);

  return (
    <InternalTeamUTAEmployeeRelationships
      entity={entity}
      cyTag={cyTag}
      mergeCardData={mergeCardData}
      blockEdit={blockEdit}
      onChange={onChange}
      onSave={({ creates, deletes, updates }) => {
        if (!creates && !deletes && !updates) {
          //nothing to do, short-circuit
          return;
        }
        if ((creates || []).some((create) => create.error) || (updates || []).some((update) => update.error)) {
          notify.show(`Submission Errors: Canceling Save`, 'warning');
          return;
        }
        const resultStatusValidator = (result) => {
          if (result.status !== 200 && result.status !== 201) {
            return Promise.reject({ message: `Status ${result.status}` });
          }
          return Promise.resolve();
        };

        return dispatch(updateInternalTeamCompanyRelationships({ companyId, creates, updates, deletes }))
          .then(resultStatusValidator)
          .then(() => {
            notify.show('Internal Team People Saved', 'success');
          })
          .catch((error) => {
            const errorMessage = error.message;
            notify.show(`Error Saving: ${errorMessage}`, 'error');
          })
          .then(() => {
            loadInternalTeamPeople(dispatch, companyId).then((relationships) => {
              setPersonRelationships(relationships);
            });
          })
          .then(onSave && onSave())
          .then(refreshProfile && refreshProfile);
      }}
      dispatch={dispatch}
      relationships={personRelationships}
      onSearchPerson={async (searchText) => {
        const data = await search({
          query: searchText,
          filtersState: {
            includeEmployee: true,
            includeIndustry: true,
          },
          searchIndex: process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX,
          hitsPerPage: 20,
        });

        return data.hits;
      }}
    />
  );
};

export default TeamView;
