import { useLaunchDarklyCustomHook } from '../../support/hooks';
const LaunchDarklyWrapper = ({ render }) => {
  const [flags] = useLaunchDarklyCustomHook();

  // Uses the render prop called render that will expose the value and
  // setter for the custom hook
  return render(flags);
};

export default LaunchDarklyWrapper;
