import React, { useState, useEffect } from 'react';
import { notify } from 'react-notify-toast';
import {
  getInternalTeamCompanyRelationships,
  updateInternalTeamCompanyRelationships,
  getInternalTeamPersonRelationships,
  updateInternalTeamPersonRelationships,
} from '@united-talent-agency/julius-frontend-store';

import InternalTeamEntityRelationships from './entity-relationships';
import InternalTeamUTAEmployeeRelationships from '../../../../components/internal-team-uta-employee-relationships/uta-employee-relationships';
import { getGroups } from '../../../../api/groups';

import search from '../../../../support/algolia';
import { getFiltersFromTypes } from '../../../search/utils';

const loadInternalTeamEntities = (dispatch, personId) => {
  const getGroups = dispatch(getInternalTeamCompanyRelationships({ employeeId: personId })).then((result) => {
    const rels = Array.isArray(result.body) ? result.body : [];
    return rels;
  });
  const getPeople = dispatch(getInternalTeamPersonRelationships({ employeeId: personId })).then((result) => {
    const rels = Array.isArray(result.body) ? result.body : [];
    return rels;
  });
  return Promise.all([getPeople, getGroups]).then((results) => {
    let relationships = results[0].concat(results[1]);
    relationships = relationships.filter((relation) => {
      return relation && (relation.group || relation.person);
    });
    relationships.sort((a, b) => {
      const aName = a.group ? a.group.name : a.person.name;
      const bName = b.group ? b.group.name : b.person.name;
      return aName.localeCompare(bName);
    });
    return relationships;
  });
};

const sortUtaEmployees = (employees) => {
  return employees.sort((a, b) => {
    const aName = a.utaEmployee.name.toString().trim().split(' ');
    const bName = b.utaEmployee.name.toString().trim().split(' ');
    const aLastName = aName.length > 1 ? aName.pop() : aName[0];
    const bLastName = bName.length > 1 ? bName.pop() : bName[0];

    return aLastName.localeCompare(bLastName);
  });
};

const loadInternalTeamEmployees = (dispatch, personId) => {
  return dispatch(getInternalTeamPersonRelationships({ personId })).then((result) => {
    let relationships = Array.isArray(result.body) ? result.body : [];
    relationships = relationships.filter((relation) => {
      return relation && relation.utaEmployee;
    });

    const pointAgents = sortUtaEmployees(relationships.filter((rel) => rel.type === 'Point Agent'));
    const agents = sortUtaEmployees(relationships.filter((rel) => rel.type === 'Agent'));
    const hasRelationshipWith = sortUtaEmployees(relationships.filter((rel) => rel.type === 'Has Relationship With'));
    const rest = sortUtaEmployees(
      relationships.filter((rel) => !['Point Agent', 'Agent', 'Has Relationship With'].includes(rel.type))
    );

    const sortedRelationships = [...pointAgents, ...agents, ...rest, ...hasRelationshipWith];

    return sortedRelationships;
  });
};

const InternalTeamView = ({
  dispatch,
  entity,
  onSave,
  mergeCardData,
  setUtaRelInfo,
  returnRelationsList,
  blockEdit,
  onChange,
  refreshProfile,
  cyTag,
}) => {
  const personId = entity._id;
  const personType = entity.type;
  const [entityRelationships, setEntityRelationships] = useState([]);
  const [employeeRelationships, setEmployeeRelationships] = useState([]);

  useEffect(() => {
    if (!personId) {
      return;
    }
    if (personType === 'Employee') {
      loadInternalTeamEntities(dispatch, personId).then((relationships) => {
        setEntityRelationships(relationships);
        (mergeCardData || returnRelationsList) && setUtaRelInfo(relationships);
      });
    } else {
      loadInternalTeamEmployees(dispatch, personId).then((relationships) => {
        setEmployeeRelationships(relationships);
        (mergeCardData || returnRelationsList) && setUtaRelInfo(relationships);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId, personType, dispatch]);
  return (
    <>
      {entity.type === 'Employee' && (
        <InternalTeamEntityRelationships
          mergeCardData={mergeCardData}
          personId={personId}
          blockEdit={true}
          cyTag={cyTag}
          onSave={({ personRelationships, groupRelationships }) => {
            const hasError = (obj) => !!obj.error;
            if (
              personRelationships.creates.some(hasError) ||
              personRelationships.updates.some(hasError) ||
              groupRelationships.creates.some(hasError) ||
              groupRelationships.updates.some(hasError)
            ) {
              notify.show(`Submission Errors: Canceling Save`, 'warning');
              return;
            }

            const resultStatusValidator = (result) => {
              if (result.status !== 200 && result.status !== 201) {
                return Promise.reject({ message: `Status ${result.status}` });
              }
              return Promise.resolve();
            };

            const personRelationshipUpdater = ({ creates, updates, deletes }) => {
              if (!creates && !deletes && !updates) {
                //nothing to do, short-circuit
                return Promise.resolve();
              }
              return dispatch(
                updateInternalTeamPersonRelationships({ employeeId: personId, creates, updates, deletes })
              ).then(resultStatusValidator);
            };
            const updatePersonRelationships = personRelationshipUpdater(personRelationships);

            const groupRelationshipUpdater = ({ creates, updates, deletes }) => {
              if (!creates && !deletes && !updates) {
                //nothing to do, short-circuit
                return Promise.resolve();
              }
              return dispatch(
                updateInternalTeamCompanyRelationships({ employeeId: personId, creates, updates, deletes })
              ).then(resultStatusValidator);
            };
            const updateGroupRelationships = groupRelationshipUpdater(groupRelationships);

            return Promise.all([updatePersonRelationships, updateGroupRelationships])
              .then(() => {
                notify.show('Internal Team People Saved', 'success');
              })
              .catch((error) => {
                const errorMessage = error.message;
                notify.show(`Error Saving: ${errorMessage}`, 'error');
              })
              .then(() => {
                loadInternalTeamEntities(dispatch, personId).then((relationships) => {
                  setEntityRelationships(relationships);
                });
              })
              .then(() => {
                onSave && onSave();
              })
              .then(refreshProfile && refreshProfile());
          }}
          dispatch={dispatch}
          relationships={entityRelationships}
          onSearchEntity={async (searchText, entityTypes) => {
            const searchPeople = search({
              query: searchText,
              filtersState: getFiltersFromTypes(entityTypes),
              searchIndex: process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX,
              hitsPerPage: 20,
            }).then((res) => {
              return res.hits.map((c) => {
                return { _id: c._id, type: c.type, name: c.name, entityType: 'person' };
              });
            });

            const response = await getGroups(searchText);
            const searchResults = (response && response.data) || [];
            const searchGroups = searchResults
              ? searchResults
                  .filter((group) => {
                    return entityTypes.some((groupType) => {
                      return group.type === groupType;
                    });
                  })
                  .map((group) => {
                    return { _id: group._id, type: group.type, name: group.name, entityType: 'group' };
                  })
              : [];
            return Promise.all([searchPeople, searchGroups]).then((results) => {
              const combinedResults = (results[0] || []).concat(results[1] || []);
              combinedResults.sort((a, b) => a.name.localeCompare(b.name.localeCompare));
              return combinedResults;
            });
          }}
        />
      )}
      {entity.type !== 'Employee' && (
        <InternalTeamUTAEmployeeRelationships
          mergeCardData={mergeCardData}
          onChange={onChange}
          personId={personId}
          blockEdit={blockEdit}
          entity={entity}
          cyTag={cyTag}
          onSave={({ creates, deletes, updates }) => {
            if (!creates && !deletes && !updates) {
              //nothing to do, short-circuit
              return;
            }
            if ((creates || []).some((create) => create.error) || (updates || []).some((update) => update.error)) {
              notify.show(`Submission Errors: Canceling Save`, 'warning');
              return;
            }
            const resultStatusValidator = (result) => {
              if (result.status !== 200 && result.status !== 201) {
                return Promise.reject({ message: `Status ${result.status}` });
              }
              return Promise.resolve();
            };

            return dispatch(updateInternalTeamPersonRelationships({ personId, creates, updates, deletes }))
              .then(resultStatusValidator)
              .then(() => {
                notify.show('Internal Team Employees Saved', 'success');
              })
              .catch((error) => {
                const errorMessage = error.message;
                notify.show(`Error Saving: ${errorMessage}`, 'error');
              })
              .then(() => {
                loadInternalTeamEmployees(dispatch, personId).then((relationships) => {
                  setEmployeeRelationships(relationships);
                });
              })
              .then(() => {
                onSave && onSave();
              });
          }}
          dispatch={dispatch}
          relationships={employeeRelationships}
          onSearchPerson={async (searchText) => {
            const data = await search({
              query: searchText,
              filtersState: {
                includeEmployee: true,
              },
              searchIndex: process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX,
            });

            return data.hits;
          }}
        />
      )}
    </>
  );
};

export default InternalTeamView;
