import {
  getInternalTeamPersonRelationships,
  getInternalTeamCompanyRelationships,
} from '@united-talent-agency/julius-frontend-store';

export async function getPersonPrivateContactsPermission(dispatch, desks, personId) {
  if (!personId) return false;
  const getInternalTeamRealtionships = getInternalTeamPersonRelationships({ personId: personId });
  const hasAuthorization = getPrivateContactsPermission(dispatch, desks, personId, getInternalTeamRealtionships);
  return hasAuthorization;
}

export async function getCompanyPrivateContactsPermission(dispatch, desks, companyId) {
  if (!companyId) return false;
  const getInternalTeamRealtionships = getInternalTeamCompanyRelationships({ companyId: companyId });
  const hasAuthorization = getPrivateContactsPermission(dispatch, desks, companyId, getInternalTeamRealtionships);
  return hasAuthorization;
}

export async function getPrivateContactsPermission(dispatch, desks = [], id, getInternalTeamRealtionships) {
  const internalTeamIds = await dispatch(getInternalTeamRealtionships).then(
    (results) => results?.body && Array.isArray(results?.body) && results.body.map((result) => result.utaEmployee?._id)
  );
  const agentIds = desks.reduce((agentIds, desk) => {
    if (desk.agentId && desk.agentId._id) agentIds.push(desk.agentId._id);
    return agentIds;
  }, []);
  const hasAuthorization = agentIds.filter((agentId) => internalTeamIds.includes(agentId)).length > 0;
  return hasAuthorization;
}
