import React from 'react';
import {
  Tooltip,
  Select,
  colors,
  ContactType,
  ContactTypeNames,
  ContactTypeIcons,
  fromStringToE164,
  fromE164ToDisplay,
} from '@united-talent-agency/components';
import { styled } from 'react-free-style';

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);
export const PrivateContactTooltipMessage = 'Please reach out to the internal team for contact details.';
export const DefaultLockedTooltipMessage = 'Please reach out to the internal team to make changes.';

const ContactTypeSelector = (props) => {
  const {
    onChange,
    onClose,
    value,
    existing = [],
    textLabels,
    styles,
    types = Object.keys(ContactTypeNames),
    isOpen, //just for testing
    disabled,
    cypressTags,
    inline,
    dropdownOnly,
  } = props;

  const values = types.map((key) => {
    return { icon: <ContactType type={key} />, contactType: key };
  });

  const closeAddContact = () => {
    onClose && onClose();
  };

  //merge in any existing contacts with the default list
  existing &&
    existing.reduce((values, item) => {
      const existingIndex = values.findIndex((v) => {
        return item.contactType === v.contactType;
      });

      const itemValue = /Phone|Fax Number/.test(item.contactType)
        ? fromE164ToDisplay(fromStringToE164(item.value || ''))
        : item.value;
      const displayValue = item.private ? 'Private' : itemValue;

      if (existingIndex === -1) {
        const icon = <ContactType type="Unknown" />;
        values.push({
          _id: item._id,
          icon,
          contactType: item.contactType,
          value: item.value,
          primary: item.primary,
        });
      } else if (!values[existingIndex].value) {
        values[existingIndex]._id = item._id;
        values[existingIndex].value = displayValue;
        values[existingIndex].primary = item.primary;
      } else {
        const icon = <ContactType type={item.contactType} />;
        values.splice(existingIndex + 1, 0, {
          _id: item._id,
          icon,
          contactType: item.contactType || 'Unknown',
          value: displayValue,
          primary: item.primary, // added primary
        });
      }
      return values;
    }, values);

  const contactNameKeys = Object.keys(ContactTypeNames);

  const dropDownItems = values
    .filter((contact) => {
      return contact.value;
    })
    .sort((a, b) => {
      const aIndex = contactNameKeys.indexOf(a.contactType);
      const bIndex = contactNameKeys.indexOf(b.contactType);
      if (a.primary && b.primary) {
        return aIndex - bIndex;
      } else if (a.primary && !b.primary) {
        return -1;
      } else if (!a.primary && b.primary) {
        return 1;
      } else {
        return aIndex - bIndex;
      }
    })
    .map((contact, index) => {
      const privateContact = contact.value === 'Private';
      return {
        key: index,
        primary: contact.primary, // this property is only for sorting
        contactType: contact.contactType, // this property is only for sorting
        content: !textLabels ? (
          <ConditionalWrapper
            condition={privateContact}
            wrapper={(children) => (
              <Tooltip id="private-contact-tooltip" text={PrivateContactTooltipMessage}>
                {children}
              </Tooltip>
            )}
          >
            <div
              data-cy={cypressTags?.existingContact}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: 10,
              }}
            >
              <span style={{ width: 20 }}>{contact.icon}</span>
              <span
                id="contactType"
                style={{
                  marginLeft: 10,
                  color: privateContact ? colors.disabledGrey : colors.utaBlack,
                  fontWeight: 400,
                }}
              >
                {ContactTypeNames[contact.contactType]}
              </span>
              <span
                id="contactPrimary"
                style={{
                  marginLeft: 5,
                  color: privateContact ? colors.disabledGrey : colors.utaBlack,
                  fontWeight: 700,
                }}
              >
                {contact.primary ? '(Primary)' : undefined}
              </span>
              <span
                id="contactValue"
                style={{
                  textAlign: 'right',
                  marginLeft: 4,
                  marginRight: 4,
                  color: privateContact ? colors.disabledGrey : '#6B6A69',
                  width: '100%',
                }}
              >
                {contact.value}
              </span>
            </div>
          </ConditionalWrapper>
        ) : (
          <div>{ContactTypeNames[contact.contactType]}</div>
        ),
        onClick: privateContact
          ? () => closeAddContact()
          : () => {
              onChange && onChange(contact);
              closeAddContact();
            },
        disabled: privateContact,
      };
    });

  const contactTypes = contactNameKeys
    .filter((contactName) => contactName !== 'Unknown')
    .map((contactName) => {
      return {
        key: contactName,
        onClick: () => {
          onChange && onChange({ contactType: contactName });
          closeAddContact();
        },
        content: (
          <div
            style={{
              display: 'flex',
              paddingLeft: 10,
            }}
            data-cy={cypressTags?.contactType}
          >
            <span style={{ width: 20, fontSize: 12 }}>{ContactTypeIcons[contactName]}</span>
            <span
              id="contactType"
              style={{
                display: 'flex',
                fontSize: 12,
                justifyContent: 'space-between',
                paddingLeft: 10,
              }}
            >
              {ContactTypeNames[contactName]}
            </span>
          </div>
        ),
      };
    });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
      }}
    >
      <Select
        id="select"
        disabled={disabled}
        isOpen={isOpen} //just for testing
        styles={textLabels && styles}
        title="Type"
        fixedMenuSize={!!textLabels}
        items={dropDownItems.concat(contactTypes)}
        chevronDisabled={disabled}
        dropdownStyle={dropdownOnly ? { marginTop: 1 } : inline ? { position: 'absolute', top: 33 } : {}}
        scrollMenu={false}
        onClickOutside={() => closeAddContact()}
        inline={inline}
        dropdownOnly={dropdownOnly}
      >
        <div style={{ whiteSpace: 'nowrap' }}>
          <span style={{ paddingRight: 4, color: disabled ? colors.disabledGrey : colors.utaBlack }}>
            {value &&
              (textLabels
                ? ContactTypeNames[value.contactType]
                : value.icon || <ContactType type={value.contactType} />)}
          </span>
        </div>
      </Select>
    </div>
  );
};
const withStyles = styled({ select: { width: '100%', position: 'relative' } });
export default withStyles(ContactTypeSelector);
