import React, { useContext, useEffect, useState } from 'react';
import _, { isEmpty, isString } from 'lodash';

import { Info, Input, Select, Multiselect } from '@united-talent-agency/julius-frontend-components';

import Card from '../../../../components/card/Card';
import { InfoContainer, SpacedInfoContainer } from '../../../../styles/components/components';
import { Error } from '../../../company/views/profile/BasicInfoCard';
import { FormContext } from '../../../../support/FormContext';
import { updateVenue } from '../../../../api/venues';
import CommonTooltip from '../../../../components/common/tooltip';

import cypressTags from '../../../../support/cypressTags';
import { TOOLTIP_MESSAGES } from '../../../../components/common/messages/tooltip-messages';

const SEATING_TYPES = ['Seated', 'Standing Room', 'Mixed Seating'];

export const VENUE_TYPES = [
  'Amphitheater',
  'Arena',
  'Casino',
  'Club',
  'College',
  'Comedy Club',
  'Cruise Ship',
  'Hotel',
  'Nightclub',
  'Non-Traditional',
  'Outdoor',
  'Private Event Space',
  'Stadium',
  'Theater/PAC',
];

const BasicInfoCard = ({ venue, onChange, blockEdit, cyTag }) => {
  const onSave = (saveInfo = {}) => {
    const { updates = {} } = saveInfo;
    const venueId = venue._id;
    const pertinentUpdates = updates[venueId] || {};
    if (isEmpty(pertinentUpdates)) {
      // nothing to do, time to die
      return new Promise(() => {});
    }

    let seatingType = isString(pertinentUpdates.seatingType) ? pertinentUpdates.seatingType.split(',') : undefined;
    // filter out seating types that are not in the list of valid seating types
    seatingType = seatingType?.filter((type) => SEATING_TYPES.includes(type));
    const finalUpdates = {
      name: pertinentUpdates.name,
      seatingType,
      venueType: pertinentUpdates.venueType,
    };
    const updatedVenue = { venueId, updates: { ...finalUpdates } };
    return updateVenue(updatedVenue);
  };

  const requiredFieldCheck = (venue = {}) => {
    const { venueType } = venue;
    return venue.name && venueType;
  };

  return (
    <div data-cy={cyTag} style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        title="Basic Info"
        data={venue}
        readView={readView}
        editView={!blockEdit && editView}
        onSave={onSave}
        onChange={onChange}
        onValidateItem={requiredFieldCheck}
      />{' '}
    </div>
  );
};

export const venueProfileTypes = [
  { key: 'Industry Contact', content: 'Industry Contact' },
  { key: 'Client', content: 'Client' },
];

const readView = ({ item: venue }) => {
  const { seatingType = [], name, venueType } = venue;
  const displayedSeatingType = Array.isArray(seatingType) ? seatingType.join(', ') : seatingType;

  return (
    <InfoContainer>
      <Info name="Name" isRequired>
        {name}
      </Info>
      <Info isRequired name="Venue Type">
        {venueType}
      </Info>
      <Info name="Seating Type">{displayedSeatingType}</Info>
    </InfoContainer>
  );
};

const editView = ({ item: venue, setState }) => {
  return <BasicInfoEditor venue={venue} setState={setState} />;
};

const BasicInfoEditor = ({ venue, setState }) => {
  const { venueType, seatingType } = venue;
  const [fieldVisited, setFieldVisited] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const { showAllValidationErrors } = useContext(FormContext);

  useEffect(() => {
    const newErrors = {};

    if (!venue || !venue.venueType) {
      newErrors['venueType'] = 'Venue type is required';
    }
    if (!venue.name) {
      newErrors['name'] = 'Venue name is required';
    }

    if (!_.isEqual(fieldErrors, newErrors)) {
      setFieldErrors(newErrors);
    }
  }, [venue, fieldErrors]);

  const venueTypeOptions = VENUE_TYPES.map((venueType) => ({
    key: venueType,
    content: venueType,
    active: false,
    onClick: () => setState({ ...venue, venueType }),
  }));

  const seatingTypeOptions = SEATING_TYPES.map((seatingType) => ({
    label: seatingType,
    value: seatingType,
  }));

  return (
    <SpacedInfoContainer>
      <Input
        isRequired
        type="text"
        title="Name"
        cyTag={cypressTags.VENUE.NAME_INPUT}
        value={venue.name || ''}
        onChange={(name) => setState({ name })}
        onBlur={() => setFieldVisited({ ...fieldVisited, name: true })}
      />
      {fieldErrors.name && (fieldVisited.name || showAllValidationErrors) && <Error msg={fieldErrors.name} />}

      <Select isRequired title="Venue Type" items={venueTypeOptions} optionStyle={{ padding: 10 }}>
        {venueType}
      </Select>
      {fieldErrors.venueType && (fieldVisited.venueType || showAllValidationErrors) && (
        <Error msg={fieldErrors.venueType} />
      )}
      <Multiselect
        value={seatingType}
        placeholder={'Seating Type'}
        options={seatingTypeOptions}
        onChange={(seatingType) => setState({ ...venue, seatingType })}
      />
      {fieldErrors.seatingType && (fieldVisited.seatingType || showAllValidationErrors) && (
        <Error msg={fieldErrors.seatingType} />
      )}
    </SpacedInfoContainer>
  );
};

export default BasicInfoCard;
