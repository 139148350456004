import React, { useState } from 'react';
import { styled } from 'react-free-style';

import { PersonInput, Select, icons } from '@united-talent-agency/julius-frontend-components';

const EntityRelationship = ({
  relationship = {},
  onEntityChanged,
  onRelationshipTypeChanged,
  onSearch,
  relationshipTypes = [],
  onDelete,
  onValidate,
  styles,
}) => {
  const [error, setError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [entityResults, setEntityResults] = useState([]);
  const relationshipTypeOptions = relationshipTypes.map((relType) => ({
    key: relType,
    content: relType,
    active: relType === relationship.type,
    styles: { padding: 10 },
    onClick: () => {
      setEntityResults([]);
      onRelationshipTypeChanged && onRelationshipTypeChanged(relType);
      onValidate && setError(onValidate({ ...relationship, type: relType }));
    },
  }));
  const entity = relationship.group || relationship.person || {};
  return (
    <>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div style={{ flex: 0.5, paddingRight: 5, paddingBottom: 10 }}>
          <Select items={relationshipTypeOptions}>{relationship.type}</Select>
        </div>

        <div style={{ flex: 0.5, paddingLeft: 5, paddingBottom: 10 }}>
          <PersonInput
            style={{
              marginBottom: 10,
              fontWeight: 100,
            }}
            value={searchText || entity.name}
            onChange={(name) => {
              setSearchText(name);
              onSearch &&
                onSearch(name).then((people) => {
                  setEntityResults(people);
                });
            }}
            results={entityResults}
            onSelect={(entity) => {
              setSearchText('');
              onEntityChanged && onEntityChanged(entity);
              const update =
                entity.entityType === 'person' ? { person: entity, group: null } : { group: entity, person: null };
              onValidate && setError(onValidate({ ...relationship, ...update }));
            }}
            dropDownMode="floating"
          />
        </div>
        {onDelete && (
          <div style={{ paddingLeft: 10, paddingTop: 8, cursor: 'pointer' }} onClick={() => onDelete()}>
            <i className={styles.deleteIcon} />
          </div>
        )}
      </div>
      {error && <div className={styles.errorPanel}>{error}</div>}
    </>
  );
};

const withStyles = styled({
  menuItem: {
    padding: 10,
  },
  delete: {
    padding: '10px 0',
  },
  deleteIcon: icons.cross,
  errorPanel: {
    fontSize: 14,
    marginLeft: 0,
    marginRight: 20,
    marginTop: -10,
    color: 'red',
  },
});

export default withStyles(EntityRelationship);
