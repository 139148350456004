import React, { useEffect, useState } from 'react';
import { notify } from 'react-notify-toast';
import { getGroupMembership, updateGroupMembership } from '@united-talent-agency/julius-frontend-store';

import EmployerEditor from './employer-editor';
import EmployerLabel from './employer-label';
import Card from '../card/Card';
import CommonTooltip from '../common/tooltip';

import cypressTags from '../../support/cypressTags';
import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export const CardEmployers = ({ person, searchGroups, dispatch, mergeCardData, blockEdit }) => {
  const [employers, setEmployers] = useState([]);

  const retrieveEmployers = () => {
    person._id &&
      dispatch(getGroupMembership({ personId: person._id })).then((result) => {
        const response = Array.isArray((result || {}).body) ? result.body : [];
        let primary = null;
        response.forEach((group) => {
          if (group.primary) {
            primary = group;
          }
        });

        // sort the response array by group.name
        response.sort((a, b) => {
          if (a.group.name < b.group.name) {
            return -1;
          }
          if (a.group.name > b.group.name) {
            return 1;
          }
          return 0;
        });

        // put the primary group at the beginning of the array
        if (primary) {
          response.splice(response.indexOf(primary), 1);
          response.unshift(primary);
        }
        setEmployers(response);
      });
  };

  useEffect(retrieveEmployers, [person]);

  const onSave = async ({ updates, creates, deletes, mergedData }) => {
    try {
      const groups = {
        updates: mergedData.filter(({ _id }) => updates[_id]),
        creates: creates,
        deletes: Object.keys(deletes),
      };

      await dispatch(updateGroupMembership({ personId: person._id, groups }));
    } catch (error) {
      const errorMessage = error.message;
      notify.show(`Error Saving: ${errorMessage}`, 'error');
    }
    retrieveEmployers(true);
  };
  const isEmployee = (person || {}).type === 'Employee';
  const showEmployers = !mergeCardData || !mergeCardData.isEmpty || employers.length > 0;
  return !showEmployers ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }} data-cy={cypressTags.PERSON.EMPLOYER_CARD.CONTAINER}>
      {isEmployee && (
        <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_HR} cyTag={cypressTags.PERSON.EMPLOYER_CARD.LOCK_BUTTON} />
      )}
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        mergeCardData={mergeCardData}
        title="Employers"
        data={employers}
        readView={readView}
        editView={!isEmployee && !blockEdit && ((props) => editView(searchGroups, props))}
        onSave={onSave}
        createNew={() => ({ group: { name: '' } })}
        canDelete
        canSetPrimary
        firstIsPrimary
        onValidateItem={onValidateItem}
      />
    </div>
  );
};

const readView = ({ item: employer }) => <EmployerLabel value={employer} />;

const editView = (searchGroups, { item: employer, setState }) => {
  return (
    <EmployerEditor
      employer={employer}
      searchGroups={searchGroups}
      onEmployerChanged={(updates) => {
        if ((updates.group && updates.group.name) || updates.title) {
          setState(updates);
        }
      }}
    />
  );
};

const onValidateItem = (employer) => {
  return employer.group && employer.group._id;
};
