import React from 'react';
import { styled } from 'react-free-style';
import {
  fromStringToE164,
  fromE164ToDisplay,
  validatePhoneInput,
  validateEmailInput,
} from '@united-talent-agency/components';
import { link } from '../../styles/elements';
import { phoneOrFaxFilter } from '../../support/contacts';
import { LockIcon } from '../contacts-card/LockIcon';
import cypressTags from '../../support/cypressTags';

export const STYLES = {
  contactLink: link,
  contactLabelContainer: { textAlign: 'right', fontSize: '12px' },
  contactTypeInfo: { float: 'left', fontWeight: 300, color: '#4A4A4A', display: 'flex' },
  primaryIndicator: { fontWeight: 700 },
  typeIndicator: { marginRight: '3px' },
  linkContainer: { marginLeft: 'auto' },
  divider: { marginTop: 10, marginBottom: 10 },
};

const ContactLabel = ({ value, hasPrivateContactsPermissions, styles = {} }) => {
  const userRegion = navigator.language.split('-')[1];
  const displayValue = _determineDisplayValue(value, hasPrivateContactsPermissions, userRegion);
  const telLink = _determineLink(value, hasPrivateContactsPermissions, phoneOrFaxFilter);
  const emailLink = _determineLink(value, hasPrivateContactsPermissions, /Email/);
  const contactTypeInfo = value.description || value.contactType;
  const showLock = value.isPrivate && !value.primary && !hasPrivateContactsPermissions;

  return (
    <div className={styles.contactLabelContainer} data-cy={cypressTags.PERSON.CONTACTS_CARDS.READ_VIEW_CONTAINER}>
      <div className={styles.contactTypeInfo}>
        <div className={styles.typeIndicator}>{contactTypeInfo}</div>
        {showLock && <LockIcon isPrivate={true} />}
        {value.primary && <div className={styles.primaryIndicator}>(Primary)</div>}
      </div>
      <div className={styles.linkContainer}>
        {displayValue === 'Invalid Data' ? (
          <div className={styles.contactLink} style={{ textDecoration: 'none' }}>
            <span className="invalid-data-warning">{displayValue}</span>
          </div>
        ) : (
          <>
            {telLink && (
              <a href={`tel:${telLink}`} className={styles.contactLink}>
                {displayValue}
              </a>
            )}
            {emailLink && (
              <a href={`mailto:${emailLink}`} className={styles.contactLink}>
                {displayValue}
              </a>
            )}
            {!telLink && !emailLink && displayValue}
          </>
        )}
      </div>
      <hr className={styles.divider} />
    </div>
  );
};

const isEmployeeContact = (contact) => {
  const employeeContactRegex = /;[0-9]{4}/;
  return employeeContactRegex.test(contact);
};

const _determinePhoneDisplayValue = (value, userRegion) => {
  return validatePhoneInput(value.contact) || isEmployeeContact(value.contact)
    ? fromE164ToDisplay(fromStringToE164(value.contact), userRegion, ' x')
    : 'Invalid Data';
};

const _determineDisplayValue = (value, hasPrivateContactsPermissions, userRegion) => {
  if (value && value.isPrivate && !hasPrivateContactsPermissions) return 'Private';
  if (value && value.contact && phoneOrFaxFilter.test(value.contactType)) {
    return _determinePhoneDisplayValue(value, userRegion);
  }
  switch (value.contactType) {
    case 'Email':
    case 'Personal Email':
    case 'Assistant Email':
      return validateEmailInput(value.contact) ? value.contact : 'Invalid Data';
    default:
      return value.contact || 'Invalid Data';
  }
};

const _determineLink = (value, hasPrivateContactsPermissions, typeTest) => {
  if (value && value.isPrivate && !hasPrivateContactsPermissions) return null;
  return value && value.contact && typeTest.test(value.contactType) ? value.contact : null;
};

const withStyles = styled(STYLES);
export default withStyles(ContactLabel);
