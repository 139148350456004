export const genders = ['Female', 'Male', 'Non-Binary'];

export const hairColors = [
  'Auburn',
  'Bald',
  'Black',
  'Blonde',
  'Blonde - Ash',
  'Blonde - Dark',
  'Blonde - Light',
  'Blonde - Platinum',
  'Blonde - Strawberry',
  'Blue',
  'Brown',
  'Brown - Dark',
  'Brown - Light',
  'Brown - Strawberry',
  'Green',
  'Grey',
  'Pink',
  'Red',
  'Salt & Pepper',
  'Shaved',
  'White',
];

export const languages = [
  'English',
  'Arabic',
  'Bengali',
  'Bulgarian',
  'Chinese',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'Estonian',
  'Filipino',
  'Finnish',
  'French',
  'German',
  'Greek',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Ilocano',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Korean',
  'Malay',
  'Mandarin',
  'Norwegian',
  'Persian',
  'Polish',
  'Portuguese',
  'Romanian',
  'Russian',
  'Serbian',
  'Somali',
  'Spanish',
  'Swedish',
  'Thai',
  'Turkish',
  'Ukrainian',
  'Urdu',
  'Vietnamese',
  'Welsh',
];

export const personalInterests = [
  'Arts and Crafts',
  'Aviation',
  'Baking',
  'Camping',
  'Car Racing',
  'Cooking',
  'Dance',
  'Fitness',
  'Gaming',
  'Gardening',
  'Photography',
  'Traveling',
];

export const selectableEthnicities = [
  'Asian',
  'Black',
  'Hispanic / Latino',
  'Middle Eastern',
  'Native American',
  'Southeast Asian',
  'White',
];

export const selectableNationalities = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Antiguans',
  'Argentinean',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Barbudans',
  'Batswana',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bhutanese',
  'Bolivian',
  'Bosnian',
  'Brazilian',
  'British',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comoran',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Djibouti',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirian',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Greek',
  'Grenadian',
  'Guatemalan',
  'Guinea-Bissauan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Herzegovinian',
  'Honduran',
  'Hungarian',
  'I-Kiribati',
  'Icelander',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakhstani',
  'Kenyan',
  'Kittian and Nevisian',
  'Kuwaiti',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourger',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivan',
  'Malian',
  'Maltese',
  'Marshallese',
  'Mauritanian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Moroccan',
  'Mosotho',
  'Motswana',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'Netherlander',
  'New Zealander',
  'Ni-Vanuatu',
  'Nicaraguan',
  'Nigerian',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Polish',
  'Portuguese',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Salvadoran',
  'Samoan',
  'San Marinese',
  'Sao Tomean',
  'Saudi',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Slovakian',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamer',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian or Tobagonian',
  'Tunisian',
  'Turkish',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbekistani',
  'Venezuelan',
  'Vietnamese',
  'Welsh',
  'Yemenite',
  'Zambian',
  'Zimbabwean',
];

export const iqSources = [
  'Everipedia',
  'Fact Celeb',
  'Famous Birthdays',
  'Google Books',
  'IMDB/Studio System/Variety/Wikipedia',
  'LinkedIn',
  'Other Database',
  'Owned Facebook',
  'Owned Instagram',
  'Owned Twitter',
  'Owned Website',
  'Press',
  'Trade Magazines',
  'UTA Department Data',
];

export const personalRepresentationAreas = [
  'Alternative TV',
  'Asian Biz Dev',
  'Comedy Touring',
  'Culture and Leadership',
  'DBA',
  'Digital Talent',
  'Emerging Platforms',
  'ESports',
  'Events and Experiences',
  'EVO',
  'Fine Arts',
  'Heartland',
  'Independent Film',
  'Marketing',
  'Media Rights',
  'MP Lit',
  'Music',
  'News and Broadcasting',
  'Production',
  'Publishing',
  'Speakers',
  'Sports',
  'Talent',
  'Theater',
  'TV Lit',
  'Video Games',
];
