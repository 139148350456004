import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { TypeIcon, colors } from '@united-talent-agency/julius-frontend-components';
import { Icons, RadioButton, RadioGroup } from '@united-talent-agency/components';

const { UserIcon, OfficePhoneIcon } = Icons;

const entityTypeOptions = new Map([
  ['person', { Icon: UserIcon }],
  ['group', { Icon: OfficePhoneIcon }],
]);
export const ContactName = ({ person, styles }) => {
  const { name } = person;

  const isMasterData = true; //more specifically, is not outlook
  const entityOptions = entityTypeOptions.get('person'); // entityTypeOptions.get(person.entityType)
  const EntityTypeIcon = entityOptions && entityOptions.Icon;
  return isMasterData ? (
    <span className={styles.linkContainer}>
      <div className={styles.name}>{person.name}</div>
      <EntityTypeIcon style={{ fontSize: 16, color: '#141414', top: 7, position: 'relative' }} />
    </span>
  ) : (
    name
  );
};

const MergeProfileTitle = ({ styles = {}, person, index, setPrimary }) => {
  return (
    <table className={styles.sticky}>
      <tbody>
        <tr className={styles.tr}>
          <td width={1} style={{ textAlign: 'left', paddingLeft: 16, paddingRight: 4 }}>
            <TypeIcon entity={person} customStyles={{ fontSize: 12, width: 20, height: 20 }} />
          </td>
          <td>
            <span className={styles.person}>
              <ContactName person={person} styles={styles} />
            </span>
          </td>
          <td>
            <RadioGroup name="primaryProfile" value={0}>
              <span style={{ float: 'right', marginRight: '5px' }}>
                <RadioButton
                  title="Primary"
                  leadingTitle
                  value={index}
                  style={{ top: '5px' }}
                  onChange={() => setPrimary(index)}
                  skipContextReset={true}
                />
              </span>
            </RadioGroup>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const withStyles = styled({
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 3,
  },
  linkContainer: { display: 'flex' },
  name: {
    fontSize: 18,
    fontWeight: 400,
    marginRight: 5,
  },
  tr: {
    '> td': {
      padding: '2px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: '11px',
      fontWeight: 300,
      color: colors.text,
      verticalAlign: 'middle',
    },
    display: 'table',
    width: '100%',
    backgroundColor: colors.contentBackground,
    marginBottom: '10px',
  },
  person: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    color: '#4A4A4A',
  },
});

ContactName.propTypes = {
  person: PropTypes.object,
  styles: PropTypes.object,
};

MergeProfileTitle.propTypes = {
  person: PropTypes.object,
  styles: PropTypes.object,
  expanded: PropTypes.bool,
  onExpandChanged: PropTypes.func,
};

export default withStyles(MergeProfileTitle);
