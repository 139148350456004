import React from 'react';

import { sectionData, TerritoryBox, TerritoryHeader, InfoLine } from './ReadViewSupport';

export const GuaranteeNamesTable = ({ territoriesData }) => {
  const sectionedData = sectionData(territoriesData);

  return (
    <>
      {Object.values(sectionedData)
        .flat()
        .filter((territoryData) => territoryData.territory === 'Default Guarantee')
        .map((territoryData, territoryIdx) => (
          <TerritoryBox key={territoryIdx}>
            <TerritoryHeader>{territoryData.territory}</TerritoryHeader>
            <InfoLine
              label={territoryData.territory}
              isPrimary={false}
              minimum={territoryData?.minimum}
              maximum={territoryData?.maximum}
            />
          </TerritoryBox>
        ))}
      {Object.values(sectionedData)
        .flat()
        .filter((territoryData) => territoryData.territory !== 'Default Guarantee')
        .map((territoryData, territoryIdx) => (
          <TerritoryBox key={territoryIdx}>
            <TerritoryHeader>{territoryData.territory}</TerritoryHeader>
            <InfoLine
              label={territoryData.territory}
              isPrimary={false}
              minimum={territoryData?.minimum}
              maximum={territoryData?.maximum}
            />
          </TerritoryBox>
        ))}
    </>
  );
};
