import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Info, Input } from '@united-talent-agency/julius-frontend-components';
import { getEmployeeDepartments, updatePersonProfile } from '@united-talent-agency/julius-frontend-store';

import Card from '../card/Card';
import { SearchableSelect } from '../searchable-select';
import { SelectorContainer } from '../../styles/components/components';
import { allClientStatuses } from './supportData';
import { updateGroup } from '../../api/groups';
import CommonTooltip from '../common/tooltip';

import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export default function NetsuiteCard({ company, person, mergeCardData, blockEdit }) {
  const dispatch = useDispatch();
  const entity = company || person;

  const onSave = async (saveInfo) => {
    return company
      ? await updateGroup(entity._id, saveInfo.updates[entity._id])
      : dispatch(updatePersonProfile(entity._id, { _id: entity._id, ...saveInfo.updates[entity._id] }));
  };

  const showNetsuite = !mergeCardData || !!entity.netsuiteId?.length;
  const showOriginatingDepartment = !mergeCardData || !!entity.originatingDepartment?.length;
  const showClientStatus = !mergeCardData || !!entity.clientStatus?.length;

  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    dispatch(getEmployeeDepartments()).then((result) => {
      setDepartments(result.body);
    });
  }, [dispatch]);

  return showNetsuite || showOriginatingDepartment || showClientStatus || !mergeCardData.isEmpty ? (
    <div style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}{' '}
      <Card
        title="Basic"
        mergeCardData={mergeCardData}
        data={company || person}
        readView={(props) => readView(props, showNetsuite, showOriginatingDepartment, showClientStatus)}
        editView={
          !blockEdit &&
          ((props) => editView(departments, props, showNetsuite, showOriginatingDepartment, showClientStatus))
        }
        onSave={onSave}
      />{' '}
    </div>
  ) : (
    <></>
  );
}

function readView({ item: entity }, showNetsuite, showOriginatingDepartment, showClientStatus) {
  const { clientStatus = '', originatingDepartment = '', netsuiteId = '' } = entity;

  return (
    <>
      {showNetsuite && <Info name="Netsuite ID">{netsuiteId}</Info>}
      {showOriginatingDepartment && <Info name="Originating Department">{originatingDepartment}</Info>}
      {showClientStatus && <Info name="Client Status">{clientStatus}</Info>}
    </>
  );
}

function editView(departments, { item: entity, setState }, showNetsuite, showOriginatingDepartment, showClientStatus) {
  const { clientStatus = '', originatingDepartment = '', netsuiteId = '' } = entity;

  return (
    <>
      {showNetsuite && (
        <Info name="Netsuite ID">
          <SelectorContainer>
            <Input type="text" value={netsuiteId} onChange={(netsuiteId) => setState({ netsuiteId })} />
          </SelectorContainer>
        </Info>
      )}
      {showOriginatingDepartment && (
        <Info name="Originating Department">
          <SelectorContainer>
            <SearchableSelect
              value={{ value: originatingDepartment, label: originatingDepartment }}
              options={departments}
              onChange={(item) => setState({ originatingDepartment: item ? item.value : item })}
            />
          </SelectorContainer>
        </Info>
      )}
      {showClientStatus && (
        <Info name="Client Status">
          <SelectorContainer>
            <SearchableSelect
              value={{ value: clientStatus, label: clientStatus }}
              options={allClientStatuses}
              onChange={(item) => setState({ clientStatus: item ? item.value : item })}
            />
          </SelectorContainer>
        </Info>
      )}
    </>
  );
}
