import React from 'react';
import { Input } from '@united-talent-agency/julius-frontend-components';
import cypressTags from '../../support/cypressTags';
import CreatableSelectDropdown from '../creatable-select-dropdown/CreatableSelectDropdown';

const NEW_COMPANY_ID = `NEW-ITEM-${Date.now()}`;

export const handleOnChangeEmployer = (selectedValue, actionType, onEmployerChanged) => {
  switch (actionType.action) {
    case 'select-option':
      onEmployerChanged({ group: { _id: selectedValue.company._id, name: selectedValue.company.name } });
      break;
    case 'create-option':
      onEmployerChanged({ group: { _id: NEW_COMPANY_ID, name: selectedValue.value } });
      break;
    case 'clear':
      onEmployerChanged({});
      break;
    default:
      break;
  }
};

const EmployerEditor = (props) => {
  const { employer, searchGroups, onEmployerChanged, readOnly } = props;

  const getEmployers = (inputValue, callback) => {
    if (!inputValue) {
      return callback([]);
    }
    searchGroups(inputValue).then((groups) => {
      const menuOptions = (groups || []).map((employer) => ({
        value: employer.name,
        label: employer.name,
        company: employer,
      }));
      return callback(menuOptions);
    });
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'rows', whiteSpace: 'nowrap', marginBottom: 10 }}
      data-cy={cypressTags.PERSON.EMPLOYER_CARD.EDIT_VIEW_CONTAINER}
    >
      <div style={{ width: '100%' }}>
        <div id="creatable-select" data-cy={cypressTags.PERSON.EMPLOYER_CARD.COMPANY_DROPDOWN_WRAPPER}>
          <CreatableSelectDropdown
            title={'Company'}
            loadable
            loadOptions={getEmployers}
            defaultValue={
              employer.group && employer.group._id ? { value: employer.group.name, label: employer.group.name } : null
            }
            onChange={(selectedValue, actionType) => {
              handleOnChangeEmployer(selectedValue, actionType, onEmployerChanged);
            }}
            readOnly={readOnly}
            matchLegacyStyle
          />
        </div>
        {!(employer.group && employer.group._id) && (
          <div style={{ color: 'red', fontSize: '14px' }}>Please select a company</div>
        )}
        <Input
          type="text"
          title="Title"
          value={employer.title || ''}
          onChange={(title) => {
            onEmployerChanged({ title });
          }}
        />
      </div>
    </div>
  );
};

export default EmployerEditor;
