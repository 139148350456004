import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from 'react-free-style';
import { Helmet } from 'react-helmet';
import MergeProfileTitle from './MergeProfileTitle';
import ProfileView from './mergeProfile';
import { cardTypes } from '../../cardTypes';
import CardSocial from '../../../../components/card-social/card-social';
import CardPageContainer from '../../../../components/card/CardPageContainer';
import { Column, Columns, InnerPageContent, PageBody, PageContainer } from '../../../../styles/components/components';

import { saveChanges } from '../../../profile/util/OldCardStyleSave';
import { ExternalTeamView, FinanceView, ProjectsView, TagsView, TeamView, WebsiteView } from '../../../profile/views';

import _ from 'lodash';

import {
  addRelation,
  loadPerson,
  removeRelation,
  updatePersonProfile,
  updateRelation,
} from '@united-talent-agency/julius-frontend-store';
import BrandingWrapped from '../../../../components/branding-wrapped/BrandingWrapped';

const creates = { relationship: addRelation };
const deletes = { relationship: removeRelation };
const updates = {
  person: updatePersonProfile,
  relationship: updateRelation,
};

function MergeProfiles({ computedMatch, user, styles, _getCardData = getCardData }) {
  const dispatch = useDispatch();
  // next lines are relevant only until link to merge page is implemented
  // after that, we don't want to use query params to determine merging profiles
  const testPeopleIds = '61f1871a44a575001d9e79ed,61f1876c44a575001d9e79f3,5c9a40e8dc70917c8103fb1d'; // 5b99f99350498b001088fab5

  const initialPeopleIds = (computedMatch.params.peopleIds || testPeopleIds).split(',');
  const [peopleIds, setPeopleIds] = useState(initialPeopleIds);
  const [people, setPeople] = useState([]);
  const [projectRoles, setProjectRoles] = useState(false);
  const [cardHeights, setCardHeights] = useState({});
  const [financeInfo, setFinanceInfo] = useState({});
  const [utaRelInfo, setUtaRelInfo] = useState({});

  const retrievePeople = useCallback(async () => {
    let profilesInfo = [];
    for (let id of peopleIds) {
      const response = await dispatch(loadPerson(id));
      const person = (response || {}).body;
      profilesInfo.push(person);
    }
    return profilesInfo;
  }, [dispatch, peopleIds]);

  const getCardHeights = useCallback(() => {
    let maxHeights = {};
    const maxHeight = (x, y) => {
      return x.clientHeight > y.clientHeight ? x : y;
    };
    for (let cardType of cardTypes) {
      let cards = document.getElementsByName(cardType);
      if (cards.length) {
        cards = Array.from(cards);
        const max = cards.reduce(maxHeight).clientHeight;
        maxHeights[cardType] = max;
      }
    }
    return maxHeights;
  }, []);

  useEffect(() => {
    const currentIds = people.map((x) => x._id);
    if (!_.isEqual(currentIds, peopleIds)) {
      retrievePeople(peopleIds, dispatch).then((profilesInfo) => profilesInfo && setPeople(profilesInfo));
    }
  }, [people, peopleIds, dispatch, retrievePeople]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const heights = getCardHeights();
    if (!_.isEqual(heights, cardHeights)) {
      setCardHeights(heights);
    }
  });

  const refreshProfiles = () => {
    retrievePeople(peopleIds, dispatch).then((profilesInfo) => profilesInfo && setPeople(profilesInfo));
  };

  const setPrimary = (primary) => {
    // move new primary profile to the front
    const newIdList = [...peopleIds];
    const newPrimaryId = newIdList.splice(primary, 1)[0];
    newIdList.unshift(newPrimaryId);
    setPeopleIds(newIdList);

    // instead of re-retrieving, move new primary person to the front
    const newPeopleList = [...people];
    const newPrimaryPerson = newPeopleList.splice(primary, 1)[0];
    newPeopleList.unshift(newPrimaryPerson);
    setPeople(newPeopleList);
  };

  const cardData = _getCardData(cardTypes, cardHeights, projectRoles);

  const isFinanceSectionEmpty =
    cardData.netsuite.isEmpty &&
    cardData.loanouts.isEmpty &&
    cardData.gstHst.isEmpty &&
    cardData.taxIds.isEmpty &&
    cardData.commission.isEmpty &&
    cardData.vat.isEmpty;

  const isProjectSectionEmpty = cardData.tracking.isEmpty && cardData.roles.isEmpty && !projectRoles;

  const saveProfileChanges = async (changes, person) => {
    if (!changes) {
      return refreshProfiles();
    }

    await saveChanges({
      creates,
      deletes,
      updates,
      changes,
      person,
      dispatch,
      loadPerson,
    }).then(() => {
      refreshProfiles();
    });
  };

  const saveWebsite = async ({ updates }, person) => {
    await dispatch(updatePersonProfile(person._id, updates[person._id])).then(() => {
      refreshProfiles();
    });
  };

  return (
    <PageContainer>
      <Helmet>
        <title>MERGE</title>
      </Helmet>
      <BrandingWrapped />
      <div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        SUBMIT MERGE CONFIRMATION
      </div>
      <div
        style={{ color: '#6B6A69', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        Select the primary profile that you want to keep and add the data from the secondary profiles that you would
        like to keep
      </div>
      <PageBody>
        <InnerPageContent style={{ maxWidth: '95%' }}>
          <div className={styles.title}>Profile Info</div>
          <div style={{ overflow: 'auto' }}>
            <Columns className={styles.scrollableCols}>
              {people &&
                people.map((person, index) => {
                  const isPrimary = index === 0;
                  return (
                    <Column key={person._id} className={isPrimary ? styles.stickyCol : styles.scrollCol}>
                      <CardPageContainer onSave={refreshProfiles}>
                        <MergeProfileTitle person={person} index={index} setPrimary={setPrimary} />
                        <ProfileView
                          person={person}
                          user={user}
                          cardData={{ ...cardData, primary: isPrimary }}
                          refreshProfile={() => refreshProfiles()}
                          saveProfileChanges={(changes) => saveProfileChanges(changes, person)}
                        />
                        {(person.type !== 'Employee' || cardData.team.cardHeight) && (
                          <TeamView
                            entity={person}
                            dispatch={dispatch}
                            mergeCardData={{ ...cardData.team, primary: isPrimary }}
                            setUtaRelInfo={(data) => setUtaRelInfo({ ...utaRelInfo, ...data })}
                          />
                        )}
                        {(person.type === 'Client' ||
                          person.type === 'Industry Contact' ||
                          cardData.social.cardHeight) && (
                          <CardSocial
                            entity={person}
                            entityFieldName="person"
                            mergeCardData={{ ...cardData.social, primary: isPrimary }}
                            refreshProfile={() => refreshProfiles()}
                          />
                        )}
                        {!cardData.relationships.isEmpty && (
                          <div className={styles.sectionTitle}>{isPrimary ? 'External Relationships' : ''}</div>
                        )}
                        <ExternalTeamView
                          entity={person}
                          dispatch={dispatch}
                          mergeCardData={{ ...cardData.relationships, primary: isPrimary }}
                          refreshProfile={() => refreshProfiles()}
                        />
                        {!isProjectSectionEmpty && (
                          <div className={styles.sectionTitle}>{isPrimary ? 'Projects' : ''}</div>
                        )}
                        <ProjectsView
                          person={person}
                          mergeCardData={{
                            roles: { ...cardData.roles, primary: isPrimary },
                            tracking: { ...cardData.tracking, primary: isPrimary },
                          }}
                          setProjectRoles={(data) => setProjectRoles(data)}
                          updatedProjectRole={() => refreshProfiles()}
                        />
                        {!isFinanceSectionEmpty && (
                          <div className={styles.sectionTitle}>{isPrimary ? 'Finance' : ''}</div>
                        )}
                        <FinanceView
                          person={person}
                          mergeCardData={{ ...cardData, primary: isPrimary }}
                          setFinance={(data) => setFinanceInfo({ ...financeInfo, ...data })}
                          refreshProfile={() => refreshProfiles()}
                        />
                        {!cardData.website.isEmpty && (
                          <div className={styles.sectionTitle}>{isPrimary ? 'Website' : ''}</div>
                        )}
                        <WebsiteView
                          person={person}
                          mergeCardData={{ ...cardData.website, primary: isPrimary }}
                          saveChanges={(changes) => saveWebsite(changes, person)}
                        />
                        {!cardData.tags.isEmpty && <div className={styles.sectionTitle}>{isPrimary ? 'Tags' : ''}</div>}
                        <TagsView
                          person={person}
                          mergeCardData={{ ...cardData.tags, primary: isPrimary }}
                          saveChanges={(changes) => saveProfileChanges(changes, person)}
                        />
                        <div className={styles.sectionTitle}>{isPrimary ? 'Touring Info' : ''}</div>
                      </CardPageContainer>
                    </Column>
                  );
                })}
            </Columns>
          </div>
        </InnerPageContent>
      </PageBody>
    </PageContainer>
  );
}

const colStyle = {
  height: '100%',
  maxWidth: '50%',
  minWidth: '388px',
  margin: '0px !important',
  backgroundColor: '#F5F5F5',
};

const withStyles = styled({
  scrollCol: {
    ...colStyle,
    zIndex: '1',
    marginRight: '2px !important',
  },
  stickyCol: {
    ...colStyle,
    position: 'sticky',
    left: '0',
    zIndex: '2',
    boxShadow: '10px 0px 5px -7px rgba(0,0,0,.1)',
  },
  title: {
    fontSize: '24px',
    marginBottom: '5px',
  },
  sectionTitle: {
    fontSize: '24px',
    marginBottom: '5px',
    height: '36px',
    marginTop: '20px',
  },
  scrollableCols: {
    whiteSpace: 'nowrap',
  },
});

export default withStyles(MergeProfiles);

export function getCardData(cardTypes, cardHeights, projectRoles) {
  return cardTypes.reduce((x, t) => {
    const height = _.get(cardHeights, t);
    let isEmpty = !height || height === 40;

    if (t === 'roles') {
      isEmpty = !projectRoles;
    }

    return {
      ...x,
      [t]: {
        primary: false,
        cardHeight: height,
        isEmpty: isEmpty,
        name: t,
      },
    };
  }, {});
}
