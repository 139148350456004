import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { PersonInput, Select } from '@united-talent-agency/julius-frontend-components';
import cypressTags from '../../support/cypressTags';

const PersonRelationshipEditor = ({
  relationship = {},
  onPersonChanged,
  onRelationshipTypeChanged,
  onSearch,
  relationshipTypes = [],
  onValidate,
  invalidRelationshipType,
  cyTag,
}) => {
  const [error, setError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [companyResults, setPeopleResults] = useState([]);
  const relationshipTypeOptions = relationshipTypes.map((relType) => ({
    key: relType,
    content: relType,
    active: relType === relationship.type,
    styles: { padding: 10 },
    onClick: () => {
      onRelationshipTypeChanged && onRelationshipTypeChanged(relType);
      onValidate && setError(onValidate({ ...relationship, type: relType }));
    },
  }));

  return (
    <>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }} data-cy={cyTag}>
        <div data-cy={cypressTags.COMMON.AGENT_TYPE_SELECT} style={{ flex: 0.5, paddingRight: 5, paddingBottom: 10 }}>
          <Select optionStyle={{ padding: 10 }} items={relationshipTypeOptions}>
            <div>{relationship.type}</div>
          </Select>
        </div>

        <div style={{ flex: 0.5, paddingLeft: 5, paddingBottom: 10 }}>
          <PersonInput
            cyTag={cypressTags.COMMON.AGENT_NAME_INPUT}
            style={{
              marginBottom: 10,
              fontWeight: 100,
            }}
            value={searchText || (relationship.utaEmployee || {}).name || ''}
            onChange={(name) => {
              setSearchText(name);
              onSearch &&
                onSearch(name).then((people) => {
                  setPeopleResults(people);
                });
            }}
            results={companyResults}
            onSelect={(utaEmployee) => {
              setSearchText('');
              onPersonChanged && onPersonChanged(utaEmployee);
              onValidate && setError(onValidate({ ...relationship, utaEmployee }));
            }}
            dropDownMode="floating"
            disabled={invalidRelationshipType && relationship.type === invalidRelationshipType}
          />
        </div>
      </div>
      {error && <ErrorPanel>{error}</ErrorPanel>}
    </>
  );
};

const ErrorPanel = styled.div`
  font-size: 14px;
  margin-left: 0px;
  margin-right: 20px;
  margin-top: -10px;
  color: red;
`;

export default PersonRelationshipEditor;
