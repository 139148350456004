import { Icons, Tooltip } from '@united-talent-agency/components';
import React from 'react';
import cypressTags from '../../../support/cypressTags';

const CommonTooltip = ({ text, top = 10, right = 10, cyTag = cypressTags.COMMON.LOCK_BUTTON }) => {
  return (
    <div style={{ position: 'absolute', right, top }} data-cy={cyTag}>
      <Tooltip place="left" text={text}>
        <Icons.LockedIcon />
      </Tooltip>
    </div>
  );
};

export default CommonTooltip;
