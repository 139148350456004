import React from 'react';
import { ContactTypeNames } from '@united-talent-agency/components';

import Card from '../card/Card';
import ContactEditor from '../contacts-card/ContactEditor';
import ContactLabel from '../contacts-card/ContactLabel';
import { primaryFirstSort } from '../../support/primary-sort';
import { allContactsFilter, contactIsValid } from '../../support/contacts';
import { updateVenue } from '../../api/venues';
import CommonTooltip from '../common/tooltip';

import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export default function ContactsCard({
  venue = {},
  contacts = [],
  dispatch,
  canSetPrimary,
  privateContactPermission,
  onChange,
  foreverType,
  firstIsPrimary,
  title = 'Contacts',
  refresh = () => {},
  contactTypeFilter = allContactsFilter,
  mergeCardData,
  blockEdit,
}) {
  const publicContacts = venue.contacts || contacts || [];
  const allContacts = publicContacts;
  const contactTypes = Object.keys(ContactTypeNames).filter((key) => contactTypeFilter.test(key));
  const pertinentContacts = allContacts
    .filter(({ contactType }) => contactTypeFilter.test(contactType))
    .sort(primaryFirstSort);

  const showContacts = !mergeCardData || !mergeCardData.isEmpty || pertinentContacts.length > 0;

  return !showContacts ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}{' '}
      <Card
        title={title}
        mergeCardData={mergeCardData}
        data={pertinentContacts}
        readView={readView}
        editView={!blockEdit && ((props) => editView(contactTypes, canSetPrimary, foreverType, mergeCardData, props))}
        canSetPrimary={canSetPrimary}
        privateContactPermission={privateContactPermission}
        canDelete
        onSave={(saveInfo) =>
          onSave({ ...venue }, dispatch, foreverType, saveInfo, privateContactPermission).then(() => refresh(venue._id))
        }
        onValidateItem={contactIsValid}
        createNew={() => ({ contact: '', contactType: foreverType || contactTypes[0] })}
        onChange={onChange}
        firstIsPrimary={firstIsPrimary}
      />
    </div>
  );
}

const onSave = async (venue, dispatch, foreverType, saveInfo, privateContactPermission) => {
  const { updates = {}, deletes = {}, creates = [] } = saveInfo;

  for (const [key, update] of Object.entries(updates)) {
    if ('isPrivate' in update) {
      if (!privateContactPermission) continue;
      if (update.isPrivate) {
        // public changed to private
        // get the rest of the public contact from company.contacts and add to creates as new private contact
        // add old _id to deletes
        const publicContact = venue.contacts.find((contact) => contact._id === key);
        if (publicContact) {
          let { _id, ...rest } = publicContact;
          deletes[_id] = publicContact;
          creates.push({ ...rest, ...update });
        }
      } else {
        // private changed to public
        // get the rest of the private contact from company.privateContacts and add to creates as new public contact
        // add old private contact _id to deletes
        const privateContact = venue.privateContacts.find((privateContact) => privateContact._id === key);
        if (privateContact) {
          let { _id, ...rest } = privateContact;
          deletes[_id] = privateContact;
          creates.push({ ...rest, ...update });
        }
      }
      delete update.isPrivate;
    }
  }

  let [publicCreates, privateCreates] = [[], []];
  creates.forEach((create) =>
    create.isPrivate ? delete create.isPrivate && privateCreates.push(create) : publicCreates.push(create)
  );

  const publicContacts = (venue.contacts || [])
    .map((contact) => {
      if (!contact.contactType && foreverType) {
        return { ...contact, contactType: foreverType, ...updates[contact._id] };
      }
      return { ...contact, ...updates[contact._id] };
    })
    .filter(({ _id }) => !deletes[_id])
    .concat(publicCreates);

  return await updateVenue({ venueId: venue._id, updates: { contacts: publicContacts } });
};

function readView({ item: contact, hasPrivateContactsPermissions }) {
  return <ContactLabel value={contact} hasPrivateContactsPermissions={hasPrivateContactsPermissions} />;
}

function editView(
  contactTypes,
  _,
  foreverType,
  mergeCardData,
  { item: contact, setState, setPrivate, hasPrivateContactsPermissions }
) {
  return (
    <ContactEditor
      mergeCardData={mergeCardData}
      contactTypes={contactTypes}
      value={contact}
      onChange={setState}
      foreverType={foreverType}
      setPrivate={setPrivate}
      hasPrivateContactsPermissions={hasPrivateContactsPermissions}
    />
  );
}
