import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TextInputInline = (props) => {
  const { id, label, value, onChange, styles = {}, placeholder } = props;

  const htmlId = id || Math.random();
  return (
    <div className="row">
      {label && (
        <label className={classnames('col-sm-2', 'col-form-label', styles.label)} htmlFor={htmlId}>
          {label}
        </label>
      )}
      <div className={label ? 'col-sm-10' : 'col-sm-12'}>
        <input
          id={htmlId}
          autoComplete="off"
          onChange={(e) => {
            onChange && onChange(e.target.value);
          }}
          value={value}
          className={classnames('form-control', styles.input)}
          type="text"
          placeholder={placeholder || label}
        />
      </div>
    </div>
  );
};
TextInputInline.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  styles: PropTypes.object,
  placeholder: PropTypes.string,
};

export default TextInputInline;
