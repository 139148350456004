import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { colors } from '@united-talent-agency/components';

const SortableColumnHeader = (props) => {
  const { text, onClick, direction, lightWeight = false, styles } = props;
  const sortIconClass = direction ? (direction === 1 ? 'fa-caret-up' : 'fa-caret-down') : 'fa-sort';
  const selectedClass = direction ? styles.selected : styles.notSelected;
  return (
    <span
      id={`sortable-column-${text}`}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        fontWeight: lightWeight ? 'normal' : 'bold',
        whiteSpace: 'noWrap',
      }}
      onClick={() => {
        onClick && onClick(direction === 1 ? -1 : direction === -1 ? 0 : 1);
      }}
    >
      {text}
      {onClick && <i className={classnames('fa', 'fa-w', sortIconClass, selectedClass)} />}
    </span>
  );
};

const withStyles = styled({
  notSelected: {
    color: colors.nobel,
    marginLeft: 5,
  },
  selected: {
    color: colors.utaBlack,
    marginLeft: 5,
  },
});

SortableColumnHeader.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  direction: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  styles: PropTypes.object,
};

export default withStyles(SortableColumnHeader);
