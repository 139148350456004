import React from 'react';
import styled from 'styled-components';
import NetsuiteCard from '../../../../components/netsuite-card/NetsuiteCard';

export default function NetsuiteView({ company, dispatch, blockEdit }) {
  return (
    <Container>
      <NetsuiteCard company={company} dispatch={dispatch} blockEdit={blockEdit} />
    </Container>
  );
}

const Container = styled.div({
  maxWidth: '50%',
});
