import React from 'react';
import Card from '../card/Card';
import { InfoContainer, PrimaryText } from '../../styles/components/components';
import { updateGroup } from '../../api/groups';
import CommonTooltip from '../common/tooltip';

import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

let roleMap = {
  network: { title: 'Network' },
  studio: { title: 'Studio' },
  productionCompany: { title: 'Production' },
  financing: { title: 'Financing' },
  talentAgency: { title: 'Talent' },
  buyer: { title: 'Buyer' },
  parentCompany: { title: 'Parent' },
  promoter: { title: 'Promoter' },
};

// TODO: get noDisabled working more correctly
export default function RolesCard({
  company,
  noDisabled,
  onChange,
  refresh,
  readOnly,
  mergeCardData,
  blockEdit,
  cyTag = '',
}) {
  const onSave = async (saveInfo) => {
    await updateGroup(company._id, { roles: { projects: saveInfo.mergedData[0] } });
    refresh(company._id);
  };

  // Is it ok to assume that roles will only ever be {} ??
  const existingRoles = Object.keys((company && company.roles && company.roles.projects) || {}).filter(
    (role) => roleMap[role]
  );
  const existingRolesSet = new Set(existingRoles);
  const roles = existingRoles.reduce((acc, curr) => ({ ...acc, [curr]: {} }), {});

  const showRoles = !mergeCardData || !mergeCardData.isEmpty || Object.keys(roles).length > 0;

  return !showRoles ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }} data-cy={cyTag}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        title="Roles"
        mergeCardData={mergeCardData}
        data={roles}
        readView={(props) => readView(props)}
        editView={!readOnly && !blockEdit && ((props) => editView(existingRolesSet, noDisabled, props))}
        onSave={onSave}
        onChange={onChange}
      />
    </div>
  );
}

function readView({ item: roles }) {
  return (
    <InfoContainer>
      {Object.keys(roleMap).map((role, index) => (
        <div key={index}>
          <input type="checkbox" checked={!!roles[role]} disabled />
          <PrimaryText>{roleMap[role].title}</PrimaryText>
        </div>
      ))}
    </InfoContainer>
  );
}

function editView(existingRolesSet, noDisabled, { item: roles, setState }) {
  return (
    <InfoContainer>
      {Object.keys(roleMap).map((role, index) => {
        return (
          <div key={index}>
            <input
              type="checkbox"
              checked={!!roles[role]}
              onChange={({ target }) => setState({ [role]: target.checked ? {} : undefined })}
              disabled={existingRolesSet.has(role) && !noDisabled}
            />
            <PrimaryText>{roleMap[role].title}</PrimaryText>
          </div>
        );
      })}
    </InfoContainer>
  );
}
