import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../../components/card/Card';
import { InfoContainer, SpacedInfoContainer } from '../../../../styles/components/components';
import { Info, Input } from '@united-talent-agency/julius-frontend-components';
import { Icons, Tooltip } from '@united-talent-agency/components';
import { FormContext } from '../../../../support/FormContext';
import _ from 'lodash';
import { updateVenue } from '../../../../api/venues';
import cypressTags from '../../../../support/cypressTags';

export default function CapacityCard({ venue, onChange, blockEdit, cyTag = '' }) {
  const onSave = async ({ mergedData }) => {
    if (!mergedData[0]) return null;
    const capacity = mergedData[0]?.capacity;
    const venueId = venue._id;
    const updatedVenue = { venueId, updates: { capacity } };
    return updateVenue(updatedVenue);
  };

  const requiredFieldCheck = (venue) => {
    const { capacity } = venue;
    return capacity && capacity > 0 && capacity % 1 === 0;
  };

  return (
    <div style={{ position: 'relative' }} data-cy={cyTag}>
      {blockEdit && (
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <Tooltip place="left" text="Please contact a client team member to make changes to this client profile.">
            <span data-cy={cypressTags.COMMON.LOCK_BUTTON}>
              <Icons.LockedIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <Card
        title="Capacity"
        data={venue}
        isRequired
        readView={(props) => readView(props)}
        editView={!blockEdit && ((props) => editView(props))}
        onSave={onSave}
        onChange={onChange}
        onValidateItem={requiredFieldCheck}
      />
    </div>
  );
}

function readView({ item: venue }) {
  const { capacity } = venue;
  return (
    <InfoContainer>
      <Info name="Max capacity" isRequired>
        {capacity}
      </Info>
    </InfoContainer>
  );
}

function editView({ item: venue, setState }) {
  return <CapacityEditor venue={venue} setState={setState} />;
}

export const Error = ({ msg }) => <div style={{ color: 'red', fontSize: '12px', clear: 'both' }}>{msg}</div>;

const CapacityEditor = ({ venue, setState }) => {
  const [fieldVisited, setFieldVisited] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const { showAllValidationErrors } = useContext(FormContext);

  useEffect(() => {
    const newErrors = {};

    if (!venue || venue?.capacity === undefined) {
      newErrors['capacity'] = 'Venue capacity is required';
    } else if (venue?.capacity <= 0) {
      newErrors['capacity'] = 'Venue capacity should be greater than zero';
    } else if (venue?.capacity % 1 !== 0) {
      newErrors['capacity'] = 'Venue capacity cannot be a decimal number';
    }

    if (!_.isEqual(fieldErrors, newErrors)) {
      setFieldErrors(newErrors);
    }
  }, [venue, fieldErrors]);

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;

    // numbers (1-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  return (
    <SpacedInfoContainer>
      <div>
        <Input
          isRequired
          cyTag={cypressTags.VENUE.CAPACITY_INPUT}
          type="Number"
          title="Max capacity"
          value={venue?.capacity || ''}
          onKeyPress={handleKeyPress}
          onChange={(capacity) => {
            setState({ ...venue, capacity: capacity !== '' ? Number(capacity) : undefined });
          }}
          onBlur={() => setFieldVisited({ ...fieldVisited, capacity: true })}
        />
      </div>
      {fieldErrors.capacity && (fieldVisited.capacity || showAllValidationErrors) && (
        <Error msg={fieldErrors.capacity} />
      )}
    </SpacedInfoContainer>
  );
};
