import React, { useState, useEffect } from 'react';
import { styled } from 'react-free-style';
import { Spinner } from '@united-talent-agency/components';
import moment from 'moment';
import { startCase, isEmpty, isArray, isNil, every, get, cloneDeep } from 'lodash';
import handleGuarenteeChange from './touring/guaranteeCard';
import handleContractSigner from './touring/contractSignerBlock';
import handleBusinessNames from './touring/businessNames';
import handleTouringAgents from './touring/territoriesCard';
import handleAccountingRep from './touring/accountingRep';
import handleTouringChange from './touring/touringAgentChange';
import cypressTags from '../../../../support/cypressTags';

import { contentColor, contentBackground, text, background } from '../../../../styles/colors';
import {
  DISPLAYED_INFO,
  DISPLAYED_REFERENTIAL_INFO,
  DISPLAYED_SUB_DOCUMENTS,
  DISPLAYED_TOURING_INFO,
} from './displays';
import { transformResponse } from './utils';
import { getGroup } from '../../../../api/groups';
import { getUtaEmployee } from '../../../../api/people';

export const PAGINATION_FACTOR = 10;

// from https://stackoverflow.com/questions/5876332/how-can-i-differentiate-between-an-object-literal-other-javascript-objects
// (probably a better way)
const isPlainObject = (obj) => typeof obj === 'object' && obj.constructor === Object;

// pulled from data-apis, app/shared/lib/mongo-utils.js
const LOOKS_LIKE_MONGO_ID = /^[0-9a-fA-F]{24}$/;

const AUDIT_LABEL_DISPLAY = [
  ...DISPLAYED_INFO,
  ...DISPLAYED_REFERENTIAL_INFO,
  ...DISPLAYED_SUB_DOCUMENTS,
  ...DISPLAYED_TOURING_INFO,
].reduce((displayedLabels, label) => {
  switch (label) {
    case 'recordCreated':
      displayedLabels[label] = '';
      break;
    case 'contactsNew':
      displayedLabels[label] = 'Contacts';
      break;
    case 'touringAgents':
      displayedLabels[label] = 'Touring Agents';
      break;
    case 'guarenteeNames':
      displayedLabels[label] = 'Guarentee Names';
      break;
    case 'contractSignerBlocks':
      displayedLabels[label] = 'Contract Signer Block';
      break;
    case '':
      displayedLabels[label] = 'Accounting Rep';
      break;
    case 'verifications':
      displayedLabels[label] = 'Verified';
      break;
    case 'w9s':
      displayedLabels[label] = 'W9s';
      break;
    case 'race':
      displayedLabels[label] = 'Ethnicity';
      break;
    case 'lgbtq':
      displayedLabels[label] = 'LGBTQ';
      break;
    case 'iqStatus':
      displayedLabels[label] = 'IQ Status';
      break;
    case 'iqState':
      displayedLabels[label] = 'IQ State';
      break;
    case 'iqSource':
      displayedLabels[label] = 'IQ Source';
      break;
    case 'neda':
      displayedLabels[label] = 'IQ ID';
      break;
    case 'clientStartDate':
      displayedLabels[label] = 'Add Date';
      break;
    case 'clientTerminationDate':
      displayedLabels[label] = 'Drop Date';
      break;
    case 'utourId':
      displayedLabels[label] = 'UTour ID';
      break;
    case 'iqEthnicitySourceURL':
      displayedLabels[label] = 'IQ Ethnicity Source URL';
      break;
    case 'iqLGBTSourceURL':
      displayedLabels[label] = 'IQ LGBTQ Source URL';
      break;
    case 'iqOtherURL':
      displayedLabels[label] = 'IQ Other URL';
      break;
    case 'groupMembership':
      displayedLabels[label] = 'Employer';
      break;
    case 'members':
      displayedLabels[label] = 'Employee';
      break;
    case 'externalTeam.groupRelationships':
      displayedLabels[label] = 'External Relationships: Group Relationship';
      break;
    case 'externalTeam.personRelationships':
      displayedLabels[label] = 'External Relationships: Person Relationship';
      break;
    default:
      displayedLabels[label] = startCase(label);
  }
  return displayedLabels;
}, {});

const ignoreCheck = (val) => (typeof val === 'string' && val.match(LOOKS_LIKE_MONGO_ID)) || val === 0;

const handleEmbeddedChange = (value, key, auditChangeObject, index) => {
  const displayedIndex = index === 0 ? '' : index;
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[key];
  const compositeLabel = displayedIndex ? `${auditLabelDisplay} ${displayedIndex}` : auditLabelDisplay;
  const disallowedFields = ['_id', 'createdAt', 'updatedAt', 'updatedBy', 'createdBy', '__v'];
  // catches another case of updating a contact reference, not an embedded contact
  if (key === 'contacts' && every(value, ignoreCheck)) {
    auditChangeObject[compositeLabel] = 'updated';
  } else if (key === 'verifications') {
    auditChangeObject[compositeLabel] = '';
  } else if (isArray(value)) {
    const wasDeleted = value.length === 3 && value[1] === 0 && value[2] === 0;
    auditChangeObject[compositeLabel] = (
      <>
        {wasDeleted ? 'deleted' : 'created'}
        {value.map((data) =>
          Object.keys(data)
            .filter((fieldDataKey) => !disallowedFields.includes(fieldDataKey))
            .map((fieldDataKey, index) => (
              <React.Fragment key={index}>
                {`\n${fieldDataKey}: `}
                <AuditChangeValue auditKey={key} value={data[fieldDataKey]} />
              </React.Fragment>
            ))
        )}
      </>
    );
  } else {
    auditChangeObject[compositeLabel] = (
      <>
        {`updated`}
        {Object.keys(value)
          .filter((fieldDataKey) => !disallowedFields.includes(fieldDataKey))
          .map((fieldDataKey, index) => {
            let auditValue = value[fieldDataKey];
            if (key === 'groupMembership' || key === 'members') {
              if (auditValue._id && auditValue._id.length === 2) {
                auditValue = [
                  { _id: auditValue._id[0], name: auditValue.name[0] },
                  { _id: auditValue._id[1], name: auditValue.name[1] },
                ];
              }
            }

            return (
              <React.Fragment key={index}>
                {`\n${fieldDataKey} => `}
                {auditValue.length === 2 ? (
                  <>
                    <AuditChangeValue auditKey={key} value={auditValue[0]} />
                    {` was changed to `}
                    <AuditChangeValue auditKey={key} value={auditValue[1]} />
                  </>
                ) : (
                  <AuditChangeValue auditKey={key} value={auditValue} />
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  }
};

const displayContactsHistoryChanges = ({ contactType, contactsObj = {}, key, listItemIndex }) => (
  <div key={listItemIndex}>
    <div>
      <strong style={{ fontWeight: 'bold' }}>{'Change Type: '}</strong>
      <AuditChangeValue auditKey={key} value={contactsObj.changeType} />
    </div>
    <div>
      <strong style={{ fontWeight: 'bold' }}>{'Contact Type: '}</strong>
      <AuditChangeValue auditKey={key} value={contactType} />
    </div>
    {contactsObj.isPrimary && (
      <div>
        <strong style={{ fontWeight: 'bold' }}>{'Primary: '}</strong>
        <AuditChangeValue auditKey={key} value={contactsObj.isPrimary} />
      </div>
    )}
    {contactsObj.contactType !== 'Primary' && contactsObj.changeType === 'Edited' && (
      <>
        <div>
          <strong style={{ fontWeight: 'bold' }}>{`Old ${contactType}: `}</strong>
          <AuditChangeValue auditKey={key} value={contactsObj.oldValue} />
        </div>
        <div>
          <strong style={{ fontWeight: 'bold' }}>{`New ${contactType}: `}</strong>
          <AuditChangeValue auditKey={key} value={contactsObj.newValue} />
        </div>
      </>
    )}
    {contactsObj.contactType !== 'Primary' &&
      (contactsObj.changeType === 'Deleted' || contactsObj.changeType === 'Added') && (
        <div>
          <strong style={{ fontWeight: 'bold' }}>{`${contactType}: `}</strong>
          <AuditChangeValue auditKey={key} value={contactsObj.contact} />
        </div>
      )}
    <br />
  </div>
);

const handleContactsChange = ({ contacts = [], auditChangeObject, key }) => {
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[key];
  let contactHistory = [];

  if (contacts.length) {
    contacts.forEach((contact = {}, index) => {
      if (Object.keys(contact).length) {
        contactHistory.push(
          displayContactsHistoryChanges({
            key,
            contactType: contact.contactType,
            contactsObj: contact,
            listItemIndex: index,
          })
        );
      }
    });
    auditChangeObject[auditLabelDisplay] = contactHistory;
  }
};

const handleInternalTeamChange = ({ internalTeam = {}, auditChangeObject, key }) => {
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[key];

  if (Object.keys(internalTeam).length) {
    auditChangeObject[auditLabelDisplay] = (
      <>
        <div>
          <strong style={{ fontWeight: 'bold' }}>{'Change Type: '}</strong>
          <AuditChangeValue auditKey={key} value={internalTeam.changeType} />
        </div>
        <div>
          <strong style={{ fontWeight: 'bold' }}>{'Agent Type: '}</strong>
          <AuditChangeValue auditKey={key} value={internalTeam.agentType} />
        </div>
        <div>
          <strong style={{ fontWeight: 'bold' }}>{'Name: '}</strong>
          <AuditChangeValue auditKey={key} value={internalTeam.name} />
        </div>
      </>
    );
  }
};

const handleUtaEmployeeChange = ({ utaEmployeeInfo = {}, auditChangeObject, key }) => {
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[key];

  if (Object.keys(utaEmployeeInfo).length) {
    auditChangeObject[auditLabelDisplay] =
      utaEmployeeInfo.changeType === 'Added' ? (
        <>
          <div>
            <strong style={{ fontWeight: 'bold' }}>{'Change Type: '}</strong>
            <AuditChangeValue auditKey={key} value={utaEmployeeInfo.changeType} />
          </div>
          <div>
            <strong style={{ fontWeight: 'bold' }}>{'Added as: '}</strong>
            <AuditChangeValue auditKey={key} value={utaEmployeeInfo.inverse} />
          </div>
          <div>
            <strong style={{ fontWeight: 'bold' }}>{'Added to: '}</strong>
            <AuditChangeValue auditKey={key} value={utaEmployeeInfo.to} />
          </div>
          <div>
            <strong style={{ fontWeight: 'bold' }}>{'Type: '}</strong>
            <AuditChangeValue auditKey={key} value={utaEmployeeInfo.type} />
          </div>
        </>
      ) : (
        <>
          <div>
            <strong style={{ fontWeight: 'bold' }}>{'Change Type: '}</strong>
            <AuditChangeValue auditKey={key} value={utaEmployeeInfo.changeType} />
          </div>
          <div>
            <strong style={{ fontWeight: 'bold' }}>{'For: '}</strong>
            <AuditChangeValue auditKey={key} value={utaEmployeeInfo.for} />
          </div>
          <div>
            <strong style={{ fontWeight: 'bold' }}>{'Type: '}</strong>
            <AuditChangeValue auditKey={key} value={utaEmployeeInfo.type} />
          </div>
        </>
      );
  }
};

const handleRepresentedByAgenciesChange = ({ representedByAgencies = {}, auditChangeObject, key }) => {
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[key];

  if (Object.keys(representedByAgencies).length) {
    auditChangeObject[auditLabelDisplay] = (
      <>
        <div>
          <strong style={{ fontWeight: 'bold' }}>{'Change Type: '}</strong>
          <AuditChangeValue auditKey={key} value={representedByAgencies.changeType} />
        </div>
        <div>
          <strong style={{ fontWeight: 'bold' }}>
            {representedByAgencies.changeType === 'Added' ? 'Representing Agency: ' : 'Deleted Agency: '}
          </strong>
          <AuditChangeValue auditKey={key} value={representedByAgencies.representingAgency} />
        </div>
      </>
    );
  }
};

const handleChange = (value, key, auditChangeObject) => {
  if (key === 'birthday' && value.map) {
    value = value.map((each) => {
      return moment(each).format('YYYY-MM-DD');
    });
  }
  if (key === 'clientStartDate' || (key === 'clientTerminationDate' && value.map)) {
    value = value.map((each) => {
      return moment(each).format('YYYY-MM-DD');
    });
  }
  if (isPlainObject(value)) {
    Object.keys(value).forEach((x) => {
      if (value[x].length === 1) {
        auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[x][0]} was added to the record`;
      }
      if (value[x].length === 2) {
        if (value[x][0] && !value[x][1]) {
          auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[x][0]} was deleted`;
        } else if (!value[x][0] && value[x][1]) {
          auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[x][1]} was added to the record`;
        } else {
          auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[x][0]} was changed to ${value[x][1]}`;
        }
      }
    });
  }
  if (value.length === 1) {
    if (key === 'profile_pic') {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = 'Image was added';
    } else {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = value + ' was added to the record';
    }
  }
  if (value.length === 2) {
    if ((!isEmpty(value[0]) || typeof value[0] === 'number') && value[1]) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[0]} was changed to ${value[1]}`;
    } else if (typeof value[0] === 'string' && value[0].length === 0 && value[1]) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[1]} was added`;
    } else if (!value[0] && value[1]) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[1]} was added to the record`;
    } else if (value[0] && !value[1]) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[0]} was deleted`;
    }
  }

  if (value.length === 3) {
    if (value[0] && !value[1] && value[2] === 2) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${value[0]} was updated`;
    }
  }

  if (value._t === 'a') {
    // we know that the change happened in an array
    let adds = [];
    let removals = [];
    Object.values(value).forEach((change) => {
      if (Array.isArray(change)) {
        if (change.length === 1) {
          change[0] && adds.push(change[0]);
        } else {
          change[0] && removals.push(change[0]);
        }
      }
    });
    if (adds.length > 0 && removals.length > 0) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${adds.join(', ')} added; ${removals.join(', ')} removed`;
    } else if (adds.length > 0) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${adds.join(', ')} added`;
    } else if (removals.length > 0) {
      auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = `${removals.join(', ')} removed`;
    }
  }
  if (key === 'profile_pic') {
    let profilePicKey = 'Profile Image';

    auditChangeObject[profilePicKey] = auditChangeObject['Profile Pic'];
    delete auditChangeObject['Profile Pic'];
  }

  if (key === 'recordCreated') {
    auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = value;
  }
};

const determineAuditLabel = (audit) => {
  let auditChangeObject = {};
  if (audit?.delta) {
    Object.keys(audit.delta).forEach((key) => {
      if (key === 'recordCreated') {
        const value = audit.delta[key][0];
        !isNil(value) && handleChange(`${value?.name} contact page created`, 'recordCreated', auditChangeObject);
      } else if (DISPLAYED_INFO.includes(key)) {
        let value = audit.delta[key];
        if (key === 'profile_pic') {
          value = value.map((data) => {
            if (value.length === 2) {
              return data.length > 0 ? 'Image' : '';
            } else if (value.length === 3) {
              if (typeof data === 'string' && data.length > 0) {
                return 'Image';
              } else {
                return data;
              }
            } else {
              return data;
            }
          });
        }

        !isNil(value) && handleChange(value, key, auditChangeObject);
      } else if (DISPLAYED_REFERENTIAL_INFO.includes(key)) {
        // not really worth displaying this info...
        auditChangeObject[AUDIT_LABEL_DISPLAY[key]] = ' updated';
      } else if (DISPLAYED_TOURING_INFO.includes(key)) {
        Object.keys(audit.delta[key]).forEach((subKey) => {
          const touringParams = {
            territoryInfo: audit.delta.territoryInfo,
            auditChangeObject,
            subKey,
            AUDIT_LABEL_DISPLAY,
          };

          switch (subKey) {
            case 'touringAgents':
              handleTouringChange(touringParams);
              break;
            case 'guarenteeNames':
              handleGuarenteeChange(touringParams);
              break;
            case 'contractSignerBlocks':
              handleContractSigner(touringParams);
              break;
            case 'businessNames':
              handleBusinessNames(touringParams);
              break;
            case 'touringAgentTerritories':
              handleTouringAgents(touringParams);
              break;
            case 'accountingRep':
              handleAccountingRep(touringParams);
              break;
            default:
              break;
          }
        });
      } else if (DISPLAYED_SUB_DOCUMENTS.includes(key)) {
        const handleSubDocument = (key) => {
          const deltaKeys = Object.keys(get(audit.delta, key, {})).filter((x) => x !== '_t');
          deltaKeys.forEach((deltaKey, index) => {
            const value = get(audit.delta, `${key}.${deltaKey}`);
            if (!isNil(value)) {
              handleEmbeddedChange(value, key, auditChangeObject, index);
            }
          });
        };

        if (key === 'externalTeam') {
          Object.keys(audit.delta[key]).forEach((subKey) => {
            handleSubDocument(`${key}.${subKey}`);
          });
        } else if (key === 'contacts') {
          handleContactsChange({ contacts: audit.delta.contacts, auditChangeObject, key });
        } else if (key === 'internalTeam') {
          handleInternalTeamChange({ internalTeam: audit.delta.internalTeam, auditChangeObject, key });
        } else if (key === 'utaEmployeeInfo') {
          handleUtaEmployeeChange({ utaEmployeeInfo: audit.delta.utaEmployeeInfo, auditChangeObject, key });
        } else if (key === 'representedByAgencies') {
          handleRepresentedByAgenciesChange({
            representedByAgencies: audit.delta.representedByAgencies,
            auditChangeObject,
            key,
          });
        } else {
          handleSubDocument(key);
        }
      }
    });
  }
  return auditChangeObject;
};

export const AuditChangeValue = ({ auditKey, value }) => {
  const hasGroupLink = ['groupMembership', 'externalTeam.groupRelationships'].includes(auditKey);
  const hasPersonLink = ['members', 'externalTeam.personRelationships'].includes(auditKey);

  if ((hasGroupLink || hasPersonLink) && (value._id || value.name)) {
    return (
      <a
        href={`/${hasGroupLink ? 'company' : 'profile'}/${value._id}?view=history`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value.name}
      </a>
    );
  } else {
    return `${value}`;
  }
};

export const AuditChange = ({ auditChange, styles = {} }) => {
  const keysToBeExcluded = [
    'Touring Agents',
    'Guarentee Names',
    'Contract Signer Block',
    'Business Names',
    'Touring Agent Territories',
    'Accounting Rep',
  ];
  return (
    <ul className={styles.changeList}>
      {Object.keys(auditChange).map((key, index) => (
        <li className={styles.changeLine} key={index}>
          {!keysToBeExcluded.includes(key) && (
            <>
              <span id={`update-label-${index}`} className={styles.changeKey}>
                {key}
              </span>
              {key !== '' && ': '}
            </>
          )}
          {auditChange[key] ? auditChange[key] : ''}
        </li>
      ))}
    </ul>
  );
};

export const AuditTable = ({ styles = {}, history }) => (
  <table data-cy={cypressTags.HISTORY.AUDIT_TABLE} className={styles.table}>
    <tbody>
      <tr className={styles.th}>
        <th style={{ width: '25%' }}>Date</th>
        <th style={{ width: '35%' }}>Change</th>
        <th style={{ width: '30%' }}>Changed By</th>
      </tr>
      {history.reduce((validAudits, audit) => {
        const auditChange = determineAuditLabel(audit);
        const timestamp = moment(audit.timestamp);
        if (!isEmpty(auditChange)) {
          return validAudits.concat(
            <tr key={audit._id} className={styles.td}>
              <td>
                <time dateTime={timestamp.format('YYYY-MM-DD HH:MM')}>{timestamp.format('YYYY-MM-DD hh:mm A')}</time>
              </td>
              <td style={{ maxWidth: '30px' }}>
                <AuditChange auditChange={auditChange} styles={styles} />
              </td>
              <td>{audit.userId ? `${audit.userId.first_name} ${audit.userId.last_name}` : 'Unknown'}</td>
            </tr>
          );
        }
        return validAudits;
      }, [])}
    </tbody>
  </table>
);

export const History = ({
  styles = {},
  dispatch,
  person,
  company,
  venue,
  entityLabel,
  listHistoryProfile,
  _dispatch = dispatch,
}) => {
  const [history, setHistory] = useState();
  const [total, setTotal] = useState(0);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    const entityId = (person || company || venue)?._id ?? null;
    if (!entityId || !listHistoryProfile) return;
    _dispatch(listHistoryProfile(entityId, entityLabel, { skip, limit: PAGINATION_FACTOR })).then((response) => {
      const { body, headers } = response;
      let bodyCopy = cloneDeep(body);

      if (body && body.length) {
        for (let i = 0; i < body.length; i++) {
          const data = body[i];
          let groupRelationships;

          if (data?.delta?.utaEmployeeInfo?.internalTeam?.groupRelationships) {
            groupRelationships = data?.delta?.utaEmployeeInfo?.internalTeam?.groupRelationships;
          }

          if (data?.delta?.internalTeam) {
            groupRelationships = data?.delta?.internalTeam;
          }

          if (data?.delta?.territoryInfo?.touringInfo) {
            groupRelationships = data?.delta?.territoryInfo?.touringInfo;
          }

          if (groupRelationships && groupRelationships['_0'] && groupRelationships['_0'].length === 3) {
            /**
             * If an agent has been deleted as one of the types of UTA relationship agents for a profile,
             * we make the API call to fetch the company/person details based on the groupId and populate
             * the history object with Name and Contact type. The hisory API does not provide thesw details.
             * Hence the need for an additional API call.
             * */

            getGroup(`${groupRelationships['_0'][0].group}`)
              .then((result) => {
                bodyCopy[i].delta.utaEmployeeInfo.internalTeam.groupRelationships['_0'][0].group = {
                  name: result.name,
                  type: result.type,
                };

                setHistory(transformResponse(bodyCopy));
              })
              .catch((err) => console.log(err));
          }

          if (isArray(groupRelationships) && groupRelationships.length) {
            getUtaEmployee(`${groupRelationships[0].utaEmployee}`)
              .then((result) => {
                bodyCopy[i].delta.internalTeam = {
                  name: result.name,
                  type: result.type,
                };

                setHistory(transformResponse(bodyCopy));
              })
              .catch((err) => console.log(err));
          } else if (typeof groupRelationships === 'object') {
            if (
              groupRelationships['_0'] &&
              groupRelationships['_0']['0'] &&
              groupRelationships['_0']['0']['utaEmploye']
            ) {
              bodyCopy[i].delta.internalTeam = {
                name: groupRelationships['_0']['0'].utaEmployee.name,
                type: groupRelationships['_0']['0'].type,
                changeType: 'Deleted',
              };
            }

            if (groupRelationships['_id'] && groupRelationships['_id'].length > 0) {
              bodyCopy[i].delta.territoryInfo = {
                name: groupRelationships['_id'][0],
                type: groupRelationships['_id'][1],
                changeType: 'Deleted',
              };
            }

            if (groupRelationships['0'] && groupRelationships[0]['0'] && groupRelationships[0]['0'].utaEmployee) {
              bodyCopy[i].delta.internalTeam = {
                name: groupRelationships[0]['0'].utaEmployee.name,
                type: groupRelationships[0]['0'].type,
                changeType: 'Added',
              };
            }
          }
        }
      }

      const total = (headers && headers.get && headers.get('Pagination-Total')) || 0;
      setHistory(transformResponse(body));
      setTotal(total);
    });
  }, [_dispatch, listHistoryProfile, person, company, venue, entityLabel, skip]);

  if (!history) return <Spinner size={20} />;

  return (
    <>
      <AuditTable styles={styles} history={history} />
      <span className={styles.pagination}>
        {total > skip && skip > 0 && (
          <i className="fa fa-caret-left fa-2x" onClick={() => setSkip(skip - PAGINATION_FACTOR)} />
        )}
        {total > skip + PAGINATION_FACTOR && (
          <i className="fa fa-caret-right fa-2x" onClick={() => setSkip(skip + PAGINATION_FACTOR)} />
        )}
      </span>
    </>
  );
};

const withStyles = styled({
  header: {
    display: 'flex',
    marginBottom: 20,
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
  },
  filterText: {
    color: contentColor,
    fontSize: '0.7em',
    fontWeight: 300,
    margin: '0 10px',
  },
  dateInput: {
    width: 150,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
    '>th': {
      textAlign: 'left',
      padding: 10,
    },
  },
  td: {
    '>td': {
      fontSize: 12,
      fontWeight: 300,
      color: text,
      padding: '15px 10px',
    },
    backgroundColor: contentBackground,
    borderBottom: `1px solid ${background}`,
  },
  changeLine: {
    fontSize: 12,
    whiteSpace: 'pre-line',
  },
  changeList: {
    listStyle: 'none',
    paddingLeft: '0px',
  },
  changeKey: {
    fontWeight: 1000,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    '> i': {
      color: 'black',
      cursor: 'pointer',
      padding: '5px',
    },
  },
});

export default withStyles(History);
