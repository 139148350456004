import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

export const DEFAULT_DATE_FORMAT = 'M/D/YYYY';
export const DEFAULT_DATE_PARSE_FORMAT = 'YYYY-MM-DD';

const inputStyles = {
  border: '1px solid #EBEBEB',
  fontSize: '13px',
  height: '40px',
  width: '23em',
  padding: '0.6em 10px',
  paddingTop: '0.9em',
  cursor: 'pointer',
};

export const DatePicker = ({ onDayChange, value, dateFormat = DEFAULT_DATE_FORMAT }) => (
  <DayPickerInput
    placeholder={dateFormat}
    format={dateFormat}
    formatDate={formatDate}
    onDayChange={onDayChange}
    parseDate={parseDate}
    value={value}
    inputProps={{
      style: inputStyles,
    }}
    dayPickerProps={{
      canClear: true,
      max: new Date(),
    }}
  />
);
