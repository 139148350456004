import React, { Fragment } from 'react';

import { sectionReadData, TerritoryBox, TerritoryHeader } from './ReadViewSupport';
import InfoLine from './InfoLine';

export const TouringAgentTable = ({ territoriesData, isClientComedy }) => {
  const sectionedData = sectionReadData(territoriesData);
  const sectionKeys = Object.keys(sectionedData);

  return (
    <>
      {sectionKeys.map((territoryData, territoryIdx) => (
        <TerritoryBox key={territoryIdx}>
          {sectionedData[territoryData].map((data, Idx) => (
            <Fragment key={Idx}>
              {Idx === 0 ? <TerritoryHeader>{data.regions[0]}</TerritoryHeader> : null}
              <div style={{ marginTop: Idx !== 0 ? 8 : 'auto' }}>
                <InfoLine relationship={data} isClientComedy={isClientComedy} />
              </div>
            </Fragment>
          ))}
        </TerritoryBox>
      ))}
    </>
  );
};
