import React from 'react';
import styled from 'styled-components';
import { colors } from '@united-talent-agency/julius-frontend-components';
export const InfoCard = (props) => {
  return (
    <div>
      <CardPane>
        <CardBody>
          <div>{props.children}</div>
        </CardBody>
      </CardPane>
    </div>
  );
};

const CardPane = styled.div((props) => ({
  background: colors ? colors.contentBackground : 'default',
  marginBottom: props.mergeCardData ? 16 : 20,
  minHeight: props.mergeCardData?.cardHeight,
}));
const CardBody = styled.div({
  padding: '15px 25px',
});

export default InfoCard;
