import React from 'react';
import styled from 'styled-components';
import { elements } from '@united-talent-agency/julius-frontend-components';
import cypressTags from '../../support/cypressTags';

export const SaveButton = ({ onSave, hasErrors }) => (
  <SaveButtonComp data-cy={cypressTags.COMMON.CREATE_SAVE_BUTTON} onClick={onSave} disabled={hasErrors}>
    SAVE
  </SaveButtonComp>
);

const SaveButtonComp = styled.div((props) => ({
  ...elements.menuItem,
  ...elements.activeMenuItem,
  marginLeft: 'auto',
  marginRight: 34,
  width: '100px',
  alignItems: 'center',
  userSelect: 'none',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
  justifyContent: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
}));
