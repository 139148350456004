import React, { useCallback } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { debounce } from 'lodash';
import { Spinner, colors } from '@united-talent-agency/components';

const matchingLegacyStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    borderColor: 'rgba(224, 224, 224, 1)',
    backgroundColor: '',
    color: '',
    border: '1px solid rgb(224, 224, 224)',
    '&:hover': {
      border: '1px solid rgb(224, 224, 224)',
    },
    boxShadow: 'none',
    height: 40,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginRight: 3,
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 500,
    top: '65%',
  }),
  placeholder: (provided, state) => {
    const showSmallerLabel = state.hasValue || state.selectProps.inputValue || state.selectProps.menuIsOpen;
    return {
      ...provided,
      position: 'absolute',
      top: showSmallerLabel ? 10 : '50%',
      transition: '0.05s ease-in',
      color: colors.nobel,
      fontSize: showSmallerLabel ? '0.6em' : '0.8em',
      textTransform: showSmallerLabel && 'uppercase',
      marginBottom: showSmallerLabel && 3,
    };
  },
  container: (provided) => ({
    ...provided,
    fontSize: 13,
    fontWeight: 300,
    height: 40,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
};

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused || props.selectProps.menuIsOpen}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(children, (child) => child && child)}
    </components.ValueContainer>
  );
};

const CustomInput = ({ ...props }) => {
  return <components.Input {...props} style={{ marginTop: 7 }} />;
};

const LoadingIndicator = (props) => <Spinner {...props} size={20} />;

/**
 * ## PROPS
 * - title: the placeholder message, used to 'title' the component
 * - options: a list of options in the format of [{value: <VALUE>, label: <LABEL>, ...canAddOtherInfoHere }]
 * - onChange: onChange handler, the easiest way to get the selected value from the dropdown
 * - onInputChange: the handler for the typing part of the component. If you need to hook into this value for any reason, use this
 * - noOptionsMessage: a function which returns what message should be displayed when, after filtering or loading, nothing matches
 * - loadingMessage: a function which returns what message should be displayed when the component is loading up more values
 * - loadable: does this component make requests to some third party source to get its values?
 * - loadOptions: if loadable is set to true, _how_ does this component get its values?
 * - isClearable: the value in the input box can be cleared by clicking the x
 * - readOnly: select is disabled, value provided in defaultValue is readOnly
 * - defaultValue: default value provided to display in the input box
 * - initialPopulate: if true, initial dropdown is loaded with options; if false, fire off the request as specified by 'loadOptions'
 * - matchLegacyStyle: hopefully soon we can rip this back out but some app(s) using this component haven't gotten the components
 *                     viewed right next to it up to date, so if we don't style it like it was before things look super weird, lets
 *                     get thematic styling in here instead
 *
 * ## A NOTE ON LOADABLE
 * - if you specify loadable, this component is gonna need loadOptions
 */
const CreatableSelectDropdown = ({
  title = 'Title',
  options = [],
  onChange,
  onInputChange,
  noOptionsMessage = () => 'No options',
  loadingMessage = () => 'Loading...',
  loadable,
  loadOptions,
  isClearable = true,
  readOnly,
  defaultValue,
  initialPopulate,
  matchLegacyStyle,
  menuIsOpen,
  cyTag,
}) => {
  const debouncedLoadOptions = useCallback(loadOptions && debounce(loadOptions, 200));

  //TODO: we need to set up theme styles on this component when prioritized
  return (
    <div data-cy={cyTag}>
      {loadable ? (
        <AsyncCreatableSelect
          placeholder={title}
          loadOptions={debouncedLoadOptions}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          defaultValue={defaultValue}
          onChange={onChange}
          isDisabled={readOnly}
          isClearable={isClearable}
          components={
            matchLegacyStyle
              ? { LoadingIndicator, ValueContainer: CustomValueContainer, Input: CustomInput, Placeholder: () => null }
              : null
          }
          styles={matchLegacyStyle && matchingLegacyStyles}
          cacheOptions
          initialPopulate
          defaultOptions={initialPopulate ? options : true}
          menuIsOpen={menuIsOpen}
        />
      ) : (
        <CreatableSelect
          placeholder={title}
          options={options}
          onInputChange={onInputChange}
          noOptionsMessage={noOptionsMessage}
          defaultValue={defaultValue}
          onChange={onChange}
          isDisabled={readOnly}
          isClearable={isClearable}
          components={
            matchLegacyStyle
              ? { LoadingIndicator, ValueContainer: CustomValueContainer, Input: CustomInput, Placeholder: () => null }
              : null
          }
          styles={matchLegacyStyle && matchingLegacyStyles}
          menuIsOpen={menuIsOpen}
        />
      )}
    </div>
  );
};

export default CreatableSelectDropdown;
