import React, { useState, useEffect } from 'react';
import { styled } from 'react-free-style';

import AddressesCard from '../../../../components/addresses-card/AddressesCard';
import CardSocial from '../../../../components/card-social/card-social';
import ContactsCard from '../../../../components/company-contacts-card/CompanyContactsCard';
import RolesCard from '../../../../components/company-roles-card/CompanyRolesCard';
import TeamView from '../team';
import { BasicInfoCard } from './BasicInfoCard';
import InfoCard from './InfoCard';
import { phoneContactFilter, emailContactFilter, otherContactFilter } from '../../../../support/contacts';
import { getCompanyPrivateContactsPermission } from '../../../../support/private-contact-permission';
import cypressTags from '../../../../support/cypressTags';

const CompanyProfileView = ({
  styles,
  companyInfo,
  dispatch,
  retrieveCompany,
  editCompany,
  isUTACompany,
  reservedCompanyNames,
  user = {},
  refreshProfile,
  mergeCardData,
  blockEdit,
  relations,
}) => {
  const [privateContactPermission, setPrivateContactPermission] = useState(false);

  useEffect(() => {
    getCompanyPrivateContactsPermission(dispatch, user.deskIds, companyInfo._id).then((hasPermission) =>
      setPrivateContactPermission(hasPermission)
    );
  }, [companyInfo, user, dispatch, relations]);

  const basicMerge = mergeCardData ? { ...mergeCardData.basic, primary: mergeCardData.primary } : null;
  const rolesMerge = mergeCardData ? { ...mergeCardData.roles, primary: mergeCardData.primary } : null;
  const phoneMerge = mergeCardData ? { ...mergeCardData.phone, primary: mergeCardData.primary } : null;
  const emailMerge = mergeCardData ? { ...mergeCardData.email, primary: mergeCardData.primary } : null;
  const otherMerge = mergeCardData ? { ...mergeCardData.other, primary: mergeCardData.primary } : null;
  const addressesMerge = mergeCardData ? { ...mergeCardData.addresses, primary: mergeCardData.primary } : null;

  const columns = mergeCardData ? '' : styles.columns;
  const column = mergeCardData ? '' : styles.column;

  return (
    <div className={columns} data-cy={cypressTags.COMPANY.PROFILE_VIEW}>
      <div className={column}>
        <BasicInfoCard
          company={companyInfo}
          editCompany={editCompany}
          retrieveCompany={retrieveCompany}
          isUTACompany={isUTACompany}
          reservedCompanyNames={reservedCompanyNames}
          mergeCardData={basicMerge}
          blockEdit={blockEdit}
          cyTag={cypressTags.COMPANY.BASIC_INFO_CARD}
        />
        <RolesCard
          company={companyInfo}
          refresh={retrieveCompany}
          readOnly={isUTACompany}
          mergeCardData={rolesMerge}
          blockEdit={blockEdit}
          cyTag={cypressTags.COMPANY.ROLES_CARD}
        />
        {blockEdit && !relations?.length && (
          <InfoCard>
            <p style={{ textAlign: 'center' }}>
              This client profile is not editable since it does not have an assigned agent or point agent. Please email{' '}
              <a href="mailto:onyxdatarequests@unitedtalent.com">onyxdatarequests@unitedtalent.com</a> to add one
            </p>
          </InfoCard>
        )}
        {!mergeCardData && (
          <TeamView
            entity={companyInfo}
            dispatch={dispatch}
            blockEdit={blockEdit}
            refreshProfile={refreshProfile}
            cyTag={cypressTags.COMPANY.UTA_RELATIONSHIP_CARD}
            onSave={() => {
              retrieveCompany(companyInfo._id);
              getCompanyPrivateContactsPermission(dispatch, user.deskIds, companyInfo._id).then((hasPermission) =>
                setPrivateContactPermission(hasPermission)
              );
            }}
          />
        )}
      </div>
      <div className={column}>
        <ContactsCard
          company={companyInfo}
          dispatch={dispatch}
          title="Phone Contacts"
          contactTypeFilter={phoneContactFilter}
          canSetPrimary
          privateContactPermission={privateContactPermission}
          refresh={retrieveCompany}
          mergeCardData={phoneMerge}
          blockEdit={blockEdit}
          cyTag={cypressTags.COMPANY.PHONE_CONTACTS_CARD}
        />
        <ContactsCard
          company={companyInfo}
          dispatch={dispatch}
          title="Email Contacts"
          contactTypeFilter={emailContactFilter}
          canSetPrimary
          privateContactPermission={privateContactPermission}
          refresh={retrieveCompany}
          mergeCardData={emailMerge}
          blockEdit={blockEdit}
          cyTag={cypressTags.COMPANY.EMAIL_CONTACTS_CARD}
        />
        <ContactsCard
          company={companyInfo}
          dispatch={dispatch}
          title="Other Contacts"
          contactTypeFilter={otherContactFilter}
          privateContactPermission={privateContactPermission}
          refresh={retrieveCompany}
          mergeCardData={otherMerge}
          blockEdit={blockEdit}
          cyTag={cypressTags.COMPANY.OTHER_CONTACTS_CARD}
        />
        <AddressesCard
          entity={companyInfo}
          dispatch={dispatch}
          validateAddresses
          mergeCardData={addressesMerge}
          blockEdit={blockEdit}
          cyTag={cypressTags.COMPANY.ADDRESSES_CARD}
        />
        {!mergeCardData && (
          <CardSocial
            entity={companyInfo}
            entityFieldName="company"
            refreshProfile={refreshProfile}
            blockEdit={blockEdit}
            cyTag={cypressTags.COMPANY.SOCIAL_CARD}
          />
        )}
      </div>
    </div>
  );
};

const withStyles = styled({
  columns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginLeft: 20,
    },
  },
});

export default withStyles(CompanyProfileView);
