import React, { useContext, useEffect, useState, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Spinner } from '@united-talent-agency/components';
import { buttonTypes, colors } from '@united-talent-agency/julius-frontend-components';

import EditButton from '../edit-button/edit-button';
import { CardContext } from './CardPageContainer';
import cypressTags from '../../support/cypressTags';

import './tooltip.css';
import { cross } from '../../styles/icons';

const { COMMON } = cypressTags;

/**
 * @typedef ItemOptions
 * This object is to be returned from the itemOptions callback
 *
 * These two are inherited from the Card props and can be overridden:
 * @property canSetPrimary
 * @property canDelete
 *
 * These are optional
 * @deleteDisabled
 * @primaryDisabled
 * @primaryTooltipText
 */

/**
 * Card - Main component for displaying and editing data in profiles
 *
 * Displays a single object item or a list of items
 *
 * This dries up some common functionality we had on all our cards:
 *  - displaying read-only & edit views and swapping read-only & edit mode
 *  - setting a primary item in a list
 *  - deleting items
 *  - merging final saved data
 *
 * @param {Object} props
 * @param props.title The card title
 * @param props.data Initial data, can be an array or a single object
 * @param props.readView Item read-only view: ({ item }) => {JSX.Element}
 * @param props.editView Item edit view: ({ item, setState, setPrimary, deleteItem }) => JSX.Element
 * @param props.createNew Returns a new object for use as a new item: () => object;
 * @param props.onSave When the user saves: ({ created, updated, deleted, mergedData }) => Promise
 * @param props.onValidateItem Validates a single item: (item) => boolean
 * @param props.itemOptions {ItemOptions} Gets options on an item, ({item, idx}) => ItemOptions
 * @param props.canSetPrimary {boolean} If the primary column is visible in edit mode
 * @param props.canDelete {boolean} If the delete column is visible in edit mode
 * @param props.firstIsPrimary {boolean} If the first item in the form is marked primary
 */
export default function Card({
  title,
  data: _data,
  readView,
  editView,
  createNew,
  onSave,
  onChange,
  onValidateItem,
  itemOptions,
  canSetPrimary,
  privateContactPermission,
  canDelete,
  firstIsPrimary,
  apiError,
  itemId: getItemId = () => 'DEFAULT_ID',
  mergeCardData,
  blockCreateMultiple,
  isLoading = false,
  blockDefaultEmptyItem = false,
}) {
  const forceEditMode = !!onChange;

  const [initialData, setInitialData] = useState(_data);
  const [updates, setUpdates] = useState({});
  const [deletes, setDeletes] = useState({});
  const [creates, setCreates] = useState({});
  const [isEditing, setIsEditing] = useState(forceEditMode);
  const [hasError, setHasError] = useState(false);
  const [multiPrimary, setMultiPrimary] = useState(false);
  const cardContext = useContext(CardContext);

  // If the underlying data changes, then reset our view
  useEffect(() => {
    // Note, there are some hoops we're going through here with initialData because:
    //  1. A user might edit another Card, that saves the company, the company updates from the API and gets new values,
    //      if we're still in edit mode and the server has added/removed something, this has unpredictable effects
    //  2. react-hooks/exhaustive-deps is fun... Having [data] as a dependency wasn't good enough,
    //      [data, isEditing] is required to make this rule pass,
    //      but we don't want to call this effect when isEditing changes
    //  3. We don't want to reset data when exiting edit mode because the onSave call is likely doing an async operation
    //      When that comes back, it will update the underlying data and initialData will be updated
    //      In the meantime, we want to display the user's recent changes in the read view
    if (_data !== initialData && !isEditing) {
      reset();
    }
  });

  // A single object (or null) can be passed in, and we deal with arrays
  const data = Array.isArray(initialData) ? initialData : [initialData];

  // TODO: Sometimes we have an array without _id's on them. WHAT TO DO
  //   Copy all the objects and create _id's = GENERATED-ID and strip these at the end?
  //   Right now, RepresentedByInfo uses company as the ID. These are enforced to be unique by the server
  //   If non-unique ids are used, the results will be unpredictable

  // Default to the item._id since we sometimes generate those for creates
  const getId = (item) => item._id || getItemId(item);

  const mergedData = [...data, ...Object.values(creates)]
    .filter((item) => !deletes[getId(item)])
    .map((item) => ({ ...item, ...updates[getId(item)] }));

  // Ignoring creates without updates (Default values, or user hit +, let them hit save and filter these out)
  // This allows for empty data to be ignored on save, go ham on that + button
  const saveableData = mergedData.filter((item) => !creates[item._id] || updates[item._id]);

  const getPrimaryCount = (array, field) => {
    return array.reduce((count, obj) => {
      if (obj[field] === true) {
        count++;
      }
      return count;
    }, 0);
  };

  // If we switch to edit mode, default to having at least one item in the list
  useEffect(() => {
    if (isEditing && mergedData.length === 0 && !blockDefaultEmptyItem) {
      createItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  // Validation
  useEffect(() => {
    if (!onValidateItem || !isEditing) return;

    const mergedDataHasError = !saveableData.every(onValidateItem);
    if (mergedDataHasError !== hasError) {
      setHasError(mergedDataHasError);
    }
    const getInfo = getSaveInfo();
    const primaryCount = getPrimaryCount(getInfo.mergedData, 'primary');

    if (primaryCount > 1) {
      setMultiPrimary(true);
      setHasError(true);
    } else {
      setMultiPrimary(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates, creates, deletes]);

  useEffect(() => {
    // ReactTooltip needs to rebind to all the data-tip's
    // Debounced for performance
    const handle = setTimeout(ReactTooltip.rebuild, 400);
    return () => clearTimeout(handle);
  }, [updates, creates, deletes]);

  useEffect(() => {
    if (onChange) {
      onChange(getSaveInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates, creates, deletes, hasError]);

  const reset = () => {
    setInitialData(_data);
    setUpdates({});
    setDeletes({});
    setCreates({});

    setIsEditing(false);
  };

  const cancel = () => {
    reset();
  };

  const canEdit = !!editView && (mergeCardData?.primary || !mergeCardData);

  const _setState = (id, data) => {
    setUpdates({ ...updates, [id]: { ...updates[id], ...data } });
  };

  const _setPrivate = (id, isPrivate) => {
    const privateUpdates = { ...updates };
    Object.values(mergedData).forEach((item) => {
      const itemId = getId(item);
      if (item.isPrivate && itemId === id) {
        privateUpdates[itemId] = { ...updates[itemId], isPrivate: false };
      }
      if (!item.isPrivate && isPrivate && itemId === id) {
        privateUpdates[itemId] = { ...updates[itemId], isPrivate: true };
      }
    });
    setUpdates(privateUpdates);
  };

  const _setPrimary = (id, primary) => {
    const primaryUpdates = { ...updates };
    Object.values(mergedData).forEach((item) => {
      const itemId = getId(item);
      if (item.primary && ((primary && itemId !== id) || (!primary && itemId === id))) {
        primaryUpdates[itemId] = { ...updates[itemId], primary: false };
      }
      if (!item.primary && primary && itemId === id) {
        primaryUpdates[itemId] = { ...updates[itemId], primary: true };
      }
    });
    setUpdates(primaryUpdates);
  };

  const _deleteItem = (id) => {
    setDeletes({ ...deletes, [id]: true });
    if (updates[id]) {
      setUpdates(removeKey(id, updates));
    }
    if (creates[id]) {
      setCreates(removeKey(id, creates));
    }
  };

  const createItem =
    createNew &&
    (() => {
      const newItem = createNew();
      const newItemId = `NEW-ITEM-${Date.now()}`;

      // This is for internal purposes of this component and will be removed on save
      newItem._id = newItemId;
      setCreates({ ...creates, [newItemId]: newItem });
    });

  const getSaveInfo = () => {
    const existingIds = data.map((item) => getId(item));

    const removeId = (item) => removeKey('_id', item);

    const mergedCreates = saveableData.filter((item) => item._id && !existingIds.includes(item._id)).map(removeId);

    const updatesWithoutCreates = {};
    existingIds
      .filter((id) => updates[id])
      .forEach((id) => {
        updatesWithoutCreates[id] = updates[id];
      });

    const cleanMergedData = saveableData.map((item) =>
      item._id && item._id.includes('NEW-ITEM-') ? removeId(item) : item
    );

    const saveInfo = {
      updates: updatesWithoutCreates,
      creates: mergedCreates,
      deletes,
      mergedData: cleanMergedData,
      hasError,
    };

    return saveInfo;
  };

  const save = () => {
    const saveInfo = getSaveInfo();
    const onSavePromise = onSave(saveInfo);

    if (cardContext.onSave) {
      if (onSavePromise instanceof Promise) {
        onSavePromise.then(() => cardContext.onSave());
      } else {
        console.warn('Card onSave should return a promise so CardContext.onSave works properly');
      }
    }

    // Delete any empty creates, these were filtered out above before saving
    const updatedCreates = {};
    Object.keys(creates)
      .filter((item) => updates[item._id])
      .forEach((item) => (updatedCreates[item._id] = item));
    setCreates(updatedCreates);

    setIsEditing(false);
  };

  const showPrimaryHeader = isEditing && canSetPrimary && mergedData.length;
  const totalEntries = mergedData.length;

  const displayData = isEditing ? mergedData : saveableData;

  return (
    <CardPane name={mergeCardData?.name} mergeCardData={mergeCardData}>
      {renderHeader({
        mergeCardData,
        canEdit,
        forceEditMode,
        cancel,
        title,
        hasError,
        isEditing,
        setIsEditing,
        save,
        mergedData,
        createItem,
        isLoading,
      })}
      <CardBody>
        {isEditing && multiPrimary && (
          <div style={{ color: 'red', fontSize: '12px' }}>{'Only one Primary company is allowed'}</div>
        )}
        {!!showPrimaryHeader && <span style={{ fontSize: '12px' }}>Primary</span>}
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Spinner size={40} />
          </div>
        )}
        {!isLoading &&
          displayData.map((item, idx) => {
            const itemId = getId(item);
            const setState = (itemUpdates) => _setState(itemId, itemUpdates);
            const setPrimary = (primary) => _setPrimary(itemId, primary);
            const setPrivate = (isPrivate) => _setPrivate(itemId, isPrivate);
            const deleteItem = () => _deleteItem(itemId);

            const _itemOptions = {
              canSetPrimary,
              canDelete,
              deleteDisabled: item.isPrivate && !privateContactPermission,
              hasPrivateContactsPermissions: privateContactPermission,
              primaryTooltipText: item.isPrivate ? 'This contact is private, unlock to make primary' : null,
              ...(itemOptions && itemOptions({ item, idx })),
            };

            // Unique keys to prevent read-only components being re-used as edit components
            const key = isEditing ? `card-edit-${itemId}` : `card-read-${itemId}`;
            const view = isEditing
              ? renderEditItemRow({
                  item,
                  setState,
                  firstIsPrimary,
                  setPrimary,
                  setPrivate,
                  deleteItem,
                  editView,
                  idx,
                  totalEntries,
                  itemOptions: _itemOptions,
                })
              : readView({ item, hasPrivateContactsPermissions: privateContactPermission });

            return <Fragment key={key}>{view}</Fragment>;
          })}
      </CardBody>
      {renderFooter({ createItem, isEditing, apiError, blockCreateMultiple })}
    </CardPane>
  );
}

function renderEditItemRow({
  item,
  setState,
  setPrimary,
  setPrivate,
  firstIsPrimary,
  deleteItem,
  totalEntries,
  editView,
  itemOptions,
  idx,
}) {
  const {
    canSetPrimary,
    canDelete,
    deleteDisabled,
    primaryDisabled,
    primaryTooltipText,
    hasPrivateContactsPermissions,
  } = itemOptions;
  const alwaysPrimary = firstIsPrimary && idx === 0 && totalEntries <= 1;
  if (alwaysPrimary && !item.primary) {
    setPrimary(true);
  }

  const primaryButton = (
    <PrimaryRadioButton
      checked={alwaysPrimary || !!item.primary}
      disabled={item.isPrivate || alwaysPrimary || !!primaryDisabled}
      onChange={() => setPrimary(!item.primary)}
      onClick={() => {
        if (item.primary) {
          setPrimary(false);
        }
      }}
    />
  );

  return (
    <>
      <EditItemRow>
        {canSetPrimary && (
          <div>
            {item.isPrivate ? (
              <span data-tip data-for={`primary-button-${item._id}`}>
                {primaryButton}
              </span>
            ) : (
              primaryButton
            )}
          </div>
        )}
        <ContentColumn>
          {editView &&
            editView({ item, setState, setPrimary, setPrivate, deleteItem, idx, hasPrivateContactsPermissions })}
        </ContentColumn>
        {canDelete && (
          <DeleteColumn>
            {!deleteDisabled && (
              <span data-cy={cypressTags.COMMON.ROW_DELETE_CROSS_BUTTON} onClick={deleteItem}>
                <DeleteButton />
              </span>
            )}
          </DeleteColumn>
        )}
      </EditItemRow>
      <ReactTooltip
        id={`primary-button-${item._id}`}
        type="light"
        place="right"
        effect="solid"
        className="react-tooltip"
      >
        {primaryTooltipText}
      </ReactTooltip>
    </>
  );
}

function renderHeader({
  mergeCardData,
  canEdit,
  forceEditMode,
  cancel,
  title,
  hasError,
  isEditing,
  setIsEditing,
  save,
  isLoading,
}) {
  if (!canEdit && !title) return null;
  const style = mergeCardData ? { background: '#DFDFDB', height: '30px', padding: '15px' } : {};
  const cardTitle = (!mergeCardData || mergeCardData?.primary) && title;

  return (
    <CardHeader isEditing={isEditing} forceEditMode={forceEditMode} style={style}>
      <CardTitle>{cardTitle}</CardTitle>
      {!forceEditMode && headerButtons({ canEdit, setIsEditing, isEditing, cancel, save, hasError, isLoading })}
    </CardHeader>
  );
}

function headerButtons({ canEdit, setIsEditing, isEditing, cancel, save, hasError, isLoading }) {
  return (
    !isLoading && (
      <>
        {!isEditing && canEdit && (
          <CardButton
            cyTag={COMMON.BASIC_INFO_CARD.EDIT_BUTTON}
            type={buttonTypes.edit}
            onClick={() => setIsEditing(true)}
          />
        )}
        {isEditing && (
          <div data-cy={cypressTags.COMMON.CANCEL_BUTTON}>
            <CardButton type={buttonTypes.cancel} onClick={cancel} />
          </div>
        )}
        {isEditing && (
          <div data-cy={cypressTags.COMMON.SAVE_BUTTON}>
            <CardButton disabled={hasError} type={buttonTypes.save} onClick={save} />
          </div>
        )}
      </>
    )
  );
}

function renderFooter({ createItem, isEditing, apiError, blockCreateMultiple }) {
  if (!isEditing || !createItem) return null;

  return (
    <>
      {apiError && <div style={{ color: 'red', fontSize: 14, marginLeft: 30 }}>{apiError}</div>}
      {!blockCreateMultiple && (
        <CardFooter>
          <CardButton type={buttonTypes.new} onClick={() => createItem()} cyTag={cypressTags.COMMON.CARD_NEW_BUTTON} />
        </CardFooter>
      )}
    </>
  );
}

const removeKey = (key, obj) => {
  // Using the spread operator to remove a key/prop from an object
  // This could be fixed with an ESLint rule change - no-unused-vars, ignoreRestSiblings=true
  //   "no-unused-vars": ["error", { "vars": "all", "args": "after-used", "ignoreRestSiblings": true }]

  // eslint-disable-next-line no-unused-vars
  const { [key]: deleted, ...rest } = obj;
  return rest;
};

export const CardPane = styled.div((props) => ({
  background: colors ? colors.contentBackground : 'default',
  marginBottom: props.mergeCardData ? 16 : 20,
  minHeight: props.mergeCardData?.cardHeight,
}));

export const CardHeader = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px 0px 15px',
  background: props.isEditing && !props.forceEditMode ? '#90E2D3' : '',
}));

export const CardBody = styled.div({
  padding: '5px 15px',
});

export const CardFooter = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px',
  borderTop: '1px dotted #D9D9D9',
});

export const CardTitle = styled.h3({
  flex: 1,
  fontSize: 13,
  fontWeight: 700,
  margin: '5px 0',
});

export const CardButton = styled(EditButton)({
  marginLeft: 10,
});

const EditItemRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'none',
  width: '100%',
});

const ContentColumn = styled.div({
  flexGrow: 1,
});

const DeleteColumn = styled.div({
  marginLeft: 10,
  paddingTop: 10,
  width: 10,
});

const DeleteButton = styled.span({
  ...cross,
  cursor: 'pointer',
});

const PrimaryRadioButton = styled.input.attrs({
  type: 'radio',
})((props) => {
  const borderSize = props.checked ? '4px' : '1px';
  const borderColor = props.disabled ? 'grey' : '#141414';
  const cursor = props.disabled ? 'default' : 'pointer';
  return {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    transition: '0.2s all linear',
    outline: 'none',
    cursor: cursor,
    marginTop: 20,
    marginRight: 14,
    marginLeft: 12,
    border: `${borderSize} solid ${borderColor}`,
  };
});
