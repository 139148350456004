import React, { useState } from 'react';

import { PersonInput, Select } from '@united-talent-agency/julius-frontend-components';

const AccountingRepEditor = ({ relationship = {}, onSearch, onPersonChanged, onAgentTypeChanged }) => {
  const [searchText, setSearchText] = useState('');
  const [peopleResults, setPeopleResults] = useState([]);

  const agentTypes = ['United Talent Agency, LLC', 'United Talent Agency UK Operations Ltd'];
  const agentTypeOptions = agentTypes.map((agentType) => ({
    key: agentType,
    content: agentType,
    active: agentType === relationship.subsidairyType,
    styles: { padding: 10 },
    onClick: () => {
      onAgentTypeChanged && onAgentTypeChanged(agentType);
    },
  }));

  return (
    <>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div style={{ flex: 0.5, paddingRight: 5, paddingBottom: 10 }}>
          <Select title="Subsidiary" optionStyle={{ padding: 10 }} items={agentTypeOptions}>
            {relationship.subsidairyType}
          </Select>
        </div>

        <div style={{ flex: 0.5, paddingLeft: 5, paddingBottom: 10 }}>
          <PersonInput
            style={{
              marginBottom: 10,
              fontWeight: 100,
            }}
            value={searchText || (relationship.personId || {}).name || ''}
            onChange={(name) => {
              if (name.length > 1) {
                setSearchText(name);
                onSearch && onSearch(name).then(setPeopleResults);
              } else {
                setPeopleResults([]);
              }
            }}
            results={peopleResults}
            onSelect={(personId) => {
              setSearchText('');
              onPersonChanged && onPersonChanged(personId);
            }}
            dropDownMode="floating"
          />
        </div>
      </div>
    </>
  );
};

export default AccountingRepEditor;
