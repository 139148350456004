import React from 'react';
import { loadPerson, updatePersonProfile } from '@united-talent-agency/julius-frontend-store';

import AgencyEditor from '../../../../components/agency-editor/AgencyEditor';
import AgencyLabel from '../../../../components/agency-editor/AgencyLabel';
import Card from '../../../../components/card/Card';
import CommonTooltip from '../../../../components/common/tooltip';

import { TOOLTIP_MESSAGES } from '../../../../components/common/messages/tooltip-messages';
import cypressTags from '../../../../support/cypressTags';

const { PERSON } = cypressTags;
const { REPRESENTED_BY_CARD } = PERSON;

export function itemOptions({ item: agency, person_ }) {
  const personIsClient = getPersonIsClient(person_);
  const agencyIsUta = getAgencyIsUta(agency);

  let primaryDisabled = false;
  let primaryTooltipText = '';

  if (personIsClient) {
    primaryDisabled = true;
    primaryTooltipText = "'Client' type must have UTA set as primary";
  }
  if (!personIsClient && agencyIsUta) {
    primaryDisabled = true;
    primaryTooltipText = "Only 'Client' type can have UTA set as primary";
    agency.primary = false;
  }

  return {
    deleteDisabled: agencyIsUta && personIsClient,
    primaryDisabled,
    primaryTooltipText,
  };
}

export const RepresentedByInfo = ({ person, searchGroups, dispatch, mergeCardData, blockEdit }) => {
  const saveChanges = ({ mergedData }) => {
    // TODO: Is this filter needed any more? Should the validator handle this?
    const validAgencies = Object.values(mergedData).filter((agency) => {
      return agency.company._id || !!agency.company.name;
    });
    const personPayload = { representedByAgencies: validAgencies };
    return dispatch(updatePersonProfile(person._id, personPayload)).then(() => {
      return dispatch(loadPerson(person._id));
    });
  };

  const _searchGroups = async (name) => {
    return await searchGroups(name);
  };

  const getRepresentedAgencies = (person) => {
    let companies = person.representedByAgencies || [];

    // filter out any companies that is null
    companies = companies.map((company) => {
      return {
        ...company,
        primary: company.company ? company.primary : false,
        company: company.company || {
          _id: Math.random().toString(36).substring(7),
          name: 'Invalid Data',
        },
      };
    });

    let primary = null;
    companies.forEach((company) => {
      if (company.primary) {
        primary = company;
      }
    });

    // sort the response array by company.name
    companies.sort((a, b) => {
      if (a.company.name < b.company.name) {
        return -1;
      }
      if (a.company.name > b.company.name) {
        return 1;
      }
      return 0;
    });

    // put the primary company at the beginning of the array
    if (primary) {
      companies.splice(companies.indexOf(primary), 1);
      companies.unshift(primary);
    }

    return companies;
  };

  const showRepresented = !mergeCardData || !mergeCardData.isEmpty || person.representedByAgencies.length > 0;

  return !showRepresented ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }} data-cy={REPRESENTED_BY_CARD.CONTAINER}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        mergeCardData={mergeCardData}
        title="Representation"
        data={getRepresentedAgencies(person)}
        readView={readView}
        editView={!blockEdit && ((props) => editView(person, _searchGroups, props))}
        canSetPrimary
        canDelete
        onSave={saveChanges}
        itemId={(item) => item.company && item.company._id}
        itemOptions={(item) => itemOptions({ item, person_: person })}
        createNew={() => ({ company: { name: '' } })}
        onValidateItem={({ company }) => company && company._id}
      />
    </div>
  );
};

function readView({ item }) {
  return <AgencyLabel value={item} />;
}

function editView(person, searchGroups, { item: agency, setState }) {
  const readOnly = getAgencyIsUta(agency) && getPersonIsClient(person);

  return (
    <AgencyEditor
      cyTag={REPRESENTED_BY_CARD.EDIT_VIEW_CONTAINER}
      dropdownCyTag={REPRESENTED_BY_CARD.AGENCY_DROPDOWN_WRAPPER}
      agency={agency}
      readOnly={readOnly}
      searchGroups={searchGroups}
      onAgencyChanged={(updatedAgency) => {
        if (updatedAgency.company && updatedAgency.company.name) {
          setState(updatedAgency);
        }
      }}
    />
  );
}

export const getPersonIsClient = (person) => person.type === 'Client';

export const getAgencyIsUta = (agency) => {
  if (!agency.company) return false;
  return agency.company.name === 'UTA' && !!agency.company._id;
};

export default RepresentedByInfo;
