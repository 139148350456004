export const socialNetworks = {
  applemusic: 'Apple Music',
  earwolf: 'Earwolf',
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  snapchat: 'Snapchat',
  soundcloud: 'SoundCloud',
  spotify: 'Spotify',
  tiktok: 'TikTok',
  twitch: 'Twitch',
  twitter: 'Twitter',
  youtube: 'YouTube',
  wikipedia: 'Wikipedia',
};
