import React from 'react';
import PropTypes from 'prop-types';
import TextInputInline from './text-input-inline';

const FormGroupEmail = (props) => {
  const { styles = {}, labelAddItem, labelRemoveItem, items, onAdd, onRemove, onItemChanged, maxItems } = props;
  return (
    <div className="form-group">
      <div className="row">
        <div className="col-sm-2">
          <label className={styles.formGroupLabel}>Email Addresses</label>
        </div>
        {(!maxItems || items.length < maxItems) && (
          <div className="col-sm-10">
            <span
              className={styles.actionLink}
              onClick={() => {
                onAdd && onAdd();
              }}
            >
              {labelAddItem || 'Add'}
            </span>
          </div>
        )}
      </div>

      {items &&
        items.map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className="col-sm-2" />
              <div className="col-sm-4 pr-0">
                <TextInputInline
                  label=""
                  value={item.address}
                  onChange={(value) => {
                    item.address = value;
                    onItemChanged && onItemChanged(index, item);
                  }}
                  placeholder="Email Address"
                  styles={styles}
                />
              </div>
              <div className="col-sm-4 pr-0 pl-0">
                <TextInputInline
                  label=""
                  value={item.name}
                  onChange={(value) => {
                    item.name = value;
                    onItemChanged && onItemChanged(index, item);
                  }}
                  placeholder="Display Name"
                  styles={styles}
                />
              </div>
              <div className="col-sm-2 pl-0">
                <span
                  className={styles.actionLink}
                  onClick={() => {
                    onRemove && onRemove(index);
                  }}
                >
                  {labelRemoveItem || 'Remove'}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
};
FormGroupEmail.propTypes = {
  items: PropTypes.array.isRequired,
  styles: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onItemChanged: PropTypes.func,
  maxItems: PropTypes.number,
  labelGroup: PropTypes.string,
  labelAddItem: PropTypes.string,
  labelRemoveItem: PropTypes.string,
};

export default FormGroupEmail;
