import React from 'react';
import { Icons } from '@united-talent-agency/components';

const {
  ExternalIcon,
  FinanceIcon,
  HistoryIcon,
  NoteIcon,
  WindowAltIcon,
  UserIcon,
  TagIcon,
  ProjectIcon,
  UTATeamIcon,
  TouringIcon,
} = Icons;

/**
 * Builds the menu views for a person
 * @param {Object} person - The person object
 * @param {Object} flags - The feature flags object
 * @param {Boolean} allowTouringMenu - Whether the touring menu is allowed
 * @returns {Array} - The menu views
 */
export const buildMenuViews = (person, flags, allowTouringMenu) => {
  let views = [
    { view: '', title: 'Profile', icon: <UserIcon /> },
    { view: 'projects', title: 'Projects', icon: <ProjectIcon /> },
    {
      view: 'externalTeam',
      title: 'External Relationships',
      icon: <ExternalIcon />,
      types: ['Client', 'Industry Contact'],
    },
    { view: 'team', title: 'UTA Relationships', icon: <UTATeamIcon />, types: ['Employee'] },
    { view: 'tags', title: 'Tags', icon: <TagIcon />, types: ['Client', 'Employee'] },
    { view: 'website', title: 'Website', icon: <WindowAltIcon />, types: ['Client', 'Employee'] },
    { view: 'finance', title: 'Finance', icon: <FinanceIcon />, types: ['Client', 'Industry Contact', 'Employee'] },
    // TODO: PROF-188 add touring icon on components
    {
      view: 'touring',
      title: 'Touring',
      icon: <TouringIcon />,
      types: flags.showTouringTab && allowTouringMenu ? ['Client', 'Industry Contact'] : [],
    },
    { view: 'tags', title: 'Tags', icon: <TagIcon />, types: ['Industry Contact'] },
    { view: 'notes', title: 'Notes', icon: <NoteIcon /> },
    { view: 'history', title: 'History', icon: <HistoryIcon /> },
  ];

  return views.filter((view) => !view.types || view.types.includes(person.type));
};
