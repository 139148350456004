import React from 'react';
import styled from 'styled-components/macro';

import { colors, Icons } from '@united-talent-agency/components';

const { CheckCircleIcon } = Icons;

// Main wrapper for our pages
export const PageContainer = styled.div({
  minWidth: 800,
  minHeight: '100vh',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

export const Header = styled.div({
  background: colors ? colors.white : 'inherit',
  borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)',
  padding: '20px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const HeaderContent = styled.div({
  display: 'flex',
  flex: 1,
  maxWidth: '1000px',
  padding: '0 10px',
  margin: '0 auto',
  flexDirection: 'column',
});

export const HeaderActions = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  right: '3%',
});

export const PageHeader = ({ children }) => (
  <Header>
    <HeaderContent>{children}</HeaderContent>
  </Header>
);

// Holds the TabContainer to the left and the PageContent to the right
export const PageBody = styled.div({
  flex: 1,
  display: 'flex',
  overflow: 'hidden',
});

// Wrapping things so we can have fixed sized content that scrolls
export const PageContent = ({ children }) => (
  <PageScroll>
    <InnerPageContent>{children}</InnerPageContent>
  </PageScroll>
);

// Allows for a scrollbar on the right, holds the InnerPageContent
export const PageScroll = styled.div({
  overflowY: 'scroll',
  flex: 1,
});

// The unique content per tab will be wrapped by this last
export const InnerPageContent = styled.div({
  display: 'flex',
  flex: 1,
  maxWidth: '1000px',
  padding: '20px',
  margin: '0 auto',
  flexDirection: 'column',
});

export const TitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Name = styled.div({
  fontSize: 24,
  fontWeight: 300,
  marginLeft: 15,
});

export const Columns = styled.div({
  display: 'flex',
});

export const Column = styled.div({
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  // for a column next to me
  '& + &': {
    marginLeft: 20,
  },
});

// Wrappers to conditionally ignore columns
export const ColumnsWrapper = ({ children, ignoreCols }) => {
  return ignoreCols ? <>{children}</> : <Columns>{children}</Columns>;
};

export const ColumnWrapper = ({ children, ignoreCols }) => {
  return ignoreCols ? <>{children}</> : <Column>{children}</Column>;
};

export const SelectorContainer = styled.div({
  width: '20rem',
});

export const InfoContainer = styled.div({
  marginBottom: 10,
});

export const SpacedInfoContainer = styled.div({
  marginBottom: 10,
  '> *': {
    padding: 3,
  },
});

export const PrimaryText = styled.span({
  marginLeft: 10,
});

export const VerifyButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  height: 34,
  lineHeight: 1,
  padding: '0 16px',
  color: '#fff',
  background: '#000',
  fontSize: 13,
  width: 108,
  cursor: 'pointer',
});

export const HeaderActionItemContainer = styled.div({
  display: 'flex',
});

export const WorkdayURLContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const VerificationIcon = ({ color }) => (
  <CheckCircleIcon color={color && colors ? colors[color] : 'default'} inline style={{ marginLeft: 5, fontSize: 25 }} />
);
