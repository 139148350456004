import React from 'react';
import { helpers, styled } from 'react-free-style';
import classnames from 'classnames';
import { icons } from '@united-talent-agency/julius-frontend-components';
import { elements } from '@united-talent-agency/julius-frontend-components';

const EditButton = ({ onClick, type, styles, className, disabled, cyTag }) => {
  const clickHandler = disabled ? () => {} : onClick;

  return (
    <div
      data-cy={cyTag}
      onClick={clickHandler}
      className={classnames(styles.container, className, disabled && styles.disabled)}
    >
      <i className={classnames(styles.icon, type.icon)} />
      <span>{type.label}</span>
    </div>
  );
};

const withStyles = styled({
  container: helpers.merge(elements.actionable, {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: 1,
    padding: '5px 0',
    textTransform: 'uppercase',
  }),
  icon: {
    marginRight: 5,
  },
  disabled: {
    cursor: 'not-allowed',
  },
  newIcon: icons.plus,
  editIcon: icons.pencil,
  cancelIcon: helpers.merge(icons.crossBlack, {
    width: icons.crossBlack.width / 2,
    height: icons.crossBlack.height / 2,
  }),
  saveIcon: icons.checkBlack,
});

export const buttonTypes = {
  edit: {
    label: 'Edit',
    icon: withStyles.styles.editIcon,
  },
  save: {
    label: 'Save',
    icon: withStyles.styles.saveIcon,
  },
  cancel: {
    label: 'Cancel',
    icon: withStyles.styles.cancelIcon,
  },
  new: {
    label: 'New',
    icon: withStyles.styles.newIcon,
  },
  publish: {
    label: 'Share with UTA',
    icon: withStyles.styles.newIcon,
  },
};

export default withStyles(EditButton);
