import React from 'react';
import { isEmpty } from 'lodash';

const displayTouringAgents = ({ subKey, changes }) => {
  return (
    <>
      {changes['created'] &&
        changes['created'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Territories: '}</strong>}
            {'Territory: '}
            {change.territory && change.territory}
            {'; Location: '}
            {change.location && change.location}
            {'; Subsidiary: '}
            {change.subsidiary && change.subsidiary}
            {'; Line Of Business: '}
            {change.lineOfBusiness && change.lineOfBusiness}
            {'; Department: '}
            {change.department && change.department}
            {' was created'}
          </div>
        ))}
      {changes['updated'] &&
        changes['updated'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Territories: '}</strong>}
            {'Territory: '}
            {Array.isArray(change?.territory)
              ? change?.territory[0]
              : Array.isArray(change?.territory)
              ? change?.territory[0]
              : change?.territory}
            {'; Location: '}
            {Array.isArray(change?.location)
              ? change?.location[0]
              : Array.isArray(change?.location)
              ? change?.location[0]
              : change?.location}
            {'; Subsidiary: '}
            {Array.isArray(change?.subsidiary)
              ? change?.subsidiary[0]
              : Array.isArray(change?.subsidiary)
              ? change?.subsidiary[0]
              : change?.subsidiary}
            {'; Line of Business: '}
            {change?.lineOfBusiness && Array.isArray(change?.lineOfBusiness) && change?.lineOfBusiness.length > 0
              ? change?.lineOfBusiness[0]
              : change?.lineOfBusiness}
            {'; Department: '}
            {Array.isArray(change?.department) ? change?.department[0] : change?.department}
            {' was changed to '}
            {' Territory: '}
            {Array.isArray(change?.territory)
              ? change?.territory[1]
              : Array.isArray(change?.territory)
              ? change?.territory[1]
              : change?.territory}
            {'; Location: '}
            {Array.isArray(change?.location)
              ? change?.location[1]
              : Array.isArray(change?.location)
              ? change?.location[1]
              : change?.location}
            {'; Subsidiary: '}
            {Array.isArray(change?.subsidiary)
              ? change?.subsidiary[1]
              : Array.isArray(change?.subsidiary)
              ? change?.subsidiary[1]
              : change?.subsidiary}
            {'; Line of Business: '}
            {change?.lineOfBusiness && Array.isArray(change?.lineOfBusiness) && change?.lineOfBusiness.length > 0
              ? change?.lineOfBusiness[1]
              : change?.lineOfBusiness}
            {'; Department: '}
            {Array.isArray(change?.department) ? change?.department[1] : change?.department}
          </div>
        ))}
      {changes['deleted'] &&
        changes['deleted'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Territories: '}</strong>}
            {'Territory: '}
            {change.territory && change.territory}
            {'; Location: '}
            {change.location && change.location}
            {'; Subsidiary: '}
            {change.subsidiary && change.subsidiary}
            {'; Line Of Business: '}
            {change.lineOfBusiness && change.lineOfBusiness}
            {'; Department: '}
            {change.department && change.department}
            {' was deleted '}
          </div>
        ))}
    </>
  );
};

const handleTouringAgents = ({ territoryInfo, auditChangeObject, subKey, AUDIT_LABEL_DISPLAY }) => {
  const { touringAgentTerritories = [] } = territoryInfo;
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[subKey];
  let touringInfoHistory = [];
  const touringAgentRecords = Object.keys(touringAgentTerritories);
  const changes = {};
  if (touringAgentRecords.length > 0) {
    touringAgentRecords.forEach((ds) => {
      if (Array.isArray(touringAgentTerritories[ds]) && touringAgentTerritories[ds].length === 1) {
        if (!changes['created']) {
          changes['created'] = [];
        }
        changes['created'].push({
          department: touringAgentTerritories[ds][0].department,
          lineOfBusiness: touringAgentTerritories[ds][0].lineOfBusiness,
          location: touringAgentTerritories[ds][0].location,
          subsidiary: touringAgentTerritories[ds][0].subsidiary,
          territory: touringAgentTerritories[ds][0].territory,
          //   primary: contractSignerBlocks[territory][dsKey][0].primary
        });
      } else if (
        typeof touringAgentTerritories[ds] === 'object' &&
        !Array.isArray(touringAgentTerritories[ds]) &&
        touringAgentTerritories[ds].changeType !== 'Deleted'
      ) {
        // agent updated
        if (!changes['updated']) {
          changes['updated'] = [];
        }
        if (
          touringAgentTerritories[ds]?.department ||
          touringAgentTerritories[ds]?.lineOfBusiness ||
          touringAgentTerritories[ds]?.location ||
          touringAgentTerritories[ds]?.subsidiary ||
          touringAgentTerritories[ds]?.territory
        ) {
          changes['updated'].push({
            department: touringAgentTerritories[ds]?.department && touringAgentTerritories[ds]?.department,
            lineOfBusiness: touringAgentTerritories[ds]?.lineOfBusiness && touringAgentTerritories[ds]?.lineOfBusiness,
            location: touringAgentTerritories[ds]?.location && touringAgentTerritories[ds]?.location,
            subsidiary: touringAgentTerritories[ds]?.subsidiary && touringAgentTerritories[ds]?.subsidiary,
            territory: touringAgentTerritories[ds]?.territory && touringAgentTerritories[ds]?.territory,
          });
        }
      } else if (
        Array.isArray(touringAgentTerritories[ds]) &&
        touringAgentTerritories[ds].length === 3 &&
        touringAgentTerritories[ds][2] === 0
      ) {
        if (!changes['deleted']) {
          changes['deleted'] = [];
        }
        changes['deleted'].push({
          department: touringAgentTerritories[ds][0].department,
          lineOfBusiness: touringAgentTerritories[ds][0].lineOfBusiness,
          location: touringAgentTerritories[ds][0].location,
          subsidiary: touringAgentTerritories[ds][0].subsidiary,
          territory: touringAgentTerritories[ds][0].territory,
        });
      }
    });
    touringInfoHistory.push(
      <>
        {displayTouringAgents({
          subKey,
          changes,
        })}
      </>
    );
    if (!isEmpty(changes) && (!changes?.updated || changes?.updated?.length > 0)) {
      auditChangeObject[auditLabelDisplay] = touringInfoHistory;
    }
  }
};

export default handleTouringAgents;
