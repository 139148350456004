import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'react-free-style';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { notify } from 'react-notify-toast';
import { Route, Switch, useParams } from 'react-router';

import {
  addRelation,
  getUTACompany,
  listHistoryProfile,
  loadPerson,
  removeRelation,
  updatePersonProfile,
  updateRelation,
  verifyPerson,
} from '@united-talent-agency/julius-frontend-store';

// VIEWS
import ExternalTeamView from './views/external-team';
import HistoryView from './views/history/history';
import NotesView from '../shared/notes';
import ProfileView from './views/profile/profile';
import ProjectsView from './views/projects/projects';
import TagsView from './views/tags';
import WebsiteView from './views/website/website';
import TeamView from './views/team';
import { FinanceView } from './views/finance/FinanceView';
import { TouringView } from '../profile/views';
import { buildMenuViews } from './util/menuViews.js';

// COMPONENTS
import { VerifyModal } from './modals/verify/VerifyModal';
import CardPageContainer from '../../components/card/CardPageContainer';
import DirtyBlock from '../../components/dirty-block/DirtyBlock';
import { Tab } from '../../components/tabs/Tab';
import { TabContainer } from '../../components/tabs/TabContainer';
import Title from '../../components/title/index.js';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped';

// STYLED
import {
  Header,
  HeaderActions,
  PageBody,
  PageContainer,
  PageContent,
  TitleContainer,
  VerifyButton,
  HeaderActionItemContainer,
  VerificationIcon,
} from '../../styles/components/components';

// HELPERS
import { contentfulPublish } from '../../helpers/profile.helpers.js';
import { saveChanges } from './util/OldCardStyleSave';
import cypressTags from '../../support/cypressTags.js';
import {
  useClientTeamAccessChecker,
  useIndustryContactAccessChecker,
  useOnlyDataStewartTeamAccessChecker,
  useLaunchDarklyCustomHook,
  useQueryStringViewRedirect,
} from '../../support/hooks';

import workdayLogo from '../../assets/logo/workday.svg';

const creates = { relationship: addRelation };

const deletes = { relationship: removeRelation };

const updates = {
  person: updatePersonProfile,
  relationship: updateRelation,
};

function Profile({ computedMatch, user, styles }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedView = location.pathname.split('/').pop();
  // computedMatch works in the UI and useParams() works in Tests.
  // Mike's hunch is that PrivateRoute is not an actual route component.
  const hookParams = useParams();
  const { personId } = (computedMatch || {}).params || hookParams;
  const [person, setPerson] = useState({});
  const [utaCompany, setUTACompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPublishSuccessful, setShowPublishSuccessful] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [filteredAllViews, setFilteredAllViews] = useState([]);
  const [financeRouteEnabled, setFinanceRouteEnabled] = useState(true);
  const [profilePic, setProfilePic] = useState('');
  // get flags using useLaunchDarklyCustomHook
  const [flags] = useLaunchDarklyCustomHook(user);
  const allowTouringMenu = touringMenuCheck(person);
  const allViews = useMemo(() => buildMenuViews(person, flags, allowTouringMenu), [person, flags, allowTouringMenu]);

  // custom hook that checks if user is data steward team to block edit/verify or not - Only for touring team
  const [blockTouringEdit] = useOnlyDataStewartTeamAccessChecker({
    entity: person,
    user,
    type: 'PERSON',
    dispatch,
  });

  // custom hook that checks if user is member of client or data steward team to block edit/verify or not
  const [relations, blockEdit, blockVerify] = useClientTeamAccessChecker({
    entity: person,
    user,
    type: 'PERSON',
    dispatch,
  });

  //Touring & Finance Tab check - custom hook that checks if user is member of client or data steward team to block edit/verify or not
  const accessCheckerMethod = person?.type === 'Client' ? useClientTeamAccessChecker : useIndustryContactAccessChecker;
  const [, blockTouringAndFinanceEdit] = accessCheckerMethod({
    entity: person,
    user,
    type: 'PERSON',
    dispatch,
  });

  const showVerifyButton =
    person.type !== 'Employee' &&
    flags.showVerifyButton &&
    !blockVerify &&
    (person.type === 'Client' || person.type === 'Industry Contact') &&
    (!person.verifiedBy || !person.verifiedOn);

  const showWorkdayButton =
    person && person.type === 'Employee' && flags.enableWorkday && person.url && person.url !== null;

  const showEmptyStylingDiv = !showVerifyButton && !showWorkdayButton;

  const baseUrl = `/profile/${personId}`;
  useQueryStringViewRedirect(baseUrl, { history: 'history' });

  const retrievePerson = useCallback(async () => {
    const response = await dispatch(loadPerson(personId));
    const { body, status } = response;
    if (!body || status !== 200) {
      notify.show('Error retrieving profile', 'error');
      return null;
    }
    const person = (response || {}).body;
    return person;
  }, [dispatch, personId]);

  useEffect(() => {
    retrievePerson(personId, dispatch).then((profileInfo) => profileInfo && setPerson(profileInfo));
  }, [personId, dispatch, retrievePerson]);

  useEffect(() => {
    dispatch(getUTACompany()).then((result) => {
      const utaCompany = result.body || {};
      setUTACompany({ utaCompany });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!Object.keys(person).length) {
      return;
    }

    // For a regular user, we need to hide the Finance view for all profile types
    if (blockTouringAndFinanceEdit) {
      setFilteredAllViews(allViews.filter((viewName) => viewName.title.toLowerCase() !== 'finance'));
      setFinanceRouteEnabled(false);
    } else {
      setFilteredAllViews(allViews);
      setFinanceRouteEnabled(true);
    }
  }, [allViews, blockTouringAndFinanceEdit, person]);

  const refreshProfile = () => {
    retrievePerson(personId, dispatch).then((profileInfo) => profileInfo && setPerson(profileInfo));
  };

  const handleVerify = async () => {
    return dispatch(verifyPerson(person._id))
      .then(() => {
        retrievePerson().then((profileInfo) => profileInfo && setPerson(profileInfo));
      })
      .finally(() => setShowVerifyModal(false));
  };

  const handleShowVerifyModal = async () => {
    setShowVerifyModal(true);
  };

  const saveNotes = async (notes = []) => {
    await dispatch(updatePersonProfile(person._id, { notes })).then(() => {
      retrievePerson().then((profileInfo) => profileInfo && setPerson(profileInfo));
    });
  };

  useEffect(() => {
    if (person?.profile_pic) {
      if (person?.profile_pic.includes('upload/')) {
        const profilePic = person?.profile_pic.split('upload/');
        const newProfilePic = profilePic[0] + 'upload/t_Profile/' + profilePic[1];
        setProfilePic(newProfilePic);
      } else {
        setProfilePic(person.profile_pic);
      }
    } else {
      setProfilePic('');
    }
  }, [person]);

  const saveProfileChanges = async (changes) => {
    if (!changes) {
      return retrievePerson().then((profileInfo) => profileInfo && setPerson(profileInfo));
    }

    await saveChanges({
      creates,
      deletes,
      updates,
      changes,
      person,
      dispatch,
      loadPerson,
    }).then(() => {
      retrievePerson().then((profileInfo) => profileInfo && setPerson(profileInfo));
    });
  };

  const saveWebsite = async ({ updates }) => {
    await dispatch(updatePersonProfile(person._id, updates[person._id])).then(() => {
      retrievePerson().then((profileInfo) => profileInfo && setPerson(profileInfo));
    });
  };

  const cleanPerson = async () => {
    setIsLoading(true);
    const shouldClean =
      person.type === 'Client' || person.type === 'Employee' ? await contentfulPublish(person, dispatch) : true;
    if (shouldClean) {
      await dispatch(updatePersonProfile(person._id, { dirty: false })).then(() => {
        retrievePerson().then((profileInfo) => profileInfo && setPerson(profileInfo));
      });
    }
  };

  const publishPerson = () => {
    cleanPerson().then(() => {
      setIsLoading(false);
      setShowPublishSuccessful(true);
      setTimeout(() => {
        setShowPublishSuccessful(false);
      }, 3500);
    });
  };

  const views = filteredAllViews.length ? filteredAllViews : allViews;
  const APP_NAME = flags.showContactsMergeFeatures ? 'CONTACTS' : 'PROFILES';

  return (
    <PageContainer data-cy={cypressTags.PERSON.PROFILE_PAGE_CONTAINER}>
      <Helmet>
        <title>{person && person.name ? `${APP_NAME}: ${person.name}` : APP_NAME}</title>
      </Helmet>
      <BrandingWrapped />
      <Header data-cy={cypressTags.PERSON.PROFILE_HEADER}>
        <TitleContainer>
          <Title entity={person} type={person.type} />
        </TitleContainer>
        <HeaderActions>
          {selectedView === 'website' && person.dirty && (
            <DirtyBlock styles={styles} onPublish={() => publishPerson()} isLoading={isLoading} />
          )}
          {showPublishSuccessful && <div className={styles.showSaved}>Saved to UTA</div>}
          {showVerifyButton && (
            <HeaderActionItemContainer>
              <VerifyButton onClick={() => handleShowVerifyModal()}>
                VERIFY
                <VerificationIcon color="white" />
              </VerifyButton>
            </HeaderActionItemContainer>
          )}
          {showWorkdayButton && (
            <HeaderActionItemContainer>
              <a
                href={person.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  height: '34px',
                  width: '108px',
                  display: 'flex',
                }}
              >
                <img src={workdayLogo} alt="Workday" width="108px" height="32px" />
              </a>
            </HeaderActionItemContainer>
          )}
          {showEmptyStylingDiv && (
            <div style={{ height: '34px', width: '108px', padding: '0 16px', display: 'flex' }} />
          )}
        </HeaderActions>
      </Header>
      <PageBody>
        <TabContainer headerImage={profilePic} cyTag={cypressTags.COMMON.TAB_CONTAINER}>
          {views.map((view) =>
            person?.type === 'Client' && view.title === 'Touring' ? (
              person.vocations.length > 0 &&
              person.vocations.some((ds) => ds === 'Musician' || ds === 'Comedy (Live)') ? (
                <Tab to={`${baseUrl}/${view.view}`} exact title={view.title} icon={view.icon} key={view.title} />
              ) : null
            ) : (
              <Tab to={`${baseUrl}/${view.view}`} exact title={view.title} icon={view.icon} key={view.title} />
            )
          )}
        </TabContainer>
        <PageContent>
          <CardPageContainer onSave={refreshProfile}>
            <Switch>
              <Route exact path={baseUrl}>
                <ProfileView
                  person={person}
                  utaCompany={utaCompany}
                  saveChanges={(changes) => saveProfileChanges(changes)}
                  user={user}
                  blockEdit={blockEdit}
                  blockTouringEdit={blockTouringEdit}
                  relations={relations}
                  refreshProfile={() => refreshProfile()}
                />
              </Route>
              <Route exact path={`${baseUrl}/projects`}>
                <ProjectsView person={person} updatedProjectRole={() => refreshProfile()} />
              </Route>
              <Route exact path={`${baseUrl}/team`}>
                <TeamView entity={person} dispatch={dispatch} />
              </Route>
              <Route exact path={`${baseUrl}/externalTeam`}>
                <ExternalTeamView entity={person} dispatch={dispatch} />
              </Route>
              <Route exact path={`${baseUrl}/tags`}>
                <TagsView person={person} saveChanges={(changes) => saveProfileChanges(changes)} />
              </Route>
              <Route exact path={`${baseUrl}/website`}>
                <WebsiteView person={person} saveChanges={(changes) => saveWebsite(changes)} />
              </Route>
              {financeRouteEnabled ? (
                <Route exact path={`${baseUrl}/finance`}>
                  <FinanceView person={person} blockEdit={blockTouringAndFinanceEdit} />
                </Route>
              ) : null}
              <Route exact path={`${baseUrl}/notes`}>
                <NotesView entity={person} saveNotes={saveNotes} user={user} />
              </Route>
              <Route exact path={`${baseUrl}/history`}>
                <HistoryView
                  person={person}
                  dispatch={dispatch}
                  entityLabel="person"
                  listHistoryProfile={listHistoryProfile}
                />
              </Route>
              {flags.showTouringTab && allowTouringMenu && (
                <Route exact path={`${baseUrl}/touring`}>
                  <TouringView
                    person={person}
                    blockEdit={blockTouringEdit}
                    saveChanges={(changes) => saveProfileChanges(changes)}
                    refreshProfile={() => refreshProfile()}
                    user={user}
                  />
                </Route>
              )}
            </Switch>
          </CardPageContainer>
        </PageContent>
      </PageBody>
      {showVerifyModal && (
        <VerifyModal
          showVerifyModal={showVerifyModal}
          closeVerifyModal={() => setShowVerifyModal(false)}
          handleVerify={() => handleVerify()}
          person={person}
        />
      )}
    </PageContainer>
  );
}

export const touringMenuCheck = (person) => {
  return (person?.type === 'Client' &&
    person?.vocations.length > 0 &&
    person?.vocations.some((ds) => ds === 'Musician' || ds === 'Comedy (Live)')) ||
    (person?.type === 'Industry Contact' &&
      person?.vocations.length > 0 &&
      person?.vocations.some((ds) => ds === 'Musician' || ds === 'Comedy (Live)'))
    ? true
    : false;
};

const withStyles = styled({
  showSaved: {
    backgroundColor: '#90E2D3',
    fontSize: 10,
    fontWeight: 100,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    padding: '18px 20px',
    marginLeft: 'auto',
    marginRight: 30,
  },
});

export default withStyles(Profile);
