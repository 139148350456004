import React, { useEffect, useState, useCallback } from 'react';

import { getTouringTypes } from '../../api/get-enum-types';
import { getTouringAgents, updateTouringAgents } from '../../api/territory-data';
import { UtaTouringAgent } from './uta-touring-agent';
import { notify } from 'react-notify-toast';

const TouringAgent = ({ onSave, user, onChange, entity, entityLabel, mergeCardData, blockEdit }) => {
  const [agentTypes, setRelationTypes] = useState([]);
  const [regionTypes, setRegionTypes] = useState([]);
  const [relationships, setRelationships] = useState([]);

  const id = entity?._id;
  const personId = entity?._id ?? null;
  const vocations = entity?.vocations ?? [];

  const loadTouringAgents = async () => {
    if (!id) {
      return [];
    }
    if (entityLabel === 'person') {
      getTouringAgents(entityLabel, personId).then((result) => {
        let relations = Array.isArray(result) ? result : [];
        setRelationships(relations);
      });
    }
  };

  const loadTouringAgentsWrapped = useCallback(loadTouringAgents, [entity, entityLabel, id]);

  const saveUpdates = async (data) => {
    try {
      const result = await updateTouringAgents('person', personId, data);
      if (!result.error) {
        setRelationships(result.data);
        loadTouringAgents();
        notify.show('Touring Agents saved successfully', 'success');
      } else {
        const errorMessage = result.message;
        console.log(`Error Saving: ${errorMessage}`);
        notify.show('Touring Agents did not save successfully. Please try again.', 'error');
      }
    } catch (error) {
      const errorMessage_1 = error.message;
      console.log(`Error Saving: ${errorMessage_1}`);
      notify.show('Touring Agents did not save successfully. Please try again.');
    }
  };

  useEffect(() => {
    getTouringTypes(`/v2/enums/master-data/touring-team-relationship-type`).then((results) =>
      setRelationTypes(results)
    );
    getTouringTypes(`/v2/enums/master-data/touring-region-type`).then((results) => setRegionTypes(results));
    loadTouringAgentsWrapped();
  }, [id, loadTouringAgentsWrapped]);

  const showTouringAgents = !mergeCardData || !mergeCardData.isEmpty || relationships.length > 0;

  return !showTouringAgents ? (
    <></>
  ) : (
    <UtaTouringAgent
      id="touring-agents"
      mergeCardData={mergeCardData}
      title="Touring Agents"
      individualItemTitle="Touring Agents"
      textFieldTitle="Touring Agent"
      territoriesData={relationships}
      blockEdit={blockEdit}
      touringAgentBlocks={relationships}
      regionTypes={regionTypes}
      agentTypes={agentTypes}
      vocations={vocations}
      saveData={saveUpdates}
    />
  );
};

export default TouringAgent;
