import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Info, Input, Select } from '@united-talent-agency/julius-frontend-components';

import Card from '../../../../components/card/Card';
import { InfoContainer, Columns, Column } from '../../../../styles/components/components';
import { updateVenue } from '../../../../api/venues';
import { FormContext } from '../../../../support/FormContext';
import CommonTooltip from '../../../../components/common/tooltip';

import { TOOLTIP_MESSAGES } from '../../../../components/common/messages/tooltip-messages';
import { isValidWebsite } from '../../../../components/card-social/card-social';
import { isValidSocialUrl } from '../../../../components/social-info/social-info';

export const websiteSelectOptions = ['Company', 'Facebook', 'Twitter', 'Instagram'];

const isValidWebSiteLink = (website) => {
  switch (website.type) {
    case 'Company':
      return isValidWebsite(website.link);
    default:
      return isValidSocialUrl({
        network: website.type.toLowerCase(),
        url: website.link,
      });
  }
};

function readView({ item: website }) {
  const regex = /^https?:/i;
  return (
    <Info isPrimary={website.primary} name={website.type}>
      {isValidWebSiteLink(website) ? (
        <a href={regex.test(website.link) ? website.link : `//${website.link}`} target="_blank noopener noreferrer">
          {website.link}
        </a>
      ) : (
        <span className="invalid-data-warning">Invalid Data</span>
      )}
    </Info>
  );
}

function editView({ item: website, setState }) {
  return <WebsiteEditor website={website} setState={setState} />;
}

export const Error = ({ msg }) => <div style={{ color: 'red', fontSize: '12px', clear: 'both' }}>{msg}</div>;

const WebsiteEditor = ({ website, setState }) => {
  const [fieldVisited, setFieldVisited] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const { showAllValidationErrors } = useContext(FormContext);

  const validateWebSiteLink = (link) => {
    // eslint-disable-next-line
    const expression =
      /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-]+)*\/?(?:#\S*)?$/gi;
    const regex = new RegExp(expression);

    return link && link.length > 0 && link.match(regex);
  };

  useEffect(() => {
    const newErrors = {};

    if (!website?.type && !website?.link) {
      newErrors['link'] = 'Website Type & Link are required';
    } else if (website?.type && !website?.link) {
      newErrors['link'] = 'Website Link is required';
    } else if (website?.link && !website?.type) {
      newErrors['link'] = 'Website Type is required';
    }

    if (!_.isEqual(fieldErrors, newErrors)) {
      setFieldErrors(newErrors);
    }
  }, [website, fieldErrors]);

  const types = websiteSelectOptions.map((type) => ({
    key: type,
    content: type,
    active: website.type === type,
    onClick: () => setState({ type }),
  }));
  return (
    <InfoContainer>
      <Columns>
        <Column>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ width: 120, marginRight: 10 }}>
              <Select optionStyle={{ padding: 10 }} items={types}>
                {website.type}
              </Select>
            </div>
            <div style={{ width: 250 }}>
              <Input
                isRequired
                type="text"
                title="Link"
                value={website.link || ''}
                onChange={(link) => {
                  setState({ ...website, link: link });
                }}
                onBlur={() => setFieldVisited({ ...fieldVisited, link: true })}
              />
            </div>
          </div>
          {((website.link && !validateWebSiteLink(website.link)) || website.link === null) && (
            <div
              style={{
                textAlign: 'right',
                marginRight: 190,
                marginTop: 6,
                marginBottom: 6,
                color: 'red',
                fontSize: '12px',
              }}
            >
              Invalid Link
            </div>
          )}
        </Column>
      </Columns>
      {fieldErrors.link && (fieldVisited.link || showAllValidationErrors) && <Error msg={fieldErrors.link} />}
    </InfoContainer>
  );
};

const WebsiteCard = ({ venue = {}, onChange, blockEdit, cyTag }) => {
  const onSave = (saveInfo) => {
    // if (!saveInfo.mergedData) return null;
    const venueId = venue._id;
    const updatedVenue = { venueId, updates: { websites: saveInfo.mergedData } };
    return updateVenue(updatedVenue);
  };

  const requiredFieldCheck = (venue) => {
    const { type, link } = venue;
    // eslint-disable-next-line
    const expression =
      /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-]+)*\/?(?:#\S*)?$/gi;
    const regex = new RegExp(expression);

    return type !== null && link && link.length > 0 && link.match(regex);
  };

  const { websites = [] } = venue;
  // put primary website at top of list
  websites.sort((b, a) => (!!a.primary && !b.primary ? 1 : -1));

  return (
    <div data-cy={cyTag} style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        title="Websites"
        data={websites}
        // isRequired
        onChange={onChange}
        canSetPrimary
        canDelete
        firstIsPrimary
        onSave={onSave}
        createNew={() => ({ type: null, link: null })}
        readView={(props) => readView(props)}
        editView={!blockEdit && ((props) => editView(props))}
        onValidateItem={requiredFieldCheck}
      />{' '}
    </div>
  );
};

export default WebsiteCard;
