import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import styled from 'styled-components/macro';
import { Checkbox, colors, PersonInputListItem, Icons } from '@united-talent-agency/components';
import { MergeProfilesSearch } from './MergeProfilesSearch';
import { profileUrl, companyProfileUrl } from '../../support/urls';

const { UserIcon, OfficeBuildingIcon } = Icons;

export const MergeProfilesModal = ({ isOpen, onCancel, onConfirm, desk }) => {
  const [profilesToMerge, setProfilesToMerge] = useState([]);
  const history = useHistory();
  const onRequestClose = () => {
    onCancel();
  };

  /**
   * When more than 1 profile is selected to be merged, \
   * clicking Continue routes to the submit merge page
   */
  const handleContinue = () => {
    if (1 < profilesToMerge.length) {
      const route = profilesToMerge[0]?.entityType === 'person' ? 'profiles' : 'companies';
      const location = `/merge/${route}/${profilesToMerge.map((each) => each._id).join(',')}`;
      history.push(location);
      onConfirm && onConfirm();
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
      <div>
        <ModalTitle>SELECT PROFILES</ModalTitle>
        <ModalSubTitle>Select at least 2 profiles to continue the merge (max 4)</ModalSubTitle>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ width: 'min-content' }}>
            <MergeProfilesSearch
              desk={desk}
              setProfilesToMerge={setProfilesToMerge}
              profilesToMerge={profilesToMerge}
            />
            {profilesToMerge.length ? (
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  listStyleType: 'none',
                  paddingLeft: 0,
                }}
              >
                {profilesToMerge.map((profile) => (
                  <li key={profile._id} style={{ marginBottom: '5px' }}>
                    <EntityListItem
                      entity={profile}
                      profilesToMerge={profilesToMerge}
                      setProfilesToMerge={setProfilesToMerge}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
        <ButtonContainer>
          <CancelButton onClick={onCancel}>CANCEL</CancelButton>
          <ContinueButton onClick={handleContinue} disabled={profilesToMerge.length < 2}>
            CONTINUE
          </ContinueButton>
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export const EntityListItem = ({ entity, profilesToMerge, setProfilesToMerge }) => {
  const entityUrl = entity.entityType === 'person' ? profileUrl : companyProfileUrl;
  return (
    <span style={{ display: 'flex' }}>
      <Checkbox
        checked={profilesToMerge.map((each) => each._id).includes(entity._id)}
        onChange={() => setProfilesToMerge((entities) => entities.filter((each) => each._id !== entity._id))}
      />
      <PersonInputListItem marker={entity.type[0].toUpperCase()}>
        <Link href={`${entityUrl}/${entity._id}`} target="_blank" rel="noopener noreferrer">
          {entity.name}
        </Link>
        <EntityTypeIcon>{entity.entityType === 'person' ? <UserIcon /> : <OfficeBuildingIcon />}</EntityTypeIcon>
      </PersonInputListItem>
    </span>
  );
};

const { white, utaBlack, disabledGrey } = colors;

const CancelButton = styled.button({
  width: '114px',
  height: '40px',
  margin: '0px 15px 0px',
  borderRadius: '2px',
  backgroundColor: white,
  color: utaBlack,
  border: `1px solid ${utaBlack}`,
  fontWeight: 'bold',
});

const ContinueButton = styled.button((props) => ({
  width: '114px',
  height: '40px',
  margin: '0px 15px 0px',
  borderRadius: '2px',
  backgroundColor: props.disabled ? disabledGrey : utaBlack,
  color: white,
  border: `1px solid ${utaBlack}`,
  fontWeight: 'bold',
}));

const ButtonContainer = styled.div({
  marginTop: '30px',
  marginBottom: '25px',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
});

const ModalTitle = styled.div({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 18,
  fontWeight: 700,
  marginBottom: '3px',
  marginTop: '25px',
});

const ModalSubTitle = styled.div({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 12,
  color: '#6b6a69',
  marginBottom: '30px',
});

const modalStyles = {
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    border: '0.5px solid rgba(0, 0, 0, 0.2)',
    background: `${colors.background} none repeat scroll 0% 0%`,
    borderRadius: 2,
    outline: 'currentcolor none medium',
    width: 634,
    overflow: 'unset',
    height: 'min-content',
    padding: '20px 0',
  },
};

const Link = styled.a({
  color: '#2187B9',
  fontSize: 14,
  marginRight: 5,
  marginBottom: 5,
  '&:hover': {
    color: '#2187B9',
  },
});

const EntityTypeIcon = styled.span({
  fontSize: '15px',
});

export default MergeProfilesModal;
