import React, { useState, useCallback } from 'react';
import { styled, helpers } from 'react-free-style';
import { debounce } from 'lodash';
import { PersonInput, Input } from '@united-talent-agency/julius-frontend-components';
import { cross } from '../../styles/icons';
import search from '../../support/algolia';

const EmployeeEditor = (props) => {
  const { member, onDelete, styles, onMemberChanged, readOnly } = props;

  const [memberResults, setMemberResults] = useState([]);
  const debounceOnNameChange = useCallback(debounce(onNameChange, 300), []);

  async function onNameChange(name) {
    onMemberChanged({ ...member, person: { name } });

    if (name) {
      const data = await search({
        query: name,
        filtersState: {
          includeClient: true,
          includeIndustry: true,
        },
        searchIndex: process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX,
        hitsPerPage: 20,
      });

      setMemberResults(data.hits);
    } else {
      setMemberResults([]);
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'rows', whiteSpace: 'nowrap', marginBottom: 10 }}>
      <div style={{ width: '100%' }}>
        <PersonInput
          className={styles.input}
          value={member.person && member.person.name}
          results={memberResults}
          onChange={debounceOnNameChange}
          onSelect={(person) => {
            onMemberChanged({ ...member, person: { _id: person._id, name: person.name } });
            setMemberResults([]);
          }}
          focus
        />
        {!(member.person && member.person._id) && <div className={styles.errorMessage}>Please select the person</div>}
        <Input
          type="text"
          title="Title"
          value={member.title || ''}
          className={styles.input}
          onChange={(title) => {
            onMemberChanged({ ...member, title });
          }}
        />
      </div>
      <div style={{ marginLeft: 10, paddingTop: 14, visibility: readOnly ? 'hidden' : 'inherit' }}>
        <i
          className={styles.deleteButton}
          onClick={() => {
            onDelete && onDelete();
          }}
        />
      </div>
    </div>
  );
};
const withStyles = styled({
  deleteButton: helpers.merge({ cursor: 'pointer' }, cross),
  errorMessage: {
    color: 'red',
    fontSize: '14px',
  },
});
export default withStyles(EmployeeEditor);
