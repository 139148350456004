import React from 'react';
import { styled } from 'react-free-style';
import { Info, Input, Multiselect } from '@united-talent-agency/julius-frontend-components';
import moment from 'moment';

import { hairColors, personalInterests, iqSources, personalRepresentationAreas } from './personalInfo';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_PARSE_FORMAT, DatePicker } from '../../../../components/date-picker';
import { SearchableSelect } from '../../../../components/searchable-select';
import Card from '../../../../components/card/ProjectCard';

const ensureAbsoluteLink = (link) => {
  if (!link || typeof link !== 'string') return link;
  if (!link.startsWith('http')) {
    return `//${link}`;
  }
  return link;
};

const withStyles = styled({
  menuItem: { padding: 10 },
  selectorContainer: { width: '20rem' },
  ageRangeSelectorContainer: { width: '20rem', display: 'flex', alignItems: 'center' },
  ageRangeHyphen: { margin: '0 6px' },
  personalTagContainer: { display: 'flex', flex: 1, flexDirection: 'row' },
});

const TagsView = ({ styles, person, saveChanges, mergeCardData }) => {
  const {
    _id,
    multiracial,
    hairColor,
    parent,
    interests = [],
    veteran,
    iqStatus,
    iqState,
    iqSource,
    clientStartDate,
    clientTerminationDate,
    representationAreas = [],
    originCity,
    originState,
    originCountry,
    census2020Latino,
    census2020Race,
    utourId,
    neda = {},
    iqEthnicitySourceURL,
    iqLGBTSourceURL,
    iqOtherURL,
    profiled,
  } = person;

  const areAllTagsEmpty =
    !person.multiracial &&
    !person.hairColor &&
    !person.parent &&
    !person.interests.length &&
    !person.veteran &&
    !person.iqEthnicitySourceURL &&
    !person.iqLGBTSourceURL &&
    !person.iqOtherURL &&
    !person.clientStartDate &&
    !person.clientTerminationDate &&
    !person.representationAreas.length &&
    !person.originCity &&
    !person.originState &&
    !person.originCountry &&
    !person.census2020Latino &&
    !person.census2020Race &&
    !person.utourId &&
    !person.neda.id &&
    !person.iqStatus &&
    !person.iqState &&
    !person.iqSource &&
    !person.profiled;

  const defaultClientStartDate = clientStartDate
    ? moment(clientStartDate, DEFAULT_DATE_PARSE_FORMAT).format(DEFAULT_DATE_FORMAT)
    : '';
  const defaultClientTerminationDate = clientTerminationDate
    ? moment(clientTerminationDate, DEFAULT_DATE_PARSE_FORMAT).format(DEFAULT_DATE_FORMAT)
    : '';

  const showTags = !mergeCardData || !mergeCardData.isEmpty || !areAllTagsEmpty;

  const PersonalCard = ({
    isEditing,
    updateBy,
    getSelectValue,
    getUpdatedInputValue,
    getParentValue,
    getParentLabel,
    getMultiselectValue,
  }) => {
    return isEditing ? (
      <>
        <Info name="Multiracial">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('multiracial') || { value: multiracial, label: multiracial }}
              options={['Yes', 'No', 'Unknown']}
              onChange={(item) => updateBy('person', _id, { multiracial: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="Hair Color">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('hairColor') || { value: hairColor, label: hairColor }}
              options={hairColors}
              onChange={(item) => updateBy('person', _id, { hairColor: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="Parent">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getParentValue(parent)}
              options={['Yes', 'No']}
              onChange={(item) => updateBy('person', _id, { parent: item && item.value.toLowerCase() === 'yes' })}
            />
          </div>
        </Info>
        <Info name="Interests">
          <div className={styles.selectorContainer}>
            <Multiselect
              value={getMultiselectValue('interests', interests)}
              _onChange={(item) => {
                updateBy('person', _id, { interests: item.split(',') });
              }}
              options={personalInterests.map((personalInterest) => ({
                label: personalInterest,
                value: personalInterest,
              }))}
            />
          </div>
        </Info>
        <Info name="Veteran">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('veteran') || { value: veteran, label: veteran }}
              options={['Yes', 'No', 'Unknown']}
              onChange={(item) => updateBy('person', _id, { veteran: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="Ethnicity Source URL">
          <div className={styles.selectorContainer}>
            <Input
              type="text"
              title="Ethnicity Source URL"
              onChange={(iqEthnicitySourceURL) => updateBy('person', _id, { iqEthnicitySourceURL })}
              value={getUpdatedInputValue('iqEthnicitySourceURL', iqEthnicitySourceURL)}
            />
          </div>
        </Info>
        <Info name="LGBTQ Source URL">
          <div className={styles.selectorContainer}>
            <Input
              type="text"
              title="LGBTQ Source URL"
              onChange={(iqLGBTSourceURL) => updateBy('person', _id, { iqLGBTSourceURL })}
              value={getUpdatedInputValue('iqLGBTSourceURL', iqLGBTSourceURL)}
            />
          </div>
        </Info>
        <Info name="Other URL">
          <div className={styles.selectorContainer}>
            <Input
              type="text"
              title="Other URL"
              onChange={(iqOtherURL) => updateBy('person', _id, { iqOtherURL })}
              value={getUpdatedInputValue('iqOtherURL', iqOtherURL)}
            />
          </div>
        </Info>
      </>
    ) : (
      <>
        {(!mergeCardData || (mergeCardData && person.multiracial)) && <Info name="Multiracial">{multiracial}</Info>}
        {(!mergeCardData || (mergeCardData && person.hairColor)) && <Info name="Hair Color">{hairColor}</Info>}
        {(!mergeCardData || (mergeCardData && person.parent)) && <Info name="Parent">{getParentLabel(parent)}</Info>}
        {(!mergeCardData || (mergeCardData && person.interests.length > 0)) && (
          <Info name="Interests">{interests.join(', ')}</Info>
        )}
        {(!mergeCardData || (mergeCardData && person.veteran)) && <Info name="Veteran">{veteran}</Info>}
        {(!mergeCardData || (mergeCardData && person.iqEthnicitySourceURL)) && (
          <Info name="Ethnicity Source URL">
            <a href={ensureAbsoluteLink(person.iqEthnicitySourceURL)} target="_blank noopener noreferrer">
              {person.iqEthnicitySourceURL}
            </a>
          </Info>
        )}
        {(!mergeCardData || (mergeCardData && person.iqLGBTSourceURL)) && (
          <Info name="LGBTQ Source URL">
            <a target="_blank noopener noreferrer" href={ensureAbsoluteLink(person.iqLGBTSourceURL)}>
              {person.iqLGBTSourceURL}
            </a>
          </Info>
        )}
        {(!mergeCardData || (mergeCardData && person.iqOtherURL)) && (
          <Info name="Other URL">
            <a href={ensureAbsoluteLink(person.iqOtherURL)} target="_blank noopener noreferrer">
              {person.iqOtherURL}
            </a>
          </Info>
        )}
      </>
    );
  };

  const EtcCard = ({
    isEditing,
    updateBy,
    getSelectValue,
    getUpdatedInputValue,
    getUpdatedDatePickerValue,
    getRepresentationAreas,
  }) => {
    return isEditing ? (
      <>
        <Info name="Add Date">
          <div className={styles.selectorContainer}>
            <DatePicker
              onDayChange={(clientStartDate) => updateBy('person', _id, { clientStartDate })}
              value={getUpdatedDatePickerValue('clientStartDate', defaultClientStartDate)}
            />
          </div>
        </Info>
        <Info name="Drop Date">
          <div className={styles.selectorContainer}>
            <DatePicker
              onDayChange={(clientTerminationDate) => updateBy('person', _id, { clientTerminationDate })}
              value={getUpdatedDatePickerValue('clientTerminationDate', defaultClientTerminationDate)}
            />
          </div>
        </Info>
        <Info name="Representation Areas">
          <div className={styles.selectorContainer}>
            <Multiselect
              title=" "
              value={getRepresentationAreas()}
              _onChange={(item) => {
                updateBy('person', _id, { representationAreas: item.split(',') });
              }}
              options={personalRepresentationAreas.map((personalRepresentationArea) => ({
                label: personalRepresentationArea,
                value: personalRepresentationArea,
              }))}
            />
          </div>
        </Info>
        <Info name="City of Origin">
          <div className={styles.selectorContainer}>
            <Input
              type="text"
              onChange={(originCity) => updateBy('person', _id, { originCity })}
              value={getUpdatedInputValue('originCity', originCity)}
            />
          </div>
        </Info>
        <Info name="State of Origin">
          <div className={styles.selectorContainer}>
            <Input
              type="text"
              onChange={(originState) => updateBy('person', _id, { originState })}
              value={getUpdatedInputValue('originState', originState)}
            />
          </div>
        </Info>
        <Info name="Country of Origin">
          <div className={styles.selectorContainer}>
            <Input
              type="text"
              onChange={(originCountry) => updateBy('person', _id, { originCountry })}
              value={getUpdatedInputValue('originCountry', originCountry)}
            />
          </div>
        </Info>
        <Info name="Census 2020 Latino">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('census2020Latino') || { value: census2020Latino, label: census2020Latino }}
              options={['Yes', 'No', 'Unknown']}
              onChange={(item) => updateBy('person', _id, { census2020Latino: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="Census 2020 Race">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('census2020Race') || { value: census2020Race, label: census2020Race }}
              options={[
                'American Indian or Alaska Native',
                'Asian',
                'Black or African American',
                'Native Hawaiian or Other Pacific Islander',
                'White',
                'Unknown',
              ]}
              onChange={(item) => updateBy('person', _id, { census2020Race: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="UTour ID">
          <div className={styles.selectorContainer}>
            <Input
              type="number"
              onChange={(utourId) => updateBy('person', _id, { utourId })}
              value={getUpdatedInputValue('utourId', utourId)}
            />
          </div>
        </Info>
        <Info name="IQ ID">
          <div className={styles.selectorContainer}>
            <Input
              type="number"
              onChange={(id) => {
                const updatedNedaBody = { ...neda, ...{ id } };
                updateBy('person', _id, { neda: updatedNedaBody });
              }}
              value={getUpdatedInputValue('neda.id', neda.id)}
            />
          </div>
        </Info>
        <Info name="IQ Status">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('iqStatus') || { value: iqStatus, label: iqStatus }}
              options={['Active', 'Inactive', 'On Hold']}
              onChange={(item) => updateBy('person', _id, { iqStatus: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="IQ State">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('iqState') || { value: iqState, label: iqState }}
              options={['Current', 'Former', 'Pursuit', 'Deceased', 'Unknown']}
              onChange={(item) => updateBy('person', _id, { iqState: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="IQ Source">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('iqSource') || { value: iqSource, label: iqSource }}
              options={iqSources}
              onChange={(item) => updateBy('person', _id, { iqSource: item ? item.value : item })}
            />
          </div>
        </Info>
        <Info name="Profiled">
          <div className={styles.selectorContainer}>
            <SearchableSelect
              value={getSelectValue('profiled') || { value: profiled, label: profiled }}
              options={['Yes', 'No']}
              onChange={(item) => updateBy('person', _id, { profiled: item ? item.value : item })}
            />
          </div>
        </Info>
      </>
    ) : (
      <>
        {person.clientStartDate && (
          <Info name="Add Date">{clientStartDate && moment(clientStartDate).format(DEFAULT_DATE_FORMAT)}</Info>
        )}
        {person.clientTerminationDate && (
          <Info name="Drop Date">
            {clientTerminationDate && moment(clientTerminationDate).format(DEFAULT_DATE_FORMAT)}
          </Info>
        )}
        {person.representationAreas.length > 0 && (
          <Info name="Representation Areas">{representationAreas.join(', ')}</Info>
        )}
        {person.originCity && <Info name="City of Origin">{originCity}</Info>}
        {person.originState && <Info name="State of Origin">{originState}</Info>}
        {person.originCountry && <Info name="Country of Origin">{originCountry}</Info>}
        {person.census2020Latino && <Info name="Census 2020 Latino">{census2020Latino}</Info>}
        {person.census2020Race && <Info name="Census 2020 Race">{census2020Race}</Info>}
        {person.utourId && <Info name="UTour ID">{utourId}</Info>}
        {person.neda.id && <Info name="IQ ID">{neda.id}</Info>}
        {person.iqStatus && <Info name="IQ Status">{iqStatus}</Info>}
        {person.iqState && <Info name="IQ State">{iqState}</Info>}
        {person.iqSource && <Info name="IQ Source">{iqSource}</Info>}
        {person.profiled && <Info name="Profiled">{profiled}</Info>}
      </>
    );
  };

  if (!showTags) {
    return <></>;
  }
  return mergeCardData ? (
    <>
      <div>
        <Card title="Personal" canEdit saveChanges={saveChanges} mergeCardData={mergeCardData}>
          {({ isEditing, updates, updateBy }) => {
            const getParentLabel = (parent) => {
              switch (parent) {
                case true:
                  return 'Yes';
                case false:
                  return 'No';
                default:
                  return null;
              }
            };
            const getSelectValue = (label) =>
              (updates.person &&
                updates.person[_id] &&
                (updates.person[_id][label] || updates.person[_id][label] === null) && {
                  value: updates.person[_id][label],
                  label: updates.person[_id][label],
                }) ||
              null;

            const getParentValue = (parent) =>
              (updates.person &&
                updates.person[_id] &&
                updates.person[_id].parent != null && {
                  value: updates.person[_id].parent,
                  label: getParentLabel(updates.person[_id].parent),
                }) || { value: parent, label: getParentLabel(parent) };

            const getMultiselectValue = (field, defaultValue) => {
              if (updates.person && updates.person[_id] && updates.person[_id][field]) {
                return updates.person[_id][field];
              } else {
                return defaultValue;
              }
            };
            const getUpdatedInputValue = (field, defaultValue) => {
              return (updates.person && updates.person[_id] && updates.person[_id][field]) || defaultValue;
            };
            const getUpdatedDatePickerValue = (field, defaultValue) => {
              return (updates.person && updates.person[_id] && updates.person[_id][field]) || defaultValue;
            };
            const getRepresentationAreas = () => {
              if (updates.person && updates.person[_id] && updates.person[_id].representationAreas) {
                return updates.person[_id].representationAreas;
              } else {
                return representationAreas;
              }
            };
            return (
              <>
                <PersonalCard
                  isEditing={isEditing}
                  updateBy={updateBy}
                  getUpdatedInputValue={getUpdatedInputValue}
                  getSelectValue={getSelectValue}
                  getParentValue={getParentValue}
                  getParentLabel={getParentLabel}
                  getMultiselectValue={getMultiselectValue}
                />
                <Info name="" />
                <EtcCard
                  isEditing={isEditing}
                  updateBy={updateBy}
                  getUpdatedInputValue={getUpdatedInputValue}
                  getSelectValue={getSelectValue}
                  getUpdatedDatePickerValue={getUpdatedDatePickerValue}
                  getRepresentationAreas={getRepresentationAreas}
                />
              </>
            );
          }}
        </Card>
      </div>
    </>
  ) : (
    <div className={styles.personalTagContainer}>
      <div style={{ width: '48%', marginRight: 20 }}>
        <Card title="Personal" canEdit saveChanges={saveChanges} mergeCardData={mergeCardData}>
          {({ isEditing, updates, updateBy }) => {
            const getParentLabel = (parent) => {
              switch (parent) {
                case true:
                  return 'Yes';
                case false:
                  return 'No';
                default:
                  return null;
              }
            };
            const getSelectValue = (label) =>
              (updates.person &&
                updates.person[_id] &&
                (updates.person[_id][label] || updates.person[_id][label] === null) && {
                  value: updates.person[_id][label],
                  label: updates.person[_id][label],
                }) ||
              null;

            const getParentValue = (parent) =>
              (updates.person &&
                updates.person[_id] &&
                updates.person[_id].parent != null && {
                  value: updates.person[_id].parent,
                  label: getParentLabel(updates.person[_id].parent),
                }) || { value: parent, label: getParentLabel(parent) };

            const getMultiselectValue = (field, defaultValue) => {
              if (updates.person && updates.person[_id] && updates.person[_id][field]) {
                return updates.person[_id][field];
              } else {
                return defaultValue;
              }
            };
            const getUpdatedInputValue = (field, defaultValue) => {
              return (updates.person && updates.person[_id] && updates.person[_id][field]) || defaultValue;
            };
            return (
              <PersonalCard
                isEditing={isEditing}
                updateBy={updateBy}
                getUpdatedInputValue={getUpdatedInputValue}
                getSelectValue={getSelectValue}
                getParentValue={getParentValue}
                getParentLabel={getParentLabel}
                getMultiselectValue={getMultiselectValue}
              />
            );
          }}
        </Card>
      </div>
      <div style={{ width: '50%', marginLeft: 0 }}>
        <Card title="" canEdit saveChanges={saveChanges} mergeCardData={mergeCardData}>
          {({ isEditing, updates, updateBy }) => {
            const getUpdatedDatePickerValue = (field, defaultValue) => {
              return (updates.person && updates.person[_id] && updates.person[_id][field]) || defaultValue;
            };
            const getRepresentationAreas = () => {
              if (updates.person && updates.person[_id] && updates.person[_id].representationAreas) {
                return updates.person[_id].representationAreas;
              } else {
                return representationAreas;
              }
            };
            const getUpdatedInputValue = (field, defaultValue) => {
              return (updates.person && updates.person[_id] && updates.person[_id][field]) || defaultValue;
            };
            const getSelectValue = (label) =>
              (updates.person &&
                updates.person[_id] &&
                (updates.person[_id][label] || updates.person[_id][label] === null) && {
                  value: updates.person[_id][label],
                  label: updates.person[_id][label],
                }) ||
              null;
            return (
              <EtcCard
                isEditing={isEditing}
                updateBy={updateBy}
                getUpdatedInputValue={getUpdatedInputValue}
                getSelectValue={getSelectValue}
                getUpdatedDatePickerValue={getUpdatedDatePickerValue}
                getRepresentationAreas={getRepresentationAreas}
              />
            );
          }}
        </Card>
      </div>
    </div>
  );
};

export default withStyles(TagsView);
