import React from 'react';
import styled from 'styled-components/macro';
import { colors } from '@united-talent-agency/components';

import { Territory } from './const';

// Sort & section things by territory / primary
export const sectionData = (data) => {
  const sortedData = [...data];
  sortedData.sort((a, b) => {
    if (a.territory !== b.territory) {
      if (a.territory === Territory.AllTerritories) return -1;
      if (b.territory === Territory.AllTerritories) return 1;
      return a.territory.localeCompare(b.territory);
    }
    if (a.primary !== b.primary) {
      if (a.primary) return -1;
      if (b.primary) return 1;
    }
    return 0;
  });

  const sectionedData = {};
  sortedData.forEach((item) => {
    if (sectionedData[item.territory]) {
      sectionedData[item.territory].push(item);
    } else {
      sectionedData[item.territory] = [item];
    }
  });

  return sectionedData;
};

// Couldn't use Info from JEFC because we want tighter spacing and control over where the bottom line goes
export const InfoLine = ({ isPrimary, label, value }) => {
  const formatValue = (input) => {
    const splitText = input?.split('\n');
    return splitText && splitText.length > 0
      ? splitText.map((str, index, array) =>
          index === array.length - 1 ? (
            str
          ) : (
            <>
              {str}
              <br />
            </>
          )
        )
      : '';
  };

  return (
    <InfoBox>
      <InfoLabel>
        {label}
        {isPrimary ? <InfoPrimary>(primary)</InfoPrimary> : null}
      </InfoLabel>
      <InfoValue>{formatValue(value)}</InfoValue>
    </InfoBox>
  );
};

export const TerritoryBox = styled.div`
  padding: 10px 0;
  border-bottom: 1px dotted ${colors.infoBorder};
`;

export const TerritoryHeader = styled.div`
  color: #494443;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 5px;
`;

const InfoBox = styled.div`
  font-size: 12px;
  display: flex;
`;

const InfoPrimary = styled.span`
  margin-left: 4px;
  font-weight: 700;
`;

const InfoLabel = styled.span`
  flex: 1;
  margin-right: 10px;
`;

const InfoValue = styled.span`
  flex: 1;
  text-align: left;
`;

export const TouringInfoLine = ({ isPrimary, label, value }) => (
  <>
    <TouringInfoLabel>
      {label}
      {isPrimary ? <InfoPrimary>(primary)</InfoPrimary> : null}
    </TouringInfoLabel>
    <TouringInfoValue>{value}</TouringInfoValue>
  </>
);

const TouringInfoLabel = styled.div`
  font-size: 12px;
`;

const TouringInfoValue = styled.div`
  font-size: 11px;
  white-space: break-spaces;
  margin-top: 5px;
  margin-bottom: 10px;
`;
