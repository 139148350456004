import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import {
  colors,
  FormArray,
  FormArrayPrimaryRadioButton,
  FormContext,
  FormInput,
  Icons,
  TextButton,
  RHF,
  FormEntitySelector,
  FormSingleSelectDropdown,
  FormTextArea,
} from '@united-talent-agency/components';
import { buttonTypes } from '@united-talent-agency/julius-frontend-components';
import { requestSansStore } from '@united-talent-agency/julius-frontend-store';

import { sectionData } from './ReadViewSupport';
import { Territory } from './const';
import { CardButton, CardHeader, CardPane, CardTitle } from '../card/Card';
import { TerritoriesSelector } from './TerritoriesSelector';
import { getGroups } from '../../api/groups';
import cypressTags from '../../support/cypressTags';

const { useForm, useWatch } = RHF;
const { PlusIcon, XCloseIcon } = Icons;

const { COMMON } = cypressTags;

export const TerritoriesTableEditor = ({
  territoriesData,
  title,
  individualItemTitle,
  textFieldTitle,
  setReadMode,
  saveData,
  id,
  loanout,
  businessNames,
  includeNotes,
  numberValues,
  entityType,
  isTouring,
}) => {
  const defaultTerritories = useExistingTerritories(territoriesData);

  const [territoriesCategory, setTerritoriesCategory] = useState(
    defaultTerritories.has(Territory.AllTerritories) ? 'All' : 'Itemized'
  );
  const [selectedTerritories, setSelectedTerritories] = useState(
    territoriesCategory === 'All' ? new Set([Territory.RestOfWorld]) : defaultTerritories
  );

  const [territoriesError, setTerritoriesError] = useState('');
  // Clear error when territories change
  useEffect(() => {
    setTerritoriesError('');
  }, [territoriesCategory, selectedTerritories]);

  const formProps = useForm({
    mode: 'onBlur',
    defaultValues: sectionData(territoriesData),
  });
  const { handleSubmit } = formProps;

  const showTerritories = territoriesCategory === 'All' ? new Set([Territory.AllTerritories]) : selectedTerritories;
  const orderedTerritoriesList = Object.values(Territory).filter((territory) => showTerritories.has(territory));

  const onSubmit = async (values) => {
    if (showTerritories.has(Territory.RestOfWorld) && showTerritories.size === 1) {
      setTerritoriesError(`You must select at least one territory along with ${Territory.RestOfWorld}`);
      return;
    }
    // Filter only selected territories and flatten the array
    const newData = [];
    orderedTerritoriesList.forEach((territory) => {
      // TODO: This sets the territory, it should have been set in data before this
      const territoryDataList = values[territory]?.map((territoryData) => ({ ...territoryData, territory }));
      if (territoryDataList) {
        newData.push(...territoryDataList);
      }
    });

    await saveData(newData);
    setReadMode();
  };

  function selectRow(territory) {
    let selectedRow;
    if (loanout || businessNames) {
      selectedRow = loanout ? LoanoutRow(entityType, territory) : BusinessNamesRow(entityType, territory);
    } else {
      selectedRow = TerritoryRow(includeNotes, numberValues, isTouring);
    }
    return selectedRow;
  }

  return (
    <FormContext {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardPane id={id}>
          <CardHeader isEditing>
            <CardTitle>{title}</CardTitle>
            <div data-cy={COMMON.CANCEL_BUTTON}>
              <CardButton type={buttonTypes.cancel} onClick={setReadMode} />
            </div>
            <div data-cy={COMMON.SAVE_BUTTON}>
              <CardButton type={buttonTypes.save} onClick={handleSubmit(onSubmit)} />
            </div>
          </CardHeader>
          <TerritoryCardBody>
            <TerritoriesSelector
              territoriesCategory={territoriesCategory}
              setTerritoriesCategory={setTerritoriesCategory}
              selectedTerritories={selectedTerritories}
              setSelectedTerritories={setSelectedTerritories}
              territoriesError={territoriesError}
            />

            {orderedTerritoriesList.map((territory) => (
              <FormArray
                key={territory}
                name={territory}
                territory={territory}
                individualItemTitle={individualItemTitle}
                textFieldTitle={textFieldTitle}
                requireOne={title === 'Tax IDs' ? false : true}
                itemForm={selectRow(territory)}
                wrapper={TerritoryWrapper}
              />
            ))}
          </TerritoryCardBody>
        </CardPane>
      </form>
    </FormContext>
  );
};

// Get a set of all the territories in the data
const useExistingTerritories = (territoriesData) => {
  return useMemo(() => {
    const territories = new Set(territoriesData.map(({ territory }) => territory));

    // If empty or AllTerritories, then ensure only AllTerritories
    if (!territories.size || territories.has(Territory.AllTerritories)) return new Set([Territory.AllTerritories]);

    // Ensure RestOfWorld
    return territories.add(Territory.RestOfWorld);
  }, [territoriesData]);
};

const TerritoryWrapper = ({ children, append, territory = Territory.AllTerritories, individualItemTitle }) => {
  return (
    <div>
      <TerritorySeparator />
      <TerritoryHeader>{territory}</TerritoryHeader>
      <div>
        <PrimaryColumn>Primary</PrimaryColumn>
      </div>
      <div>{children}</div>
      <div style={{ paddingLeft: '48px', marginTop: 10 }}>
        <TextButton
          text={`New ${individualItemTitle}`}
          icon={PlusIcon}
          fontSize={14}
          onMouseDown={() =>
            append({
              territory,
              label: '',
            })
          }
        />
      </div>
    </div>
  );
};

// NEED TO DO THIS BECAUSE WE DO NOT HAVE DISPATCH DOWN HERE
const searchPeople = (searchTerm) => {
  const query = [
    { $populate: 'noAzureIndex' },
    { $search: `name:${searchTerm}` },
    { $limit: 10 },
    { type: 'Client' },
    { type: 'Industry Contact' },
  ];
  return requestSansStore('/person', 'GET', null, query);
};

const IndividualLoanout = ({ remove, entryKey, relTypes }) => {
  const selectedRelType = useWatch({ name: `${entryKey}.relationshipType` });
  const personSelector = useWatch({ name: `${entryKey}.personId` });
  return (
    <TerritoryRowContainer>
      <PrimaryColumn style={{ marginTop: 4 }}>
        <FormArrayPrimaryRadioButton />
      </PrimaryColumn>
      <span style={{ width: 200, display: 'flex' }}>
        <FormSingleSelectDropdown title="Type" name="relationshipType" required options={relTypes} />
      </span>
      <FormEntitySelector
        required
        cacheOptions={false}
        title={selectedRelType || 'Loanout'}
        name={personSelector ? 'personId' : 'groupId'}
        loadOptions={(inputVal, callback) => {
          if (!inputVal) {
            callback([]);
            return;
          }
          if (!selectedRelType) {
            callback([]);
            return;
          }
          if (selectedRelType === 'Loanout For') {
            const calls = [getGroups(inputVal, ['Client', 'Industry Contact']), searchPeople(inputVal)];
            Promise.all(calls)
              .then((res) => {
                const [groups = {}, people = {}] = res;
                const { body = {} } = people;
                const { data = [] } = groups;
                const massagedPeople = (body.data || []).map((person) => {
                  person.designateType = 'Person';
                  return person;
                });
                const massagedGroups = data.slice(0, 10).map((group) => {
                  group.designateType = 'Company';
                  return group;
                });
                return callback([...massagedPeople, ...massagedGroups]);
              })
              .catch((err) => console.error(err));
          }
          if (selectedRelType === 'Loanout') {
            getGroups(inputVal, ['Client', 'Industry Contact']).then((res) => {
              return callback(
                (res.data || []).map((datum) => {
                  datum.designateType = 'Company';
                  return datum;
                })
              );
            });
          }
        }}
      />
      <XCloseIcon
        color={colors.cinnabar}
        width={18}
        height={18}
        onMouseDown={remove}
        style={{ cursor: 'pointer', marginTop: '10px' }}
      />
    </TerritoryRowContainer>
  );
};

const IndividualBusinessNamesRow = ({ remove, entryKey, relTypes }) => {
  const personSelector = useWatch({ name: `${entryKey}.personId` });
  return (
    <TerritoryRowContainer>
      <PrimaryColumn style={{ marginTop: 4 }}>
        <FormArrayPrimaryRadioButton />
      </PrimaryColumn>
      <span style={{ width: 200, display: 'flex' }}>
        <FormInput style={{ width: '100%' }} title="Label" required={true} name="label" />
      </span>
      <FormEntitySelector
        required
        cacheOptions={false}
        title={'Business'}
        name={personSelector ? 'personId' : 'groupId'}
        loadOptions={(inputVal, callback) => {
          if (!inputVal) {
            callback([]);
            return;
          }

          getGroups(inputVal, ['Client', 'Industry Contact']).then((res) => {
            return callback(
              (res.data || []).map((datum) => {
                datum.designateType = 'Company';
                return datum;
              })
            );
          });
        }}
      />
      <XCloseIcon
        color={colors.cinnabar}
        width={18}
        height={18}
        onMouseDown={remove}
        style={{ cursor: 'pointer', marginTop: '10px' }}
      />
    </TerritoryRowContainer>
  );
};

const LoanoutRow = (entityType, territory) => {
  let relTypes = [{ value: 'Loanout', label: 'Loanout' }];
  entityType === 'company' && relTypes.push({ label: 'Loanout For', value: 'Loanout For' });

  // eslint-disable-next-line react/display-name
  return ({ remove, idx }) => {
    return <IndividualLoanout remove={remove} relTypes={relTypes} entryKey={`${territory}.${idx}`} />;
  };
};

const BusinessNamesRow = (entityType, territory) => {
  // eslint-disable-next-line react/display-name
  return ({ remove, idx }) => {
    return <IndividualBusinessNamesRow remove={remove} entryKey={`${territory}.${idx}`} />;
  };
};

const TerritoryRow =
  (includeNotes, numbersOnly, isTouring) =>
  ({ remove, textFieldTitle }) => {
    return (
      <TerritoryRowContainer>
        <PrimaryColumn style={{ marginTop: 4 }}>
          <FormArrayPrimaryRadioButton />
        </PrimaryColumn>
        {isTouring ? (
          <>
            <section style={{ flexGrow: 1 }}>
              <FormInput style={{ width: '100%' }} title="Label" required={true} name="label" />
              <ProvisionsRow>
                <TextAreaContainer>
                  <FormTextArea title={textFieldTitle} name="text" />
                </TextAreaContainer>
              </ProvisionsRow>
            </section>
            <XCloseIcon
              color={colors.cinnabar}
              width={18}
              height={18}
              onMouseDown={remove}
              style={{ cursor: 'pointer' }}
            />
          </>
        ) : (
          <div>
            <span style={{ display: 'flex', width: 400, alignItems: 'baseline' }}>
              <FormInput title="Label" name="label" />
              <FormInput title={textFieldTitle} name="text" required autofocus type={numbersOnly ? 'number' : 'text'} />
              <XCloseIcon
                color={colors.cinnabar}
                width={18}
                height={18}
                onMouseDown={remove}
                style={{ cursor: 'pointer' }}
              />
            </span>

            {includeNotes && (
              <span style={{ display: 'flex' }}>
                <FormInput title="Notes" name="note" />
              </span>
            )}
          </div>
        )}
      </TerritoryRowContainer>
    );
  };

export const TerritoryCardBody = styled.div({
  padding: '5px 15px',
  display: 'flex',
  flexDirection: 'column',
});

const TerritoryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PrimaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  width: 48px;
  align-items: center;
`;

const TerritoryHeader = styled.div`
  margin-left: 48px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #141414;
`;
const TerritorySeparator = styled.hr`
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
`;

const ProvisionsRow = styled.section`
  display: flex;
  flex-direction: row;
`;
const TextAreaContainer = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
`;
