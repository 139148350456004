import React from 'react';
import { Info, Input, Select, TypeIcon } from '@united-talent-agency/julius-frontend-components';
import { MultiSelectDropdown } from '@united-talent-agency/components';

import { InfoContainer } from '../../../../styles/components/components';
import Card from '../../../../components/card/Card';
import { vocationOptions } from '../../../../components/basic-info/options';
import CommonTooltip from '../../../../components/common/tooltip';

import cypressTags from '../../../../support/cypressTags';
import { TOOLTIP_MESSAGES } from '../../../../components/common/messages/tooltip-messages';

export const Error = ({ msg }) => <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>;

export const companyTypes = [
  { key: 'Industry Contact', content: 'Industry Contact' },
  { key: 'Client', content: 'Client' },
  { key: 'Shared Contact', content: 'Shared Contact' },
];

const profileDisplayView = (isRequired, isUTACompany, { item: company }, mergeCardData) => {
  const companyType = companyTypes.find((companyType) => companyType.key === company.type);
  const companyTypeLabel = isUTACompany ? 'UTA' : (companyType && companyType.content) || company.type;
  const isClientOrIndustryContact = company.type === 'Client' || company.type === 'Industry Contact';
  const vocationsLabel = (company.vocations || []).join(', ');

  return (
    <div style={{ marginBottom: 10 }}>
      {(!mergeCardData || company.name) && (
        <Info name="Name" isRequired={isRequired}>
          {company.name}
        </Info>
      )}
      <Info name="Type" isRequired={isRequired}>
        <TypeIcon split entity={isUTACompany ? { type: 'Employee' } : company} /> {companyTypeLabel}
      </Info>
      {(!mergeCardData || company.distribution) && <Info name="Distribution">{company.distribution}</Info>}
      {isClientOrIndustryContact && (!mergeCardData || vocationsLabel) && (
        <Info name="Vocations">{vocationsLabel}</Info>
      )}
    </div>
  );
};

const profileEditView = (isRequired, errors, { item: company, setState }, companyNameValidator) => {
  const distributionOptions = ['Broadcast', 'Cable', 'Digital Platform', 'Premium Cable', 'Streaming'].map(
    (distribution) => ({
      key: distribution,
      content: distribution,
      active: company.distribution && distribution.key === company.distribution,
      onClick: () => setState({ distribution }),
    })
  );

  const typeOptions = companyTypes.map((type) => ({
    key: type.key,
    content: type.content,
    onClick: () => setState({ type: type.key }),
  }));

  const isClientOrIndustryContact = company.type === 'Client' || company.type === 'Industry Contact';

  return (
    <InfoContainer>
      <Input
        cyTag={cypressTags.COMPANY.NAME_INPUT}
        type="text"
        title="Name"
        value={company.name || ''}
        onChange={(name) => {
          if (!companyNameValidator(name) && name) {
            errors.name = `${name} is a Reserved Company Name`;
          } else {
            errors.name = '';
          }
          setState({ name });
        }}
        isRequired
      />
      {errors.name && <Error msg={errors.name} />}

      {/* { TODO: PROF-187 updated julius-frontend-components so that SELECT tag accepts cyTag} */}
      <Select title="Type" items={typeOptions} optionStyle={{ padding: 10 }} isRequired>
        {company.type}
      </Select>
      {errors.type && <Error msg={errors.type} />}
      <Select title="Distribution" items={distributionOptions} optionStyle={{ padding: 10 }}>
        {company.distribution}
      </Select>
      {/* { TODO: PROF-187 updated julius-frontend-components so that MultiSelectDropdown tag accepts cyTag} */}
      {isClientOrIndustryContact && (
        <div style={{ marginTop: 10 }}>
          <MultiSelectDropdown
            title="Vocations"
            defaultValues={(company.vocations || []).map((vocation) => ({
              label: vocation,
              value: vocation,
            }))}
            options={vocationOptions.map((vocation) => ({
              label: vocation,
              value: vocation,
            }))}
            onChange={(vocations) => {
              const vocationSelections = vocations.map((vocation) => vocation.value);
              setState({ vocations: vocationSelections });
            }}
          />
        </div>
      )}
    </InfoContainer>
  );
};

export const BasicInfoCard = ({
  company,
  editCompany,
  retrieveCompany,
  isRequired,
  onChange,
  errors = {},
  isUTACompany,
  reservedCompanyNames = [],
  mergeCardData,
  blockEdit,
  cyTag,
}) => {
  const readOnly = isUTACompany;
  const onSave = async (saveInfo = {}) => {
    await editCompany(company._id, saveInfo.updates[company._id]);
    retrieveCompany(company._id);
  };
  const companyNameValidator = (name) => {
    if (!name) return false;
    const regExp = RegExp(`^${name.trim()}$`, 'i');
    const isEmpty = name.trim() === '';
    const isReserved = reservedCompanyNames.some((reserved) => regExp.test(reserved));
    const isValid = !isReserved && !isEmpty;
    return isValid;
  };
  return (
    <div data-cy={cyTag} style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        title="Basic Info"
        mergeCardData={mergeCardData}
        data={company}
        readView={(props) => profileDisplayView(isRequired, isUTACompany, props, mergeCardData)}
        editView={
          !readOnly &&
          !blockEdit &&
          ((props) => profileEditView(isRequired, errors, props, companyNameValidator, mergeCardData))
        }
        onSave={onSave}
        onChange={onChange}
        errors={errors}
        onValidateItem={(item) => companyNameValidator(item.name)}
      />{' '}
    </div>
  );
};
