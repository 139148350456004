import React, { useState, useEffect } from 'react';
import { TextButton, Icons, colors } from '@united-talent-agency/components';
import { Info } from '@united-talent-agency/julius-frontend-components';
import { SearchableSelect } from '../searchable-select';
import { SelectorContainer } from '../../styles/components/components';
import styled from 'styled-components/macro';
import { getTouringTypes } from '../../api/get-enum-types';
import './styles.css';

const { PlusIcon, XCloseIcon } = Icons;

export default function EditView({
  territoryProps,
  territoryInitErrors,
  clearErrorsOnDelete,
  clearErrorsOnSelectAll,
  restErrors,
  errors,
}) {
  const [territoryValues, setTerritoryValues] = useState([]);
  const [locationValues, setLocationValues] = useState([]);
  const [subsidiaryValues, setSubsidiaryValues] = useState([]);
  const [lineOfBusinessValues, setLineOfBusinessValues] = useState([]);
  const [departmentValues, setDepartmentValues] = useState([]);
  const [showAddTerritoryBtn, setShowAddTerritoryBtn] = useState(true);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    getTouringTypes(`/v2/enums/master-data/touring-region-type`).then((results) => setTerritoryValues(results));
    getTouringTypes(`/v2/enums/master-data/touring-office-location-type`).then((results) => setLocationValues(results));
    getTouringTypes(`/v2/enums/master-data/touring-uta-subsidiary-type`).then((results) => {
      const subsidiary = results.map((ds) =>
        ds === 'The Agency Group Ltd' ? 'United Talent Agency UK Operations Ltd' : ds
      );
      setSubsidiaryValues(subsidiary);
    });
    getTouringTypes(`/v2/enums/master-data/touring-uta-lineofbusiness-type`).then((results) =>
      setLineOfBusinessValues(results)
    );
    getTouringTypes(`/v2/enums/master-data/touring-uta-department-type`).then((results) =>
      setDepartmentValues(results)
    );
  }, []);

  const { item: entity, setState } = territoryProps;
  const [touringTerritories, setTouringTerritories] = useState(
    entity?.territoryInfo?.touringAgentTerritories?.length
      ? entity?.territoryInfo?.touringAgentTerritories
      : [
          {
            territory: '',
            location: '',
            subsidiary: '',
            lineOfBusiness: '',
            department: '',
          },
        ]
  );

  useEffect(() => {
    if (touringTerritories.length > 0 && touringTerritories[0].territory === 'All Territories') {
      setShowAddTerritoryBtn(false);
    }
    if (touringTerritories.length === 0) {
      setShowAddTerritoryBtn(true);
    }
  }, [touringTerritories]);

  useEffect(() => {
    const errorCopy = [];
    touringTerritories.forEach((item, index) => {
      errorCopy.push({
        territory: item?.territory ? (item?.territory?.length === 0 ? true : false) : true,
        location: item?.location ? (item?.location?.length === 0 ? true : false) : true,
        subsidiary: item?.subsidiary ? (item?.subsidiary?.length === 0 ? true : false) : true,
        lineOfBusiness: item?.lineOfBusiness ? (item?.lineOfBusiness?.length === 0 ? true : false) : true,
        department: item?.department ? (item?.department?.length === 0 ? true : false) : true,
      });
    });
    territoryInitErrors(errorCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remove = (idx) => {
    let touringTerritoriesCopy = [...touringTerritories];
    touringTerritoriesCopy.splice(idx, 1);
    clearErrorsOnDelete(idx);
    setTouringTerritories(touringTerritoriesCopy);
    setState({ touringTerritories: [...touringTerritoriesCopy] });
  };

  let touringTerritoriesCopy = [...touringTerritories];
  return (
    <>
      <div>
        {touringTerritories.map((territoryData, idx) => {
          const {
            territory = '',
            location = '',
            subsidiary = '',
            lineOfBusiness = '',
            department = '',
          } = territoryData;

          return (
            <div key={idx} className="wrapper">
              <TerritoryRowContainer>
                <div style={{ flexGrow: 1 }}>
                  <Info name="Territory" styles={{ info: 'field-row' }} isRequired={true}>
                    <SelectorContainer>
                      <SearchableSelect
                        value={{ value: territory, label: territory }}
                        options={territoryValues}
                        onChange={(item) => {
                          const territoryValue = item ? item.value : item;
                          touringTerritoriesCopy[idx]['territory'] = territoryValue;

                          if (territoryValue === 'All Territories') {
                            clearErrorsOnSelectAll(idx, item, 'territory');
                            touringTerritoriesCopy = touringTerritoriesCopy.filter((_blank, index) => index === idx);
                            !touched && setTouched(true);
                          } else {
                            restErrors(idx, item, 'territory');
                            !touched && setTouched(true);
                          }

                          setState({ touringTerritories: [...touringTerritoriesCopy] });
                          setTouringTerritories(touringTerritoriesCopy);
                          setShowAddTerritoryBtn(!(item?.value === 'All Territories'));
                        }}
                      />
                    </SelectorContainer>
                    {touched && errors && errors[idx] && errors[idx]['territory'] && (
                      <ErrorPanel>{'Please select a Territory from drop down list'}</ErrorPanel>
                    )}
                  </Info>
                  <Info name="Location" styles={{ info: 'field-row' }} isRequired={true}>
                    <SelectorContainer>
                      <SearchableSelect
                        value={{ value: location, label: location }}
                        options={locationValues}
                        onChange={(item) => {
                          touringTerritoriesCopy[idx]['location'] = item ? item.value : item;
                          restErrors(idx, item, 'location');
                          !touched && setTouched(true);
                          setState({ touringTerritories: [...touringTerritoriesCopy] });
                          setTouringTerritories(touringTerritoriesCopy);
                        }}
                      />
                    </SelectorContainer>
                    {touched && errors && errors[idx] && errors[idx]['location'] && (
                      <ErrorPanel>{'Please select a Location from drop down list'}</ErrorPanel>
                    )}
                  </Info>
                  <Info name="Subsidiary" styles={{ info: 'field-row' }} isRequired={true}>
                    <SelectorContainer>
                      <SearchableSelect
                        value={{ value: subsidiary, label: subsidiary }}
                        options={subsidiaryValues}
                        onChange={(item) => {
                          restErrors(idx, item, 'subsidiary');
                          !touched && setTouched(true);
                          touringTerritoriesCopy[idx]['subsidiary'] = item ? item.value : item;
                          setState({ touringTerritories: [...touringTerritoriesCopy] });
                          setTouringTerritories(touringTerritoriesCopy);
                        }}
                      />
                    </SelectorContainer>
                    {touched && errors && errors[idx] && errors[idx]['subsidiary'] && (
                      <ErrorPanel>{'Please select a Subsidiary from drop down list'}</ErrorPanel>
                    )}
                  </Info>
                  <Info name="Line of Business" styles={{ info: 'field-row' }} isRequired={true}>
                    <SelectorContainer>
                      <SearchableSelect
                        value={{ value: lineOfBusiness, label: lineOfBusiness }}
                        options={lineOfBusinessValues}
                        onChange={(item) => {
                          restErrors(idx, item, 'lineOfBusiness');
                          !touched && setTouched(true);
                          touringTerritoriesCopy[idx]['lineOfBusiness'] = item ? item.value : item;
                          setState({ touringTerritories: [...touringTerritoriesCopy] });
                          setTouringTerritories(touringTerritoriesCopy);
                        }}
                      />
                    </SelectorContainer>
                    {touched && errors && errors[idx] && errors[idx]['lineOfBusiness'] && (
                      <ErrorPanel>{'Please select a Line of Business from drop down list'}</ErrorPanel>
                    )}
                  </Info>
                  <Info name="Department" styles={{ info: 'field-row' }} isRequired={true}>
                    <SelectorContainer>
                      <SearchableSelect
                        value={{ value: department, label: department }}
                        options={departmentValues}
                        onChange={(item) => {
                          restErrors(idx, item, 'department');
                          !touched && setTouched(true);
                          touringTerritoriesCopy[idx]['department'] = item ? item.value : item;
                          setState({ touringTerritories: [...touringTerritoriesCopy] });
                          setTouringTerritories(touringTerritoriesCopy);
                        }}
                      />
                    </SelectorContainer>
                    {touched && errors && errors[idx] && errors[idx]['department'] && (
                      <ErrorPanel>{'Please select a Department from drop down list'}</ErrorPanel>
                    )}
                  </Info>
                </div>
                {/* // wrap the close icon in a div so it is padded from left */}
                <CrossIconWrapper>
                  <XCloseIcon
                    color={colors.cinnabar}
                    width={18}
                    height={18}
                    onMouseDown={() => {
                      remove(idx);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </CrossIconWrapper>
              </TerritoryRowContainer>
            </div>
          );
        })}
      </div>
      <div style={{ paddingLeft: '48px', marginTop: 10 }}>
        {/* only render add new button after the last territory */}
        {showAddTerritoryBtn ? (
          <TextButton
            text={`Add new Territory`}
            icon={PlusIcon}
            fontSize={14}
            onMouseDown={() => {
              // if last entry has keys with no values, don't add new entry
              const lastEntry = touringTerritories.length > 0 && touringTerritories[touringTerritories.length - 1];
              if (
                lastEntry.territory === '' &&
                lastEntry.location === '' &&
                lastEntry.subsidiary === '' &&
                lastEntry.lineOfBusiness === '' &&
                lastEntry.department === ''
              ) {
                return;
              }
              setTouringTerritories([
                ...touringTerritories,
                {
                  territory: '',
                  location: '',
                  subsidiary: '',
                  lineOfBusiness: '',
                  department: '',
                },
              ]);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

const TerritoryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CrossIconWrapper = styled.div`
  padding-left: 1rem;
  padding-top: 1rem;
`;

const ErrorPanel = styled.div`
  font-size: 14px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  color: red;
`;
