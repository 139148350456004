import React from 'react';
import Modal from 'react-modal';

// app.js might be a better place to register this
Modal.setAppElement(document.getElementById('app'));

export default function AlertDialog({
  title,
  promptText,
  children,
  isOpen,
  onRequestClose,
  showConfirm,
  onConfirm,
  ariaHideApp = true,
}) {
  return (
    <Modal
      contentLabel={title}
      ariaHideApp={ariaHideApp}
      isOpen={!!isOpen}
      onRequestClose={() => onRequestClose()}
      style={{
        content: {
          ...modalStyles.container,
        },
      }}
    >
      <div style={modalStyles.xButtonContainer}>
        <i onClick={() => onRequestClose()} className="fa fa-times" style={modalStyles.xButton} />
      </div>
      <div style={modalStyles.contentContainer}>
        <p style={modalStyles.headerText}>{title}</p>
        {promptText && (
          <div className="promptText" style={modalStyles.promptText}>
            {promptText}
          </div>
        )}
        {children && <div className="specifiedText">{children}</div>}
        {showConfirm && (
          <div style={modalStyles.buttonContainer}>
            <button style={modalStyles.cancelButton} onClick={() => onRequestClose()}>
              CANCEL
            </button>
            <button style={modalStyles.confirmButton} onClick={() => onConfirm()}>
              CONFIRM
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export const modalStyles = {
  container: {
    top: '50%',
    left: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    fontFamily: 'aktiv-grotesk',
    border: '1px solid #CCCCCC',
    borderRadius: '1px',
    backgroundColor: '#F5F5F5',
    width: '500px',
    padding: '0',
    paddingBottom: 20,
  },
  xButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  xButton: {
    padding: '5px 5px 0px 0px',
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerText: {
    color: '#212529',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'aktiv-grotesk',
    textTransform: 'uppercase',
  },
  promptText: { flex: 1, fontSize: '14px', padding: '0px 30px 10px 30px', alignSelf: 'left', width: '100%' },
  buttonContainer: { display: 'flex', justifyContent: 'space-evenly', paddingTop: '10px', width: '60%' },
  cancelButton: {
    color: '#212529',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
  confirmButton: {
    backgroundColor: '#212529',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
};
