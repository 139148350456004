import React, { useContext, useEffect, useState, useCallback } from 'react';
import { styled } from 'react-free-style';
import AddressFormatter from '@shopify/address/index.js';
import _ from 'lodash';

import { Info, Input, Select } from '@united-talent-agency/julius-frontend-components';
import { AddressSelector, Address } from '@united-talent-agency/components';

import { FormContext } from '../../support/FormContext';

// This was pulled over from JFEC for easier editing
// TODO: This could be split into AddressEditor / AddressInfo - Do this after the old Card is refactored out
// The viewer could go into Components
// Only Profiles uses the editor, data-steward uses the viewer
// TODO: Convert this to styled-components format

export const Error = ({ msg }) => <div style={{ color: 'red', fontSize: '12px', clear: 'both' }}>{msg}</div>;

const InputWithErrorMessage = ({ fieldKey, showAllValidationErrors, fieldVisited, fieldErrors, onBlur, ...props }) => {
  const errorMessage = showAllValidationErrors || fieldVisited[fieldKey] ? fieldErrors[fieldKey] : null;
  return (
    <div style={{ flex: 1 }}>
      <Input {...props} isRequired onBlur={() => onBlur(fieldKey)} />
      <Error msg={errorMessage} />
    </div>
  );
};

function AddressInfo({ styles, address, onChange, isEditing, addressTypes, noRequirements }) {
  const [fieldVisited, setFieldVisited] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, selectCountry] = useState({});
  const [countryData, setCountryData] = useState({});
  const [fieldOrder, setFieldOrder] = useState([]);
  const [fieldsToRemove, setFieldsToRemove] = useState({});

  const { showAllValidationErrors } = useContext(FormContext);
  const relevantFields = ['address1', 'address2', 'city', 'zip', 'province'];

  const addressFormatter = new AddressFormatter('en');

  const getCountries = useCallback(async () => {
    const response = await addressFormatter.getCountries();
    return response;
  }, [addressFormatter]);

  const getCountry = useCallback(
    async (code) => {
      return await addressFormatter.getCountry(code);
    },
    [addressFormatter]
  );

  const determineCountryFromAddress = useCallback(
    (name) => {
      const option = countryOptions.filter((country) => {
        return country.name.toLowerCase() === name.toLowerCase();
      });
      if (option.length) {
        return option[0];
      }
      return { name: 'United States', code: 'US' };
    },
    [countryOptions]
  );

  const isValidAddress = useCallback(
    (address) => {
      return (
        fieldOrder.length &&
        fieldOrder
          .map((line) => {
            return line.every((field) => {
              if (field === 'address2') {
                return true;
              }
              if (field === 'address1') {
                return address.address && address.address.length;
              }
              const value = field === 'province' ? address.state : address[field];
              return value && value.length;
            });
          })
          .every((line) => Boolean(line))
      );
    },
    [fieldOrder]
  );

  const getFieldOrder = useCallback(
    (formatting) => {
      let format = [];
      for (let line of formatting.split('_')) {
        line = line.slice(1, -1).split('}{');

        const lineFormat = line.filter(function (field) {
          return relevantFields.includes(field);
        });
        if (lineFormat.length) {
          format.push(lineFormat);
        }
      }
      setFieldOrder(format);

      const removalFields = { province: 'state', city: 'city', zip: 'zip' };
      let remove = {};
      for (const [key, value] of Object.entries(removalFields)) {
        if (address[value] && !format.flat().includes(key)) {
          remove[value] = '';
        }
      }
      setFieldsToRemove(remove);
    },
    [address, relevantFields, setFieldOrder, setFieldsToRemove]
  );

  const removeFields = useCallback(() => {
    if (!_.isEqual(fieldsToRemove, {})) {
      onChange(fieldsToRemove);
      setFieldsToRemove({});
    }
  }, [fieldsToRemove, onChange, setFieldsToRemove]);

  const onCountryChange = useCallback(
    (country) => {
      // if only name given, determine country from valid options
      if (!country.code) {
        country = determineCountryFromAddress(country.name);
      }
      const { name, code } = country;
      selectCountry({ label: name, value: code });
      onChange && onChange({ country: name });

      if (country.labels) {
        setCountryData({
          labels: country.labels,
          optional: country.optionalLabels,
          zones: country.zones,
        });
        getFieldOrder(country.formatting.edit);
      } else if (code) {
        getCountry(code).then((data) => {
          setCountryData({
            labels: data.labels,
            optional: data.optionalLabels,
            formatting: data.formatting,
            zones: data.zones,
          });
          getFieldOrder(data.formatting.edit);
        });
      }
    },
    [getCountry, onChange, determineCountryFromAddress, selectCountry, setCountryData, getFieldOrder]
  );

  useEffect(() => {
    // get the list of country options
    if (!countryOptions.length) {
      getCountries().then((data) => setCountryOptions(data));
    }
    // if no country selected (at beginning)
    if (!fieldOrder.length && countryOptions.length) {
      // for new address or address with invalid country, default to United States / US
      const name = address.address && address.country ? address.country : 'United States';
      const code = address.country ? null : 'US';
      onCountryChange({ name, code });
    }

    onChange && removeFields();

    if (!noRequirements && fieldOrder.length) {
      let newErrors = {};
      let includedFields = fieldOrder.flat();
      includedFields.push('country');

      const fieldDict = {
        province: ['state', 'State'],
        zip: ['zip', 'Zip'],
        city: ['city', 'City'],
        country: ['country', 'Country'],
        address1: ['address', 'Address'],
      };

      // determine which required fields are missing from address, and reduce to a map of errors
      for (const [key, [value, str]] of Object.entries(fieldDict)) {
        if (includedFields.includes(key) && !address[value]) {
          newErrors[value] = `${str} is required`;
        }
      }

      const errorNumber = Object.keys(newErrors).length;
      if (onChange && errorNumber !== address.errors) {
        onChange({ errors: errorNumber });
      }
      if (!_.isEqual(fieldErrors, newErrors)) {
        setFieldErrors(newErrors);
      }
    }
  }, [
    address,
    fieldErrors,
    noRequirements,
    countryOptions,
    fieldOrder,
    fieldsToRemove,
    getCountries,
    onChange,
    onCountryChange,
    removeFields,
  ]);

  const label = address && address.type ? address.type : (address.type = 'Work');

  if (!isEditing) {
    return (
      <Info name={label} isPrimary={address.primary}>
        {isValidAddress(address) ? (
          <Address name="read-address" address={address} fieldOrder={fieldOrder} />
        ) : (
          <div style={{ textAlign: 'right' }}>Invalid Data</div>
        )}
      </Info>
    );
  }

  const onBlur = (fieldKey) => {
    setFieldVisited({ ...fieldVisited, [fieldKey]: true });
  };

  const typeItems = (addressTypes || ['Home', 'Work', 'Vacation']).map((type) => {
    return {
      key: type,
      content: type,
      active: type === address.type,
      className: styles.menuItem,
      onClick: () => onChange({ type }),
    };
  });

  const countryItems = countryOptions.map((country) => {
    const name = country.name;
    const code = country.code;
    return {
      value: code,
      label: name,
    };
  });

  const zoneItems = countryData.zones
    ? countryData.zones.map((zone) => {
        return {
          value: zone.code,
          label: zone.code,
        };
      })
    : [];

  const getInputFlavor = (props) => {
    return noRequirements ? (
      <Input name={props.fieldKey} type="text" className={styles.input} {...props} />
    ) : (
      <InputWithErrorMessage
        name={props.fieldKey}
        key={props.fieldKey}
        type="text"
        className={styles.input}
        showAllValidationErrors={showAllValidationErrors}
        fieldVisited={fieldVisited}
        onBlur={onBlur}
        fieldErrors={fieldErrors}
        {...props}
      />
    );
  };

  const address2Input = (i) => {
    return (
      <Input
        name="edit-address2"
        key={'address2' + i}
        type="text"
        title={countryData.labels?.address2}
        value={address.suite || ''}
        className={styles.input}
        onChange={(suite) => onChange({ suite })}
      />
    );
  };

  const zoneInput = (i) => {
    const selected = { value: address.state, label: address.state };
    return (
      <AddressSelector
        name="zone-selector"
        key={'zone' + i}
        items={zoneItems}
        title={countryData.labels?.zone}
        required
        onChange={handleZoneChange}
        value={selected}
      />
    );
  };

  const fieldKeys = {
    address1: ['address', 'address1'],
    city: ['city', 'city'],
    zip: ['zip', 'postalCode'],
    province: ['state', 'zone'],
  };

  const renderField = (fieldType, i) => {
    if (fieldType === 'address2') return address2Input(i);

    if (fieldType === 'province') {
      if (countryData.zones?.length) return zoneInput(i);
    }

    let change;
    if (fieldType === 'address1') {
      change = (address) => onChange({ address });
    } else if (fieldType === 'city') {
      change = (city) => onChange({ city });
    } else if (fieldType === 'zip') {
      change = (zip) => onChange({ zip });
    } else if (fieldType === 'province') {
      change = (state) => onChange({ state });
    } else {
      return <></>;
    }

    const [addressKey, countryKey] = fieldKeys[fieldType];
    const title = countryData.labels[countryKey];
    const value = address[addressKey] || '';
    const fieldKey = addressKey;

    return getInputFlavor({
      title: title,
      fieldKey: fieldKey,
      value: value,
      onChange: change,
    });
  };

  const handleCountryChange = (selection) => {
    // remove fields from address that arent in this country
    onChange({ country: selection.label });
    onCountryChange({ name: selection.label, code: selection.value });
    selectCountry(selection);
  };

  const handleZoneChange = (selection) => {
    onChange({ state: selection.label });
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <Select items={typeItems} className={styles.selectInput}>
          {label}
        </Select>
      </div>
      <div className={styles.section} style={{ width: '100%' }}>
        <div className={styles.lineInput}>
          <AddressSelector
            name="country-selector"
            items={countryItems}
            title="Country"
            required
            onChange={handleCountryChange}
            value={selectedCountry}
          />
        </div>
        {fieldOrder.length &&
          fieldOrder.map((fields, i) => {
            return (
              <div key={`line-key-${i}`} className={styles.lineInput} name="edit-address-line">
                {fields.map((field) => {
                  return renderField(field, i);
                })}
              </div>
            );
          })}
        {!isValidAddress(address) && (
          <div
            style={{
              marginTop: 6,
              marginBottom: 16,
              color: 'red',
              fontSize: '12px',
            }}
          >
            Invalid Address
          </div>
        )}
      </div>
    </div>
  );
}

const withStyles = styled({
  container: {
    display: 'flex',
    marginTop: '4px',
    marginBottom: 10,
  },
  section: {
    '&+&': {
      marginLeft: 10,
    },
  },
  lineInput: {
    display: 'flex',
    marginBottom: 10,
  },
  input: {
    flex: 1,
    '&+&': {
      marginLeft: 10,
    },
  },
  selectInput: {
    width: 100,
  },
  menuItem: {
    padding: 10,
  },
});

export default withStyles(AddressInfo);
