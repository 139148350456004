import React from 'react';
import { styled } from 'react-free-style';

const Checkbox = ({ checked, onChange, styles = {}, text, disabled }) => (
  <div>
    <input type="checkbox" disabled={disabled} checked={checked} className={styles.primary} onChange={onChange} />
    <span className={styles.primaryText}>{text}</span>
  </div>
);

const withStyles = styled({
  primaryText: { marginLeft: 10 },
});

export default withStyles(Checkbox);
