import React from 'react';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { colors } from '@united-talent-agency/julius-frontend-components';

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }
  onClickEvent() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
  render() {
    const { styles, name, meta, children, isPrimary, clickable, clicked, isRequired, cyTag } = this.props;
    const title = isRequired ? (
      <span>
        {name}
        <span style={{ color: 'red', padding: '0 2px' }}>*</span>
      </span>
    ) : (
      name
    );
    return (
      <div
        onClick={() => {
          if (clickable) {
            this.onClickEvent();
          }
        }}
        className={clicked ? styles.clicked : styles.info}
      >
        <span data-cy={cyTag} className={classnames(styles.name, isPrimary && styles.bold)}>
          {title}
          {meta ? <span className={styles.meta}> ({meta})</span> : undefined}
        </span>
        {isPrimary && <span className={styles.primary}>(Primary)</span>}
        <div className={styles.right}>{children}</div>
      </div>
    );
  }
}

const withStyles = styled({
  info: {
    fontSize: 12,
    display: 'flex',
    padding: '10px 0',
    borderBottom: `1px dotted ${colors.infoBorder}`,
  },
  clicked: {
    fontSize: 12,
    display: 'flex',
    padding: '10px 0',
    borderBottom: `1px dotted ${colors.infoBorder}`,
    background: 'rgba(113,186,83, 0.25)',
  },
  primary: {
    marginLeft: 5,
    fontWeight: 700,
  },
  name: {
    color: colors.text,
    fontWeight: 300,
  },
  meta: {
    color: colors.lightText,
  },
  right: {
    marginLeft: 'auto',
    paddingLeft: '10px',
    whiteSpace: 'break-spaces',
  },
  bold: {
    fontWeight: 'normal',
  },
});

export default withStyles(Info);
