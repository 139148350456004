import * as React from 'react';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { notify } from 'react-notify-toast';
import cypressTags from '../../../src/support/cypressTags';

import { Avatar } from '@united-talent-agency/julius-frontend-components';

import PlusIcon from '../../assets/images/plus-circle.svg';

class ProfilePic extends React.Component {
  convertPhoto(event) {
    return new Promise((resolve) => {
      const file = event.nativeEvent.target.files[0];

      if (!file) {
        notify.show('No file selected', 'info');
        return;
      }

      // Check the file size (e.g., 20MB limit)
      const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
      if (file.size > maxSizeInBytes) {
        notify.show('The image file size cannot exceed 20MB.', 'error');
        return;
      }

      const { type } = file;
      const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

      if (!allowedFormats.includes(type)) {
        notify.show('Invalid file format. Please select a JPEG, JPG or PNG image.', 'error');
        return;
      }

      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          let image = new Image();
          image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;

            ctx.drawImage(image, 0, 0, image.width, image.height);
            const dataUrl = canvas.toDataURL('image/jpeg', 1);
            resolve(dataUrl);
          };
          image.src = reader.result;
        },
        false
      );

      if (file) reader.readAsDataURL(file);

      event.target.value = null;
    });
  }

  render() {
    const { styles, isEditing, person, onChange, cyTag } = this.props;

    return (
      <div
        className={classnames(styles.container, isEditing && styles.editHeadshot)}
        onClick={() => isEditing && this.photoInput.click()}
        data-cy={cyTag}
      >
        {
          <div
            data-cy={cypressTags.COMMON.BASIC_INFO_CARD.PROFILE_IMAGE_FETCH}
            className={styles.headshotPhoto}
            style={{ backgroundImage: `url(${person?.profile_pic || Avatar})` }}
          />
        }
        {isEditing && (
          <div className={styles.overlay}>
            <div className={styles.overlayBackground} />
            <img className={styles.overlayIcon} src={PlusIcon} alt="plus icon" />
          </div>
        )}
        <input
          type="file"
          className={styles.uploadInput}
          ref={(n) => (this.photoInput = n)}
          onChange={(e) => {
            this.convertPhoto(e).then((src) => onChange(src));
          }}
        />
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    width: 140,
    height: 140,
    borderRadius: 999,
    padding: 5,
    border: '1px solid #DEDEDE',
    margin: '0 auto 25px',
    position: 'relative',
    overflow: 'hidden',
  },
  uploadInput: {
    display: 'none',
  },
  overlay: {
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 10px)',
    position: 'absolute',
    top: 5,
    left: 5,
  },
  overlayBackground: {
    width: '100%',
    height: '100%',
    borderRadius: 999,
    backgroundColor: 'white',
    opacity: 0.5,
  },
  overlayIcon: {
    position: 'absolute',
    top: 0,
    marginTop: '50%',
    marginLeft: '50%',
    transform: 'translate(-50%, -50%) scale(0.75, 0.75)',
  },
  headshotPhoto: {
    width: '100%',
    height: '100%',
    borderRadius: 1000,
    backgroundSize: 'cover',
  },
  editHeadshot: {
    cursor: 'pointer',
  },
});

export default withStyles(ProfilePic);
