import React from 'react';
import { isEmpty } from 'lodash';

const contractSignerText = ({ index, change }) => {
  return (
    <>
      {Array.isArray(change?.text)
        ? typeof change?.text[index] === 'string'
          ? change?.text[index].replace(/\n/g, ' ')
          : change?.text[index]
        : change?.text.replace(/\n/g, ' ')}
    </>
  );
};

const displayContractSinger = ({ subKey, changes }) => {
  return (
    <>
      {changes['created'] &&
        changes['created'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Contract Signer Block: '}</strong>}
            {change.territory === 'All Territories' ? 'All Territories' : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change.label && change.label}
            {'; Contract Signer Block: '}
            {change.text && change.text.replace(/\n/g, ' ')}
            {' was created'}
            {change.primary && change.primary === true ? ' and is the Primary' : ''}
          </div>
        ))}
      {changes['updated'] &&
        changes['updated'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Contract Signer Block: '}</strong>}
            {change?.territory && Array.isArray(change?.territory) && change?.territory.length > 0
              ? change.territory[0] === 'All Territories'
                ? 'All Territories'
                : `Itemized Territories: ${change.territory[0]}`
              : change.territory === 'All Territories'
              ? 'All Territories'
              : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change?.label && Array.isArray(change?.label) && change?.label.length > 0
              ? change?.label[0]
              : change?.label}
            {'; Contract Signer Block: '}
            {contractSignerText({ index: 0, change })}
            {' was changed to '}
            {change?.territory && Array.isArray(change?.territory) && change?.territory.length > 0
              ? change.territory[1] === 'All Territories'
                ? 'All Territories'
                : `Itemized Territories: ${change.territory[1]}`
              : change.territory === 'All Territories'
              ? 'All Territories'
              : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change?.label && Array.isArray(change?.label) && change?.label.length > 0
              ? change?.label[1]
              : change?.label}
            {'; Contract Signer Block: '}
            {contractSignerText({ index: 1, change })}
            {change?.primary === true || (change?.primary?.length === 1 && change?.primary[0] === true)
              ? ' and is the Primary'
              : ''}
          </div>
        ))}
      {changes['deleted'] &&
        changes['deleted'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Contract Signer Block: '}</strong>}
            {change.territory === 'All Territories' ? 'All Territories' : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change?.label && change?.label}
            {'; Contract Signer Block: '}
            {change?.text.replace(/\n/g, ' ')}
            {' was deleted '}
          </div>
        ))}
    </>
  );
};

const handleContractSigner = ({ territoryInfo, auditChangeObject, subKey, AUDIT_LABEL_DISPLAY }) => {
  const { contractSignerBlocks = [] } = territoryInfo;
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[subKey];
  let touringInfoHistory = [];
  const contractSingerTerritory = Object.keys(contractSignerBlocks);
  const changes = {};
  if (contractSingerTerritory.length > 0) {
    contractSingerTerritory.forEach((territory) => {
      const dsKeys = Object.keys(contractSignerBlocks[territory]);
      dsKeys.forEach((dsKey) => {
        if (
          Array.isArray(contractSignerBlocks[territory][dsKey]) &&
          contractSignerBlocks[territory][dsKey].length === 1
        ) {
          if (!changes['created']) {
            changes['created'] = [];
          }
          changes['created'].push({
            label: contractSignerBlocks[territory][dsKey][0].label,
            text: contractSignerBlocks[territory][dsKey][0].text,
            territory: contractSignerBlocks[territory][dsKey][0].territory,
            primary: contractSignerBlocks[territory][dsKey][0].primary,
          });
        } else if (
          typeof contractSignerBlocks[territory][dsKey] === 'object' &&
          !Array.isArray(contractSignerBlocks[territory][dsKey]) &&
          contractSignerBlocks.changeType !== 'Deleted'
        ) {
          // agent updated
          if (!changes['updated']) {
            changes['updated'] = [];
          }
          if (
            contractSignerBlocks[territory][dsKey]?.label ||
            contractSignerBlocks[territory][dsKey]?.text ||
            contractSignerBlocks[territory][dsKey]?.territory
          ) {
            changes['updated'].push({
              label: contractSignerBlocks[territory][dsKey]?.label && contractSignerBlocks[territory][dsKey]?.label,
              text: contractSignerBlocks[territory][dsKey]?.text && contractSignerBlocks[territory][dsKey]?.text,
              territory:
                contractSignerBlocks[territory][dsKey]?.territory && contractSignerBlocks[territory][dsKey]?.territory,
              primary:
                contractSignerBlocks[territory][dsKey]?.primary && contractSignerBlocks[territory][dsKey]?.primary,
            });
          }
        } else if (
          typeof contractSignerBlocks[territory][dsKey] === 'object' &&
          !Array.isArray(contractSignerBlocks[territory][dsKey]) &&
          contractSignerBlocks.changeType === 'Deleted'
        ) {
          // agent deleted
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          if (
            contractSignerBlocks[dsKey]?.personId ||
            contractSignerBlocks[dsKey]?.type ||
            contractSignerBlocks[dsKey]?.regions ||
            contractSignerBlocks[dsKey]?.primary
          ) {
            changes['deleted'].push({
              label: contractSignerBlocks[dsKey]?.label && contractSignerBlocks[dsKey]?.label,
              text: contractSignerBlocks[dsKey]?.text && contractSignerBlocks[dsKey]?.text,
              territory: contractSignerBlocks[dsKey]?.territory && contractSignerBlocks[dsKey]?.territory,
              primary: contractSignerBlocks[dsKey]?.primary && contractSignerBlocks[dsKey]?.primary,
            });
          }
        } else if (
          Array.isArray(contractSignerBlocks[territory][dsKey]) &&
          contractSignerBlocks[territory][dsKey].length === 3 &&
          contractSignerBlocks[territory][dsKey][2] === 0
        ) {
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          changes['deleted'].push({
            label: contractSignerBlocks[territory][dsKey][0].label,
            text: contractSignerBlocks[territory][dsKey][0].text,
            territory: contractSignerBlocks[territory][dsKey][0].territory,
            primary: contractSignerBlocks[territory][dsKey][0].primary,
          });
        }
      });
    });
    touringInfoHistory.push(
      <>
        {displayContractSinger({
          subKey,
          changes,
        })}
      </>
    );
    if (!isEmpty(changes)) {
      auditChangeObject[auditLabelDisplay] = touringInfoHistory;
    }
  }
};

export default handleContractSigner;
