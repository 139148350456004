import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { createPerson } from '@united-talent-agency/julius-frontend-store';
import { omit } from 'lodash';

import {
  PageBody,
  Columns,
  Column,
  PageHeader,
  TitleContainer,
  Name,
  PageContainer,
  PageContent,
} from '../../styles/components/components';

import ContactsCard from '../../components/person-contacts-card/PersonContactsCard';
import AddressesCard from '../../components/addresses-card/AddressesCard';
import { SaveButton } from '../../components/save-button/SaveButton';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped';
import { BasicInfoCard } from '../profile/views/profile/BasicInfoCard';
import TeamView from '../profile/views/team';
import { FormValidationContainer } from '../../support/FormContext';
import { emailContactFilter, otherContactFilter, phoneContactFilter } from '../../support/contacts';
import cypressTags from '../../support/cypressTags';
import { getAccessGroup } from '../../api/access-groups';

const { PERSON } = cypressTags;

const CreatePersonView = (props) => {
  const { dispatch, history, user } = props;

  const [newPerson, setNewPerson] = useState({ contacts: [], addresses: [], internalTeam: [] });
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [emailContacts, setEmailContacts] = useState([]);
  const [otherContacts, setOtherContacts] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [validCards, setValidCards] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [isDataStewardsMember, setIsDataStewardsMember] = useState(false);
  const [showAllValidationErrors, setShowAllValidationErrors] = useState(false);
  const hasErrors = Object.values(validCards).some((valid) => !valid);

  const savePerson = async () => {
    if (hasErrors) {
      setShowAllValidationErrors(true);
    }
    if (!newPerson.name && !newPerson.type) {
      setFormErrors({ name: 'Name is required', type: 'Type is required' });
    } else if (!newPerson.name) {
      setFormErrors({ name: 'Name is required' });
    } else if (!newPerson.type) {
      setFormErrors({ type: 'Type is required' });
    } else if (hasErrors) {
      setFormErrors({});
    } else if (!saving) {
      // Empty contacts & addresses will be filtered out by the server
      const personToCreate = {
        ...newPerson,
        contacts: [...phoneContacts, ...emailContacts, ...otherContacts],
        addresses,
      };

      setSaving(true);
      const response = await dispatch(createPerson(personToCreate));
      setSaving(false);
      const newlyCreatedId = response.body && response.body._id;
      if (response.status === 200 && newlyCreatedId) {
        notify.show('Person created', 'success');
        history.push(`/profile/${newlyCreatedId}`);
      } else {
        notify.show('Error saving person', 'error');
      }
    }
  };

  useEffect(() => {
    if (user) {
      getAccessGroup({
        params: {
          name: 'DATA_STEWARD',
        },
      })
        .then((members) => {
          if (members.includes(user?.personId?._id)) {
            setIsDataStewardsMember(true);
          }
        })
        .catch((error) => {
          notify.show('Error getting territory data', 'error');
          throw error;
        });
    }
  }, [user]);

  useEffect(() => {
    if (!hasErrors && showAllValidationErrors) {
      // User cleared up existing errors, we can clear this flag
      setShowAllValidationErrors(false);
    }
  }, [hasErrors, showAllValidationErrors]);

  return (
    <PageContainer>
      <Helmet>
        <title>CREATE PERSON</title>
      </Helmet>
      <BrandingWrapped />
      <PageHeader>
        <TitleContainer>
          <Name>Create Person</Name>
          <SaveButton onSave={savePerson} hasErrors={hasErrors} />
          <div style={{ padding: '28px 0px' }}></div>
        </TitleContainer>
      </PageHeader>
      <PageBody>
        <PageContent>
          <FormValidationContainer showAllValidationErrors={showAllValidationErrors}>
            <Columns>
              <Column>
                <BasicInfoCard
                  person={newPerson}
                  cyTag={PERSON.BASIC_INFO_CARD}
                  isRequired
                  onChange={(saveInfo) => {
                    const newData = saveInfo.mergedData[0];
                    if (formErrors.name && newData.name && newData.name !== '') {
                      setFormErrors(omit(formErrors, 'name'));
                    }
                    if (formErrors.type && newData.type && newData.type !== '') {
                      setFormErrors(omit(formErrors, 'type'));
                    }
                    setNewPerson({ ...newPerson, ...newData });
                    setValidCards({ ...validCards, 'basic-info': !saveInfo.hasError });
                  }}
                  errors={formErrors}
                  isDataStewardsMember={isDataStewardsMember}
                />

                <TeamView
                  onChange={(saveInfo) => {
                    setNewPerson({ ...newPerson, internalTeam: saveInfo.mergedData });
                    setValidCards({ ...validCards, internalTeam: !saveInfo.hasError });
                  }}
                  cyTag={PERSON.UTA_RELATIONSHIP_CARD}
                  entity={newPerson}
                  dispatch={dispatch}
                />
              </Column>
              <Column>
                <ContactsCard
                  person={newPerson}
                  canSetPrimary
                  title="Phone Contacts"
                  contactTypeFilter={phoneContactFilter}
                  onChange={(saveInfo) => {
                    setPhoneContacts(saveInfo.mergedData);
                    setValidCards({ ...validCards, 'phone-contacts': !saveInfo.hasError });
                  }}
                />
                <ContactsCard
                  person={newPerson}
                  canSetPrimary
                  title="Email Contacts"
                  contactTypeFilter={emailContactFilter}
                  onChange={(saveInfo) => {
                    setEmailContacts(saveInfo.mergedData);
                    setValidCards({ ...validCards, 'email-contacts': !saveInfo.hasError });
                  }}
                />
                <ContactsCard
                  person={newPerson}
                  title="Other Contacts"
                  contactTypeFilter={otherContactFilter}
                  onChange={(saveInfo) => {
                    setOtherContacts(saveInfo.mergedData);
                    setValidCards({ ...validCards, 'other-contacts': !saveInfo.hasError });
                  }}
                />

                <AddressesCard
                  entity={newPerson}
                  validateAddresses
                  onChange={(saveInfo) => {
                    for (let data of Object.values(saveInfo.mergedData)) {
                      delete data.errors;
                    }
                    setAddresses(saveInfo.mergedData);
                    setValidCards({ ...validCards, addresses: !saveInfo.hasError });
                  }}
                  blockDefaultEmptyItem={true}
                />
              </Column>
            </Columns>
          </FormValidationContainer>
        </PageContent>
      </PageBody>
    </PageContainer>
  );
};

export default withRouter(CreatePersonView);
