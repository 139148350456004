import { Checkbox, RadioButton, RadioGroup } from '@united-talent-agency/components';
import { Territory } from './utils/const';
import React from 'react';
import styled from 'styled-components/macro';

export const TerritoriesSelector = ({
  territoriesCategory,
  setTerritoriesCategory,
  selectedTerritories,
  setSelectedTerritories,
  territoriesError,
}) => (
  <RadioGroup name="territoriesCategory" value={territoriesCategory} onValueChange={setTerritoriesCategory}>
    <TerritoriesCategoryRadioContainer>
      <RadioButton title="All Territories" value="All" />
    </TerritoriesCategoryRadioContainer>
    <div>
      <TerritoriesCategoryRadioContainer>
        <RadioButton title="Itemized Territories" value="Itemized" />
      </TerritoriesCategoryRadioContainer>
      <ItemizedTerritoriesContainer>
        {territoriesCategory === 'Itemized' &&
          Object.values(Territory)
            .filter((territory) => territory !== Territory.AllTerritories)
            .map((territory) => (
              <CenteredCheckDiv key={territory}>
                <Checkbox
                  title={territory}
                  value={territory}
                  name={territory}
                  checked={selectedTerritories.has(territory)}
                  disabled={
                    territory === Territory.RestOfWorld ||
                    territory === Territory.Canada ||
                    territory === Territory.LatinAmerica ||
                    territory === Territory.Scandinavia ||
                    territory === Territory.UnitedStates
                  }
                  onChange={({ target }) => {
                    const updatedSet = new Set(selectedTerritories);
                    target.checked ? updatedSet.add(territory) : updatedSet.delete(territory);
                    setSelectedTerritories(updatedSet);
                  }}
                />
              </CenteredCheckDiv>
            ))}
      </ItemizedTerritoriesContainer>
      {territoriesError && <TerritoriesError>{territoriesError}</TerritoriesError>}
    </div>
  </RadioGroup>
);

const CenteredCheckDiv = styled.div`
  & svg {
    margin: -10px 0 0 -3.5px !important;
  }
`;

const TerritoriesCategoryRadioContainer = styled.span`
  display: inline-flex;
`;

const ItemizedTerritoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  & > * {
    margin-left: 30px;
    flex-shrink: 1;
  }
`;

const TerritoriesError = styled.div`
  color: red;
`;
