import React, { Fragment } from 'react';
import styled from 'styled-components/macro';

import { sectionData, TerritoryBox, TerritoryHeader, InfoLine, TouringInfoLine } from './ReadViewSupport';

export const TerritoriesTable = ({ territoriesData, includeNotes, displayPercent, isTouring }) => {
  const sectionedData = sectionData(territoriesData);

  return (
    <>
      {Object.values(sectionedData).map((territoryDataArr, territoryIdx) => (
        <TerritoryBox key={territoryIdx}>
          {territoryDataArr?.map((territoryData, idx) => {
            const value = displayPercent ? `${territoryData.text}%` : territoryData.text;
            const { label, primary } = territoryData;
            return (
              <Fragment key={idx}>
                {idx === 0 ? <TerritoryHeader>{territoryData.territory}</TerritoryHeader> : null}
                {isTouring ? (
                  <TouringInfoLine label={label} isPrimary={primary} value={value} />
                ) : (
                  <InfoLine label={label} isPrimary={primary} value={value} />
                )}
                {includeNotes && <NoteDisplay>{territoryData.note}</NoteDisplay>}
              </Fragment>
            );
          })}
        </TerritoryBox>
      ))}
    </>
  );
};

const NoteDisplay = styled.div`
  margin-bottom: 6px;
  font-weight: 400;
  font-style: italic;
  text-align: end;
  font-size: 13px;
`;
