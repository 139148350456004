import React from 'react';
import { styled } from 'react-free-style';
import { Info, Input, Multiselect } from '@united-talent-agency/julius-frontend-components';
import moment from 'moment';

import Card from '../card/ProjectCard';

import {
  selectableEthnicities,
  selectableNationalities,
  genders,
  languages,
} from '../../containers/profile/views/tags/personalInfo';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_PARSE_FORMAT, DatePicker } from '../date-picker';
import { SearchableSelect } from '../searchable-select';
import CommonTooltip from '../common/tooltip';
import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';
import cypressTags from '../../support/cypressTags';

const { PERSON } = cypressTags;
const { PRIMARY_TAGS } = PERSON;

const withStyles = styled({
  menuItem: { padding: 10 },
  selectorContainer: { width: '20rem' },
  ageRangeSelectorContainer: { width: '20rem', display: 'flex', alignItems: 'center' },
  ageRangeHyphen: { margin: '0 6px' },
  personalTagContainer: { display: 'flex', flex: 1, flexDirection: 'row' },
});

const PrimaryTags = ({ styles, person, saveChanges, mergeCardData, blockEdit }) => {
  const {
    _id,
    maximumAge,
    minimumAge,
    nationalities = [],
    gender,
    birthday,
    primaryLanguage,
    secondaryLanguages = [],
    ethnicities = [],
    disability,
    lgbtq,
  } = person;

  const showPrimaryTags =
    !mergeCardData ||
    !mergeCardData.isEmpty ||
    birthday ||
    minimumAge ||
    maximumAge ||
    ethnicities.length > 0 ||
    nationalities.length > 0 ||
    gender ||
    primaryLanguage ||
    secondaryLanguages.length > 0 ||
    disability ||
    lgbtq;

  return !showPrimaryTags ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }} data-cy={PRIMARY_TAGS.CONTAINER}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card title="Primary Tags" canEdit={!blockEdit} saveChanges={saveChanges} mergeCardData={mergeCardData}>
        {({ isEditing, updates, updateBy }) => {
          const defaultBirthday = birthday
            ? moment(birthday, DEFAULT_DATE_PARSE_FORMAT).format(DEFAULT_DATE_FORMAT)
            : '';
          const getLabel = (label, notFound = null) =>
            (updates.person && updates.person[_id] && updates.person[_id][label]) || notFound;

          const getSelectValue = (label) =>
            (updates.person &&
              updates.person[_id] &&
              (updates.person[_id][label] || updates.person[_id][label] === null) && {
                value: updates.person[_id][label],
                label: updates.person[_id][label],
              }) ||
            null;

          const getMultiselectValue = (field, defaultValue) => {
            if (updates.person && updates.person[_id] && updates.person[_id][field]) {
              return updates.person[_id][field];
            } else {
              return defaultValue;
            }
          };

          return isEditing ? (
            <div data-cy={PRIMARY_TAGS.EDIT_VIEW_CONTAINER}>
              <>
                <Info name="Birthday">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.BITHDAY_DATE_PICKER}>
                    <DatePicker
                      onDayChange={(birthday) => updateBy('person', _id, { birthday })}
                      value={getLabel('birthday', defaultBirthday)}
                    />
                  </div>
                </Info>
                <Info name="Age Range">
                  <div className={styles.ageRangeSelectorContainer}>
                    <span data-cy={PRIMARY_TAGS.MIN_AGE_INPUT}>
                      <Input
                        dataMarker="min-age"
                        title="Min Age"
                        type="number"
                        onChange={(minimumAge) => updateBy('person', _id, { minimumAge: Number(minimumAge) })}
                        value={getLabel('minimumAge', 0) === false || minimumAge}
                      />
                    </span>
                    <span className={styles.ageRangeHyphen}>-</span>
                    <span data-cy={PRIMARY_TAGS.MAX_AGE_INPUT}>
                      <Input
                        dataMarker="max-age"
                        title="Max Age"
                        type="number"
                        onChange={(maximumAge) => updateBy('person', _id, { maximumAge: Number(maximumAge) })}
                        value={getLabel('maximumAge', 0) === false || maximumAge}
                      />
                    </span>
                  </div>
                </Info>
                <Info name="Ethnicity">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.ETHNICITY_SELECT}>
                    <Multiselect
                      value={getMultiselectValue('ethnicities', ethnicities)}
                      _onChange={(item) => {
                        updateBy('person', _id, { ethnicities: item.split(',') });
                      }}
                      options={selectableEthnicities.map((ethnicity) => ({
                        label: ethnicity,
                        value: ethnicity,
                      }))}
                    />
                  </div>
                </Info>
                <Info name="Nationality">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.NATIONALITY_SELECT}>
                    <Multiselect
                      value={getMultiselectValue('nationalities', nationalities)}
                      _onChange={(item) => {
                        updateBy('person', _id, { nationalities: item.split(',') });
                      }}
                      options={selectableNationalities.map((nationality) => ({
                        label: nationality,
                        value: nationality,
                      }))}
                    />
                  </div>
                </Info>
                <Info name="Gender">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.GENDER_SELECT}>
                    <SearchableSelect
                      value={getSelectValue('gender') || { value: gender, label: gender }}
                      options={genders}
                      onChange={(item) => updateBy('person', _id, { gender: item ? item.value : item })}
                    />
                  </div>
                </Info>
                <Info name="Primary Language">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.PRIMARY_LANGUAGE_SELECT}>
                    <SearchableSelect
                      value={getSelectValue('primaryLanguage') || { value: primaryLanguage, label: primaryLanguage }}
                      options={languages}
                      onChange={(item) => updateBy('person', _id, { primaryLanguage: item ? item.value : item })}
                    />
                  </div>
                </Info>
                <Info name="Secondary Languages">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.SECONDARY_LANGUAGE_SELECT}>
                    <Multiselect
                      value={getMultiselectValue('secondaryLanguages', secondaryLanguages)}
                      _onChange={(item) => {
                        updateBy('person', _id, { secondaryLanguages: item.split(',') });
                      }}
                      options={languages.map((language) => ({
                        label: language,
                        value: language,
                      }))}
                    />
                  </div>
                </Info>
                <Info name="Disability">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.DISABILITY_SELECT}>
                    <SearchableSelect
                      value={getSelectValue('disability') || { value: disability, label: disability }}
                      options={['Yes', 'No', 'Unknown']}
                      onChange={(item) => updateBy('person', _id, { disability: item ? item.value : item })}
                    />
                  </div>
                </Info>
                <Info name="LGBTQ">
                  <div className={styles.selectorContainer} data-cy={PRIMARY_TAGS.LGBTQ_SELECT}>
                    <SearchableSelect
                      value={getSelectValue('lgbtq') || { value: lgbtq, label: lgbtq }}
                      options={['Yes', 'No', 'Unknown']}
                      onChange={(item) => updateBy('person', _id, { lgbtq: item ? item.value : item })}
                    />
                  </div>
                </Info>
              </>
            </div>
          ) : (
            <div data-cy={PRIMARY_TAGS.READ_VIEW_CONTAINER}>
              <>
                {(!mergeCardData || (mergeCardData && birthday)) && (
                  <Info name="Birthday">{birthday && moment(birthday).format(DEFAULT_DATE_FORMAT)}</Info>
                )}
                {(!mergeCardData || (mergeCardData && minimumAge !== 0 && maximumAge !== 0)) && (
                  <Info name="Age Range">
                    {minimumAge} - {maximumAge}
                  </Info>
                )}
                {(!mergeCardData || (mergeCardData && ethnicities.length > 0)) && (
                  <Info name="Ethnicity">{ethnicities.join(', ')}</Info>
                )}
                {(!mergeCardData || (mergeCardData && nationalities.length > 0)) && (
                  <Info name="Nationality">{nationalities.join(', ')}</Info>
                )}
                {(!mergeCardData || (mergeCardData && gender)) && <Info name="Gender">{gender}</Info>}
                {(!mergeCardData || (mergeCardData && primaryLanguage)) && (
                  <Info name="Primary Language">{primaryLanguage}</Info>
                )}
                {(!mergeCardData || (mergeCardData && secondaryLanguages.length > 0)) && (
                  <Info name="Secondary Languages">{secondaryLanguages.join(', ')}</Info>
                )}
                {(!mergeCardData || (mergeCardData && disability)) && <Info name="Disability">{disability}</Info>}
                {(!mergeCardData || (mergeCardData && lgbtq)) && <Info name="LGBTQ">{lgbtq}</Info>}
              </>
            </div>
          );
        }}
      </Card>
    </div>
  );
};

export default withStyles(PrimaryTags);
