import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ContactTypeNames } from '@united-talent-agency/components';
import {
  getPersonPrivateContacts,
  updatePersonPrivateContacts,
  updatePerson,
} from '@united-talent-agency/julius-frontend-store';

import Card from '../card/Card';
import ContactEditor from '../contacts-card/ContactEditor';
import ContactLabel from '../contacts-card/ContactLabel';
import { primaryFirstSort } from '../../support/primary-sort';
import { allContactsFilter, contactIsValid } from '../../support/contacts';
import CommonTooltip from '../common/tooltip';
import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export default function ContactsCard({
  person = {},
  canSetPrimary,
  title = 'Contacts',
  onChange,
  refresh = () => {},
  contactTypeFilter = allContactsFilter,
  privateContactPermission,
  mergeCardData,
  blockEdit,
  cyTag = 'common-contacts-card',
}) {
  const [privateContacts, setPrivateContacts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!person._id) return;
    dispatch(getPersonPrivateContacts(person._id)).then((result) => {
      const personPrivateContacts =
        result && result.body && result.body.privateContacts
          ? result.body.privateContacts.map((privateContact) => ({ ...privateContact, isPrivate: true }))
          : [];
      setPrivateContacts(personPrivateContacts);
    });
  }, [dispatch, person]);

  const publicContacts = person.contacts || [];
  const allContacts = publicContacts.concat(privateContacts);
  const contactTypes = Object.keys(ContactTypeNames).filter((key) => contactTypeFilter.test(key));
  const pertinentContacts = allContacts
    .filter(({ contactType }) => contactTypeFilter.test(contactType))
    .sort(primaryFirstSort);
  const isEmployee = (person || {}).type === 'Employee';

  const showContacts = !mergeCardData || !mergeCardData.isEmpty || pertinentContacts.length > 0;

  return !showContacts ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }} data-cy={cyTag}>
      {isEmployee && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_HR} />}
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        mergeCardData={mergeCardData}
        title={title}
        data={pertinentContacts}
        readView={readView}
        editView={!isEmployee && !blockEdit && ((props) => editView(contactTypes, canSetPrimary, mergeCardData, props))}
        canSetPrimary={canSetPrimary}
        privateContactPermission={privateContactPermission}
        canDelete
        onSave={(saveInfo) =>
          onSave({ ...person, privateContacts }, dispatch, saveInfo, privateContactPermission).then(() =>
            refresh(person._id)
          )
        }
        onChange={onChange}
        onValidateItem={contactIsValid}
        createNew={() => ({ contact: '', contactType: contactTypes[0] })}
      />
    </div>
  );
}

const onSave = async (entity, dispatch, saveInfo, privateContactPermission) => {
  const { updates = {}, deletes = {}, creates = [] } = saveInfo;

  for (const [key, update] of Object.entries(updates)) {
    if ('isPrivate' in update) {
      if (!privateContactPermission) continue;
      if (update.isPrivate) {
        // public changed to private
        // get the rest of the public contact from entity.contacts and add to creates as new private contact
        // add old _id to deletes
        const publicContact = entity.contacts.find((contact) => contact._id === key);
        if (publicContact) {
          let { _id, ...rest } = publicContact;
          deletes[_id] = publicContact;
          creates.push({ ...rest, ...update });
        }
      } else {
        // private changed to public
        // get the rest of the private contact from entity.privateContacts and add to creates as new public contact
        // add old private contact _id to deletes
        const privateContact = entity.privateContacts.find((privateContact) => privateContact._id === key);
        if (privateContact) {
          let { _id, ...rest } = privateContact;
          deletes[_id] = privateContact;
          creates.push({ ...rest, ...update });
        }
      }
      delete update.isPrivate;
    }
  }

  let [publicCreates, privateCreates] = [[], []];
  creates.forEach((create) =>
    create.isPrivate ? delete create.isPrivate && privateCreates.push(create) : publicCreates.push(create)
  );

  const publicContacts = entity.contacts
    .map((contact) => {
      return { ...contact, ...updates[contact._id] };
    })
    .filter(({ _id }) => !deletes[_id])
    .concat(publicCreates);

  const privateContacts = entity.privateContacts
    .map((privateContact) => {
      delete privateContact.isPrivate;
      return { ...privateContact, ...updates[privateContact._id] };
    })
    .filter(({ _id }) => !deletes[_id])
    .concat(privateCreates);

  const updatedEntity = {
    _id: entity._id,
    contacts: publicContacts,
  };

  const privateContactsPromise = privateContactPermission
    ? dispatch(updatePersonPrivateContacts(entity._id, privateContacts))
    : Promise.resolve({});

  // wait for the first update to finish to avoid duplicate audit logs
  await privateContactsPromise;
  return dispatch(updatePerson(updatedEntity));
};

function readView({ item: contact, hasPrivateContactsPermissions }) {
  return <ContactLabel value={contact} hasPrivateContactsPermissions={hasPrivateContactsPermissions} />;
}

function editView(
  contactTypes,
  _,
  mergeCardData,
  { item: contact, setState, setPrivate, hasPrivateContactsPermissions }
) {
  return (
    <ContactEditor
      mergeCardData={mergeCardData}
      contactTypes={contactTypes}
      value={contact}
      onChange={setState}
      setPrivate={setPrivate}
      hasPrivateContactsPermissions={hasPrivateContactsPermissions}
    />
  );
}
