import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { styled, helpers } from 'react-free-style';
import { Helmet } from 'react-helmet';
import Notifications from 'react-notify-toast';
import { connect } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Login } from '@united-talent-agency/julius-frontend-components';
import { LoginCallback, redirectToAzure, PrivateRoute } from '@united-talent-agency/julius-frontend-store';
import { notifications } from '@united-talent-agency/components';

import { MergeCompanies, MergeProfiles } from './containers/merge';
import Profile from './containers/profile';
import CreateCompanyProfile from './containers/create-company';
import CompanyProfile from './containers/company';
import VenueProfile from './containers/venue';
import Search from './containers/search';

import aktivGroteskLight from './assets/fonts/aktiv-grotesk-light.woff';
import aktivGroteskRegular from './assets/fonts/aktiv-grotesk-regular.woff';
import aktivGroteskBold from './assets/fonts/aktiv-grotesk-bold.woff';

import * as colors from './styles/colors';
import { defaultUrl, apiServerUrl } from './support/urls';
import ReactTooltip from 'react-tooltip';
import CreatePersonView from './containers/create-person';
import CreateVenueView from './containers/create-venue';
import LaunchDarklyWrapper from './components/class-component-LD-wrapper/LaunchDarklyWrapper';
import SearchV2 from './containers/search_v2';

const localVersion = require('./git.json').sha;
const { NewRelease } = notifications;
const { REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS, REACT_APP_RELEASE_CHECK_FORCE_RELOAD } = process.env;
const forceReload = REACT_APP_RELEASE_CHECK_FORCE_RELOAD === true.toString();
class Routes extends React.Component {
  render() {
    const { props } = this;
    const { styles, user } = props;

    const LoginComponent = () => {
      return <Login defaultUrl={defaultUrl} apiServerUrl={apiServerUrl} redirectToAzure={redirectToAzure} />;
    };

    return (
      <LaunchDarklyWrapper
        render={(flags) => {
          const APP_NAME = flags.showContactsMergeFeatures ? 'CONTACTS' : 'PROFILES';
          return (
            <Router>
              <div className={styles.container}>
                <Helmet>
                  <title>{APP_NAME}</title>
                </Helmet>
                <Notifications />
                <NewRelease
                  currentVersion={localVersion}
                  intervalSeconds={REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS}
                  forceReload={forceReload}
                />
                <ReactTooltip type="light" place="right" effect="solid" className={styles.tooltip} />
                <Switch>
                  <Route exact path="/login" component={LoginComponent} />
                  <Route path="/login-callback" component={() => LoginCallback(props)} />
                  <PrivateRoute
                    exact
                    path="/person"
                    component={CreatePersonView}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  <PrivateRoute
                    path="/profile/:personId"
                    component={Profile}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  <PrivateRoute
                    path="/merge/profiles/:peopleIds?"
                    component={MergeProfiles}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  <PrivateRoute
                    path="/merge/companies/:companyIds?"
                    component={MergeCompanies}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  <PrivateRoute
                    exact
                    path="/company"
                    component={CreateCompanyProfile}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  <PrivateRoute
                    path="/company/:companyId"
                    component={CompanyProfile}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  <PrivateRoute
                    exact
                    path="/venue/"
                    component={CreateVenueView}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  <PrivateRoute
                    path="/venue/:venueId"
                    component={VenueProfile}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                  {flags.showContactsMergeFeatures ? (
                    <PrivateRoute
                      exact
                      path="/"
                      component={SearchV2}
                      user={user}
                      dispatch={props.dispatch}
                      apiServerUrl={apiServerUrl}
                    />
                  ) : (
                    <PrivateRoute
                      exact
                      path="/"
                      component={Search}
                      user={user}
                      dispatch={props.dispatch}
                      apiServerUrl={apiServerUrl}
                    />
                  )}

                  <PrivateRoute
                    exact
                    path="/search"
                    component={Search}
                    user={user}
                    dispatch={props.dispatch}
                    apiServerUrl={apiServerUrl}
                  />
                </Switch>
              </div>
            </Router>
          );
        }}
      />
    );
  }
}

const withStyles = styled(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.background,
      fontFamily: 'aktiv-grotesk, sans-serif',
    },
    tooltip: {
      filter: 'drop-shadow(0 2px 10px rgba(173, 165, 159, 0.3))',
      opacity: '1 !important',
    },
  },
  {
    css: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        backgroundColor: colors.background,
        fontFamily: 'aktiv-grotesk, sans-serif',
        minWidth: 320,
        fontSize: 15,
        lineHeight: 1.3,
      },
    },
    rules: [
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskRegular),
          fontWeight: 400,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskBold),
          fontWeight: 700,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskLight),
          fontWeight: 300,
          fontStyle: 'normal',
        },
      ],
    ],
  }
);

const withState = connect((state) => {
  const { user } = state;
  return { user };
});

export default withState(
  withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    user: {
      anonymous: true,
    },
    options: {
      bootstrap: 'localStorage',
    },
  })(withStyles(Routes))
);
