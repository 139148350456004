import * as React from 'react';
import { styled } from 'react-free-style';
import * as slugify from 'slugify';

import { Info, Input, Multiselect } from '@united-talent-agency/julius-frontend-components';

class CardAgentWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      representationDepartments: transformEntityRepresentationDepartments(props.entity),
    };
  }

  render() {
    const { isEditing, onChange, entity } = this.props;
    const { representationDepartments } = this.state;

    return (
      <div className={`mb-3`}>
        {isEditing ? (
          <AgentWebsiteEdit
            onChange={onChange}
            entity={entity}
            representationDepartments={representationDepartments}
            onMultiselect={this.onMultiselect}
          />
        ) : (
          <AgentWebsiteDisplay entity={entity} representationDepartments={representationDepartments} />
        )}
      </div>
    );
  }

  onMultiselect = (value, onChange) => {
    const representationDepartments = value.split(',');
    this.setState({ representationDepartments: transformRepresentationDepartments(representationDepartments) });
    onChange({ representationDepartments });
  };
}

const AgentWebsiteEdit = (props) => {
  const { onChange, onMultiselect, entity, representationDepartments } = props;
  const slugSuggestion = !entity.slug && slugify(entity.name).toLowerCase();
  // const slugSuggestion =
  //   !entity.slug &&
  //   entity.name
  //     .toLowerCase()
  //     .split(' ')
  //     .join('-');
  return (
    <div className={`mb-3`}>
      <Multiselect
        value={representationDepartments}
        _onChange={(value) => {
          onMultiselect(value, onChange);
        }}
        className={`mb-2`}
        options={DEPARTMENT_TAGS}
      />
      <Input
        type="text"
        title={slugSuggestion || 'Slug'}
        value={entity.slug || slugSuggestion}
        className={`mb-2`}
        onChange={(slug) => onChange({ slug })}
      />
    </div>
  );
};

const AgentWebsiteDisplay = (props) => {
  const { entity, representationDepartments } = props;
  return (
    <div className={`mb-3`}>
      <Info name="Representation Departments">{representationDepartments.replace(/,/g, ', ')}</Info>
      <Info name="Slug">{entity.slug}</Info>
    </div>
  );
};

const transformEntityRepresentationDepartments = (entity, delimiter = ',') => {
  return transformRepresentationDepartments(entity && entity.representationDepartments, delimiter);
};

const transformRepresentationDepartments = (representationDepartments, delimiter = ',') => {
  return (representationDepartments && representationDepartments.sort().join(delimiter)) || '';
};

const DEPARTMENT_TAGS = [
  { label: 'Comedy', value: 'Comedy' },
  { label: 'Digital', value: 'Digital' },
  { label: 'Esports', value: 'Esports' },
  { label: 'Music', value: 'Music' },
  { label: 'Speaker', value: 'Speaker' },
];

const withStyles = styled({});

export default withStyles(CardAgentWebsite);
