export const CountTypeByTerritory = (data) => {
  return data.reduce((acc, item) => {
    if (!acc[item.regions[0]]) {
      acc[item.regions[0]] = {};
    }

    if (!acc[item.regions[0]][item.type]) {
      acc[item.regions[0]][item.type] = 1;
    } else {
      acc[item.regions[0]][item.type]++;
    }

    return acc;
  }, {});
};
