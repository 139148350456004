import React, { useState, useEffect } from 'react';
import { styled } from 'react-free-style';

import { Info, Input, Multiselect } from '@united-talent-agency/julius-frontend-components';
import * as slugify from 'slugify';

const InfoArtist = ({ isEditing, onChange, entity, mergeCardData }) => {
  const [representationDepartments, setRepresentationDepartments] = useState('');

  useEffect(() => {
    setRepresentationDepartments(transformEntityRepresentationDepartments(entity));
  }, [entity]);

  const onMultiselect = (value, onChange) => {
    const newRepresentationDepartments = value.split(',');
    setRepresentationDepartments(transformRepresentationDepartments(newRepresentationDepartments));
    onChange({ representationDepartments: newRepresentationDepartments });
  };

  return (
    <div className={`mb-3`}>
      {isEditing ? (
        <InfoArtistEdit
          onChange={onChange}
          entity={entity}
          representationDepartments={representationDepartments}
          onMultiselect={onMultiselect}
        />
      ) : (
        <InfoArtistDisplay
          entity={entity}
          representationDepartments={representationDepartments}
          mergeCardData={mergeCardData}
        />
      )}
    </div>
  );
};

const InfoArtistEdit = (props) => {
  const { onChange, onMultiselect, entity, representationDepartments } = props;
  const slugSuggestion = !entity.slug && slugify(entity.name).toLowerCase();
  // const slugSuggestion =
  //   !entity.slug &&
  //   entity.name
  //     .toLowerCase()
  //     .split(' ')
  //     .join('-');
  return (
    <div className={`mb-3`}>
      <Multiselect
        value={representationDepartments}
        _onChange={(value) => {
          onMultiselect(value, onChange);
        }}
        className={`mb-2`}
        options={DEPARTMENT_TAGS}
      />
      <Input
        type="text"
        title="Artist Name"
        value={entity.artistName}
        className={`mb-2`}
        onChange={(artistName) => onChange({ artistName })}
      />
      <Input
        type="text"
        title={slugSuggestion || 'Slug'}
        value={entity.slug}
        className={`mb-2`}
        onChange={(slug) => onChange({ slug })}
      />
      <Input
        type="text"
        title="Territories of Representation"
        value={entity.territoriesOfRepresentation}
        className={`mb-2`}
        onChange={(territoriesOfRepresentation) => onChange({ territoriesOfRepresentation })}
      />
      <Input
        type="text"
        title="BandsInTown Artist Name"
        value={entity.bandsintown || ''}
        className={`mb-2`}
        onChange={(bandsintown) => onChange({ bandsintown })}
      />
    </div>
  );
};

const InfoArtistDisplay = (props) => {
  const { entity, representationDepartments } = props;

  return (
    <div className={`mb-3`}>
      {(!props.mergeCardData || (props.mergeCardData && representationDepartments.replace(/,/g, ', '))) && (
        <Info name="Representation Departments">{representationDepartments.replace(/,/g, ', ')}</Info>
      )}
      {(!props.mergeCardData || (props.mergeCardData && entity.artistName)) && (
        <Info name="Artist Name">{entity.artistName}</Info>
      )}
      {(!props.mergeCardData || (props.mergeCardData && entity.slug)) && <Info name="Slug">{entity.slug}</Info>}
      {(!props.mergeCardData || (props.mergeCardData && entity.territoriesOfRepresentation)) && (
        <Info name="Territories of Representation">{entity.territoriesOfRepresentation}</Info>
      )}
      {(!props.mergeCardData || (props.mergeCardData && entity.bandsintown)) && (
        <Info name="BandsInTown Artist Name">{entity.bandsintown}</Info>
      )}
    </div>
  );
};

const transformEntityRepresentationDepartments = (entity, delimiter = ',') => {
  return transformRepresentationDepartments(entity && entity.representationDepartments, delimiter);
};

const transformRepresentationDepartments = (representationDepartments, delimiter = ',') => {
  return (representationDepartments && representationDepartments.sort().join(delimiter)) || '';
};

const DEPARTMENT_TAGS = [
  { label: 'Comedy', value: 'Comedy' },
  { label: 'Digital', value: 'Digital' },
  { label: 'Esports', value: 'Esports' },
  { label: 'Music', value: 'Music' },
  { label: 'Production Arts', value: 'Production Arts' },
  { label: 'Speaker', value: 'Speaker' },
];

const withStyles = styled({});

export default withStyles(InfoArtist);
