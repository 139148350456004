import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { getVenueById } from '../../api/venues';
import { listHistoryProfile } from '@united-talent-agency/julius-frontend-store';
import { Icons } from '@united-talent-agency/components';
import Title from '../../components/title';
import ProfileView from './views/profile/ProfileView';
import NetsuiteView from './views/netsuite/NetsuiteView';
import VenueRadiusView from './views/venue/VenueRadiusView';
import ExternalTeamView from '../shared/external-team-company-relationships';
import WebsiteView from '../profile/views/website/website';
import HistoryView from '../profile/views/history/history';
import { PageBody, PageContainer, PageContent, Header, TitleContainer } from '../../styles/components/components';
import CardPageContainer from '../../components/card/CardPageContainer';
import { notify } from 'react-notify-toast';
import NotesView from '../shared/notes';
import { Tab } from '../../components/tabs/Tab';
import { TabContainer } from '../../components/tabs/TabContainer';
import { useClientTeamAccessChecker, useQueryStringViewRedirect } from '../../support/hooks';
import { updateGroup } from '../../api/groups';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped';

const { ExternalIcon, HistoryIcon, NoteIcon, EnvironmentOutlinedIcon, TripIcon, FinanceIcon, WindowAltIcon } = Icons;

export default function VenueProfile({ computedMatch, dispatch, user, _retrieveVenue = retrieveVenue }) {
  const { venueId } = computedMatch.params;

  const [venue, setVenue] = useState({});

  const allViews = buildMenuViews(venue);

  // custom hook that checks if user is member of client or data steward team to block edit/verify or not
  const [relations, blockEdit] = useClientTeamAccessChecker({
    entity: venue,
    user,
    type: 'GROUP',
    dispatch,
  });

  useEffect(() => {
    _retrieveVenue(venueId).then((venueInfo) => venueInfo && setVenue(venueInfo));
  }, [venueId, _retrieveVenue]);

  const baseUrl = `/venue/${venueId}`;

  // Example usage, venues never used the query string
  useQueryStringViewRedirect(baseUrl, { history: 'history' });

  const onSave = () => {
    _retrieveVenue(venueId).then((venueInfo) => venueInfo && setVenue(venueInfo));
  };

  const saveNotes = async (notes) => {
    await updateGroup(venueId, { notes: Object.values(notes) });
    onSave();
  };

  const refreshProfile = () => {
    _retrieveVenue(venueId).then((venueInfo) => venueInfo);
  };

  /** Updates venue and refreshes venue profile
   *
   * @param {Object} updates: updates for the venue
   */
  const saveWebsite = async ({ updates }) => {
    await updateGroup(venueId, updates[venueId]);
    refreshProfile();
  };

  return (
    <PageContainer>
      <BrandingWrapped />
      <Header>
        <TitleContainer>
          <Title entity={venue} type={venue.type} />
        </TitleContainer>
      </Header>
      <PageBody>
        <TabContainer>
          {allViews.map((view) => (
            <Tab to={`${baseUrl}/${view.view}`} exact title={view.title} icon={view.icon} key={view.title} />
          ))}
        </TabContainer>
        <PageContent>
          <CardPageContainer onSave={onSave}>
            <Switch>
              <Route exact path={baseUrl}>
                <ProfileView
                  venue={venue}
                  dispatch={dispatch}
                  retrieveVenue={(venueId) => _retrieveVenue(venueId)}
                  user={user}
                  relations={relations}
                  blockEdit={blockEdit}
                />
              </Route>
              <Route exact path={`${baseUrl}/radius`}>
                <VenueRadiusView venue={venue} dispatch={dispatch} />
              </Route>
              <Route exact path={`${baseUrl}/notes`}>
                <NotesView entity={venue} saveNotes={saveNotes} user={user} />
              </Route>
              <Route exact path={`${baseUrl}/externalTeam`}>
                <ExternalTeamView entity={venue} dispatch={dispatch} hideEmployeeCard title="Relationships" />
              </Route>
              <Route path={`${baseUrl}/netsuite`}>
                <NetsuiteView company={venue} dispatch={dispatch} relations={relations} blockEdit={blockEdit} />
              </Route>
              <Route exact path={`${baseUrl}/website`}>
                <WebsiteView company={venue} saveChanges={(changes) => saveWebsite(changes)} />
              </Route>
              <Route path={`${baseUrl}/history`}>
                <HistoryView
                  venue={venue}
                  dispatch={dispatch}
                  listHistoryProfile={listHistoryProfile}
                  entityLabel="venue"
                />
              </Route>
            </Switch>
          </CardPageContainer>
        </PageContent>
      </PageBody>
    </PageContainer>
  );
}

/** Building menu views for venues
 *
 * @param {Object} venue: the venue
 * @returns {[Object]} This returns the navigation tabs for venues
 */
const buildMenuViews = (venue) => {
  let views = [
    { view: '', title: 'Profile', icon: <EnvironmentOutlinedIcon /> },
    { view: 'externalTeam', title: 'External Relationships', icon: <ExternalIcon /> },
    { view: 'radius', title: 'Radius', icon: <TripIcon /> },
    { view: 'notes', title: 'Notes', icon: <NoteIcon /> },
    { view: 'netsuite', title: 'Finance', icon: <FinanceIcon /> },
  ];
  // Only show Website tab for Client's
  if (venue.type === 'Client') {
    views.push({ view: 'website', title: 'Website', icon: <WindowAltIcon /> });
  }

  views.push({ view: 'history', title: 'History', icon: <HistoryIcon /> });
  return views;
};

/** Retrieves the full company/venue record
 *
 * @param {string} companyId: the id of the venue parent company
 * @param {function} _getVenueById: fetches the company/venue record. injected for testing
 * @param {Object} _notify: fdisplays toast messages. injected for testing
 * @returns {Object} the company with populated venue
 */
export const retrieveVenue = async (venueId, _getVenueById = getVenueById, _notify = notify) => {
  const response = await _getVenueById(venueId);
  const { body, status } = response;
  if (!body || status !== 200) {
    _notify.show('Error retrieving venue', 'error');
    return null;
  }
  if (body?.addresses) {
    // add _id to each address for react key and since Card component requires _id
    body.addresses = body.addresses.map((address, idx) => {
      return { ...address, _id: String(idx) };
    });
  }
  body.type = 'Industry Contact';
  return body;
};
