import React from 'react';
import { isEmpty } from 'lodash';

const displayTouringInfoChanges = ({ subKey, changes }) => {
  return (
    <>
      {changes['created'] &&
        changes['created'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Touring Agents: '}</strong>}
            {'Territory: '}
            {change.regions && change.regions[0]}
            {'; Agent Type: '}
            {change.type && change.type}
            {'; Name: '}
            {change.person && change.person.name}
            {' was created'}
          </div>
        ))}
      {changes['updated'] &&
        changes['updated'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Touring Agents: '}</strong>}
            {'Territory: '}
            {change.regions && change.regions[0]}
            {'; Agent Type: '}
            {change?.type && Array.isArray(change?.type) && change?.type.length > 0 ? change?.type[0] : change?.type}
            {'; Name: '}
            {Array.isArray(change?.person)
              ? change?.person[0]?.name
              : Array.isArray(change?.person?.name)
              ? change?.person?.name[0]
              : change?.person?.name}
            {' was changed to '}
            {'Territory: '}
            {change.regions && change.regions[0]}
            {'; Agent Type: '}
            {change?.type && Array.isArray(change?.type) && change?.type.length > 0 ? change?.type[1] : change?.type}
            {'; Name: '}
            {Array.isArray(change?.person)
              ? change?.person[1]?.name
              : Array.isArray(change?.person?.name)
              ? change?.person?.name[1]
              : change?.person?.name}
          </div>
        ))}
      {changes['deleted'] &&
        changes['deleted'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Touring Agents: '}</strong>}
            {'Territory: '}
            {change.regions && change.regions[0]}
            {'; Agent Type: '}
            {change?.type && change?.type}
            {'; Name: '}
            {change?.person?.name}
            {' was deleted '}
          </div>
        ))}
    </>
  );
};

const handleTouringChange = ({ territoryInfo, auditChangeObject, subKey, AUDIT_LABEL_DISPLAY }) => {
  const { touringAgents } = territoryInfo;
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[subKey];
  let touringInfoHistory = [];
  const changes = {};
  if (Array.isArray(touringAgents[0]) && touringAgents[0].length > 0) {
    touringAgents[0].forEach((ds, index) => {
      const dsKeys = Object.keys(ds.agent);
      dsKeys.forEach((dsKey) => {
        if (Array.isArray(ds.agent[dsKey]) && ds.agent[dsKey].length === 1) {
          // agent created
          if (!changes['created']) {
            changes['created'] = [];
          }
          changes['created'].push({
            person: ds.agent[dsKey][0].personId,
            type: ds.agent[dsKey][0].type,
            regions: ds.agent[dsKey][0].regions,
          });
        } else if (
          typeof ds.agent[dsKey] === 'object' &&
          !Array.isArray(ds.agent[dsKey]) &&
          ds.changeType !== 'Deleted'
        ) {
          // agent updated
          if (!changes['updated']) {
            changes['updated'] = [];
          }
          if (ds.agent[dsKey]?.personId || ds.agent[dsKey]?.type || ds.agent[dsKey]?.regions) {
            changes['updated'].push({
              person: ds.agent[dsKey]?.personId && ds.agent[dsKey]?.personId,
              type: ds.agent[dsKey]?.type && ds.agent[dsKey]?.type,
              regions: ds.agent[dsKey]?.regions && ds.agent[dsKey]?.regions,
            });
          }
        } else if (
          typeof ds.agent[dsKey] === 'object' &&
          !Array.isArray(ds.agent[dsKey]) &&
          ds.changeType === 'Deleted'
        ) {
          // agent deleted
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          if (ds.agent[dsKey]?.personId || ds.agent[dsKey]?.type || ds.agent[dsKey]?.regions) {
            changes['deleted'].push({
              person: ds.agent[dsKey]?.personId && ds.agent[dsKey]?.personId,
              type: ds.agent[dsKey]?.type && ds.agent[dsKey]?.type,
              regions: ds.agent[dsKey]?.regions && ds.agent[dsKey]?.regions,
            });
          }
        } else if (Array.isArray(ds.agent[dsKey]) && ds.agent[dsKey].length === 3 && ds.agent[dsKey][2] === 0) {
          // agent deleted
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          changes['deleted'].push({
            person: ds.agent[dsKey][0].personId,
            type: ds.agent[dsKey][0].type,
            regions: ds.agent[dsKey][0].regions,
          });
        }
      });
    });

    touringInfoHistory.push(
      <>
        {displayTouringInfoChanges({
          subKey,
          changes,
        })}
      </>
    );
    if (!isEmpty(changes)) {
      auditChangeObject[auditLabelDisplay] = touringInfoHistory;
    }
  }
};

export default handleTouringChange;
