import React from 'react';
import { fromE164ToDisplay, fromStringToE164 } from '@united-talent-agency/components';
import { colors } from '@united-talent-agency/julius-frontend-components';

import { phoneContactFilter, emailContactFilter } from '../../../../support/contacts';
import AlertDialog, { modalStyles } from '../../../../components/alert-dialog/AlertDialog';

export const VerifyModal = ({ showVerifyModal, closeVerifyModal, handleVerify, person = {}, ariaHideApp = true }) => {
  const userRegion = navigator.language.split('-')[1];
  const personContacts = person.contacts || [];
  const primaryPhone = personContacts.find((contact) => {
    return phoneContactFilter.test(contact.contactType) && contact.primary;
  });
  const primaryEmail = personContacts.find((contact) => {
    return emailContactFilter.test(contact.contactType) && contact.primary;
  });
  const missingInfo = !primaryPhone || !primaryEmail;
  const title = missingInfo ? 'Missing Information' : 'Verification Confirmation';
  const promptText = missingInfo
    ? 'Please ensure information below is provided and correct before verifying.'
    : 'By clicking on Confirm, you are acknowledging that the following information is accurate. Please ensure the following information is up to date before proceeding.';

  return (
    <AlertDialog
      title={title}
      promptText={promptText}
      isOpen={showVerifyModal}
      onRequestClose={closeVerifyModal}
      showConfirm={!missingInfo}
      onConfirm={handleVerify}
      ariaHideApp={ariaHideApp}
    >
      {(!missingInfo || !primaryPhone) && (
        <div style={{ ...modalStyles.promptText, fontWeight: 700 }}>
          Primary Phone Number:{' '}
          {primaryPhone ? (
            <span style={{ fontWeight: 300, marginLeft: 5 }}>
              {fromE164ToDisplay(fromStringToE164(primaryPhone.contact), userRegion, ' x')}
            </span>
          ) : (
            <span style={{ color: colors.invalidBorder, fontWeight: 300, marginLeft: 5 }}>Not Specified</span>
          )}
        </div>
      )}
      {(!missingInfo || !primaryEmail) && (
        <div style={{ ...modalStyles.promptText, fontWeight: 700 }}>
          Primary Email:{' '}
          {primaryEmail ? (
            <span style={{ fontWeight: 300, marginLeft: 5 }}>{primaryEmail.contact}</span>
          ) : (
            <span style={{ color: colors.invalidBorder, fontWeight: 300, marginLeft: 5 }}>Not Specified</span>
          )}{' '}
        </div>
      )}
    </AlertDialog>
  );
};
