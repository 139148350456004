import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { colors, elements } from '@united-talent-agency/julius-frontend-components';
import { setDesk } from '@united-talent-agency/julius-frontend-store';
import { Icons, Spinner } from '@united-talent-agency/components';

import ContactTable from './contact-table';
import Header from './header';
import DropCapCheckBox from '../../components/dropcap-checkbox';
import { findPeople, findPeopleByContact } from '../../data/people-dao';
import { findCompanies, findCompaniesByContact } from '../../data/companies-dao';
import { getInternalTeam } from '../../data/internal-team-dao';
import { findContacts } from '../../data/outlook-dao';
import entitySorter from '../../data/entity-sorter';
import { profileUrl } from '../../support/urls';
import CountLabel from '../../components/count-label';
import MergeProfilesModal from '../../components/merge-profiles-modal/MergeProfilesModal';
import { datadogRum } from '@datadog/browser-rum';
import cypressTags from '../../support/cypressTags';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped';
import LaunchDarklyWrapper from '../../components/class-component-LD-wrapper/LaunchDarklyWrapper';

const { SEARCH } = cypressTags;

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const { ChevronRightIcon, ChevronLeftIcon, UserIcon, OfficeBuildingIcon, MergeIcon } = Icons;

// Flag used to mask quick search and my client sections of the UI
const HIDE_MY_CLIENTS = true;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includeClients: localStorage.getItem('includeClients') === true.toString(),
      includeOutlook: localStorage.getItem('includeOutlook') === true.toString(),
      includeIndustry: localStorage.getItem('includeIndustry') === true.toString(),
      includeShared: localStorage.getItem('includeShared') === true.toString(),
      includeEmployee: localStorage.getItem('includeEmployee') === true.toString(),
      includeCompanies: localStorage.getItem('includeCompanies') === true.toString(),
      includePeople: localStorage.getItem('includePeople') === true.toString(),
      isSearching: false,
      notesIsOpen: false,
      entities: [],
      searchText: '',
      sortColumn: 'Name',
      sortDirection: 1,
      myClients: false,
      myClientsPoint: false,
      myClientsAgent: false,
      filtersExpanded: true,
      showMergeProfilesModal: false,
    };
  }

  componentDidMount() {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }

    const windowpath = window.location.href;
    const isSearch = windowpath.split('/')[3].split('?')[0].toLowerCase() === 'search';
    if (isSearch) {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const query = params.get('q');
      this.search({ searchText: query, contact: true });
      this.setState({ searchText: query });
    }
  }

  componentDidUpdate() {
    const { user } = this.props;

    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }

  findRelationships() {
    const { dispatch, desk, _searchEmployeeRelationships } = this.props;
    const { state } = this;
    const { myClientsAgent, myClientsPoint, sortDirection, sortColumn } = state;
    const types = [];
    if (myClientsAgent) {
      types.push('Client');
    }
    if (myClientsPoint) {
      types.push('Client (Point)');
    }
    const typesArgument = types.length === 1 ? types : undefined;
    this.setState({ isSearching: true });
    return getInternalTeam(dispatch, desk, typesArgument, _searchEmployeeRelationships).then((results) => {
      const sortedResults = entitySorter(results, sortColumn, sortDirection);
      this.setState({ entities: sortedResults, isSearching: false });
    });
  }

  search(params) {
    const { dispatch, desk } = this.props;
    const { state } = this;
    const { sortDirection, sortColumn } = state;
    const searchText = params.searchText || state.searchText;
    const isContactSearch = params.contact;
    const includeClients = this._coalesce(params.includeClients, state.includeClients);
    const includeIndustry = this._coalesce(params.includeIndustry, state.includeIndustry);
    const includeOutlook = this._coalesce(params.includeOutlook, state.includeOutlook);
    const includeShared = this._coalesce(params.includeShared, state.includeShared);
    const includeEmployee = this._coalesce(params.includeEmployee, state.includeEmployee);
    const includeCompanies = this._coalesce(params.includeCompanies, state.includeCompanies);
    const includePeople = this._coalesce(params.includePeople, state.includePeople);

    const includeAllTypes =
      !includeIndustry && !includeOutlook && !includeClients && !includeShared && !includeEmployee;
    const includeAllEntities = !includeCompanies && !includePeople;

    this.setState({ myClients: false, myClientsAgent: false, myClientsPoint: false });
    //3 char min
    if (searchText.trim().length <= 2) {
      this.setState({ entities: [] });
      return;
    }

    this.setState({ isSearching: true });
    const searches = [];
    if (includePeople || includeAllEntities) {
      searches.push(
        findPeople({
          dispatch,
          searchText,
          deskId: desk && desk._id,
          includeClients: includeClients || includeAllTypes,
          includeIndustry: includeIndustry || includeAllTypes,
          includeShared: includeShared || includeAllTypes,
          includeEmployee: includeEmployee || includeAllTypes,
        })
      );
      if (isContactSearch) {
        searches.push(
          findPeopleByContact({
            dispatch,
            searchText,
            deskId: desk && desk._id,
            includeClients: includeClients || includeAllTypes,
            includeIndustry: includeIndustry || includeAllTypes,
            includeShared: includeShared || includeAllTypes,
            includeEmployee: includeEmployee || includeAllTypes,
          })
        );
      }
    }
    if (includeCompanies || includeAllEntities) {
      searches.push(
        findCompanies({
          searchText,
          includeClients: includeClients || includeAllTypes,
          includeIndustry: includeIndustry || includeAllTypes,
          includeShared: includeShared || includeAllTypes,
        })
      );
      if (isContactSearch) {
        searches.push(
          findCompaniesByContact({
            searchText,
            includeClients: includeClients || includeAllTypes,
            includeIndustry: includeIndustry || includeAllTypes,
            includeShared: includeShared || includeAllTypes,
          })
        );
      }
    }
    if (desk && desk._id && (includeOutlook || includeAllTypes)) {
      searches.push(
        findContacts({
          dispatch,
          searchText,
          deskId: desk._id,
        })
      );
    }

    return Promise.all(searches).then((results) => {
      const combinedResults = results.reduce((allResults, searchResults) => {
        return allResults.concat(searchResults);
      }, []);
      const sortedResults = entitySorter(combinedResults, sortColumn, sortDirection);
      this.setState({ entities: sortedResults, isSearching: false });
    });
  }

  _coalesce(obj1, obj2) {
    return obj1 === undefined ? obj2 : obj1;
  }

  _setInclude(includeObject) {
    const key = Object.keys(includeObject)[0];
    localStorage.setItem(key, includeObject[key]);
    this.setState(includeObject);
    this.search(includeObject);
  }

  async setDesk(desk) {
    const { dispatch } = this.props;
    const { myClients } = this.state;
    const _desk = desk || {};
    const resetQuickSearch = { myClients: false, myClientsPoint: false, myClientsAgent: false };
    if (myClients) {
      resetQuickSearch.entities = [];
    }
    this.setState(resetQuickSearch);
    return dispatch(setDesk(_desk));
  }

  render() {
    const { styles = {}, desk, user, desks } = this.props;
    const {
      searchText,
      includeIndustry,
      includeOutlook,
      includeClients,
      includeEmployee,
      includeShared,
      includeCompanies,
      includePeople,
      isSearching,
      entities,
      sortColumn,
      sortDirection,
      myClients,
      myClientsAgent,
      myClientsPoint,
      filtersExpanded,
      showMergeProfilesModal,
    } = this.state;

    const noRelationships = !desk || !desk.agentId || !desk.agentId.relationships || !desk.agentId.relationships[0];

    return (
      <LaunchDarklyWrapper
        render={(flags) => {
          return (
            <div className={styles.container}>
              <BrandingWrapped />
              <Header
                noDebounce={false}
                cyTag={SEARCH.MAIN_SEARCH_INPUT}
                searchText={searchText}
                onSearchTextChanged={(value) => {
                  const update = { searchText: value };
                  this.setState(update);
                  if (value.trim().length >= 3) {
                    this.search(update);
                  } else {
                    this.setState({ entities: [] });
                  }
                }}
                desk={desk}
                desks={desks}
                setDesk={(desk) => this.setDesk(desk)}
                showReleaseNotes={() => {
                  this.setState({ notesIsOpen: true });
                }}
              />
              <div className={styles.content}>
                {!filtersExpanded && (
                  <ChevronRightIcon
                    onClick={() => this.setState({ filtersExpanded: true })}
                    style={{
                      cursor: 'pointer',
                      fontSize: 12,
                      fontWeight: 700,
                      color: '#000',
                      marginTop: 22,
                      marginLeft: 10,
                    }}
                  />
                )}
                {filtersExpanded && (
                  <div className={styles.filterColumn}>
                    <span>
                      <span style={{ float: 'right' }}>
                        <ChevronLeftIcon
                          onClick={() => this.setState({ filtersExpanded: false })}
                          style={{ cursor: 'pointer', fontSize: 12, fontWeight: 700, color: '#000' }}
                        />
                      </span>
                      <h5 style={{ fontSize: '18px', marginBottom: 20 }}>Filters</h5>
                    </span>
                    <div style={{ marginLeft: 15 }}>
                      <DropCapCheckBox
                        cyTag={SEARCH.DROPBOX_CHECK_CLIENT}
                        text="Client"
                        onCheckChanged={(checked) => {
                          const update = { includeClients: checked };
                          this._setInclude(update);
                        }}
                        checked={includeClients}
                        capBackgroundColor={elements.clientMarker.background}
                        capTextColor={elements.clientMarker.color}
                      />
                      <DropCapCheckBox
                        text="Industry"
                        cyTag={SEARCH.DROPBOX_CHECK_INDUSTRY_CONTACT}
                        onCheckChanged={(checked) => {
                          const update = { includeIndustry: checked };
                          this._setInclude(update);
                        }}
                        checked={includeIndustry}
                        capBackgroundColor={elements.industryMarker.background}
                        capTextColor={elements.industryMarker.color}
                      />
                      <DropCapCheckBox
                        text="Shared"
                        cyTag={SEARCH.DROPBOX_CHECK_SHARED_CONTACT}
                        onCheckChanged={(checked) => {
                          const update = { includeShared: checked };
                          this._setInclude(update);
                        }}
                        checked={includeShared}
                        capBackgroundColor={elements.sharedMarker.background}
                        capTextColor={elements.sharedMarker.color}
                      />
                      <DropCapCheckBox
                        text="Employee"
                        cyTag={SEARCH.DROPBOX_CHECK_EMPLOYEE}
                        letter="U"
                        onCheckChanged={(checked) => {
                          const update = { includeEmployee: checked };
                          this._setInclude(update);
                        }}
                        checked={includeEmployee}
                        capBackgroundColor={elements.employeeMarker.background}
                        capTextColor={elements.employeeMarker.color}
                      />
                      <DropCapCheckBox
                        text="Outlook"
                        cyTag={SEARCH.DROPBOX_CHECK_OUTLOOK}
                        onCheckChanged={(checked) => {
                          const update = { includeOutlook: checked };
                          this._setInclude(update);
                        }}
                        checked={includeOutlook}
                        capBackgroundColor={elements.outlookMarker.background}
                        capTextColor={elements.outlookMarker.color}
                        enabled={desks.length > 0}
                      />
                    </div>
                    <div
                      style={{
                        borderTop: '1px solid #ccc',
                        marginTop: 20,
                        paddingTop: 20,
                        paddingLeft: 16,
                      }}
                    >
                      <DropCapCheckBox
                        text="Company"
                        cyTag={SEARCH.DROPBOX_CHECK_COMPANY}
                        onCheckChanged={(checked) => {
                          const update = { includeCompanies: checked };
                          this._setInclude(update);
                        }}
                        checked={includeCompanies}
                        IconComponent={OfficeBuildingIcon}
                      />
                      <DropCapCheckBox
                        text="Person"
                        cyTag={SEARCH.DROPBOX_CHECK_PERSON}
                        onCheckChanged={(checked) => {
                          const update = { includePeople: checked };
                          this._setInclude(update);
                        }}
                        checked={includePeople}
                        IconComponent={UserIcon}
                      />
                    </div>
                    {!HIDE_MY_CLIENTS && (
                      <>
                        <h5
                          style={{
                            borderTop: '1px solid #ccc',
                            marginTop: 20,
                            paddingTop: 20,
                            color: noRelationships ? '#ccc' : '',
                            fontSize: '18px',
                          }}
                        >
                          Quick Search
                        </h5>
                        <button
                          disabled={noRelationships}
                          style={{
                            padding: 5,
                            marginTop: 10,
                            marginBottom: 10,
                            height: 32,
                            background: noRelationships ? '' : '#fff',
                            border: `1px solid ${noRelationships ? '#999' : '#28292A'}`,
                            cursor: noRelationships ? 'not-allowed' : 'pointer',
                          }}
                          onClick={() => {
                            this.setState(
                              {
                                myClients: true,
                                searchText: '',
                                includeClients: false,
                                includeIndustry: false,
                                includeOutlook: false,
                                includeEmployee: false,
                                includeShared: false,
                                includeCompanies: false,
                                includePeople: false,
                              },
                              this.findRelationships
                            );
                          }}
                        >
                          My Clients
                        </button>
                        <DropCapCheckBox
                          text="I am a point agent on"
                          onCheckChanged={(checked) => {
                            this.setState({ myClientsPoint: checked }, this.findRelationships);
                          }}
                          checked={myClientsPoint}
                          hideCap
                          enabled={myClients}
                        />
                        <DropCapCheckBox
                          text="I am an agent on"
                          onCheckChanged={(checked) => {
                            this.setState({ myClientsAgent: checked }, this.findRelationships);
                          }}
                          checked={myClientsAgent}
                          hideCap
                          enabled={myClients}
                        />
                        {noRelationships || !(desk.agentId && desk.agentId._id) ? (
                          <div className={styles.manageMyClientsDisabled}>
                            Manage My Clients
                            <i style={{ marginLeft: '5px' }} className="fa fa-angle-double-right" />
                          </div>
                        ) : (
                          <a
                            href={`${profileUrl}/${desk.agentId._id}`}
                            className={styles.manageMyClients}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Manage My Clients
                            <i style={{ marginLeft: '5px' }} className="fa fa-angle-double-right" />
                          </a>
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className={styles.tableColumn}>
                  {isSearching ? (
                    <div className={styles.spinner}>
                      <Spinner size={60} style={{ paddingTop: 30 }} />
                    </div>
                  ) : (
                    <div className={styles.gridContainer}>
                      {entities.length > 0 && (
                        <>
                          <CountLabel count={entities.length} label="Result" className={styles.resultCount} />
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <button
                              label="reset"
                              className={styles.resetButton}
                              onClick={() => {
                                this.setState({
                                  searchText: '',
                                  myClients: false,
                                  myClientsAgent: false,
                                  myClientsPoint: false,
                                  entities: [],
                                  includeClients: false,
                                  includeIndustry: false,
                                  includeOutlook: false,
                                  includeEmployee: false,
                                  includeShared: false,
                                  includeCompanies: false,
                                  includePeople: false,
                                });
                              }}
                            >
                              Reset
                            </button>
                            {flags.showMergeButtons ? (
                              <div>
                                <MergeIcon rotate={1} style={{ marginRight: 5 }} />
                                <button
                                  label="merge"
                                  className={styles.mergeButton}
                                  onClick={() => {
                                    this.setState({
                                      showMergeProfilesModal: true,
                                    });
                                  }}
                                >
                                  Merge
                                </button>
                                {showMergeProfilesModal ? (
                                  <MergeProfilesModal
                                    isOpen={true}
                                    desk={desk}
                                    onCancel={() => {
                                      this.setState({ showMergeProfilesModal: false });
                                    }}
                                  ></MergeProfilesModal>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                          <ContactTable
                            contacts={entities}
                            user={user}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            onSortChanged={(column, direction) => {
                              const sortedPeople = entitySorter(entities, column, direction);
                              this.setState({ sortColumn: column, sortDirection: direction, entities: sortedPeople });
                            }}
                          />
                        </>
                      )}
                      {entities.length === 0 && (
                        <div className={styles.searchResults}>
                          {myClients
                            ? `No ${myClientsPoint ? 'Point' : ''} Agent Relationships`
                            : !searchText || searchText.trim().length < 3
                            ? 'Searches Must Include 3 or More Characters'
                            : 'No Search Results Found'}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.background,
    fontFamily: 'aktiv-grotesk, sans-serif',
    height: '100%',
  },
  gridContainer: {
    width: '100%',
    padding: '0 10px',
    paddingRight: 20,
    alignItems: 'left',
  },
  searchResults: {
    textAlign: 'center',
    fontWeight: '700',
    paddingTop: 100,
    fontSize: 20,
  },
  spinner: {
    display: 'flex',
    paddingTop: 100,
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  filterColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    padding: 20,
    height: '100%',
    marginTop: -10,
    paddingTop: 27,
    paddingRight: 0,
  },
  tableColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    overflow: 'visible',
  },
  manageMyClients: {
    '&:hover': {
      textDecoration: 'underline',
      color: '#2187B9',
    },
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 500,
    color: '#2187B9',
    textAlign: 'right',
    marginTop: '5px',
  },
  manageMyClientsDisabled: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#AAA',
    textAlign: 'right',
    marginTop: '5px',
  },
  resultCount: {
    color: '#141414',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  resetButton: {
    marginLeft: 0,
    marginBottom: 10,
    paddingLeft: 0,
    fontSize: 12,
    color: '#141414',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none !important',
  },
  mergeButton: {
    marginLeft: 0,
    paddingLeft: 0,
    fontSize: 12,
    marginBottom: 10,
    color: '#141414',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none !important',
  },
});

Search.propTypes = {
  dispatch: PropTypes.func,
  styles: PropTypes.object,
  contacts: PropTypes.object,
  desk: PropTypes.object,
  desks: PropTypes.array,
  user: PropTypes.object,
  outlook: PropTypes.object,
};

const withState = connect((store) => {
  const { user, desk } = store;
  const desks = desk.available;
  return { user, desk: desk.current, desks };
});

export default withState(withStyles(Search));
