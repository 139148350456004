import React, { Component } from 'react';
import { styled } from 'react-free-style';
import helper from 'immutability-helper';
import classnames from 'classnames';

import { EditButton, buttonTypes, colors } from '@united-talent-agency/julius-frontend-components';

import PropTypes from 'prop-types';
import cypressTags from '../../support/cypressTags';

const { COMMON } = cypressTags;
class Card extends Component {
  state = {
    isEditing: false,
    creates: {},
    updates: {},
    deletes: {},
  };

  onChange = () => {
    const { onChange } = this.props;
    const { creates, updates, deletes } = this.state;

    onChange && onChange({ creates, updates, deletes });
  };

  updateBy(type, id, data) {
    return this.setState(
      {
        updates: helper(this.state.updates, {
          [type]: {
            $apply: (x) =>
              helper(x || {}, {
                [id]: {
                  $apply: (x) => helper(x || {}, { $merge: data }),
                },
              }),
          },
        }),
      },
      this.onChange
    );
  }

  createBy(type, index, data) {
    if (typeof index === 'number') {
      return this.setState(
        {
          creates: helper(this.state.creates, {
            [type]: {
              [index]: {
                $merge: data,
              },
            },
          }),
        },
        this.onChange
      );
    }

    return this.setState(
      {
        creates: helper(this.state.creates, {
          [type]: {
            $apply: (x) => helper(x || [], { $push: [data] }),
          },
        }),
      },
      this.onChange
    );
  }

  deleteBy(type, id) {
    return this.setState(
      {
        deletes: helper(this.state.deletes, {
          [type]: {
            $apply: (x) =>
              helper(x || {}, {
                [id]: {
                  $set: true,
                },
              }),
          },
        }),
      },
      this.onChange
    );
  }

  reset() {
    this.setState({ creates: {}, updates: {}, deletes: {} });
    this.setIsEditing(false);
  }

  setIsEditing(isEditing) {
    const { onIsEditingChanged } = this.props;

    this.setState({ isEditing });

    onIsEditingChanged && onIsEditingChanged(isEditing);
  }

  save() {
    const { hasError, saveChanges = () => {} } = this.props;
    const { creates, updates, deletes } = this.state;
    if (!hasError) {
      saveChanges({ creates, updates, deletes });
      this.reset();
    }
  }

  renderHeader() {
    const { canEdit, onCancel, title, styles, hasError, mergeCardData } = this.props;
    const { isEditing } = this.state;

    const style = mergeCardData ? { background: '#DFDFDB', height: '30px', padding: '15px' } : {};
    const cardTitle = (!mergeCardData || mergeCardData?.primary) && title;
    const allowEdit = (!mergeCardData || mergeCardData?.primary) && canEdit;

    return (
      <div className={classnames(styles.header, isEditing ? styles.headerEdit : undefined)} style={style}>
        <h3 className={styles.title}>{cardTitle}</h3>
        {!isEditing && allowEdit && (
          <span data-cy={COMMON.BASIC_INFO_CARD.EDIT_BUTTON}>
            <EditButton type={buttonTypes.edit} className={styles.icon} onClick={() => this.setIsEditing(true)} />
          </span>
        )}
        {isEditing && (
          <span data-cy={COMMON.CANCEL_BUTTON}>
            <EditButton
              type={buttonTypes.cancel}
              className={styles.icon}
              onClick={() => {
                this.reset();
                onCancel && onCancel();
              }}
            />
          </span>
        )}
        {isEditing && (
          <span data-cy={COMMON.SAVE_BUTTON}>
            <EditButton
              disabled={hasError}
              type={buttonTypes.save}
              className={styles.icon}
              onClick={() => this.save()}
            />
          </span>
        )}
      </div>
    );
  }

  renderFooter(childProps) {
    const { onCreate, styles } = this.props;
    const { isEditing } = this.state;

    if (!isEditing || !onCreate) return null;

    return (
      <div className={styles.create}>
        <EditButton type={buttonTypes.new} onClick={() => onCreate(childProps)} />
      </div>
    );
  }

  render() {
    const { styles, children, mergeCardData } = this.props;

    const childProps = {
      creates: this.state.creates,
      updates: this.state.updates,
      deletes: this.state.deletes,
      isEditing: this.state.isEditing,
      updateBy: (type, id, data) => this.updateBy(type, id, data),
      createBy: (type, index, data) => this.createBy(type, index, data),
      deleteBy: (type, id) => this.deleteBy(type, id),
    };

    const style = mergeCardData
      ? {
          marginBottom: mergeCardData ? 16 : 20,
          minHeight: mergeCardData?.cardHeight,
        }
      : {};

    return (
      <div className={styles.pane} name={mergeCardData?.name} style={style}>
        {this.renderHeader()}
        <div className={styles.body}>{children(childProps)}</div>
        {this.renderFooter(childProps)}
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  canEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  onIsEditingChanged: PropTypes.func,
};

const withStyles = styled({
  pane: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  button: {
    float: 'right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  headerEdit: {
    background: '#90E2D3',
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 13,
    fontWeight: 700,
    margin: '5px 0',
  },
  create: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderTop: '1px dotted #D9D9D9',
  },
  icon: {
    marginLeft: 10,
  },
});

export default withStyles(Card);
