import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { DeskSelector, Input, colors } from '@united-talent-agency/julius-frontend-components';
import { Icons } from '@united-talent-agency/components';

const { SearchOutlineIcon, XCloseIcon } = Icons;

const SearchBar = (props) => {
  const { onSearchTextChanged, styles, searchText, cyTag } = props;
  const debouncedSearch =
    onSearchTextChanged &&
    debounce((value) => {
      onSearchTextChanged(value ? value : '');
    }, 500);
  return (
    <div className={styles.search}>
      <Input
        type="text"
        cyTag={cyTag}
        id="header-search-input"
        title="Search"
        value={searchText}
        inputClassName={classnames(styles.input, styles.searchInput)}
        onChange={debouncedSearch}
      />
    </div>
  );
};

const Menu = (props) => {
  const { styles } = props;
  return (
    <Popover>
      <Popover.Title style={{ color: '#141414', textAlign: 'center', textTransform: 'uppercase', padding: 0 }}>
        Menu
      </Popover.Title>
      <Popover.Content>
        <ul className={styles.menuList}>
          <li className={styles.menuItem}>
            <div
              onClick={() => {
                localStorage.removeItem('uta.auth');
                window.location = '/login';
              }}
            >
              <i className="fa fa-fw fa-sign-out" aria-hidden="true" />
              Sign Out
            </div>
          </li>
        </ul>
      </Popover.Content>
    </Popover>
  );
};

const Header = (props) => {
  const { styles, desk, desks = [], setDesk, searchText, onSearchTextChanged, cyTag } = props;
  const menu = Menu(props);
  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftColumn}>
        {desks.length > 0 ? (
          <DeskSelector desk={desk} deskIds={desks} setDesk={setDesk} />
        ) : (
          <span style={{ marginLeft: 220 }} />
        )}
      </div>
      <div className={styles.searchColumn}>
        <div className={styles.searchIconColumn}>
          {!searchText && <SearchOutlineIcon style={{ fontSize: 20, color: '#999' }} />}
          {searchText && (
            <XCloseIcon
              onClick={() => onSearchTextChanged('')}
              style={{ cursor: 'pointer', fontSize: 20, color: '#999' }}
            />
          )}
        </div>
        <SearchBar
          cyTag={cyTag}
          searchText={searchText}
          styles={styles}
          onSearchTextChanged={onSearchTextChanged}
        ></SearchBar>
      </div>

      <div className={styles.userColumn}>
        <div className={styles.header}>
          <div className={styles.user}>
            <div className={styles.userContent}>
              <div className={styles.imageColumn}>
                <OverlayTrigger trigger="click" overlay={menu} placement="bottom" rootClose={true}>
                  <Icons.HamburgerMenuIcon className={styles.userImage} />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const withStyles = styled({
  headerContainer: {
    background: colors.contentBackground,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '60px',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    justifyContent: 'center',
  },
  search: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    margin: 10,
    paddingLeft: 20,
  },
  searchContainer: {
    margin: '10px',
  },
  searchInput: {
    '&&': {
      border: `1px solid ${colors.border}`,
      background: colors.background,
    },
  },
  menuList: {
    listStyle: 'none',
    position: 'relative',
    width: '200%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: '10px',
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
    padding: '5px',
  },
  menuIcon: {
    marginRight: '10px',
  },
  userContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  userImage: {
    borderRadius: '50%',
    height: '35px',
    width: '35px',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  imageColumn: {
    display: 'flex',
    flex: 0.4,
    marginRight: '10px',
    marginTop: '3px',
    flexDirection: 'column',
  },
  leftColumn: {
    display: 'flex',
    flex: 0.25,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'flex-start',
  },
  searchColumn: {
    display: 'flex',
    flex: 2,
    minWidth: 150,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  searchIconColumn: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 18,
    marginRight: 20,
  },
  userColumn: {
    display: 'flex',
    flex: 0.1,
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: '5px',
  },
  settingLabel: {
    marginBottom: '0px',
  },
});

Header.propTypes = {
  styles: PropTypes.object,
  desk: PropTypes.object,
  user: PropTypes.object,
  onSearchTextChanged: PropTypes.func,
  desks: PropTypes.array,
  setDesk: PropTypes.func,
  searchText: PropTypes.string,
  showReleaseNotes: PropTypes.func,
};

const headerWithStyles = withStyles(Header);

export default headerWithStyles;
