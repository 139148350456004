import { ErrorMsg, AgentType } from './const';
import { CountTypeByTerritory } from './CountTypeByTerritory';

export const ComedyClientPreChecks = async ({
  newData,
  territoriesCategory,
  selectedTerritories,
  saveData,
  setReadMode,
  handleError,
}) => {
  if (newData.length === 0) {
    handleError({ save: true, error: ErrorMsg.ResponsibleAgent });
  } else {
    const checkForMandatoryType = newData.some((ds) => !ds.type);
    const checkForMandatoryPerson = newData.some((ds) => !ds.personId);

    if (territoriesCategory === 'Itemized') {
      const result = CountTypeByTerritory(newData);
      const checkForResponsibleAgent = Array.from(selectedTerritories).every(
        (ds) => result[ds] && result[ds][AgentType.ResponsibleAgent]
      );
      const checkIfContractAdminIsRepeated = Array.from(selectedTerritories).some(
        (ds) => result[ds] && result[ds][AgentType.ContractAdministrator] > 1
      );
      if (!checkForResponsibleAgent) {
        handleError({ save: true, error: ErrorMsg.ResponsibleAgent });
      } else {
        if (checkIfContractAdminIsRepeated) {
          handleError({ save: true, error: ErrorMsg.ContractAdmin });
        } else if (checkForMandatoryType) {
          handleError({ save: true, error: ErrorMsg.AgentType });
        } else if (checkForMandatoryPerson) {
          handleError({ save: true, error: ErrorMsg.Agent });
        } else {
          handleError({ save: true, error: '' });
          await saveData(newData);
          setReadMode();
        }
      }
    } else {
      const checkForResponsibleAgent = newData.some((ds) => ds['type'] === AgentType.ResponsibleAgent);
      const checkIfContractAdminIsRepeated = newData.filter((ds) => ds['type'] === AgentType.ContractAdministrator);

      if (!checkForResponsibleAgent) {
        handleError({ save: true, error: ErrorMsg.ResponsibleAgent });
      } else {
        if (checkIfContractAdminIsRepeated.length > 1) {
          handleError({ save: true, error: ErrorMsg.ContractAdmin });
        } else if (checkForMandatoryType) {
          handleError({ save: true, error: ErrorMsg.AgentType });
        } else if (checkForMandatoryPerson) {
          handleError({ save: true, error: ErrorMsg.Agent });
        } else {
          handleError({ save: true, error: '' });
          await saveData(newData);
          setReadMode();
        }
      }
    }
  }
};
