import { validateEmailInput, validatePhoneInput } from '@united-talent-agency/components';

export const phoneContactFilter = /phone/i;
export const emailContactFilter = /email/i;
export const otherContactFilter = /^(?!.*(phone|email)).*/i;
export const personalEmailContactFilter = /personal email/i;
export const phoneOrFaxFilter = /Phone|Fax Number/;
export const allContactsFilter = /.*/;
export const isEmail = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;
export const isValidPhoneNumber = /^\+?\d{1,3}[-\s]?\(?\d{1,4}\)?[-\s]?\d{1,4}[-\s]?\d{1,4}$/;
export const TELEPHONE_TYPE_CONTACTS = ['Office Phone', 'Assistant Phone', 'Mobile Phone', 'Home Phone', 'Fax Number'];
export const EMAIL_TYPE_CONTACTS = ['Email', 'Assistant Email', 'Personal Email'];

export const contactIsValid = (contact) => {
  const isTelephoneType = TELEPHONE_TYPE_CONTACTS.includes(contact.contactType);
  const isEmailType = EMAIL_TYPE_CONTACTS.includes(contact.contactType);
  const isMessage = contact.contactType === 'Message';

  if ((isTelephoneType || isEmailType) && !contact.contact) return false;
  if (isMessage && (!contact?.contact || !contact?.contact?.trim()?.length)) return false;
  if (isTelephoneType) {
    // telephone input validation
    return (
      // we cannot validate private numbers since we may not know the contact details
      contact.isPrivate ||
      (contact.contactType && !phoneOrFaxFilter.test(contact.contactType)) ||
      validatePhoneInput(contact.contact)
    );
  }

  if (isEmailType) {
    // email input validation
    // we cannot validate private numbers since we may not know the contact details
    return (
      contact.isPrivate ||
      (contact.contactType && !emailContactFilter.test(contact.contactType)) ||
      validateEmailInput(contact.contact)
    );
  }

  return true;
};
