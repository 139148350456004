import React from 'react';
import { Profile as OutlookProfile } from '@united-talent-agency/julius-frontend-components';
import ContactRow from './contact-row';
import Profile from './Profile';
import OutlookContactEditor from '../../components/outlook-contact-editor';

export const ContactDetails = ({
  person,
  expanded,
  onEdit,
  styles = {},
  onSave = () => {},
  onCancel = () => {},
  editing,
  onExpandChanged = () => {},
}) => {
  const isCompany = person?.isCompany || false;
  const isOutlook = person?.type === 'Outlook';

  return (
    <>
      <ContactRow person={person} expanded={expanded} onExpandChanged={onExpandChanged} />
      {expanded && (
        <tr key={`active:${person._id}`}>
          <td className={styles.pane} colSpan={7}>
            {editing ? (
              <OutlookContactEditor person={person} onSave={onSave} onCancel={onCancel} />
            ) : (
              <div>
                {isOutlook ? (
                  <OutlookProfile person={person} call={person} hidePic={false} onEdit={onEdit} />
                ) : (
                  <Profile person={person} call={person} onEdit={onEdit} baseProfileUrl={''} isCompany={isCompany} />
                )}
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  );
};
