import React, { useState, useEffect } from 'react';

import { Input, Info } from '@united-talent-agency/julius-frontend-components';
import { updatePersonProfile } from '@united-talent-agency/julius-frontend-store';
import { useDispatch } from 'react-redux';

import { updateGroup } from '../../api/groups';
import Card from '../card/ProjectCard';
import SocialInfo, { isValidSocialUrl } from '../social-info/social-info';
import CommonTooltip from '../common/tooltip';

import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export const isValidWebsite = (website) => {
  const urlRegex = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );

  return urlRegex.test(website);
};

const CardSocial = ({ entity, entityFieldName = 'person', refreshProfile, mergeCardData, blockEdit, cyTag = '' }) => {
  const dispatch = useDispatch();
  const [socials, setSocials] = useState([]);
  const [website, setWebsite] = useState();
  const createSocial = () => setSocials([...socials, { network: 'facebook', url: '', followers: '' }]);
  const saveChanges = async () => {
    if (entityFieldName === 'person') {
      dispatch(updatePersonProfile(entity._id, { social: socials, artistWebsite: website })).then(() =>
        refreshProfile()
      );
    } else {
      await updateGroup(entity._id, { social: socials, artistWebsite: website }).then(() => refreshProfile());
    }
  };

  const onCancel = () => {
    setSocials(entity.social || []);
    setWebsite(entity.artistWebsite);
  };

  useEffect(() => {
    setSocials(entity.social || []);
    setWebsite(entity.artistWebsite);
  }, [entity]);

  const showSocial = !mergeCardData || !mergeCardData.isEmpty || socials.length > 0 || entity.artistWebsite;

  const hasError = (socials, website) => {
    if (!website || !isValidWebsite(website)) return true;
    if (socials.some((social) => !social.url)) return true;

    socials.forEach((social) => {
      if (isValidSocialUrl(social)) return false;
    });
  };

  return !showSocial ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }} data-cy={cyTag}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        title="Social Info"
        mergeCardData={mergeCardData}
        canEdit={!blockEdit}
        saveChanges={saveChanges}
        onCreate={createSocial}
        onCancel={onCancel}
        hasError={hasError(socials, website)}
      >
        {({ isEditing }) => (
          <>
            {!isEditing && (
              <Info name="Website" key="Website">
                {entity.artistWebsite && isValidWebsite(entity.artistWebsite) ? (
                  <a
                    href={entity.artistWebsite}
                    className=""
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                    target="_blank noopener noreferrer"
                  >
                    {entity.artistWebsite}
                  </a>
                ) : (
                  <span className="invalid-data-warning">Invalid Data</span>
                )}
              </Info>
            )}

            {isEditing && (
              <div style={{ marginBottom: 12, marginTop: 8, paddingRight: 0 }}>
                <Input
                  type="text"
                  title="Website"
                  value={website || ''}
                  onChange={(artistWebsite) => setWebsite(artistWebsite)}
                />
                {!isValidWebsite(website) && (
                  <div style={{ color: 'red', fontSize: 12, marginTop: 6 }}>Invalid Link</div>
                )}
              </div>
            )}
            {socials.map((social, index) => {
              if (!social) return null;

              return (
                <SocialInfo
                  key={index}
                  social={social}
                  isEditing={isEditing}
                  onChange={(data) => {
                    const updatedSocials = socials.map((social, socialIdx) =>
                      socialIdx === index ? { ...social, ...data } : social
                    );
                    setSocials(updatedSocials);
                  }}
                  onDelete={() => {
                    const updatedSocials = socials.filter((_x, idx) => idx !== index);
                    setSocials(updatedSocials);
                  }}
                />
              );
            })}
          </>
        )}
      </Card>
    </div>
  );
};

export default CardSocial;
