import algoliasearch from 'algoliasearch';

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);

const getFilterString = (filtersState) => {
  const { includeIndustry, includeOutlook, includeClients, includeEmployee, includeShared, hasContacts } = filtersState;
  const filters = [];
  if (includeIndustry) {
    filters.push(`type:'Industry Contact'`);
  }
  if (includeOutlook) {
    filters.push(`type:'Outlook Contact'`);
  }
  if (includeClients) {
    filters.push('type:Client');
  }
  if (includeEmployee) {
    filters.push('type:Employee');
  }
  if (includeShared) {
    filters.push(`type:'Shared Contact'`);
  }

  let filterString = filters.join(' OR ');
  if (hasContacts) {
    let operator = filterString.length > 0 ? 'AND ' : '';
    filterString = filterString + ` ${operator}hasPhoneOrEmail:true`;
  }
  return filterString;
};

async function search({ query, filtersState = {}, searchIndex, hitsPerPage = 50 }) {
  try {
    const filters = getFilterString(filtersState);
    const index = client.initIndex(searchIndex);
    return await index.search(query, { filters, hitsPerPage, restrictSearchableAttributes: ['name'] });
  } catch (error) {
    console.log('ALGOLIA ERROR', error);
    return { hits: [] };
  }
}

export default search;
