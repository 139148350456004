import React from 'react';
import PropTypes from 'prop-types';

const DropCapCheckBox = (props) => {
  const {
    checked,
    onCheckChanged,
    text,
    capBackgroundColor,
    capTextColor,
    letter,
    enabled = true,
    hideCap = false,
    IconComponent,
    cyTag,
  } = props;
  const firstLetter = letter || (text && text.charAt(0).toUpperCase());
  const glyph = checked ? 'fa-check-square' : 'fa-square-o';
  const disabledColor = '#AAA';
  return (
    <div
      data-cy={cyTag}
      style={{
        fontSize: 18,
        fontWeight: 300,
        overflow: 'auto',
        whiteSpace: 'nowrap',
        cursor: enabled ? 'pointer' : 'not-allowed',
        width: 'max-content',
      }}
      onClick={() => {
        enabled && onCheckChanged && onCheckChanged(!checked);
      }}
    >
      <span>
        <i className={`fa ${glyph} fa-w`} style={enabled ? {} : { color: disabledColor }} />
      </span>
      {!IconComponent && !hideCap && firstLetter && (
        <span
          onClick={() => {}}
          style={{
            cursor: enabled ? 'pointer' : 'not-allowed',
            color: capTextColor,
            backgroundColor: enabled ? capBackgroundColor : disabledColor,
            fontWeight: 400,
            fontSize: '12px',
            padding: 0,
            verticalAlign: 'middle',
            textAlign: 'center',
            marginLeft: 8,
            marginTop: -3,
            display: 'inline-block',
            height: 16,
            width: 16,
          }}
        >
          <span style={{ top: -1, position: 'relative', fontSize: '12px' }}>{firstLetter}</span>
        </span>
      )}
      {/* {IconComponent && <Icon icon={icon} style={{ fontSize: 16, marginLeft: 8, marginTop: -3 }} />} */}
      {IconComponent && <IconComponent style={{ fontSize: 16, marginLeft: 8, marginTop: -3 }} />}
      <span
        style={{ fontSize: '14px', fontWeight: 300, marginLeft: hideCap ? 8 : 3, color: enabled ? '' : disabledColor }}
      >
        {text}
      </span>
    </div>
  );
};
DropCapCheckBox.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  onCheckChanged: PropTypes.func,
  capBackgroundColor: PropTypes.string,
  capTextColor: PropTypes.string,
  enabled: PropTypes.bool,
  // TODO: add IconComponent
};
export default DropCapCheckBox;
