import React from 'react';
import { useDispatch } from 'react-redux';

import { updatePerson } from '@united-talent-agency/julius-frontend-store';
import { notify } from 'react-notify-toast';

import Card from '../../../../components/card/Card';
import BasicInfo from '../../../../components/basic-info/basic-info';
import CommonTooltip from '../../../../components/common/tooltip';

import { TOOLTIP_MESSAGES } from '../../../../components/common/messages/tooltip-messages';

const profileDisplayView = (isRequired, { item: person }, mergeCardData) => {
  return <BasicInfo person={person} isEditing={false} isRequired={isRequired} mergeCardData={mergeCardData} />;
};

const profileEditView = (isRequired, errors, { item: person, setState }, mergeCardData, isDataStewardsMember) => {
  return (
    <BasicInfo
      person={person}
      onChange={setState}
      isEditing
      isRequired={isRequired}
      errors={errors}
      mergeCardData={mergeCardData}
      isDataStewardsMember={isDataStewardsMember}
    />
  );
};

export const validateItem = (item) => {
  if (
    item.vocations &&
    item.vocations.length > 0 &&
    (item.vocations.includes('Musician') || item.vocations.includes('Comedy (Live)'))
  ) {
    return !!(item.name && item.name.trim() && item.type && item.genres && item.genres.length > 0);
  }
  return !!(item.name && item.name.trim() && item.type); // this
};

export const BasicInfoCard = ({
  person,
  onChange,
  isRequired,
  errors,
  mergeCardData,
  blockEdit,
  cyTag,
  isDataStewardsMember,
}) => {
  const dispatch = useDispatch();

  const onSave = async ({ updates }) => {
    const { _id } = person;

    if (updates?.[_id]?.vocations?.includes('Comedy (Live)') && updates?.[_id]?.vocations?.includes('Musician')) {
      notify.show('Vocations can either be Musician or Comedy but not both', 'error');
    } else {
      const updatedPerson = {
        _id: person._id,
        ...updates[person._id],
        isFormerClient:
          updates[person._id]?.type === 'Industry Contact' && person?.type === 'Client'
            ? true
            : updates[person._id]?.type && updates[person._id]?.type !== 'Industry Contact'
            ? false
            : person?.isFormerClient || false,
      };
      return dispatch(updatePerson(updatedPerson))
        .then((data) => {
          if (data?.status === 400) {
            notify.show(data?.body?.message, 'error');
          } else if (data?.status === 200) {
            if (updatedPerson?.profile_pic) {
              if (updatedPerson?.profile_pic.length > 0) {
                notify.show('The image saved successfully.', 'success');
              }
            }
          }
        })
        .catch((error) => {
          const errorMessage = error.message;
          notify.show(`Error Saving: ${errorMessage}`, 'error');
        });
    }
  };

  const isEmployee = (person || {}).type === 'Employee';

  return (
    <div data-cy={cyTag} style={{ position: 'relative' }}>
      {isEmployee && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_HR} />}
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        title="Basic Info"
        mergeCardData={mergeCardData}
        data={person}
        readView={(props) => profileDisplayView(isRequired, props, mergeCardData)}
        editView={
          !isEmployee &&
          !blockEdit &&
          ((props) => profileEditView(isRequired, errors, props, mergeCardData, isDataStewardsMember))
        }
        onChange={onChange}
        onSave={onSave}
        onValidateItem={validateItem}
      />
    </div>
  );
};
