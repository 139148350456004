import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateGroup } from '../../../../api/groups.js';
import { getUTACompany } from '@united-talent-agency/julius-frontend-store';

import CompanyProfileView from '../../../company/views/profile';
import CardSocial from '../../../../components/card-social/card-social';
import ExternalTeamView from '../../../shared/external-team-company-relationships';
import TeamView from '../../../company/views/team';

export default function Company({ companyInfo, user = {}, cardData = {}, styles = {}, setTeamInfo, refreshProfiles }) {
  const [utaCompany, setUTACompany] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUTACompany()).then((result) => {
      const utaCompany = result.body || {};
      setUTACompany({ utaCompany });
    });
  }, [dispatch]);

  const isUTACompany = companyInfo._id === utaCompany._id;

  const showExternalTeamView = !cardData.companyEmployees.isEmpty || !cardData.relationships.isEmpty;

  return (
    <>
      <CompanyProfileView
        companyInfo={companyInfo}
        dispatch={dispatch}
        editCompany={updateGroup}
        retrieveCompany={() => refreshProfiles}
        isUTACompany={isUTACompany}
        reservedCompanyNames={[utaCompany.name]}
        user={user}
        mergeCardData={cardData}
      />
      <TeamView
        entity={companyInfo}
        dispatch={dispatch}
        mergeCardData={{ ...cardData.team, primary: cardData.primary }}
        setTeamInfo={setTeamInfo}
      />
      <CardSocial
        entity={companyInfo}
        entityFieldName="company"
        mergeCardData={{ ...cardData.social, primary: cardData.primary }}
        refreshProfile={() => refreshProfiles()}
      />
      {showExternalTeamView && (
        <div className={styles.sectionTitle}>{cardData.primary ? 'External Relationships' : ''}</div>
      )}
      <ExternalTeamView
        entity={companyInfo}
        dispatch={dispatch}
        isUTACompany={isUTACompany}
        mergeCardData={{ ...cardData, primary: cardData.primary }}
        refreshProfile={() => refreshProfiles()}
      />
    </>
  );
}
