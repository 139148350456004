import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { colors } from '@united-talent-agency/julius-frontend-components';

import SortableColumnHeader from '../../components/sortable-column-header/SortableColumnHeader';
import { ContactDetails } from './contact-details';

class ContactTable extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: {}, editing: {} };
  }
  render() {
    const { styles, contacts = [], onSortChanged, sortColumn, sortDirection, onOutlookContactUpdated } = this.props;
    const { expanded, editing } = this.state;

    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th} style={{ paddingRight: '20px', width: 0, textAlign: 'center' }}>
                <SortableColumnHeader
                  text="Type"
                  direction={sortColumn === 'Type' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Type', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Name"
                  direction={sortColumn === 'Name' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Name', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Title"
                  direction={sortColumn === 'Title' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Title', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Company"
                  direction={sortColumn === 'Company' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Company', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Phone"
                  direction={sortColumn === 'Phone' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Phone', direction);
                  }}
                />
              </th>
              <th className={styles.th} colSpan="2">
                <SortableColumnHeader
                  text="Email"
                  direction={sortColumn === 'Email' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Email', direction);
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 &&
              contacts.map((person = {}) => {
                const onEdit =
                  person.type === 'Outlook' &&
                  ((person) => {
                    editing[person._id] = true;
                    this.setState({ editing });
                  });
                const onSave = (updates) => {
                  onOutlookContactUpdated && onOutlookContactUpdated(person._id, updates);
                  delete editing[person._id];
                  this.setState({ editing });
                };
                const onCancel = () => {
                  delete editing[person._id];
                  this.setState({ editing });
                };
                const onExpandChanged = () => {
                  if (expanded[person._id] !== undefined) {
                    delete expanded[person._id];
                  } else {
                    expanded[person._id] = true;
                  }
                  this.setState({ expanded });
                };
                return (
                  <ContactDetails
                    person={person}
                    expanded={expanded[person._id] !== undefined}
                    editing={editing[person._id] !== undefined}
                    onExpandChanged={() => onExpandChanged(person)}
                    onCancel={onCancel}
                    onSave={onSave}
                    styles={styles}
                    key={`row:${person._id}`}
                    onEdit={onEdit}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

ContactTable.propTypes = {
  contacts: PropTypes.array,
  styles: PropTypes.object,
  onSortChanged: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.number,
};

const withStyles = styled({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    paddingTop: 20,
  },
  th: {
    padding: 0,
    paddingLeft: 2,
    color: '#464645',
    fontSize: '15px',
  },
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
    borderTopColor: colors.border,
    borderBottomColor: colors.border,
    border: `1px solid ${colors.background}`,
    overflow: 'visible',
  },
});

export default withStyles(ContactTable);
