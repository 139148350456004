import React from 'react';
import { styled } from 'react-free-style';

import { elements } from '@united-talent-agency/julius-frontend-components';
import { personTypes } from '@united-talent-agency/julius-frontend-store';

const TypeIcon = ({ styles = {}, entity, customStyles }) => {
  const baseStyles = {
    marginRight: 5,
    marginLeft: -3,
    paddingTop: 0,
    height: 20,
    width: 20,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...customStyles,
  };

  const Icon = () => {
    if (entity) {
      switch (entity.type) {
        case personTypes.client:
          return (
            <span className={styles.clientMarker} style={baseStyles}>
              C
            </span>
          );
        case personTypes.personalContact:
          return (
            <span className={styles.privateMarker} style={baseStyles}>
              P
            </span>
          );
        case personTypes.employee:
          return (
            <span className={styles.employeeMarker} style={baseStyles}>
              U
            </span>
          );
        case personTypes.buyer:
          return (
            <span className={styles.buyerMarker} style={{ ...baseStyles, paddingTop: 2 }}>
              B
            </span>
          );
        case personTypes.outlook:
          return (
            <span className={styles.outlookMarker} style={{ ...baseStyles, paddingTop: 2 }}>
              O
            </span>
          );
        case personTypes.shared:
          return (
            <span className={styles.sharedMarker} style={{ ...baseStyles, paddingTop: 2 }}>
              S
            </span>
          );
        case personTypes.deskContact:
          return (
            <span className={styles.deskContactMarker} style={{ ...baseStyles, paddingTop: 2 }}>
              P
            </span>
          );
        case personTypes.industryContact:
          return (
            <span className={styles.industryMarker} style={{ ...baseStyles, paddingTop: 2 }}>
              I
            </span>
          );
        default:
          return (
            <span className={styles.adhocMarker} style={{ ...baseStyles, paddingTop: 2 }}>
              A
            </span>
          );
      }
    } else {
      return (
        <span className={styles.adhocMarker} style={{ ...baseStyles, paddingTop: 2 }}>
          A
        </span>
      );
    }
  };

  return (
    <span className={entity ? `${entity?.type?.split(' ')?.join('-')?.toLowerCase()}-badge` : 'badge'}>
      <Icon />
    </span>
  );
};

const withStyles = styled({
  clientMarker: elements.clientMarker,
  buyerMarker: elements.buyerMarker,
  privateMarker: elements.privateMarker,
  employeeMarker: elements.employeeMarker,
  adhocMarker: elements.adhocMarker,
  industryMarker: elements.industryMarker,
  sharedMarker: elements.sharedMarker,
  deskContactMarker: elements.deskContactMarker,
  outlookMarker: elements.outlookMarker,
});

export default withStyles(TypeIcon);
