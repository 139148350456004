import styled from 'styled-components/macro';
import { colors } from '@united-talent-agency/components';
import { Territory } from './utils/const';

// Sort & section things by territory / primary
export const sectionReadData = (data) => {
  const sortedData = [...data];
  sortedData.sort((a, b) => {
    if (a.regions[0] !== b.regions[0]) {
      if (a.regions[0] === Territory.AllTerritories) return -1;
      if (b.regions[0] === Territory.AllTerritories) return 1;
      return a.regions[0].localeCompare(b.regions[0]);
    }
    if (a.primary !== b.primary) {
      if (a.primary) return -1;
      if (b.primary) return 1;
    }
    return 0;
  });

  const sectionedData = {};
  sortedData.forEach((item) => {
    if (sectionedData[item.regions[0]]) {
      sectionedData[item.regions[0]].push(item);
    } else {
      sectionedData[item.regions[0]] = [item];
    }
  });

  return sectionedData;
};

// Sort & section things by territory / primary
export const sectionEditData = (data) => {
  const sortedData = [...data];
  sortedData.sort((a, b) => {
    if (a.regions[0] !== b.regions[0]) {
      if (a.regions[0] === Territory.AllTerritories) return -1;
      if (b.regions[0] === Territory.AllTerritories) return 1;
      return a.regions[0].localeCompare(b.regions[0]);
    }
    if (a.primary !== b.primary) {
      if (a.primary) return -1;
      if (b.primary) return 1;
    }
    return 0;
  });

  const sectionedData = {};
  sortedData.forEach((item) => {
    item = { ...item, personId: { name: item?.personId?.name, _id: item?.personId?._id } };
    if (sectionedData[item.regions[0]]) {
      sectionedData[item.regions[0]].push(item);
    } else {
      sectionedData[item.regions[0]] = [item];
    }
  });

  return sectionedData;
};

export const TerritoryBox = styled.div`
  padding: 10px 0;
  border-bottom: 1px dotted ${colors.infoBorder};
`;

export const TerritoryHeader = styled.div`
  color: #494443;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 5px;
`;
