import React, { useState, useEffect } from 'react';
import { notify } from 'react-notify-toast';
import { styled } from 'react-free-style';
import {
  getPersonExternalTeamCompanies,
  getPersonExternalTeamPeople,
  updatePersonExternalTeamCompanies,
  updatePersonExternalTeamPeople,
} from '@united-talent-agency/julius-frontend-store';

import { getFiltersFromTypes } from '../../../search/utils';
import search from '../../../../support/algolia';

import { getGroups } from '../../../../api/groups';
import ExternalTeamEntityRelationships from './entity-relationships';

const loadExternalTeamEntities = (dispatch, personId) => {
  const peopleRelationships = dispatch(getPersonExternalTeamPeople(personId)).then((result) => {
    const rels = Array.isArray(result.body) ? result.body : [];

    return rels;
  });
  const groupRelationships = dispatch(getPersonExternalTeamCompanies(personId)).then((result) => {
    const rels = Array.isArray(result.body) ? result.body : [];
    return rels;
  });
  return Promise.all([peopleRelationships, groupRelationships]).then((results) => {
    const relationships = results[0].concat(results[1]);
    relationships.sort((a, b) => {
      const aEntity = a.group || a.person;
      const bEntity = b.group || b.person;
      return aEntity.name.localeCompare(bEntity.name);
    });
    return relationships;
  });
};

const ExternalTeamView = ({ styles, dispatch, entity, mergeCardData, refreshProfile }) => {
  const personId = entity._id;
  const [entityRelationships, setEntityRelationships] = useState([]);
  useEffect(() => {
    if (!personId) {
      return;
    }
    loadExternalTeamEntities(dispatch, personId).then((relationships) => {
      setEntityRelationships(relationships);
    });
  }, [personId, dispatch]);

  const columns = mergeCardData ? '' : styles.columns;
  const column = mergeCardData ? '' : styles.column;

  return (
    <div className={columns}>
      <div className={column}>
        <ExternalTeamEntityRelationships
          personId={personId}
          mergeCardData={mergeCardData}
          onSave={({ personRelationships, groupRelationships }) => {
            const hasError = (obj) => !!obj.error;
            if (
              personRelationships.creates.some(hasError) ||
              personRelationships.updates.some(hasError) ||
              groupRelationships.creates.some(hasError) ||
              groupRelationships.updates.some(hasError)
            ) {
              notify.show(`Submission Errors: Canceling Save`, 'warning');
              return;
            }

            const resultStatusValidator = (result) => {
              if (result.status !== 200 && result.status !== 201) {
                return Promise.reject({ message: `Status ${result.status}` });
              }
              return Promise.resolve();
            };

            const personRelationshipUpdater = ({ creates, updates, deletes }) => {
              if (!creates && !deletes && !updates) {
                //nothing to do, short-circuit
                return Promise.resolve();
              }
              return dispatch(updatePersonExternalTeamPeople(personId, creates, updates, deletes)).then(
                resultStatusValidator
              );
            };
            const updatePersonRelationships = personRelationshipUpdater(personRelationships);

            const groupRelationshipUpdater = ({ creates, updates, deletes }) => {
              if (!creates && !deletes && !updates) {
                //nothing to do, short-circuit
                return Promise.resolve();
              }
              return dispatch(updatePersonExternalTeamCompanies(personId, creates, updates, deletes)).then(
                resultStatusValidator
              );
            };
            const updateGroupRelationships = groupRelationshipUpdater(groupRelationships);

            return Promise.all([updatePersonRelationships, updateGroupRelationships])
              .then(() => {
                notify.show('External Relationships Saved', 'success');
              })
              .catch((error) => {
                const errorMessage = error.message;
                notify.show(`Error Saving: ${errorMessage}`, 'error');
              })
              .then(() => {
                loadExternalTeamEntities(dispatch, personId).then((relationships) => {
                  setEntityRelationships(relationships);
                  refreshProfile && refreshProfile();
                });
              });
          }}
          dispatch={dispatch}
          relationships={entityRelationships}
          onSearchEntity={async (searchText, options) => {
            const searches = [];
            if (options.people) {
              const result = search({
                query: searchText,
                filtersState: getFiltersFromTypes(options.types),
                searchIndex: process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX,
                hitsPerPage: 20,
              }).then((result) => {
                return result.hits.map((c) => {
                  return { _id: c._id, type: c.type, name: c.name, entityType: 'person' };
                });
              });

              searches.push(result);
            }
            if (options.groups) {
              const response = await getGroups(searchText);
              const companySearch = (response && response.data) || [];
              const filteredCompanySearch = companySearch
                ? companySearch
                    .filter((company) => {
                      return options.types.some((ct) => {
                        return company.type === ct;
                      });
                    })
                    .map((c) => {
                      return { _id: c._id, type: c.type, name: c.name, entityType: 'group' };
                    })
                : [];
              searches.push(filteredCompanySearch);
            }

            return Promise.all(searches).then((searchResults) => {
              const combinedResults = [];
              searchResults.forEach((results) => {
                combinedResults.push(...results);
              });
              combinedResults.sort((a, b) => a.name.localeCompare(b.name.localeCompare));
              return combinedResults;
            });
          }}
        />
      </div>
    </div>
  );
};

const withStyles = styled({
  columns: {
    display: 'flex',
    height: '100%',
    'flex-flow': 'row wrap',
    justifyContent: 'space-between',
  },
  column: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
});

export default withStyles(ExternalTeamView);
