import React from 'react';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { elements } from '@united-talent-agency/julius-frontend-components';

const determineGroupRef = ({ isVenue, group }) => {
  if (group) {
    return isVenue ? `/venue/${group._id}/` : `/company/${group._id}?view=team`;
  }
  return '';
};

const EntityRelationshipViewer = ({ relationship = {}, styles = {} }) => {
  const groupHref = determineGroupRef(relationship);
  return (
    <>
      <div className={styles.container}>
        <div className={classnames(styles.typeColumn, relationship.inverse ? '' : styles.toRelationship)}>
          {relationship.type}
        </div>
        {relationship.person && (
          <div className={styles.companyColumn}>
            <a href={`/profile/${relationship.person._id}?view=team`} className={styles.link}>
              {relationship.person.name}
            </a>
          </div>
        )}
        {relationship.group && (
          <div className={styles.companyColumn}>
            <a href={groupHref} className={styles.link}>
              {relationship.group.name}
            </a>
          </div>
        )}
      </div>
      <hr className={styles.divider} />
    </>
  );
};

const withStyles = styled({
  container: { display: 'flex', flex: 1, flexDirection: 'row', fontWeight: 400, color: '#4A4A4A', fontSize: '12px' },
  typeColumn: { flex: 0.5, paddingRight: 5, paddingBottom: 10 },
  toRelationship: { fontWeight: 300 },
  companyColumn: { flex: 0.5, paddingRight: 5, paddingBottom: 10, textAlign: 'right' },
  divider: { marginTop: 0, marginBottom: 10 },
  link: elements.link,
});

export default withStyles(EntityRelationshipViewer);
