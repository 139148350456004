import React, { useEffect, useState } from 'react';
import { TerritoriesCard } from '../../../../components/territories-card/TerritoriesCard';
import TerritoriesCardWrapper from '../../../../components/territories-card/TerritoriesCardWrapper';
import { LoanoutsCard } from '../../../../components/territories-card/LoanoutsCard';
import { ColumnWrapper, ColumnsWrapper } from '../../../../styles/components/components';
import NetsuiteCard from '../../../../components/netsuite-card/NetsuiteCard';
import {
  getGstHstNumbers,
  getTaxIds,
  getVatNumbers,
  getLoanouts,
  updateGstHstNumbers,
  updateTaxIds,
  updateVatNumbers,
  updateLoanouts,
  getAgencyCommissionRates,
  updateAgencyCommissionRates,
} from '../../../../api/territory-data';

export const FinanceView = ({ company, person, mergeCardData, setFinance, refreshProfile, blockEdit }) => {
  const [taxIds, setTaxIds] = useState(null);
  const [vatNumbers, setVatNumbers] = useState(null);
  const [gstHstNumbers, setGstHstNumbers] = useState(null);
  const [loanouts, setLoanouts] = useState(null);
  const [agencyCommissionRates, setAgencyCommissionRates] = useState(null);
  const entity = person || company;
  const entityType = (person && 'person') || (company && 'company');
  const entityId = entity && entity._id;

  const showFinanceCards = ['Client', 'Industry Contact'].includes(entity.type) || null;

  useEffect(() => {
    if (showFinanceCards && entityId) {
      // Doing cheap validation, if these fail, the data remains null and we won't show the cards
      // This will prevent the user from overriding good data if no data came back from the get request
      getTaxIds(entityType, entityId).then(setTaxIds);
      getVatNumbers(entityType, entityId).then(setVatNumbers);
      getGstHstNumbers(entityType, entityId).then(setGstHstNumbers);
      getLoanouts(entityType, entityId).then(setLoanouts);
      getAgencyCommissionRates(entityType, entityId).then((resp) => {
        setAgencyCommissionRates(resp);
        mergeCardData && setFinance({ commission: resp });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, entityId, entityType, showFinanceCards]);

  const netsuiteMerge = mergeCardData ? { ...mergeCardData.netsuite, primary: mergeCardData.primary } : null;
  const loanoutsMerge = mergeCardData ? { ...mergeCardData.loanouts, primary: mergeCardData.primary } : null;
  const gstHstMerge = mergeCardData ? { ...mergeCardData.gstHst, primary: mergeCardData.primary } : null;
  const taxIdsMerge = mergeCardData ? { ...mergeCardData.taxIds, primary: mergeCardData.primary } : null;
  const commissionMerge = mergeCardData ? { ...mergeCardData.commission, primary: mergeCardData.primary } : null;
  const vatMerge = mergeCardData ? { ...mergeCardData.vat, primary: mergeCardData.primary } : null;

  const showLoanouts = !mergeCardData || !mergeCardData.loanouts.isEmpty || !!loanouts?.length;
  const showGstHst = !mergeCardData || !mergeCardData.gstHst.isEmpty || !!gstHstNumbers?.length;
  const showTaxIds = !mergeCardData || !mergeCardData.taxIds.isEmpty || !!taxIds?.length;
  const showCommission = !mergeCardData || !mergeCardData.commission.isEmpty || !!agencyCommissionRates?.length;
  const showVat = !mergeCardData || !mergeCardData.vat.isEmpty || !!vatNumbers?.length;

  return (
    <ColumnsWrapper ignoreCols={mergeCardData}>
      <ColumnWrapper ignoreCols={mergeCardData}>
        <NetsuiteCard company={company} person={person} mergeCardData={netsuiteMerge} blockEdit={blockEdit} />
        {showTaxIds && (
          <TerritoriesCardWrapper conditional={taxIds} title="Tax IDs" mergeData={taxIdsMerge}>
            <TerritoriesCard
              id="tax-id"
              mergeCardData={taxIdsMerge}
              title="Tax IDs"
              individualItemTitle="Tax ID"
              textFieldTitle="Tax ID"
              territoriesData={taxIds}
              blockEdit={blockEdit}
              saveData={(data) =>
                updateTaxIds(entityType, entityId, data).then(() => {
                  setTaxIds(data);
                  refreshProfile && refreshProfile();
                })
              }
            />
          </TerritoriesCardWrapper>
        )}
        {showLoanouts && (
          <TerritoriesCardWrapper conditional={loanouts} title="Loanouts" mergeData={loanoutsMerge}>
            <LoanoutsCard
              entityType={entityType}
              blockEdit={blockEdit}
              mergeCardData={loanoutsMerge}
              id="loanouts"
              title="Loanouts"
              individualItemTitle="Loanout"
              textFieldTitle="Loanout"
              territoriesData={loanouts}
              saveData={(data = []) => {
                const massagedData = data.map((datum) => {
                  const loanoutRelationship = {
                    primary: datum.primary || false,
                    territory: datum.territory,
                    relationshipType: datum.relationshipType,
                  };
                  // this else/if puts the reference in the right slot
                  if (datum.groupId) {
                    if (!datum.groupId.designateType) {
                      loanoutRelationship.groupId = datum.groupId._id;
                    }
                    if (datum.groupId.designateType === 'Person') {
                      loanoutRelationship.personId = (datum.personId || {})._id || datum.groupId._id;
                      delete loanoutRelationship.groupId;
                    }
                    if (datum.groupId.designateType === 'Company') {
                      loanoutRelationship.groupId = (datum.groupId || {})._id || datum.personId._id;
                      delete loanoutRelationship.personId;
                    }
                  } else if (datum.personId) {
                    if (!datum.personId.designateType) {
                      loanoutRelationship.personId = datum.personId._id;
                    }
                    if (datum.personId.designateType === 'Person') {
                      loanoutRelationship.personId = (datum.personId || {})._id || datum.groupId._id;
                      delete loanoutRelationship.groupId;
                    }
                    if (datum.personId.designateType === 'Company') {
                      loanoutRelationship.groupId = (datum.personId || {})._id || datum.personId._id;
                      delete loanoutRelationship.personId;
                    }
                  }
                  return loanoutRelationship;
                });
                updateLoanouts(entityType, entityId, massagedData)
                  .then((res) => setLoanouts(res))
                  .catch((err) => console.log(err));
              }}
            />
          </TerritoriesCardWrapper>
        )}
      </ColumnWrapper>
      <ColumnWrapper ignoreCols={mergeCardData}>
        {showVat && (
          <TerritoriesCardWrapper conditional={vatNumbers} title="VAT Numbers" mergeData={vatNumbers}>
            <TerritoriesCard
              id="vat-number"
              mergeCardData={vatMerge}
              title="VAT Numbers"
              individualItemTitle="VAT Number"
              textFieldTitle="VAT Number"
              territoriesData={vatNumbers}
              blockEdit={blockEdit}
              saveData={(data) =>
                updateVatNumbers(entityType, entityId, data).then(() => {
                  setVatNumbers(data);
                  refreshProfile && refreshProfile();
                })
              }
            />
          </TerritoriesCardWrapper>
        )}
        {showGstHst && (
          <TerritoriesCardWrapper conditional={gstHstNumbers} title="GST/HST Numbers" mergeData={gstHstMerge}>
            <TerritoriesCard
              id="ght-hst-number"
              mergeCardData={gstHstMerge}
              title="GST/HST Numbers"
              individualItemTitle="GST/HST Number"
              textFieldTitle="GST/HST Number"
              territoriesData={gstHstNumbers}
              blockEdit={blockEdit}
              saveData={(data) =>
                updateGstHstNumbers(entityType, entityId, data).then(() => {
                  setGstHstNumbers(data);
                  refreshProfile && refreshProfile();
                })
              }
            />
          </TerritoriesCardWrapper>
        )}
        {showCommission && (
          <TerritoriesCardWrapper
            conditional={entity.type === 'Client' && entityType === 'person' && agencyCommissionRates}
            title="Agency Commission Rates"
            mergeData={commissionMerge}
          >
            <TerritoriesCard
              id="agency-commission-rates"
              mergeCardData={commissionMerge}
              title="Agency Commission Rates"
              individualItemTitle="Agency Commission Rate"
              textFieldTitle="Agency Commission Rate %"
              includeNotes
              numberValues
              displayPercent
              territoriesData={agencyCommissionRates}
              blockEdit={blockEdit}
              saveData={(data) =>
                updateAgencyCommissionRates(entityType, entityId, data).then(() => {
                  setAgencyCommissionRates(data);
                  refreshProfile && refreshProfile();
                })
              }
            />
          </TerritoriesCardWrapper>
        )}
      </ColumnWrapper>
    </ColumnsWrapper>
  );
};
