import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import {
  colors,
  FormArray,
  FormContext,
  Icons,
  TextButton,
  RHF,
  SingleSelectDropdown,
} from '@united-talent-agency/components';
import { buttonTypes, PersonInput } from '@united-talent-agency/julius-frontend-components';

import { sectionEditData } from './ReadViewSupport';
import { Territory, AgentType } from './utils/const';
import { CardButton, CardHeader, CardPane, CardTitle } from '../card/Card';
import { TerritoriesSelector } from './TerritoriesSelector';
import cypressTags from '../../support/cypressTags';
import { ComedyClientPreChecks } from './utils/ComedyPreChecks';
import { MusicianPreChecks } from './utils/MusicianPreChecks';
import search from '../../support/algolia';

const { useForm } = RHF;
const { PlusIcon, XCloseIcon } = Icons;

const { COMMON } = cypressTags;

export const TouringAgentEditor = ({
  territoriesData,
  title,
  individualItemTitle,
  textFieldTitle,
  setReadMode,
  agentTypes,
  saveData,
  isClientComedy,
  id,
}) => {
  const [errorCheck, setErrorCheck] = useState({ save: false, error: '' });
  const defaultTerritories = useExistingTerritories(territoriesData);
  const [territoriesCategory, setTerritoriesCategory] = useState(
    defaultTerritories.has(Territory.AllTerritories) ? 'All' : 'Itemized'
  );
  const [selectedTerritories, setSelectedTerritories] = useState(
    new Set([
      Territory.RestOfWorld,
      Territory.Canada,
      Territory.LatinAmerica,
      Territory.Scandinavia,
      Territory.UnitedStates,
    ])
  );

  const [territoriesError, setTerritoriesError] = useState('');
  const [touringAgentData, setTouringAgentData] = useState(territoriesData);

  // Clear error when territories change
  useEffect(() => {
    setTerritoriesError('');
    setErrorCheck({ save: false, error: '' });
  }, [territoriesCategory, selectedTerritories]);

  const formProps = useForm({
    mode: 'onBlur',
    defaultValues: sectionEditData(territoriesData),
  });
  const { handleSubmit, setValue } = formProps;

  const showTerritories = territoriesCategory === 'All' ? new Set([Territory.AllTerritories]) : selectedTerritories;
  const orderedTerritoriesList = Object.values(Territory).filter((territory) => showTerritories.has(territory));

  const onSubmit = async (values) => {
    // Filter only selected territories and flatten the array
    const newData = [];
    orderedTerritoriesList.forEach((territory) => {
      const territoryDataList = values[territory]?.map((territoryData) => ({ ...territoryData, regions: [territory] }));
      if (territoryDataList) {
        newData.push(...territoryDataList);
      }
    });

    if (!isClientComedy) {
      MusicianPreChecks({ newData, territoriesCategory, selectedTerritories, saveData, setReadMode, handleError });
    } else if (isClientComedy) {
      const comedyClientData = newData.map((ds) => {
        return {
          ...ds,
          type: ds.type === 'Papering Agent' ? 'Responsible Agent' : ds.type,
        };
      });
      ComedyClientPreChecks({
        newData: comedyClientData,
        territoriesCategory,
        selectedTerritories,
        saveData,
        setReadMode,
        handleError,
      });
    }
  };

  const handleError = ({ save, error }) => {
    setErrorCheck({ save, error });
  };

  function selectRow(
    territory,
    agentTypes,
    setValue,
    touringAgentData,
    handleErrorCheck,
    handleTouringAgentData,
    isClientComedy
  ) {
    let selectedRow = TouringAgentRow(
      territory,
      agentTypes,
      setValue,
      touringAgentData,
      handleErrorCheck,
      handleTouringAgentData,
      isClientComedy
    );
    return selectedRow;
  }

  const handleTouringAgentData = (selectedRegion, idx) => {
    setTouringAgentData([...touringAgentData.filter((ds) => ds._id !== selectedRegion[idx]?._id)]);
  };

  const handleErrorCheck = (val) => {
    setErrorCheck(val);
  };

  return (
    <FormContext {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardPane id={id}>
          <CardHeader isEditing>
            <CardTitle>{title}</CardTitle>
            <div data-cy={COMMON.CANCEL_BUTTON}>
              <CardButton type={buttonTypes.cancel} onClick={setReadMode} />
            </div>
            <div data-cy={COMMON.SAVE_BUTTON}>
              <CardButton type={buttonTypes.save} onClick={handleSubmit(onSubmit)} />
            </div>
          </CardHeader>
          <TerritoryCardBody>
            <TerritoriesSelector
              territoriesCategory={territoriesCategory}
              setTerritoriesCategory={setTerritoriesCategory}
              selectedTerritories={selectedTerritories}
              setSelectedTerritories={setSelectedTerritories}
              territoriesError={territoriesError}
            />
            {errorCheck && errorCheck.save && errorCheck['error']?.length > 0 && (
              <ErrorPanel>{errorCheck['error']}</ErrorPanel>
            )}
            {orderedTerritoriesList.map((territory) => (
              <FormArray
                key={territory}
                name={territory}
                territory={territory}
                individualItemTitle={individualItemTitle}
                textFieldTitle={textFieldTitle}
                requireOne={false}
                itemForm={selectRow(
                  territory,
                  agentTypes,
                  setValue,
                  touringAgentData,
                  handleErrorCheck,
                  handleTouringAgentData,
                  isClientComedy
                )}
                wrapper={TerritoryWrapper}
                noPrimary={true}
              />
            ))}
          </TerritoryCardBody>
        </CardPane>
      </form>
    </FormContext>
  );
};

// Get a set of all the territories in the data
const useExistingTerritories = (territoriesData) => {
  return useMemo(() => {
    const territories = new Set(territoriesData.map(({ regions }) => regions[0]));

    // If empty or AllTerritories, then ensure only AllTerritories
    if (!territories.size || territories.has(Territory.AllTerritories)) return new Set([Territory.AllTerritories]);

    // Ensure RestOfWorld
    return territories.add(Territory.RestOfWorld);
  }, [territoriesData]);
};

const TerritoryWrapper = ({ children, append, territory = Territory.AllTerritories }) => {
  return (
    <div>
      <TerritorySeparator />
      <TerritoryHeader>{territory}</TerritoryHeader>
      <div>{children}</div>
      <div style={{ paddingLeft: '5px', marginTop: 10 }}>
        <TextButton
          text={`New`}
          icon={PlusIcon}
          fontSize={14}
          onMouseDown={() => {
            append({
              territory,
              label: '',
            });
          }}
        />
      </div>
    </div>
  );
};

const IndividualTouringAgentRow = ({
  idx,
  remove,
  territory,
  entryKey,
  agentTypes,
  setValue,
  touringAgentData,
  handleErrorCheck,
  handleTouringAgentData,
  isClientComedy,
}) => {
  const [searchText, setSearchText] = useState('');
  const [peopleResults, setPeopleResults] = useState([]);

  const selectedRegion = touringAgentData.filter((ds) => ds.regions[0] === territory);

  let agentOptions = [];
  let defaultAgents = {};

  if (isClientComedy) {
    agentOptions = agentTypes
      .filter((ds) => ds === AgentType.ResponsibleAgent || ds === AgentType.ContractAdministrator)
      .map((name) => ({ value: name, label: name }));
    defaultAgents =
      selectedRegion[idx]?.type === AgentType.PaperingAgent
        ? { label: AgentType.ResponsibleAgent, value: AgentType.ResponsibleAgent }
        : { label: selectedRegion[idx]?.type, value: selectedRegion[idx]?.type };
  } else {
    agentOptions = agentTypes
      .filter(
        (ds) =>
          ds === AgentType.PaperingAgent || ds === AgentType.ResponsibleAgent || ds === AgentType.ContractAdministrator
      )
      .map((name) => ({ value: name, label: name }));
    defaultAgents = { label: selectedRegion[idx]?.type, value: selectedRegion[idx]?.type };
  }
  const onSearch = async (searchText) => {
    const data = await search({
      query: searchText,
      filtersState: {
        includeEmployee: true,
      },
      searchIndex: process.env.REACT_APP_ALGOLIA_PEOPLE_INDEX,
      hitsPerPage: 20,
    });

    return data.hits;
  };

  return (
    <TerritoryRowContainer>
      <span style={{ display: 'flex', flexDirection: 'column', width: 380 }}>
        <div style={{ marginBottom: 10 }}>
          <SingleSelectDropdown
            title="Agent Type"
            defaultValue={defaultAgents}
            required
            options={agentOptions}
            onChange={(data) => {
              setValue(`${entryKey}.type`, data.value);
              handleErrorCheck({ save: false, error: '' });
            }}
          />
        </div>
        <PersonInput
          style={{
            marginBottom: 10,
            fontWeight: 100,
          }}
          value={searchText || (selectedRegion[idx]?.personId || {}).name || ''}
          required
          onChange={(name) => {
            setSearchText(name);
            onSearch && onSearch(name).then(setPeopleResults);
          }}
          results={peopleResults}
          onSelect={(personId) => {
            setSearchText(personId.name);
            setValue(`${entryKey}.personId`, { name: personId.name, _id: personId._id });
            handleErrorCheck({ save: false, error: '' });
          }}
        />
      </span>
      <XCloseIcon
        color={colors.cinnabar}
        width={18}
        height={18}
        onMouseDown={() => {
          handleErrorCheck({ save: false, error: '' });
          handleTouringAgentData(selectedRegion, idx);
          remove();
          return;
        }}
        style={{ cursor: 'pointer', marginTop: '10px', width: '48px' }}
      />
    </TerritoryRowContainer>
  );
};

const TouringAgentRow = (
  territory,
  agentTypes,
  setValue,
  touringAgentData,
  handleErrorCheck,
  handleTouringAgentData,
  isClientComedy
) => {
  // eslint-disable-next-line react/display-name
  return ({ remove, idx }) => {
    return (
      <IndividualTouringAgentRow
        idx={idx}
        territory={territory}
        agentTypes={agentTypes}
        remove={remove}
        entryKey={`${territory}.${idx}`}
        setValue={setValue}
        touringAgentData={touringAgentData}
        handleErrorCheck={handleErrorCheck}
        handleTouringAgentData={handleTouringAgentData}
        isClientComedy={isClientComedy}
      />
    );
  };
};

const ErrorPanel = styled.div`
  font-size: 14px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  color: red;
`;

export const TerritoryCardBody = styled.div({
  padding: '5px 15px',
  display: 'flex',
  flexDirection: 'column',
});

const TerritoryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  margin-bottom: 10px;
`;

const TerritoryHeader = styled.div`
  margin-left: 0px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #141414;
`;
const TerritorySeparator = styled.hr`
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
`;
