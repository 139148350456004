import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { isString } from 'lodash';
import { createVenue } from '../../api/venues';

import BasicInfoCard from '../venue/views/profile/BasicInfoCard';
import CapacityCard from '../venue/views/profile/CapacityCard';
import WebsiteCard from '../venue/views/profile/WebsiteCard';
import AddressesCard from '../../components/addresses-card/AddressesCard';
import { SaveButton } from '../../components/save-button/SaveButton';
import cypressTags from '../../support/cypressTags';
import ContactsCard from '../../components/venue-contacts-card/VenueContactsCard';
import { phoneContactFilter, emailContactFilter, otherContactFilter } from '../../support/contacts';

import {
  PageBody,
  Columns,
  Column,
  PageHeader,
  TitleContainer,
  Name,
  PageContainer,
  PageContent,
} from '../../styles/components/components';
import { FormValidationContainer } from '../../support/FormContext';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped';
const { VENUE } = cypressTags;

const CreateVenueView = (props) => {
  const { history } = props;
  const [newVenue, setNewVenue] = useState({});
  const [validCards, setValidCards] = useState({ basicInfo: false, addresses: false, capacity: false });
  const [showAllValidationErrors, setShowAllValidationErrors] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [emailContacts, setEmailContacts] = useState([]);
  const [otherContacts, setOtherContacts] = useState([]);

  const hasErrors = Object.values(validCards).some((valid) => !valid);

  useEffect(() => {
    if (!hasErrors && showAllValidationErrors) {
      // User cleared up existing errors, we can clear this flag
      setShowAllValidationErrors(false);
    }
  }, [hasErrors, showAllValidationErrors]);

  const saveVenue = async () => {
    if (hasErrors) {
      setShowAllValidationErrors(true);
      return;
    }

    // preserve a copy of new venue. If there are errors saving we can revert back without losing data
    const newVenueCopy = Object.assign({}, newVenue);
    const { websites = [] } = newVenue;

    // at least one website must be designated as primary
    if (websites.length && !websites.some((website) => website.primary)) websites[0].primary = true;
    newVenue.websites = websites.filter(({ type, link }) => type && link);

    // TODO: the onChange should handle the conversion to a list if its not one
    if (newVenue.seatingType && isString(newVenue.seatingType)) {
      newVenue.seatingType = newVenue.seatingType.split(',');
    }

    const venueData = { ...newVenue, contacts: [...phoneContacts, ...emailContacts, ...otherContacts] };

    const response = await createVenue(venueData);
    const newlyCreatedId = response?.body?._id;
    if ([200, 201].includes(response.status) && newlyCreatedId) {
      notify.show('Venue created', 'success');
      history.push(`/venue/${newlyCreatedId}`);
    } else {
      setNewVenue(newVenueCopy);
      const errorMsg = (response.body && response.body.message) || '';
      if (errorMsg.addresses) {
        setApiError(errorMsg.addresses);
      } else {
        // saving this, although errors from API need to be  addressed
        notify.show(`Error saving venue: ${errorMsg}`, 'error');
      }
    }
  };

  return (
    <PageContainer>
      <Helmet>
        <title>CREATE VENUE</title>
      </Helmet>
      <BrandingWrapped />
      <PageHeader>
        <TitleContainer>
          <Name>Create Venue</Name>
          <SaveButton onSave={saveVenue} hasErrors={hasErrors} />
          <div style={{ padding: '28px 0px' }}></div>
        </TitleContainer>
      </PageHeader>
      <PageBody>
        <PageContent>
          <FormValidationContainer showAllValidationErrors={showAllValidationErrors}>
            <Columns>
              <Column>
                <BasicInfoCard
                  venue={newVenue}
                  cyTag={VENUE.BASIC_INFO_CARD}
                  isRequired
                  onChange={(saveInfo) => {
                    setValidCards({ ...validCards, basicInfo: !saveInfo.hasError });
                    setNewVenue({ ...newVenue, ...saveInfo.mergedData[0] });
                  }}
                />

                <CapacityCard
                  venue={newVenue}
                  onChange={(saveInfo) => {
                    const capacity = saveInfo.mergedData[0]?.capacity;
                    setNewVenue({ ...newVenue, capacity });
                    setValidCards({ ...validCards, capacity: !saveInfo.hasError });
                  }}
                />
              </Column>
              <Column>
                <ContactsCard
                  venue={newVenue}
                  canSetPrimary
                  title="Phone Contacts"
                  contactTypeFilter={phoneContactFilter}
                  onChange={(saveInfo) => {
                    setPhoneContacts(saveInfo.mergedData);
                    setValidCards({ ...validCards, 'phone-contacts': !saveInfo.hasError });
                  }}
                />
                <ContactsCard
                  venue={newVenue}
                  canSetPrimary
                  title="Email Contacts"
                  contactTypeFilter={emailContactFilter}
                  onChange={(saveInfo) => {
                    setOtherContacts(saveInfo.mergedData);
                    setValidCards({ ...validCards, 'email-contacts': !saveInfo.hasError });
                  }}
                />
                <ContactsCard
                  venue={newVenue}
                  canSetPrimary
                  title="Other Contacts"
                  contactTypeFilter={otherContactFilter}
                  onChange={(saveInfo) => {
                    setEmailContacts(saveInfo.mergedData);
                    setValidCards({ ...validCards, 'other-contacts': !saveInfo.hasError });
                  }}
                />
                <AddressesCard
                  entity={newVenue}
                  validateAddresses
                  requirePrimary
                  firstIsPrimary
                  apiError={apiError}
                  onChange={(saveInfo) => {
                    for (let data of Object.values(saveInfo.mergedData)) {
                      delete data.errors;
                    }
                    if (apiError) setApiError('');
                    setNewVenue({ ...newVenue, ...{ addresses: saveInfo.mergedData } });
                    setValidCards({ ...validCards, addresses: !saveInfo.hasError });
                  }}
                />
                <WebsiteCard
                  venue={newVenue}
                  onChange={(saveInfo) => {
                    setNewVenue({ ...newVenue, ...{ websites: saveInfo.mergedData } });
                    setValidCards({ ...validCards, websites: !saveInfo.hasError });
                  }}
                />
              </Column>
            </Columns>
          </FormValidationContainer>
        </PageContent>
      </PageBody>
    </PageContainer>
  );
};

export default withRouter(CreateVenueView);
