import {
  loadPerson,
  getInternalTeamCompanyRelationships,
  getInternalTeamPersonRelationships,
} from '@united-talent-agency/julius-frontend-store';

export const loadInternalTeamEntities = (dispatch, entityId, type) => {
  const getGroups =
    type === 'GROUP'
      ? dispatch(getInternalTeamCompanyRelationships({ companyId: entityId })).then((result) => {
          const rels = Array.isArray(result.body) ? result.body : [];
          return rels;
        })
      : [];

  const getPeople =
    type === 'PERSON'
      ? dispatch(getInternalTeamPersonRelationships({ personId: entityId })).then((result) => {
          const rels = Array.isArray(result.body) ? result.body : [];
          return rels;
        })
      : [];

  return Promise.all([getPeople, getGroups]).then((results) => {
    let relationships = results[0].concat(results[1]);
    return relationships
      .filter((relation) => {
        return relation && relation.utaEmployee;
      })
      .sort((a, b) => a?.utaEmployee?.name.localeCompare(b?.utaEmployee?.name));
  });
};

export const loadEmployeeAssistants = (dispatch, personId) => {
  return dispatch(loadPerson(personId)).then((result) => {
    if (result?.body?.assistants?.length) {
      return result.body.assistants.map((assistant) => assistant?.person);
    } else {
      return [];
    }
  });
};
