import React from 'react';
import { notify } from 'react-notify-toast';
import BasicInfoCard from './BasicInfoCard';

import WebsiteCard from './WebsiteCard';
import CapacityCard from './CapacityCard';

import AddressesCard from '../../../../components/addresses-card/AddressesCard';

import { Column, Columns } from '../../../../styles/components/components';
import { updateVenue } from '../../../../api/venues';
import cypressTags from '../../../../support/cypressTags';
import ContactsCard from '../../../../components/venue-contacts-card/VenueContactsCard';
import { phoneContactFilter, emailContactFilter, otherContactFilter } from '../../../../support/contacts';

export default function ProfileView({ venue, blockEdit, mergeCardData }) {
  const phoneMerge = mergeCardData ? { ...mergeCardData.phone, primary: mergeCardData.primary } : null;
  const emailMerge = mergeCardData ? { ...mergeCardData.email, primary: mergeCardData.primary } : null;
  const otherMerge = mergeCardData ? { ...mergeCardData.other, primary: mergeCardData.primary } : null;

  return (
    <Columns data-cy={cypressTags.VENUE.PROFILE_VIEW}>
      <Column>
        <BasicInfoCard venue={venue} cyTag={cypressTags.VENUE.BASIC_INFO_CARD} blockEdit={blockEdit} />
        <CapacityCard venue={venue} blockEdit={blockEdit} cyTag={cypressTags.VENUE.CAPACITY_CARD} />
      </Column>
      <Column>
        <ContactsCard
          venue={venue}
          canSetPrimary
          title="Phone Contacts"
          blockEdit={blockEdit}
          contactTypeFilter={phoneContactFilter}
          mergeCardData={phoneMerge}
        />
        <ContactsCard
          venue={venue}
          canSetPrimary
          title="Email Contacts"
          blockEdit={blockEdit}
          contactTypeFilter={emailContactFilter}
          mergeCardData={emailMerge}
        />
        <ContactsCard
          venue={venue}
          canSetPrimary
          title="Other Contacts"
          blockEdit={blockEdit}
          contactTypeFilter={otherContactFilter}
          mergeCardData={otherMerge}
        />
        <AddressesCard
          entity={venue}
          validateAddresses
          requirePrimary
          firstIsPrimary
          blockEdit={blockEdit}
          onSave={(saveInfo) => {
            const addresses = Object.values(saveInfo.mergedData);
            for (let data of addresses) {
              delete data.errors;
            }
            return updateVenue({ venueId: venue._id, updates: { addresses } }).then((resp) => {
              if (resp.status !== 200 && resp.status !== 201) {
                const errorMsg = (resp.body && resp.body.errors) || '';
                if (errorMsg.addresses) {
                  notify.show(`Error saving venue: ${errorMsg.addresses}`, 'error');
                }
              }
            });
          }}
        />

        <WebsiteCard cyTag={cypressTags.VENUE.WEBSITE_CARD} venue={venue} blockEdit={blockEdit} />
      </Column>
    </Columns>
  );
}
