import React from 'react';
import { isEmpty } from 'lodash';

const displayGuaranteeChanges = ({ subKey, changes }) => {
  return (
    <>
      {changes['created'] &&
        changes['created'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Guarantee: '}</strong>}
            {'Type: '}
            {change.territory && change.territory}
            {'; Minimum: '}
            {change.minimum && change.minimum}
            {'; Maximum: '}
            {change.maximum && change.maximum}
            {' was created'}
          </div>
        ))}
      {changes['updated'] &&
        changes['updated'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Guarantee: '}</strong>}
            {'Type: '}
            {change.territory && change.territory}
            {'; Minimum: '}
            {change?.minimum && Array.isArray(change?.minimum) && change?.minimum.length > 0
              ? change?.minimum[0]
              : change?.minimum}
            {'; Maximum: '}
            {Array.isArray(change?.maximum)
              ? change?.maximum[0]
              : Array.isArray(change?.maximum)
              ? change?.maximum[0]
              : change?.maximum}
            {' was changed to '}
            {'Type: '}
            {change.territory && change.territory}
            {'; Minimum: '}
            {change?.minimum && Array.isArray(change?.minimum) && change?.minimum.length > 0
              ? change?.minimum[1]
              : change?.minimum}
            {'; Maximum: '}
            {Array.isArray(change?.maximum)
              ? change?.maximum[1]
              : Array.isArray(change?.maximum)
              ? change?.maximum[1]
              : change?.maximum}
          </div>
        ))}
      {changes['deleted'] &&
        changes['deleted'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Guarantee: '}</strong>}
            {'Type: '}
            {change.territory && change.territory}
            {'; Minimum: '}
            {change?.minimum && change?.minimum}
            {'; Maximum: '}
            {change?.maximum}
            {' was deleted '}
          </div>
        ))}
    </>
  );
};

const handleGuarenteeChange = ({ territoryInfo, auditChangeObject, subKey, AUDIT_LABEL_DISPLAY }) => {
  const { guarenteeNames } = territoryInfo;
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[subKey];
  let touringInfoHistory = [];
  const guaranteeTerritory = Object.keys(guarenteeNames);

  const changes = {};
  if (guaranteeTerritory.length > 0) {
    guaranteeTerritory.forEach((territory) => {
      const dsKeys = Object.keys(guarenteeNames[territory]);
      dsKeys.forEach((dsKey) => {
        if (Array.isArray(guarenteeNames[territory][dsKey]) && guarenteeNames[territory][dsKey].length === 1) {
          if (!changes['created']) {
            changes['created'] = [];
          }
          changes['created'].push({
            minimum: guarenteeNames[territory][dsKey][0].minimum,
            maximum: guarenteeNames[territory][dsKey][0].maximum,
            territory: guarenteeNames[territory][dsKey][0].territory,
          });
        } else if (
          typeof guarenteeNames[territory][dsKey] === 'object' &&
          !Array.isArray(guarenteeNames[territory][dsKey]) &&
          guarenteeNames.changeType !== 'Deleted'
        ) {
          // agent updated
          if (!changes['updated']) {
            changes['updated'] = [];
          }
          if (
            guarenteeNames[territory][dsKey]?.minimum ||
            guarenteeNames[territory][dsKey]?.maximum ||
            guarenteeNames[territory][dsKey]?.territory
          ) {
            changes['updated'].push({
              minimum: guarenteeNames[territory][dsKey]?.minimum && guarenteeNames[territory][dsKey]?.minimum,
              maximum: guarenteeNames[territory][dsKey]?.maximum && guarenteeNames[territory][dsKey]?.maximum,
              territory: guarenteeNames[territory][dsKey]?.territory && guarenteeNames[territory][dsKey]?.territory,
            });
          }
        } else if (
          typeof guarenteeNames[territory][dsKey] === 'object' &&
          !Array.isArray(guarenteeNames[territory][dsKey]) &&
          guarenteeNames.changeType === 'Deleted'
        ) {
          // agent deleted
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          if (guarenteeNames[dsKey]?.personId || guarenteeNames[dsKey]?.type || guarenteeNames[dsKey]?.regions) {
            changes['deleted'].push({
              minimum: guarenteeNames[dsKey]?.minimum && guarenteeNames[dsKey]?.minimum,
              maximum: guarenteeNames[dsKey]?.maximum && guarenteeNames[dsKey]?.maximum,
              territory: guarenteeNames[dsKey]?.territory && guarenteeNames[dsKey]?.territory,
            });
          }
        } else if (
          Array.isArray(guarenteeNames[territory][dsKey]) &&
          guarenteeNames[territory][dsKey].length === 3 &&
          guarenteeNames[territory][dsKey][2] === 0
        ) {
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          changes['deleted'].push({
            minimum: guarenteeNames[territory][dsKey][0].minimum,
            maximum: guarenteeNames[territory][dsKey][0].maximum,
            territory: guarenteeNames[territory][dsKey][0].territory,
          });
        }
      });
    });

    touringInfoHistory.push(
      <>
        {displayGuaranteeChanges({
          subKey,
          changes,
        })}
      </>
    );
    if (!isEmpty(changes)) {
      auditChangeObject[auditLabelDisplay] = touringInfoHistory;
    }
  }
};

export default handleGuarenteeChange;
