import { datadogRum } from '@datadog/browser-rum';

const HOST_MAP = {
  'contact.unitedtalent.com': 'prod',
  'contacts.unitedtalent.com': 'prod',
  'contact.staging.unitedtalent.com': 'staging',
  'contacts.staging.unitedtalent.com': 'staging',
  'contact.qa.unitedtalent.com': 'qa',
  'contacts.qa.unitedtalent.com': 'qa',
  'contact.dev.unitedtalent.com': 'dev',
  'contacts.dev.unitedtalent.com': 'dev',
  localhost: 'local',
};

/**
 * Initializes data dog for the appropriate host (environment)
 *
 * @param { string } host:
 * @param { string[] } allowableEnvs: a list of allowable environments to track.
 */
export const initializeDataDog = (host, allowableEnvs) => {
  const env = HOST_MAP[host];
  if (allowableEnvs.includes(env)) {
    datadogRum.init({
      env,
      version: '0.3.1',
      service: 'profiles-united-talent',
      site: 'us3.datadoghq.com',
      applicationId: '797eae25-34ff-497f-966a-aec8cf350c6a',
      clientToken: 'pub0d46955d703d2eaedd21e4402ed4b547',
      allowedTracingOrigins: ['https://api.unitedtalent.com', /https:\/\/.*\.unitedtalent\.com/],
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
  }
};
