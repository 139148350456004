const addressTransformer = (a) => {
  return {
    street: (a && a.address) || '',
    city: (a && a.city) || '',
    state: (a && a.state) || '',
    postalCode: (a && a.zip) || '',
    countryOrRegion: (a && a.country) || '',
  };
};

const OutlookContactTransformer = (person) => {
  const displayName = person.name.trim();
  const fileAs = person.name.trim();
  const jobTitle = person.title ? person.title.trim() : '';
  const companyName = person.companyId ? person.companyId.name.trim() : '';
  const personalNotes = person.note ? person.note.trim() : '';
  const contactMobilePhone = person.contacts.find((c) => {
    return c.contactType === 'Mobile Phone';
  });
  const mobilePhone = contactMobilePhone ? contactMobilePhone.contact : '';
  const businessPhones = person.contacts
    .filter((c) => {
      return c.contactType === 'Office Phone';
    })
    .map((p) => {
      return p.contact;
    });
  const homePhones = person.contacts
    .filter((c) => {
      return c.contactType === 'Home Phone';
    })
    .map((p) => {
      return p.contact;
    });
  const emailAddresses = person.contacts
    .filter((a) => {
      return a.contactType === 'Email';
    })
    .map((p) => {
      return Object.assign({}, p.original);
    });
  const imAddresses = person.contacts
    .filter((a) => {
      return a.contactType === 'Message';
    })
    .map((p) => {
      return p.contact;
    });

  const businessAddress = addressTransformer(
    person.addresses.find((a) => {
      return a.type === 'Work';
    })
  );
  const homeAddress = addressTransformer(
    person.addresses.find((a) => {
      return a.type === 'Home';
    })
  );
  const otherAddress = addressTransformer(
    person.addresses.find((a) => {
      return a.type === 'Unknown';
    })
  );

  return {
    displayName,
    fileAs,
    jobTitle,
    companyName,
    mobilePhone,
    businessPhones,
    homePhones,
    emailAddresses,
    imAddresses,
    businessAddress,
    homeAddress,
    otherAddress,
    personalNotes,
  };
};
export default OutlookContactTransformer;
