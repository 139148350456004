import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'normalize.css/normalize.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-day-picker/lib/style.css';

// Import CSS before React components.

import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';

import { initializeDataDog } from './support/dataDog';
import { createStoreWithOptions } from '@united-talent-agency/julius-frontend-store';
import { OnyxThemeProvider } from '@united-talent-agency/components';

import Routes from './routes';

import { apiServerUrl } from './support/urls';
const store = createStoreWithOptions({ apiServerUrl });

/**
 * Data Dog RUM tracking initialization
 *
 * active host is interrogated against the allowable env flags using this map:
 *    "prod": profile.unitedtalent.com
 *    "staging": profile.staging.unitedtalent.com
 *    "dev": profile.dev.unitedtalent.com
 *    "local": localhost
 */
initializeDataDog(window.location.hostname, ['staging', 'prod']);

render(
  <AppContainer>
    <Provider store={store}>
      <OnyxThemeProvider>
        <Routes />
      </OnyxThemeProvider>
    </Provider>
  </AppContainer>,
  document.getElementById('app')
);
