import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { TabContext } from './TabContainer';
import cypressTags from '../../support/cypressTags';

export const Tab = ({ title, icon, to, exact }) => {
  const { open } = useContext(TabContext);

  return (
    <MenuLink to={to} exact={exact} open={open}>
      {icon}
      <Title open={open}>
        <span data-cy={cypressTags.COMMON.SIDENAV_TAB + title}>{title}</span>
      </Title>
    </MenuLink>
  );
};

const focusHelperColor = '#DFDFDB';

const activeClassName = 'nav-item-active';

// Title states:
//  - When open, always show title to the right
//  - When closed, show title under icon
const titleOpenStyle = {
  fontSize: '16px',
  marginLeft: '10px',
  whiteSpace: 'nowrap',
};
const titleClosedStyle = {
  fontSize: '10px',
  textAlign: 'center',
  lineHeight: '10px',
  padding: '4px 4px 0 4px',
};
const Title = styled.span(({ open }) => ({
  textDecoration: 'none',
  textTransform: 'capitalize',
  ...(open ? titleOpenStyle : titleClosedStyle),
}));

const menuOpenStyle = {
  borderRadius: '2px',
  padding: '0 16px',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  marginRight: '8px',
};

const menuClosedStyle = {
  borderRadius: '2px 0 0 2px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  // Only show the title on hover
  [`& > ${Title}`]: {
    display: 'none',
  },
  [`&:hover > ${Title}`]: {
    display: 'inline',
  },
};

export const MenuLink = styled(NavLink).attrs({
  activeClassName,
})(({ activeClassName, open }) => ({
  display: 'flex',
  color: '#494443',
  background: 'white',
  height: '50px',
  marginLeft: '8px',
  overflow: 'hidden',
  // Icon font size
  fontSize: '20px',
  '& svg': { flexShrink: 0 },

  '&:hover': {
    textDecoration: 'none',
    color: 'inherit',
    background: '#F5F5F5',
  },
  ...(open ? menuOpenStyle : menuClosedStyle),
  ['&.' + activeClassName]: {
    color: '#141414',
    background: focusHelperColor,
    '&:hover': {
      background: focusHelperColor,
    },
    ...(open && {
      [`& > ${Title}`]: {
        fontWeight: 'bold',
      },
    }),
  },
}));
