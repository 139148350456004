import React from 'react';
import { isEmpty } from 'lodash';

const displayBusinessNames = ({ subKey, changes }) => {
  return (
    <>
      {changes['created'] &&
        changes['created'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Business Names: '}</strong>}
            {change.territory === 'All Territories' ? 'All Territories' : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change.label && change.label}
            {'; Business: '}
            {change.groupId && change.groupId?.name}
            {' was created'}
            {change.primary && change.primary === true ? ' and is the Primary' : ''}
          </div>
        ))}
      {changes['updated'] &&
        changes['updated'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Business Names: '}</strong>}
            {change?.territory && Array.isArray(change?.territory) && change?.territory.length > 0
              ? change.territory[0] === 'All Territories'
                ? 'All Territories'
                : `Itemized Territories: ${change.territory[0]}`
              : change.territory === 'All Territories'
              ? 'All Territories'
              : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change?.label && Array.isArray(change?.label) && change?.label.length > 0
              ? change?.label[0]
              : change?.label}
            {'; Business: '}
            {Array.isArray(change.groupId?.name) ? change?.groupId?.name[0] : change?.groupId?.name}
            {' was changed to '}
            {change?.territory && Array.isArray(change?.territory) && change?.territory.length > 0
              ? change.territory[1] === 'All Territories'
                ? 'All Territories'
                : `Itemized Territories: ${change.territory[1]}`
              : change.territory === 'All Territories'
              ? 'All Territories'
              : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change?.label && Array.isArray(change?.label) && change?.label.length > 0
              ? change?.label[1]
              : change?.label}
            {'; Business: '}
            {Array.isArray(change.groupId?.name) ? change?.groupId?.name[1] : change?.groupId?.name}
            {change?.primary === true || (change?.primary?.length === 1 && change?.primary[0] === true)
              ? ' and is the Primary'
              : ''}
          </div>
        ))}
      {changes['deleted'] &&
        changes['deleted'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Business Names: '}</strong>}
            {change.territory === 'All Territories' ? 'All Territories' : `Itemized Territories: ${change.territory}`}
            {'; Label: '}
            {change?.label && change?.label}
            {'; Business: '}
            {change.groupId?.name}
            {' was deleted '}
          </div>
        ))}
    </>
  );
};

const handleBusinessNames = ({ territoryInfo, auditChangeObject, subKey, AUDIT_LABEL_DISPLAY }) => {
  const { businessNames = [] } = territoryInfo;
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[subKey];
  let touringInfoHistory = [];
  const businessNamesTerritory = Object.keys(businessNames);
  const changes = {};
  if (businessNamesTerritory.length > 0) {
    businessNamesTerritory.forEach((territory) => {
      const dsKeys = Object.keys(businessNames[territory]);
      dsKeys.forEach((dsKey) => {
        if (Array.isArray(businessNames[territory][dsKey]) && businessNames[territory][dsKey].length === 1) {
          if (!changes['created']) {
            changes['created'] = [];
          }
          changes['created'].push({
            label: businessNames[territory][dsKey][0].label,
            groupId: businessNames[territory][dsKey][0].groupId,
            territory: businessNames[territory][dsKey][0].territory,
            primary: businessNames[territory][dsKey][0].primary,
          });
        } else if (
          typeof businessNames[territory][dsKey] === 'object' &&
          !Array.isArray(businessNames[territory][dsKey]) &&
          businessNames.changeType !== 'Deleted'
        ) {
          // agent updated
          if (!changes['updated']) {
            changes['updated'] = [];
          }
          if (
            businessNames[territory][dsKey]?.label ||
            businessNames[territory][dsKey]?.groupId ||
            businessNames[territory][dsKey]?.territory ||
            businessNames[territory][dsKey]?.primary
          ) {
            changes['updated'].push({
              label: businessNames[territory][dsKey]?.label && businessNames[territory][dsKey]?.label,
              groupId: businessNames[territory][dsKey]?.groupId && businessNames[territory][dsKey]?.groupId,
              territory: businessNames[territory][dsKey]?.territory && businessNames[territory][dsKey]?.territory,
              primary: businessNames[territory][dsKey]?.primary && businessNames[territory][dsKey]?.primary,
            });
          }
        } else if (
          typeof businessNames[territory][dsKey] === 'object' &&
          !Array.isArray(businessNames[territory][dsKey]) &&
          businessNames.changeType === 'Deleted'
        ) {
          // agent deleted
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          if (businessNames[dsKey]?.personId || businessNames[dsKey]?.type || businessNames[dsKey]?.regions) {
            changes['deleted'].push({
              label: businessNames[dsKey]?.label && businessNames[dsKey]?.label,
              groupId: businessNames[dsKey]?.groupId && businessNames[dsKey]?.groupId,
              territory: businessNames[dsKey]?.territory && businessNames[dsKey]?.territory,
            });
          }
        } else if (
          Array.isArray(businessNames[territory][dsKey]) &&
          businessNames[territory][dsKey].length === 3 &&
          businessNames[territory][dsKey][2] === 0
        ) {
          if (!changes['deleted']) {
            changes['deleted'] = [];
          }
          changes['deleted'].push({
            label: businessNames[territory][dsKey][0].label,
            groupId: businessNames[territory][dsKey][0].groupId,
            territory: businessNames[territory][dsKey][0].territory,
          });
        }
      });
    });
    touringInfoHistory.push(
      <>
        {displayBusinessNames({
          subKey,
          changes,
        })}
      </>
    );
    if (!isEmpty(changes)) {
      auditChangeObject[auditLabelDisplay] = touringInfoHistory;
    }
  }
};

export default handleBusinessNames;
