import { notify } from 'react-notify-toast';
import { publishToContentful } from '@united-talent-agency/julius-frontend-store';

/**
 * Publishes a person to contentful
 * @param {Object} person - The person object to publish
 * @param {Function} dispatch - The dispatch function
 * @param {Function} _publishToContentful - The publishToContentful function
 * @param {Function} _notify - The notify function to show messages
 * @returns {Promise<Boolean>} - True if the person was published, false otherwise
 */
export const contentfulPublish = async (
  person,
  dispatch,
  _publishToContentful = publishToContentful,
  _notify = notify
) => {
  const response = await dispatch(_publishToContentful(person._id));
  const data = response.body && response.body.data;
  const isPublished = data && data.result && data.result === 'published';

  if (isPublished) {
    _notify.show('Published to Contentful', 'info');
    return true;
  } else {
    _notify.show('Error publishing to Contentful', 'error');
    return false;
  }
};
