import venueIcon from '../../../../assets/images/pin-on-map.svg';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { listVenues } from '@united-talent-agency/julius-frontend-store';
import { contentColor } from '../../../../styles/colors';

const selectOptions = [
  { value: 1000, label: '1km' },
  { value: 5000, label: '5km' },
  { value: 10000, label: '10km' },
  { value: 20000, label: '20km' },
];

export function NearbyVenuesList({ venue }) {
  const [otherVenues, setOtherVenues] = useState([]);
  const [selectedOption, setSelectedOption] = useState(selectOptions[2]);

  useEffect(() => {
    const { venueInfo } = venue;
    if (!venueInfo) return;

    const { geoLocation } = venueInfo;

    if (!geoLocation) return;

    // Could send up a whole geoLocation to the query? Or the id of an existing venue?

    listVenues({
      longitude: geoLocation.coordinates[0],
      latitude: geoLocation.coordinates[1],
      radius: selectedOption.value,
    }).then((response) => {
      // Remove this venue from the list
      const filteredVenues = response.body.filter((otherVenue) => otherVenue._id !== venue._id);
      setOtherVenues(filteredVenues);
    });
  }, [venue, selectedOption]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      <Header>
        <Title>Nearby Venues</Title>
        <div>
          <RadiusSelect
            options={selectOptions}
            value={selectedOption}
            isSearchable={false}
            placeholder="Radius"
            onChange={handleChange}
          />
        </div>
      </Header>
      <Table>
        <tbody>
          {otherVenues.map((otherVenue) => {
            const { ticketMasterInfo = {} } = otherVenue.venueInfo;
            const otherAddress = otherVenue.addresses[0];

            const imageUrl = ticketMasterInfo && ticketMasterInfo.images && ticketMasterInfo.images[0].url;
            return (
              <tr key={otherVenue._id}>
                <Td>
                  <VenueLink href={`/venue/${otherVenue._id}`}>
                    <VenueIcon width={200} height={200} src={imageUrl || venueIcon} alt="Venue" />
                  </VenueLink>
                </Td>
                <Td>
                  <VenueLink href={`/venue/${otherVenue._id}`}>{otherVenue.name}</VenueLink>
                </Td>
                <Td>
                  {otherAddress.city}, {otherAddress.state}
                </Td>
                <Td>{(Number.parseFloat(otherVenue.distance) / 1000).toFixed(2)}km</Td>
                <Td>{ticketMasterInfo.url && <WebsiteLink href={ticketMasterInfo.url}>Website</WebsiteLink>}</Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

const Header = styled.div({
  display: 'flex',
  marginLeft: '50px',
  marginRight: '50px',
  marginBottom: '10px',
});

const Title = styled.div({
  fontWeight: 'bold',
  color: contentColor,
  fontSize: 12,
  textTransform: 'uppercase',
  textAlign: 'left',
  padding: 10,
  flexGrow: 1,
});

const RadiusSelect = styled(Select)({
  width: '100px',
});

const Table = styled.table({
  margin: 'auto',
  width: '90%',
  borderCollapse: 'separate',
  borderSpacing: '0 10px',
  marginTop: '-10px',
});

const Td = styled.td({
  border: 'solid 1px #000',
  borderStyle: 'solid none',
  padding: '10px',
  '&:first-child': {
    'border-left-style': 'solid',
    'border-top-left-radius': '10px',
    'border-bottom-left-radius': '10px',
  },

  '&:last-child': {
    'border-right-style': 'solid',
    'border-bottom-right-radius': '10px',
    'border-top-right-radius': '10px',
  },
});

const VenueIcon = styled.img({
  width: 100,
  height: 100,
  objectFit: 'contain',
});

const VenueLink = styled.a({
  color: 'black',
});

const WebsiteLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})({
  color: 'black',
});
