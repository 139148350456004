export const DISPLAYED_INFO = [
  'birthday',
  'netsuiteId',
  'type',
  'w9s',
  'appointments',
  'credits',
  'occupations',
  'note',
  'nationality',
  'gender',
  'maximumAge',
  'minimumAge',
  'representationDepartments',
  'originatingDepartment',
  'clientStartDate',
  'clientTerminationDate',
  'clientStatus',
  'race',
  'artistName',
  'artistWebsite',
  'bandsintown',
  'slug',
  'territoriesOfRepresentation',
  'name',
  'distribution',
  'veteran',
  'multiracial',
  'legalName',
  'disability',
  'lgbtq',
  'primaryLanguage',
  'secondaryLanguages',
  'ethnicities',
  'nationalities',
  'interests',
  'iqStatus',
  'iqState',
  'iqSource',
  'representationAreas',
  'originCity',
  'originState',
  'originCountry',
  'census2020Latino',
  'census2020Race',
  'utourId',
  'neda',
  'iqEthnicitySourceURL',
  'iqLGBTSourceURL',
  'iqOtherURL',
  'profiled',
  'vocations',
  'genres',
  'profile_pic',
  'recordCreated',
];

export const DISPLAYED_TOURING_INFO = [
  'territoryInfo',
  'touringAgents',
  'guarenteeNames',
  'contractSignerBlocks',
  'businessNames',
  'touringAgentTerritories',
  'accountingRep',
];

export const DISPLAYED_REFERENTIAL_INFO = ['oldAddresses', 'relationships', 'roles', 'tags', 'contactsNew'];

export const DISPLAYED_SUB_DOCUMENTS = [
  'social',
  'verifications',
  'notes',
  'contacts',
  'privateContacts',
  'addresses',
  'groupMembership',
  'members',
  'externalTeam',
  'externalTeam.groupRelationships',
  'externalTeam.personRelationships',
  'utaEmployeeInfo',
  'internalTeam',
  'representedByAgencies',
];
