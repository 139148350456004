export const profileCardTypes = [
  'basic',
  'phone',
  'email',
  'other',
  'employers',
  'represented',
  'primaryTags',
  'addresses',
  'social',
  'team',
  'relationships',
  'companyEmployees',
];

export const touringCardTypes = ['clauses', 'provisions', 'remittances', 'signerBlocks'];

export const projectsCardTypes = ['roles', 'tracking'];

export const financeCardTypes = ['netsuite', 'loanouts', 'taxIds', 'gstHst', 'commission', 'vat'];

export const cardTypes = [
  ...profileCardTypes,
  ...projectsCardTypes,
  ...financeCardTypes,
  ...touringCardTypes,
  'tags',
  'website',
];
