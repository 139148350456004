import React from 'react';
import { styled } from 'react-free-style';
import { link } from '../../styles/elements';
import cypressTags from '../../support/cypressTags';

const EmployerLabel = (props) => {
  const { value, styles } = props;

  return (
    <div style={{ fontSize: '12px' }} data-cy={cypressTags.PERSON.EMPLOYER_CARD.READ_VIEW_CONTAINER}>
      <div style={{ fontWeight: 300, color: '#4A4A4A' }}>
        <a href={`/company/${value.group && value.group._id}`} className={styles.contactLink}>
          {value.group && value.group.name}
        </a>
        {value.primary && ' (Primary)'}
        <div style={{ display: 'inline-block', float: 'right' }} className="group-title">
          {value.title}
        </div>
      </div>

      <hr style={{ marginTop: 10, marginBottom: 10 }} />
    </div>
  );
};
const withStyles = styled({ contactLink: link });
export default withStyles(EmployerLabel);
