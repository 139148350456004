import React, { Component } from 'react';
import { styled, helpers } from 'react-free-style';
import classnames from 'classnames';
import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import { Info, Input, Select } from '@united-talent-agency/julius-frontend-components';

import { socialNetworks } from '../../support/social';

const PREFIXES = ['', 'https://', 'www.', 'https://www.'];

const FACEBOOK_PREFIX = 'facebook.com/';

const INSTAGRAM_PREFIX = 'instagram.com/';

const TWITTER_PREFIX = 'twitter.com/';
const X_PREFIX = 'x.com';

const YOUTUBE_PREFIX = 'youtube.com/';

const APPLE_MUSIC_PREFIX = 'music.apple.com/';

const EARWOLF_PREFIX = 'earwolf.com/';

const TIKTOK_PREFIX = 'tiktok.com/';

const SNAPCHAT_PREFIX = 'snapchat.com/';

const SOUND_CLOUD_PREFIX = 'soundcloud.com/';

const SPOTIFY_PREFIX = 'spotify.com/';

const TWITCH_PREFIX = 'twitch.tv/';

const LINKEDIN_PREFIX = 'linkedin.com/';

const WIKIPEDIA_PREFIX = 'wikipedia.org/';

export const isValidSocialUrl = (social) => {
  if (!social.url) return false;
  const url = social.url.toLowerCase();
  switch (social.network) {
    case 'facebook':
      return PREFIXES.map((prefix) => prefix + FACEBOOK_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'instagram':
      return PREFIXES.map((prefix) => prefix + INSTAGRAM_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'twitter':
      return (
        PREFIXES.map((prefix) => prefix + TWITTER_PREFIX).some((prefix) => url.startsWith(prefix)) ||
        PREFIXES.map((prefix) => prefix + X_PREFIX).some((prefix) => url.startsWith(prefix))
      );
    case 'youtube':
      return PREFIXES.map((prefix) => prefix + YOUTUBE_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'applemusic':
      return PREFIXES.map((prefix) => prefix + APPLE_MUSIC_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'earwolf':
      return PREFIXES.map((prefix) => prefix + EARWOLF_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'tiktok':
      return PREFIXES.map((prefix) => prefix + TIKTOK_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'snapchat':
      return PREFIXES.map((prefix) => prefix + SNAPCHAT_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'soundcloud':
      return PREFIXES.map((prefix) => prefix + SOUND_CLOUD_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'spotify':
      return PREFIXES.map((prefix) => prefix + SPOTIFY_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'twitch':
      return PREFIXES.map((prefix) => prefix + TWITCH_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'linkedin':
      return PREFIXES.map((prefix) => prefix + LINKEDIN_PREFIX).some((prefix) => url.startsWith(prefix));
    case 'wikipedia':
      return PREFIXES.map((prefix) => prefix + WIKIPEDIA_PREFIX).some((prefix) => url.startsWith(prefix));
    default:
      return false;
  }
};

class SocialInfo extends Component {
  render() {
    let name_;
    const { styles, social, onChange, onDelete, isEditing } = this.props;
    const noFollowers = ['wikipedia', 'applemusic', 'tiktok', 'linkedin'];
    if (social) name_ = socialNetworks[social.network];
    if (!isEditing && social) {
      const visibleLink = social.url ? this.getVisibleLink(social.url) : '';
      return (
        <Info name={`${name_} ${this.socialCountDisplay(social.followers)}`} key={name_}>
          {!isValidSocialUrl(social) ? (
            <span className="invalid-data-warning">Invalid Data</span>
          ) : (
            <a href={social.url} className={styles.link} target="_blank noopener noreferrer">
              {visibleLink}
            </a>
          )}
        </Info>
      );
    } else if (!social) {
      return (
        <Info name={name_} key={name_}>
          <span className="invalid-data-warning">Invalid Data</span>
        </Info>
      );
    }

    const items = Object.keys(socialNetworks).map((key) => {
      return {
        key: key,
        content: socialNetworks[key],
        active: social && key === social.network,
        className: styles.menuItem,
        onClick: () => onChange({ network: key }),
      };
    });

    return (
      <div className={styles.container}>
        <div className={styles.inputArea}>
          <Select items={items} className={classnames(styles.select, styles.section)}>
            {name_}
          </Select>
        </div>
        <div className={styles.descriptionArea}>
          <Input
            type="text"
            title="URL"
            value={social ? social.url : ''}
            className={classnames(styles.input, styles.section)}
            onChange={(url) => onChange({ url })}
          />
          {social && !noFollowers.includes(social.network) && (
            <Input
              type="number"
              title="Followers"
              value={social ? social.followers : ''}
              className={classnames(styles.input, styles.descr)}
              onChange={(followers) => onChange({ followers })}
            />
          )}
          {social && social.network === 'twitch' && (
            <Input
              type="number"
              title="Views"
              value={social ? social.views : ''}
              className={classnames(styles.input, styles.descr)}
              onChange={(views) => onChange({ views })}
            />
          )}
          {!isValidSocialUrl(social) && <div style={{ color: 'red', fontSize: 12, marginTop: 6 }}>Invalid Link</div>}
        </div>
        {onDelete ? (
          <div className={classnames(styles.delete, styles.section)} onClick={() => onDelete()}>
            <i className={styles.deleteIcon} />
          </div>
        ) : undefined}
      </div>
    );
  }

  socialCountDisplay = (count) => {
    if (!count) {
      return '';
    }

    let roundedCount = count;
    let postfix = '';

    if (count < 1000) {
      return `(${roundedCount})`;
    }

    if (count >= 1000 && count < 1000000) {
      roundedCount = Math.round(count / 100);
      postfix = 'K';
    } else if (count >= 1000000 && count < 1000000000) {
      roundedCount = Math.round(count / 100000);
      postfix = 'M';
    } else if (count >= 1000000000) {
      roundedCount = Math.round(count / 100000000);
      postfix = 'B';
    }

    return `(${(roundedCount / 10).toFixed(1)}${postfix})`;
  };

  getVisibleLink(url) {
    const urlLastSlashRemoved = url?.endsWith('/') ? url.slice(0, url.length - 1) : url;
    return urlLastSlashRemoved.replace(/.*\//g, '');
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    marginBottom: 10,
  },
  descr: {
    marginTop: 5,
  },
  link: elements.link,
  menuItem: {
    padding: 10,
  },
  select: {
    width: 110,
  },
  input: {
    flex: '1 0 auto',
  },
  section: {
    '&+&': {
      marginLeft: 10,
    },
  },
  delete: helpers.merge(elements.actionable, {
    padding: '10px 0',
  }),
  deleteIcon: helpers.merge(icons.cross, {
    marginLeft: 10,
  }),
  inputArea: {
    flex: 0.2,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  contactArea: {
    flex: 0.33,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  descriptionArea: {
    flex: 0.6,
    display: 'flex',
    flexDirection: 'column',
  },
  primary: {
    backgroundColor: '#90E2D3',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    border: '1px solid #141414',
    transition: '0.2s all linear',
    outline: 'none',
  },
  primaryArea: {
    flex: 0.2,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  primaryText: {
    marginLeft: '10px',
    marginRight: '10px',
    fontWeight: '800',
    fontSize: '12px',
  },
});

export default withStyles(SocialInfo);
