import { Checkbox } from '@united-talent-agency/components';
import { Territory } from './const';
import React from 'react';
import styled from 'styled-components/macro';

export const TouringSelector = ({ selectedTerritories, setSelectedTerritories, territoriesError }) => (
  <div>
    <ItemizedTerritoriesContainer>
      {Object.values(Territory).map((territory) => (
        <CenteredCheckDiv key={territory}>
          <Checkbox
            title={territory}
            value={territory}
            name={territory}
            checked={selectedTerritories.has(territory)}
            disabled={territory === Territory.RestOfGuarantees}
            onChange={({ target }) => {
              const updatedSet = new Set(selectedTerritories);
              target.checked ? updatedSet.add(territory) : updatedSet.delete(territory);
              setSelectedTerritories(updatedSet);
            }}
          />
        </CenteredCheckDiv>
      ))}
    </ItemizedTerritoriesContainer>
    {territoriesError && <TerritoriesError>{territoriesError}</TerritoriesError>}
  </div>
);

const CenteredCheckDiv = styled.div`
  & svg {
    margin: -10px 0 0 -3.5px !important;
  }
`;

const ItemizedTerritoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  & > * {
    margin-left: 30px;
    flex-shrink: 1;
  }
`;

const TerritoriesError = styled.div`
  color: red;
`;
