import { searchEmployeeRelationships } from '@united-talent-agency/julius-frontend-store';

export const getInternalTeam = (
  dispatch,
  desk,
  relationshipTypes,
  _searchEmployeeRelationships = searchEmployeeRelationships
) => {
  return dispatch(_searchEmployeeRelationships(desk.agentId._id, relationshipTypes)).then((results) => {
    const { groups = [], people = [] } = results;
    const mappedGroups = groups.map((group) => {
      group.entityType = 'group';
      return group;
    });
    const mappedPeople = people.map((person) => {
      person.entityType = 'person';
      return person;
    });
    const combined = mappedGroups.concat(mappedPeople);
    const filtered = combined.filter((entity) => entity.type === 'Client');

    return filtered;
  });
};

export default { getInternalTeam };
