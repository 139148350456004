import React from 'react';
import styled from 'styled-components';
import { useLocalStorage } from '../../support/hooks';

import { ExpandableImage, Icons } from '@united-talent-agency/components';

const { DoubleRightOutlinedIcon } = Icons;

export const TabContext = React.createContext({});

export const TabContainer = ({ headerImage, children, cyTag }) => {
  const [open, setOpen] = useLocalStorage('tabContainerOpen', true);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div data-cy={cyTag}>
      <ExpandableImage
        expanded={expanded}
        onClick={() => setExpanded(false)}
        image={headerImage}
        customStyles={{ height: 800, width: 800, borderRadius: '50%' }}
      />
      <TabContext.Provider value={{ open }}>
        <Menu open={open}>
          {!headerImage ? (
            <Chevron onClick={() => setOpen(!open)} open={open} />
          ) : (
            <>
              <ChevronImageBar
                onClick={() => setOpen(!open)}
                open={open}
                headerImage={headerImage}
                openExpanded={() => setExpanded(true)}
              />
              {!open && <ProfilePic src={headerImage} alt="" onClick={() => setExpanded(true)} />}
            </>
          )}
          {children}
        </Menu>
      </TabContext.Provider>
    </div>
  );
};

export const ChevronImageBar = ({ open, onClick, headerImage, openExpanded }) => (
  <div style={{ display: `${open ? 'inherit' : 'flex'}` }}>
    {open && <ProfilePicInBar src={headerImage} alt="headerImage" onClick={openExpanded} />}
    <Chevron onClick={onClick} open={open} />
  </div>
);

export const Chevron = ({ open, onClick }) => (
  <DoubleRightOutlinedIcon
    inline
    onClick={onClick}
    style={{
      fontSize: '20px',
      cursor: 'pointer',
      transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
      // lingers a little bit longer than the menu resize transition (0.2s)
      transition: 'all .3s ease-out',
      margin: `16px ${open ? '16px' : '24px'} 16px auto`,
      alignSelf: open ? 'flex-end' : 'inherit',
    }}
  />
);

export const Menu = styled.div(({ open }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 10,
  width: open ? '300px' : '78px',
  transition: 'width 0.2s ease-out',
  backgroundColor: 'white',
  overflow: 'hidden',
}));

export const ProfilePic = styled.img({
  borderRadius: '50%',
  height: 50,
  width: 50,
  backgroundSize: 'cover',
  margin: '0 auto 15px',
  cursor: 'pointer',
});

export const ProfilePicInBar = styled.img({
  borderRadius: '50%',
  height: 50,
  width: 50,
  backgroundSize: 'cover',
  margin: 15,
  cursor: 'pointer',
});
