import React from 'react';

import {
  Input,
  PhoneInput,
  ContactTypeNames,
  validateEmailInput,
  validatePhoneInput,
} from '@united-talent-agency/components';

import { emailContactFilter, phoneOrFaxFilter } from '../../support/contacts';
import LockIcon from './LockIcon';
import ContactTypeSelector from './ContactTypeSelector';
import cypressTags from '../../support/cypressTags';

const ContactEditor = (props) => {
  const {
    onChange,
    value,
    foreverType,
    contactTypes = Object.keys(ContactTypeNames),
    setPrivate,
    hasPrivateContactsPermissions,
    mergeCardData,
  } = props;

  const isPhoneOrFax = phoneOrFaxFilter.test(value.contactType);
  const isEmail = emailContactFilter.test(value.contactType);
  const isOther = !(isPhoneOrFax || isEmail);

  return (
    <div data-cy={cypressTags.PERSON.CONTACTS_CARDS.EDIT_VIEW_CONTAINER}>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          justifyContent: !mergeCardData && !foreverType ? 'flex-end' : 'flex-start',
        }}
      >
        {!foreverType && (
          <div style={{ minWidth: '140px', marginTop: '4px', marginRight: '4px' }}>
            <ContactTypeSelector
              value={value}
              disabled={value.isPrivate && !hasPrivateContactsPermissions}
              textLabels
              onChange={(selected) => {
                value.contactType = selected.contactType;
                onChange && onChange(value);
              }}
              types={contactTypes}
            />
          </div>
        )}
        {!mergeCardData && (
          <ContactInput
            value={value}
            hasPrivateContactsPermissions={hasPrivateContactsPermissions}
            foreverType={foreverType}
            onChange={onChange}
            isPhoneOrFax={isPhoneOrFax}
          />
        )}
      </div>
      {mergeCardData && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginLeft: '19px',
            width: '285px',
          }}
        >
          <ContactInput
            value={value}
            hasPrivateContactsPermissions={hasPrivateContactsPermissions}
            foreverType={foreverType}
            onChange={onChange}
            isPhoneOrFax={isPhoneOrFax}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: foreverType && 'flex-start',
          marginLeft: !mergeCardData ? !foreverType && '125px' : 0,
        }}
      >
        <div style={{ alignSelf: 'center', marginRight: 4, marginLeft: foreverType ? 4 : 0 }}>
          <LockIcon
            deskConnected={hasPrivateContactsPermissions}
            primaryContact={value.primary}
            isPrivate={value.isPrivate}
            onClick={(isLocked) => {
              setPrivate(isLocked);
            }}
          />
        </div>
        {isPhoneOrFax ? (
          value.contact !== undefined && !validatePhoneInput(value.contact) ? (
            <div
              style={{
                textAlign: 'right',
                marginRight: 134,
                marginTop: 6,
                marginBottom: 16,
                color: 'red',
                fontSize: '12px',
              }}
            >
              Invalid Number
            </div>
          ) : (
            <div style={{ width: foreverType ? '100%' : 285, marginRight: 3 }}>
              <Input
                value={value.description}
                readOnly={value.isPrivate && !hasPrivateContactsPermissions}
                title="Description"
                onChange={(text) => {
                  value.description = text;
                  onChange && onChange(value);
                }}
              />
            </div>
          )
        ) : null}
        {isEmail ? (
          (value.contact && !validateEmailInput(value.contact)) || value.contact === null ? (
            <div
              style={{
                textAlign: 'left',
                marginRight: 3,
                width: '100%',
                minWidth: 285,
                marginTop: 6,
                marginBottom: 16,
                color: 'red',
                fontSize: '12px',
              }}
            >
              Invalid Email Address
            </div>
          ) : (
            <div style={{ width: foreverType ? '100%' : 285, marginRight: 3 }}>
              <Input
                value={value.description}
                readOnly={value.isPrivate && !hasPrivateContactsPermissions}
                title="Description"
                onChange={(text) => {
                  value.description = text;
                  onChange && onChange(value);
                }}
              />
            </div>
          )
        ) : null}
        {isOther ? (
          value.contact === null ? (
            <div
              style={{
                textAlign: 'left',
                marginRight: 3,
                width: '100%',
                minWidth: 285,
                marginTop: 6,
                marginBottom: 16,
                color: 'red',
                fontSize: '12px',
              }}
            >
              Invalid Contact
            </div>
          ) : (
            <div style={{ width: foreverType ? '100%' : 285, marginRight: 3 }}>
              <Input
                value={value.description}
                readOnly={value.isPrivate && !hasPrivateContactsPermissions}
                title="Description"
                onChange={(text) => {
                  value.description = text;
                  onChange && onChange(value);
                }}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

const ContactInput = ({ value, hasPrivateContactsPermissions, foreverType, onChange, isPhoneOrFax }) => {
  const contactInput = isPhoneOrFax ? (
    <div data-cy={cypressTags.PERSON.CONTACTS_CARDS.PHONE_INPUT}>
      <PhoneInput
        isPrivate={value.isPrivate && !hasPrivateContactsPermissions}
        value={value.contact}
        title={value.contactType}
        userRegion={navigator.language.split('-')[1]}
        wide={!!foreverType}
        onChange={(number) => {
          value.contact = number;
          onChange && onChange(value);
        }}
      />
    </div>
  ) : (
    <Input
      value={value.isPrivate && !hasPrivateContactsPermissions ? 'Private' : value.contact}
      readOnly={value.isPrivate}
      title={value.contactType}
      onChange={(v) => {
        value.contact = v;
        onChange && onChange(value);
      }}
    />
  );
  const containerStyle = { width: '100%', marginTop: isPhoneOrFax ? 0 : 4, marginRight: 3 };
  return <div style={containerStyle}>{contactInput}</div>;
};

export default ContactEditor;
