import React, { useState, useEffect } from 'react';

import {
  getGroupToPersonRelationshipTypes,
  getGroupToGroupRelationshipTypes,
} from '@united-talent-agency/julius-frontend-store';

import EntityRelationshipViewer from './entity-relationship-viewer';
import EntityRelationshipEditor from './entity-relationship-editor';
import Card from '../../../components/card/ProjectCard';

const cardDataKey = 'externalTeamEntityRelationships';
const onCreate = (props) => props.createBy(cardDataKey, undefined, { _random: Math.random() });
const InternalTeamEntityRelationships = ({
  onSave,
  dispatch,
  relationships = [],
  onSearchEntity,
  title = '',
  mergeCardData,
}) => {
  const [relationshipTypesG2P, setRelationshipTypesG2P] = useState([]);
  const [relationshipTypesG2G, setRelationshipTypesG2G] = useState([]);

  //this is to force a redraw of the child component (deleting a pending create)
  const [lastUpdate, setLastUpdate] = useState(new Date());

  useEffect(() => {
    dispatch(getGroupToPersonRelationshipTypes()).then((result) => {
      const relationshipTypes = result && Array.isArray(result.body) ? result.body : [];
      const relTypes = relationshipTypes.reduce((ary, relType) => {
        ary.push(relType.target);
        return ary;
      }, []);
      setRelationshipTypesG2P(relTypes);
    });
    dispatch(getGroupToGroupRelationshipTypes()).then((result) => {
      const relationshipTypes = result && Array.isArray(result.body) ? result.body : [];
      const relTypes = relationshipTypes.reduce((ary, relType) => {
        ary.push(relType.target);
        if (relType.target !== relType.inverse) {
          ary.push(relType.inverse);
        }
        return ary;
      }, []);
      setRelationshipTypesG2G(relTypes);
    });
  }, [dispatch]);
  const validateEntry = (relationship, relationships, changes) => {
    const { deletes, updates, creates } = changes;
    if (!relationship.type) {
      return 'Type is Required';
    }
    const entity = relationship.person || relationship.group;
    if (!entity || !entity._id) {
      return 'Person or Company is Required';
    }
    if (relationship.person) {
      const validType = relationshipTypesG2P.includes(relationship.type);
      if (!validType) {
        return `${relationship.type} relationships cannot be made to people`;
      }
    }
    if (relationship.group) {
      const validType = relationshipTypesG2G.includes(relationship.type);
      if (!validType) {
        return `${relationship.type} relationships cannot be made to companies`;
      }
    }
    if (relationship.type === 'Connected To') {
      if (entity.type !== 'Shared Contact') {
        return `Connected To relationships must be made to Shared Contacts`;
      }
    }

    const appliedRelationships = relationships
      .map((r) => {
        if (deletes[cardDataKey] && deletes[cardDataKey][r._id]) {
          return null;
        }
        const appliedRelationship = updates[cardDataKey]
          ? {
              ...r,
              ...updates[cardDataKey][r._id],
            }
          : r;
        return appliedRelationship;
      })
      .filter((r) => r)
      .concat(creates[cardDataKey] || []);

    const entriesAreDifferent = (rel1, rel2) => {
      const rel1Id = rel1._id ? rel1._id : rel1._random;
      const rel2Id = rel2._id ? rel2._id : rel2._random;
      return rel1Id !== rel2Id;
    };

    const dupeEntry = appliedRelationships.find((ar) => {
      const shouldConsider = entriesAreDifferent(ar, relationship);
      const arEntity = ar.person || ar.group;
      return shouldConsider && ar.type === relationship.type && arEntity._id === entity._id;
    });
    if (dupeEntry) {
      return 'Duplicate Entry';
    }
  };
  const saveChanges = (changes) => {
    const updates = (changes.updates || {})[cardDataKey] || {};
    const updateKeys = Object.keys(updates);
    const changeStructure = {
      personRelationships: { creates: [], updates: [], deletes: [] },
      groupRelationships: { creates: [], updates: [], deletes: [] },
    };

    //handle anything that switched entity types as an create/delete instead of an update
    const reducedChanges = updateKeys.reduce((ops, updateKey) => {
      const existingRelationship = relationships.find((relationship) => {
        return relationship._id === updateKey;
      });
      const relationshipUpdate = { ...existingRelationship, ...updates[updateKey] };

      if (relationshipUpdate.originalEntityType === 'person' && relationshipUpdate.group) {
        ops.groupRelationships.creates.push({ type: relationshipUpdate.type, group: relationshipUpdate.group._id });
        ops.personRelationships.deletes.push(relationshipUpdate._id);
      }
      if (relationshipUpdate.originalEntityType === 'group' && relationshipUpdate.person) {
        ops.personRelationships.creates.push({ type: relationshipUpdate.type, person: relationshipUpdate.person._id });
        ops.groupRelationships.deletes.push(relationshipUpdate._id);
      }
      return ops;
    }, changeStructure);

    //layer on top the real creates
    const creates = (changes.creates || {})[cardDataKey] || [];
    const personRelCreates = creates
      .filter((relationship) => relationship.person)
      .map((relationship) => {
        const error = validateEntry(relationship, relationships, changes);
        return { type: relationship.type, person: relationship.person._id, error };
      });
    reducedChanges.personRelationships.creates.push(...personRelCreates);

    const groupRelCreates = creates
      .filter((relationship) => relationship.group)
      .map((relationship) => {
        const error = validateEntry(relationship, relationships, changes);
        return { type: relationship.type, group: relationship.group._id, error };
      });
    reducedChanges.groupRelationships.creates.push(...groupRelCreates);

    //layer on top the real deletes
    const deletes = (changes.deletes || {})[cardDataKey] || {};
    const deleteKeys = Object.keys(deletes);
    const personRelDeletes = deleteKeys.filter((deleteKey) => {
      const relationship = relationships.find((relationship) => relationship._id === deleteKey);
      return relationship.person;
    });
    reducedChanges.personRelationships.deletes.push(...personRelDeletes);

    const groupRelDeletes = deleteKeys.filter((deleteKey) => {
      const relationship = relationships.find((relationship) => relationship._id === deleteKey);
      return relationship.group;
    });
    reducedChanges.groupRelationships.deletes.push(...groupRelDeletes);

    //add updates - careful of existing deletes
    const personRelUpdates = updateKeys
      .filter((updateKey) => {
        //make sure this wasn't deleted
        const wasDeleted = reducedChanges.personRelationships.deletes.some((deletedId) => deletedId === updateKey);
        if (wasDeleted) {
          return false;
        }
        const existingRelationship = relationships.find((relationship) => {
          return relationship._id === updateKey;
        });
        //make sure this wasnt an entity switch
        return updates[updateKey].originalEntityType
          ? updates[updateKey].originalEntityType === 'person' && updates[updateKey].person
          : !existingRelationship.group; //...and is not a group
      })
      .map((relationshipId) => {
        const existingRelationship = relationships.find((relationship) => {
          return relationship._id === relationshipId;
        });
        const relationshipChanges = changes.updates[cardDataKey][relationshipId];
        const mergedRelationship = { ...existingRelationship, ...relationshipChanges };
        const error = validateEntry(mergedRelationship, relationships, changes);
        return { _id: relationshipId, type: mergedRelationship.type, person: mergedRelationship.person._id, error };
      });
    reducedChanges.personRelationships.updates.push(...personRelUpdates);

    const groupRelUpdates = updateKeys
      .filter((updateKey) => {
        //make sure this wasn't deleted
        const wasDeleted = reducedChanges.groupRelationships.deletes.some((deletedId) => deletedId === updateKey);
        if (wasDeleted) {
          return false;
        }
        const existingRelationship = relationships.find((relationship) => {
          return relationship._id === updateKey;
        });
        //make sure this wasnt an entity switch
        return updates[updateKey].originalEntityType
          ? updates[updateKey].originalEntityType === 'group' && updates[updateKey].group
          : !existingRelationship.person; //...and is not a person
      })
      .map((relationshipId) => {
        const existingRelationship = relationships.find((relationship) => {
          return relationship._id === relationshipId;
        });
        const relationshipChanges = changes.updates[cardDataKey][relationshipId];
        const mergedRelationship = { ...existingRelationship, ...relationshipChanges };
        const error = validateEntry(mergedRelationship, relationships, changes);
        return { _id: relationshipId, type: mergedRelationship.type, group: mergedRelationship.group._id, error };
      });
    reducedChanges.groupRelationships.updates.push(...groupRelUpdates);

    //propagate reduced changes to outside controller to deal with - good luck buddy!
    onSave && onSave(reducedChanges);
  };
  const relationshipTypes = Array.from(new Set([...relationshipTypesG2P, ...relationshipTypesG2G])).sort((a, b) =>
    a.localeCompare(b)
  );

  const showRelationships = !mergeCardData || !mergeCardData.isEmpty || relationships.length > 0;

  return !showRelationships ? (
    <></>
  ) : (
    <Card title={title} saveChanges={saveChanges} onCreate={onCreate} canEdit mergeCardData={mergeCardData}>
      {({ creates, updates, deletes, createBy, updateBy, deleteBy, isEditing }) => {
        return (
          <div>
            {relationships.map((relationship, i) => {
              if (deletes[cardDataKey] && deletes[cardDataKey][relationship._id]) return null;
              if (!isEditing) {
                return <EntityRelationshipViewer key={`entity-rel-viewer-${i}`} relationship={relationship} />;
              }
              const editedRelationship = updates[cardDataKey]
                ? {
                    ...relationship,
                    ...updates[cardDataKey][relationship._id],
                  }
                : relationship;

              return (
                <EntityRelationshipEditor
                  key={editedRelationship._id}
                  relationship={editedRelationship}
                  onSearch={(text) => {
                    const relationshipType = editedRelationship.type;
                    const searchOptions = { types: ['Client', 'Industry Contact', 'Shared Contact'] };
                    if (relationshipTypesG2P.includes(relationshipType)) {
                      searchOptions.people = true;
                    }
                    if (relationshipTypesG2G.includes(relationshipType)) {
                      searchOptions.groups = true;
                    }
                    if (relationshipType === 'Connected To') {
                      searchOptions.types = ['Shared Contact'];
                    }
                    return onSearchEntity(text, searchOptions);
                  }}
                  onEntityChanged={(entity) => {
                    const originalEntityType = relationship.person ? 'person' : 'group';
                    const update =
                      entity.entityType === 'person'
                        ? { person: entity, group: null, originalEntityType }
                        : { group: entity, person: null, originalEntityType };
                    updateBy(cardDataKey, relationship._id, update);
                  }}
                  relationshipTypes={relationshipTypes}
                  onRelationshipTypeChanged={(type) => updateBy(cardDataKey, relationship._id, { type })}
                  lastUpdate={lastUpdate}
                  onDelete={() => deleteBy(cardDataKey, relationship._id)}
                  onValidate={(relationship) =>
                    validateEntry(relationship, relationships, { creates, updates, deletes })
                  }
                />
              );
            })}
            {(creates[cardDataKey] || []).map((relationship, index) => {
              return (
                <EntityRelationshipEditor
                  key={index}
                  relationship={relationship}
                  onSearch={(text) => {
                    const relationshipType = relationship.type;
                    const searchOptions = { types: ['Client', 'Industry Contact', 'Shared Contact'] };
                    if (relationshipTypesG2P.includes(relationshipType)) {
                      searchOptions.people = true;
                    }
                    if (relationshipTypesG2G.includes(relationshipType)) {
                      searchOptions.groups = true;
                    }
                    if (relationshipType === 'Connected To') {
                      searchOptions.types = ['Shared Contact'];
                    }
                    return onSearchEntity(text, searchOptions);
                  }}
                  onEntityChanged={(entity) => {
                    const update =
                      entity.entityType === 'person'
                        ? { person: entity, group: null }
                        : { group: entity, person: null };
                    createBy(cardDataKey, index, update);
                  }}
                  relationshipTypes={relationshipTypes}
                  onRelationshipTypeChanged={(type) => createBy(cardDataKey, index, { type })}
                  lastUpdate={lastUpdate}
                  onDelete={() => {
                    creates[cardDataKey].splice(index, 1);
                    setLastUpdate(new Date());
                  }}
                  onValidate={(relationship) =>
                    validateEntry(relationship, relationships, { creates, updates, deletes })
                  }
                />
              );
            })}
          </div>
        );
      }}
    </Card>
  );
};

export default InternalTeamEntityRelationships;
