import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';
import { getUTACompany, listHistoryProfile } from '@united-talent-agency/julius-frontend-store';
import { Icons } from '@united-talent-agency/components';
import { getGroup, updateGroup } from '../../api/groups.js';
import { Tab } from '../../components/tabs/Tab';
import { TabContainer } from '../../components/tabs/TabContainer';
import {
  useClientTeamAccessChecker,
  useIndustryContactAccessChecker,
  useQueryStringViewRedirect,
  useLaunchDarklyCustomHook,
} from '../../support/hooks';
import HistoryView from '../profile/views/history/history';
import CompanyProfileView from './views/profile';
import NotesView from '../shared/notes/';
import ExternalTeamView from '../shared/external-team-company-relationships';
import WebsiteView from '../profile/views/website/website';
import {
  Header,
  HeaderActions,
  PageBody,
  PageContainer,
  PageContent,
  TitleContainer,
} from '../../styles/components/components';
import CardPageContainer from '../../components/card/CardPageContainer';
import { FinanceView } from '../profile/views/finance/FinanceView';
import cypressTags from '../../support/cypressTags.js';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped.js';
import Title from '../../components/title/index.js';

const { ExternalIcon, FinanceIcon, HistoryIcon, NoteIcon, OfficePhoneIcon, WindowAltIcon } = Icons;

function CompanyProfile({
  computedMatch,
  dispatch,
  user,
  _getGroup = getGroup,
  _useQueryStringViewRedirect = useQueryStringViewRedirect,
}) {
  const { companyId } = (computedMatch || {}).params;
  const [companyInfo, setCompanyInfo] = useState({});
  const [utaCompany, setUTACompany] = useState({});
  const [filteredAllViews, setFilteredAllViews] = useState([]);
  const [financeRouteEnabled, setFinanceRouteEnabled] = useState(true);
  const [flags] = useLaunchDarklyCustomHook(user);
  const allViews = useMemo(() => buildMenuViews(companyInfo, flags), [companyInfo, flags]);

  // custom hook that checks if user is member of client or data steward team to block edit/verify or not
  const [relations, blockEdit] = useClientTeamAccessChecker({
    entity: companyInfo,
    user,
    type: 'GROUP',
    dispatch,
  });

  //Touring & Finance Tab check - custom hook that checks if user is member of client or data steward team to block edit/verify or not
  const accessCheckerMethod =
    companyInfo?.type === 'Client' ? useClientTeamAccessChecker : useIndustryContactAccessChecker;
  const [, blockTouringAndFinanceEdit] = accessCheckerMethod({
    entity: companyInfo,
    user,
    type: 'GROUP',
    dispatch,
  });

  const isUTACompany = companyInfo._id === utaCompany._id;

  const baseUrl = `/company/${companyId}`;
  _useQueryStringViewRedirect(baseUrl, { history: 'history' });

  useEffect(() => {
    _getGroup(companyId).then(setCompanyInfo);
  }, [companyId, _getGroup]);

  useEffect(() => {
    dispatch(getUTACompany()).then((result) => {
      const utaCompany = result.body || {};
      setUTACompany(utaCompany);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!Object.keys(companyInfo).length) {
      return;
    }

    // For a regular user, we need to hide the Finance view for all profile types
    if (blockTouringAndFinanceEdit) {
      setFilteredAllViews(allViews.filter((viewName) => viewName.title.toLowerCase() !== 'finance'));
      setFinanceRouteEnabled(false);
    } else {
      setFilteredAllViews(allViews);
      setFinanceRouteEnabled(true);
    }
  }, [allViews, blockTouringAndFinanceEdit, companyInfo]);

  const refreshProfile = () => {
    _getGroup(companyId).then(setCompanyInfo);
  };

  const saveNotes = async (notes = []) => {
    await updateGroup(companyId, { notes });
    refreshProfile();
  };

  const saveWebsite = async ({ updates }) => {
    await updateGroup(companyId, updates[companyId]);
    refreshProfile();
  };

  const { name } = companyInfo;
  const views = filteredAllViews.length ? filteredAllViews : allViews;
  const APP_NAME = flags.showContactsMergeFeatures ? 'CONTACTS' : 'PROFILES';
  return (
    <PageContainer>
      <Helmet>
        <title>{name ? `${APP_NAME}: ${name}` : `${APP_NAME}`}</title>
      </Helmet>
      <BrandingWrapped />

      <Header>
        <TitleContainer>
          <Title entity={companyInfo} type={isUTACompany ? 'Employee' : companyInfo.type} />
        </TitleContainer>
        <HeaderActions>
          {/* empty element added for spacing style */}
          <div style={{ height: '34px', width: '108px', padding: '0 16px', display: 'flex' }}></div>
        </HeaderActions>
      </Header>
      <PageBody>
        <TabContainer cyTag={cypressTags.COMMON.TAB_CONTAINER}>
          {views.map((view) => (
            <Tab to={`${baseUrl}/${view.view}`} exact title={view.title} icon={view.icon} key={view.title} />
          ))}
        </TabContainer>
        <PageContent>
          <CardPageContainer onSave={refreshProfile}>
            <Switch>
              <Route exact path={baseUrl}>
                <CompanyProfileView
                  companyInfo={companyInfo}
                  dispatch={dispatch}
                  editCompany={updateGroup}
                  refreshProfile={refreshProfile}
                  retrieveCompany={() => _getGroup(companyId)}
                  isUTACompany={isUTACompany}
                  reservedCompanyNames={[utaCompany.name]}
                  user={user}
                  blockEdit={blockEdit}
                  relations={relations}
                />
              </Route>
              <Route exact path={`${baseUrl}/notes`}>
                <NotesView entity={companyInfo} saveNotes={saveNotes} user={user} />
              </Route>
              {financeRouteEnabled ? (
                <Route exact path={`${baseUrl}/finance`}>
                  <FinanceView company={companyInfo} blockEdit={blockTouringAndFinanceEdit} />
                </Route>
              ) : null}
              <Route exact path={`${baseUrl}/externalTeam`}>
                <ExternalTeamView entity={companyInfo} dispatch={dispatch} isUTACompany={isUTACompany} />
              </Route>
              <Route exact path={`${baseUrl}/history`}>
                <HistoryView
                  company={companyInfo}
                  dispatch={dispatch}
                  entityLabel="company"
                  listHistoryProfile={listHistoryProfile}
                />
              </Route>
              <Route exact path={`${baseUrl}/website`}>
                <WebsiteView company={companyInfo} saveChanges={(changes) => saveWebsite(changes)} />
              </Route>
            </Switch>
          </CardPageContainer>
        </PageContent>
      </PageBody>
    </PageContainer>
  );
}

const buildMenuViews = (company, flags) => {
  let views = [
    { view: '', title: 'Profile', icon: <OfficePhoneIcon /> },
    { view: 'notes', title: 'Notes', icon: <NoteIcon /> },
    { view: 'externalTeam', title: 'External Relationships', icon: <ExternalIcon /> },
    { view: 'finance', title: 'Finance', icon: <FinanceIcon /> },
  ];

  if (company.type === 'Client') {
    views.push({ view: 'website', title: 'Website', icon: <WindowAltIcon /> });
  }

  // hide touring tab for types other than client and industry contact
  if (company.type !== 'Client' && company.type !== 'Industry Contact') {
    views = views.filter((viewObject) => viewObject.view !== 'touring');
  }

  views.push({ view: 'history', title: 'History', icon: <HistoryIcon /> });
  return views;
};

export default CompanyProfile;
