import { get, post, patch } from './index.js';

/** Gets the full Company/Venue record set
 *
 * @param {String} companyId: the companyId of the company to retrive.
 * This company should be the parent company of the venue
 * @returns {Object} the response object containing the company and populated venue
 */
export async function getVenueById(venueId) {
  return await get(`/venues/${venueId}`);
}

/** Creates a new Venue record
 *
 * @param {Object} payload: The data to create the new venue
 * @returns {Object} the response object containing the venue
 */
export async function createVenue(payload) {
  return await post('/venues', payload);
}

/** Update a Venue record
 *
 * @param {Object} payload: The data to update the venue
 * @returns {Object} the response object containing the venue
 */
export async function updateVenue(payload) {
  return await patch(`/venues/${payload.venueId}`, payload.updates);
}
