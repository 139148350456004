import React from 'react';
import { styled } from 'react-free-style';
import { link } from '../../styles/elements';

const EmployeeLabel = (props) => {
  const { value, styles } = props;

  return (
    <div style={{ fontSize: '12px' }}>
      <div style={{ fontWeight: 300, color: '#4A4A4A', display: 'flex', justifyContent: 'space-between' }}>
        <a href={`/profile/${value.person && value.person._id}`} className={styles.contactLink}>
          {value.person && value.person.name}
        </a>
        <div>{value.title}</div>
      </div>

      <hr style={{ marginTop: 10, marginBottom: 10 }} />
    </div>
  );
};
const withStyles = styled({ contactLink: link });
export default withStyles(EmployeeLabel);
