import React from 'react';
import { isEmpty } from 'lodash';

const displayAccountingRep = ({ subKey, changes }) => {
  return (
    <>
      {changes['created'] &&
        changes['created'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Accounting Rep: '}</strong>}
            {'; Name: '}
            {change.name}
            {'; Subsidiary Type: '}
            {change.subsidairyType && change.subsidairyType}
            {' was created'}
          </div>
        ))}
      {changes['updated'] &&
        changes['updated'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Accounting Rep: '}</strong>}
            {'; Name: '}
            {change?.name && Array.isArray(change?.name) && change?.name.length > 0 ? change.name[0] : change.name}
            {'; Subsidiary Type: '}
            {change?.subsidairyType && Array.isArray(change?.subsidairyType) && change?.subsidairyType.length > 0
              ? change?.subsidairyType[0]
              : change?.subsidairyType}
            {' was changed to '}
            {' Name: '}
            {change?.name && Array.isArray(change?.name) && change?.name.length > 0 ? change.name[1] : change.name}
            {'; Subsidiary Type: '}
            {change?.subsidairyType && Array.isArray(change?.subsidairyType) && change?.subsidairyType.length > 0
              ? change?.subsidairyType[1]
              : change?.subsidairyType}
          </div>
        ))}
      {changes['deleted'] &&
        changes['deleted'].map((change, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            {<strong style={{ fontWeight: 'bold' }}>{'Accounting Rep: '}</strong>}
            {'; Name: '}
            {change.name}
            {'; Subsidiary Type: '}
            {change.subsidairyType && change.subsidairyType}
            {' was deleted'}
          </div>
        ))}
    </>
  );
};

const handleAccountingRep = ({ territoryInfo, auditChangeObject, subKey, AUDIT_LABEL_DISPLAY }) => {
  const { accountingRep = [] } = territoryInfo;
  const auditLabelDisplay = AUDIT_LABEL_DISPLAY[subKey];
  let touringInfoHistory = [];
  const dsKeys = Object.keys(accountingRep);
  const changes = {};
  if (dsKeys.length > 0) {
    dsKeys.forEach((dsKey) => {
      if (Array.isArray(accountingRep[dsKey]) && accountingRep[dsKey].length === 1) {
        if (!changes['created']) {
          changes['created'] = [];
        }
        changes['created'].push({
          name: accountingRep[dsKey][0].personId?.name,
          subsidairyType: accountingRep[dsKey][0].subsidairyType,
        });
      } else if (
        typeof accountingRep[dsKey] === 'object' &&
        !Array.isArray(accountingRep[dsKey]) &&
        accountingRep.changeType !== 'Deleted'
      ) {
        // agent updated
        if (!changes['updated']) {
          changes['updated'] = [];
        }
        if (accountingRep[dsKey]?.personId || accountingRep[dsKey]?.subsidairyType) {
          changes['updated'].push({
            name: accountingRep[dsKey]?.personId && accountingRep[dsKey]?.personId.name,
            subsidairyType: accountingRep[dsKey]?.subsidairyType && accountingRep[dsKey]?.subsidairyType,
          });
        }
      } else if (
        typeof accountingRep[dsKey] === 'object' &&
        !Array.isArray(accountingRep[dsKey]) &&
        accountingRep.changeType === 'Deleted'
      ) {
        // agent deleted
        if (!changes['deleted']) {
          changes['deleted'] = [];
        }
        if (accountingRep[dsKey]?.personId || accountingRep[dsKey]?.subsidairyType) {
          changes['deleted'].push({
            name: accountingRep[dsKey]?.personId && accountingRep[dsKey]?.personId.name,
            subsidairyType: accountingRep[dsKey]?.subsidairyType && accountingRep[dsKey]?.subsidairyType,
          });
        }
      } else if (
        Array.isArray(accountingRep[dsKey]) &&
        accountingRep[dsKey].length === 3 &&
        accountingRep[dsKey][2] === 0
      ) {
        if (!changes['deleted']) {
          changes['deleted'] = [];
        }
        changes['deleted'].push({
          name: accountingRep[dsKey][0].personId?.name,
          subsidairyType: accountingRep[dsKey][0].subsidairyType,
        });
      }
    });
    touringInfoHistory.push(
      <>
        {displayAccountingRep({
          subKey,
          changes,
        })}
      </>
    );
    if (!isEmpty(changes)) {
      auditChangeObject[auditLabelDisplay] = touringInfoHistory;
    }
  }
};

export default handleAccountingRep;
