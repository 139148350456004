import React, { useState, useEffect } from 'react';

import {
  getInternalTeamRelationshipTypes,
  getInvalidRelationshipType,
} from '@united-talent-agency/julius-frontend-store';
import PersonRelationshipViewer from './uta-employee-relationship-viewer';
import PersonRelationshipEditor from './uta-employee-relationship-editor';
import Card from '../card/Card';
import { Icons, Tooltip } from '@united-talent-agency/components';
import cypressTags from '../../support/cypressTags';

const loadInvalidRelationshipType = (dispatch) => {
  return dispatch(getInvalidRelationshipType()).then((result) => {
    const values = Array.isArray(result?.body) ? result.body : [];
    return values[0];
  });
};

const InternalTeamUTAEmployeeRelationships = ({
  onSave,
  onChange,
  dispatch,
  relationships = [],
  onSearchPerson,
  entity,
  entityType,
  mergeCardData,
  blockEdit,
  cyTag,
}) => {
  const [relationshipTypes, setRelationshipTypes] = useState([]);
  const [invalidRelationshipType, setInvalidRelationshipType] = useState();

  const comparedType = entityType || entity.type;

  useEffect(() => {
    dispatch(getInternalTeamRelationshipTypes()).then((result) => {
      const relTypes =
        result?.body && result.body.length > 0
          ? result.body.reduce((ary, relType) => {
              if (relType.targetType === comparedType) {
                ary.push(relType.employee);
              }
              return ary;
            }, [])
          : [];
      setRelationshipTypes(relTypes);
    });
    loadInvalidRelationshipType(dispatch).then((typeName) => {
      setInvalidRelationshipType(typeName);
    });
  }, [dispatch, comparedType]);

  const saveChanges = ({ creates, updates, deletes, mergedData }) => {
    const externalChanges = {};
    if (creates && creates.length > 0) {
      externalChanges.creates = creates.map((relationship) => {
        return { type: relationship.type, utaEmployee: (relationship.utaEmployee || {})._id };
      });
    }

    if (deletes && Object.keys(deletes).length > 0) {
      // Just need an array of ids
      externalChanges.deletes = Object.keys(deletes);
    }

    if (updates && Object.keys(updates).length > 0) {
      externalChanges.updates = mergedData
        .filter(({ _id }) => updates[_id])
        .map((relationship) => {
          return {
            _id: relationship._id,
            type: relationship.type,
            utaEmployee: relationship.utaEmployee._id,
          };
        });
    }
    onSave && onSave(externalChanges);
  };

  const showRelationships = !mergeCardData || !mergeCardData.isEmpty || relationships.length > 0;

  return !showRelationships ? (
    <></>
  ) : (
    <div data-cy={cyTag} style={{ position: 'relative' }}>
      {blockEdit && (
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <Tooltip place="left" text="Please contact a client team member to make changes to this client profile.">
            <span data-cy={cypressTags.COMMON.LOCK_BUTTON}>
              <Icons.LockedIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <Card
        title="UTA RELATIONSHIPS"
        mergeCardData={mergeCardData}
        data={relationships}
        readView={readView}
        editView={
          !blockEdit &&
          ((props) => editView({ relationships, relationshipTypes, invalidRelationshipType, onSearchPerson }, props))
        }
        onSave={saveChanges}
        onChange={onChange}
        createNew={() => ({ _random: Math.random() })}
        onValidateItem={(relationship) => !validateEntry(relationship, relationships)}
        blockEdit={blockEdit}
        canDelete
      />
    </div>
  );
};

function readView({ item: relationship, idx }) {
  return (
    <PersonRelationshipViewer
      key={idx}
      relationship={relationship}
      cyTag={cypressTags.PERSON.UTA_RELATIONSHIP.VIEW_MODE_CONTAINER}
    />
  );
}

function editView(
  { relationships, relationshipTypes, invalidRelationshipType, onSearchPerson },
  { item: relationship, setState, idx }
) {
  return (
    <PersonRelationshipEditor
      key={idx}
      relationship={relationship}
      onSearch={onSearchPerson}
      onPersonChanged={(utaEmployee) => setState({ utaEmployee })}
      relationshipTypes={relationshipTypes}
      onRelationshipTypeChanged={(type) => setState({ type })}
      onValidate={(relationship) => validateEntry(relationship, relationships)}
      invalidRelationshipType={invalidRelationshipType}
      cyTag={cypressTags.PERSON.UTA_RELATIONSHIP.EDIT_MODE_CONTAINER}
    />
  );
}

const validateEntry = (relationship, relationships) => {
  if (!relationship.type) {
    return 'Type is Required';
  }
  if (!relationship.utaEmployee || !relationship.utaEmployee._id) {
    return 'Employee is Required';
  }

  const entriesAreDifferent = (rel1, rel2) => {
    const rel1Id = rel1._id ? rel1._id : rel1._random;
    const rel2Id = rel2._id ? rel2._id : rel2._random;
    return rel1Id !== rel2Id;
  };

  const dupeEntry = relationships.find((ar) => {
    const shouldConsider = entriesAreDifferent(ar, relationship);
    return (
      shouldConsider &&
      ar.type === relationship.type &&
      (ar.utaEmployee || {})._id === (relationship.utaEmployee || {})._id
    );
  });
  if (dupeEntry) {
    return 'Duplicate Entry';
  }
};

export default InternalTeamUTAEmployeeRelationships;
