import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { FormArray, FormContext, RHF, FormSingleSelectDropdown } from '@united-talent-agency/components';
import { buttonTypes } from '@united-talent-agency/julius-frontend-components';

import { sectionData } from './ReadViewSupport';
import { Territory } from './const';
import { CardButton, CardHeader, CardPane, CardTitle } from '../card/Card';
import { TouringSelector } from './TouringSelector';
import cypressTags from '../../support/cypressTags';

const { useForm, useWatch } = RHF;

const { COMMON } = cypressTags;

export const TouringTableEditor = ({
  territoriesData,
  title,
  individualItemTitle,
  textFieldTitle,
  setReadMode,
  saveData,
  id,
  entityType,
}) => {
  const defaultTerritories = useExistingTerritories(territoriesData);
  const [selectedTerritories, setSelectedTerritories] = useState(defaultTerritories);
  const [disabled, setDisabled] = useState(false);
  const [guaranteeError, setGuaranteeError] = useState({});
  const [territoriesError, setTerritoriesError] = useState('');
  const [minMax, setMinMax] = useState({});

  // Clear error when territories change
  useEffect(() => {
    setTerritoriesError('');
  }, [selectedTerritories]);

  useEffect(() => {
    const guaranteeErrorKeys = Object.keys(guaranteeError);
    if (
      guaranteeErrorKeys.some(
        (ds) => selectedTerritories.has(ds) && (!guaranteeError[ds].min || !guaranteeError[ds].max)
      )
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedTerritories, guaranteeError]);

  const formProps = useForm({
    mode: 'onBlur',
    defaultValues: sectionData(territoriesData),
  });
  const { handleSubmit } = formProps;

  const showTerritories = selectedTerritories;
  const orderedTerritoriesList = Object.values(Territory).filter((territory) => showTerritories.has(territory));

  const onSubmit = async (values) => {
    // Filter only selected territories and flatten the array
    const newData = [];
    orderedTerritoriesList.forEach((territory) => {
      // TODO: This sets the territory, it should have been set in data before this
      const territoryDataList = values[territory]?.map((territoryData) => ({ ...territoryData, territory }));
      if (territoryDataList) {
        newData.push(...territoryDataList);
      }
    });

    await saveData(newData);
    setReadMode();
  };

  function selectRow(territory, guaranteeError, handleGuaranteeError, handleMinMaxCheck) {
    return GuaranteeNamesRow(territory, guaranteeError, handleGuaranteeError, handleMinMaxCheck);
  }

  const handleGuaranteeError = (updateEntry) => {
    const updateEntryKey = Object.keys(updateEntry);
    if (guaranteeError[updateEntryKey]) {
      if (
        guaranteeError[updateEntryKey].min !== updateEntry[updateEntryKey].min ||
        guaranteeError[updateEntryKey].max !== updateEntry[updateEntryKey].max
      ) {
        setGuaranteeError((prev) => ({ ...prev, ...updateEntry }));
      }
    } else {
      setGuaranteeError((prev) => ({ ...prev, ...updateEntry }));
    }
  };

  const handleMinMaxCheck = (val) => {
    const keyName = Object.keys(val);
    if (minMax[keyName] !== val[keyName]) {
      setMinMax((preMinMax) => ({
        ...preMinMax,
        ...val,
      }));
    }
  };

  return (
    <FormContext {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardPane id={id}>
          <CardHeader isEditing>
            <CardTitle>{title}</CardTitle>
            <div data-cy={COMMON.CANCEL_BUTTON}>
              <CardButton type={buttonTypes.cancel} onClick={setReadMode} />
            </div>
            <div data-cy={COMMON.SAVE_BUTTON}>
              <CardButton
                type={buttonTypes.save}
                onClick={handleSubmit(onSubmit)}
                disabled={
                  Object.keys(minMax).some((key) => selectedTerritories.has(key) && minMax[key]) ? true : disabled
                }
              />
            </div>
          </CardHeader>
          <TerritoryCardBody>
            <TouringSelector
              selectedTerritories={selectedTerritories}
              setSelectedTerritories={setSelectedTerritories}
              territoriesError={territoriesError}
            />
            {orderedTerritoriesList.map((territory) => (
              <FormArray
                key={territory}
                name={territory}
                territory={territory}
                individualItemTitle={individualItemTitle}
                textFieldTitle={textFieldTitle}
                requireOne
                itemForm={selectRow(territory, guaranteeError, handleGuaranteeError, handleMinMaxCheck)}
                wrapper={TerritoryWrapper}
                FormArrayPrimaryRadioGroup={false}
              />
            ))}
            {Object.keys(minMax).some((key) => selectedTerritories.has(key) && minMax[key]) && (
              <div style={{ color: 'red', fontSize: '12px', clear: 'both' }}>
                {'The Maximum Price must be equal to or greater than the Minimum Price.'}
              </div>
            )}
          </TerritoryCardBody>
        </CardPane>
      </form>
    </FormContext>
  );
};

// Get a set of all the territories in the data
const useExistingTerritories = (territoriesData) => {
  return useMemo(() => {
    const territories = new Set(territoriesData.map(({ territory }) => territory));

    // Ensure RestOfGuarantees
    return territories.add(Territory.RestOfGuarantees);
  }, [territoriesData]);
};

const TerritoryWrapper = ({ children, append, territory, individualItemTitle }) => {
  return (
    <div>
      <TerritorySeparator />
      <TerritoryHeader>{territory === 'Default Guarantee' ? 'Default' : territory}</TerritoryHeader>
      <div>{children}</div>
    </div>
  );
};

const createMinValues = (price) => {
  let arr = [];
  let value = 0;

  arr.push({
    value: '$' + value.toLocaleString('en-US'),
    label: '$' + value.toLocaleString('en-US'),
  });

  while (value < price) {
    value = value < 100000 ? (value += 10000) : (value += 100000);
    let formattedValue = value.toLocaleString('en-US');
    arr.push({
      value: '$' + formattedValue,
      label: '$' + formattedValue,
    });
  }
  return arr;
};

const createMaxValues = (price) => {
  let arr = [];
  let value = 0;

  arr.push({
    value: '$' + value.toLocaleString('en-US'),
    label: '$' + value.toLocaleString('en-US'),
  });

  while (value < price) {
    value = value < 100000 ? (value += 10000) : (value += 100000);
    let formattedValue = value.toLocaleString('en-US');
    arr.push({
      value: '$' + formattedValue,
      label: '$' + formattedValue,
    });
  }
  return arr;
};

const IndividualGuaranteeNamesRow = ({ entryKey, guaranteeError, handleGuaranteeError, handleMinMaxCheck }) => {
  const minmaxval = useWatch({ name: entryKey });

  useEffect(() => {
    if (minmaxval.length > 0) {
      const minimum = parseFloat(minmaxval[0]?.minimum?.replace(/\$/g, '').replace(/,/g, ''));
      const maximum = parseFloat(minmaxval[0]?.maximum?.replace(/\$/g, '').replace(/,/g, ''));

      const updateEntry = {
        [entryKey]: {
          min: !!minmaxval[0].minimum,
          max: !!minmaxval[0].maximum,
        },
      };

      const updateMinMaxCheck = {
        [entryKey]: minimum > maximum,
      };
      handleMinMaxCheck(updateMinMaxCheck);
      handleGuaranteeError(updateEntry);
    }
  }, [minmaxval, entryKey, handleGuaranteeError, handleMinMaxCheck]);

  const minVal = createMinValues(1000000);
  const maxVal = createMaxValues(1000000);

  return (
    <TerritoryRowContainer>
      <PrimaryColumn style={{ marginTop: 4 }}></PrimaryColumn>
      <span style={{ width: 200, display: 'flex', flexDirection: 'column' }}>
        <FormSingleSelectDropdown title="Minimum Price" name="minimum" required options={minVal} />
        {guaranteeError[entryKey] && !guaranteeError[entryKey].min && (
          <div style={{ color: 'red', fontSize: '12px', clear: 'both', padding: '0px 4px' }}>
            {'Min Price Required'}
          </div>
        )}
      </span>
      <span style={{ width: 200, display: 'flex', flexDirection: 'column' }}>
        <FormSingleSelectDropdown title="Maximum Price" name="maximum" required options={maxVal} />
        {guaranteeError[entryKey] && !guaranteeError[entryKey].max && (
          <div style={{ color: 'red', fontSize: '12px', clear: 'both', padding: '0px 4px' }}>
            {'Max Price Required'}
          </div>
        )}
      </span>
    </TerritoryRowContainer>
  );
};

const GuaranteeNamesRow = (territory, guaranteeError, handleGuaranteeError, handleMinMaxCheck) => {
  // eslint-disable-next-line react/display-name
  return () => {
    return (
      <IndividualGuaranteeNamesRow
        entryKey={`${territory}`}
        guaranteeError={guaranteeError}
        handleGuaranteeError={handleGuaranteeError}
        handleMinMaxCheck={handleMinMaxCheck}
      />
    );
  };
};

export const TerritoryCardBody = styled.div({
  padding: '5px 15px',
  display: 'flex',
  flexDirection: 'column',
});

const TerritoryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PrimaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  width: 48px;
  align-items: center;
`;

const TerritoryHeader = styled.div`
  margin-left: 48px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #141414;
`;
const TerritorySeparator = styled.hr`
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
`;
