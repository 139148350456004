import React, { Component } from 'react';
import { styled } from 'react-free-style';
import { connect } from 'react-redux';

import { Info } from '@united-talent-agency/julius-frontend-components';
import { loadTrackings, updatePersonProfile } from '@united-talent-agency/julius-frontend-store';

import { projectsUrl } from '../../../../support/urls';
import Checkbox from '../../../../components/checkbox';
import Card from '../../../../components/card/ProjectCard';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [
        'Staff',
        'Talent',
        'Agent',
        'Talent Agent',
        'Literary Agent',
        'Indie Agent',
        'Point Agent',
        'Executive',
        'Contact',
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { person } = props;
    let newState = state;
    // Only add buyer roles for industry contact types
    const addBuyer = person.type === 'Industry Contact' && !state.roles.includes('Buyer');
    newState = addBuyer ? { ...state, roles: [...state.roles, 'Buyer'] } : state;
    // Only add Contract Administrator roles for Client, Employee, Industry Contact and Shared Contact types
    const addContractAdministrator = person.type !== 'Outlook' && !state.roles.includes('Contract Administrator');
    newState = addContractAdministrator ? { ...state, roles: [...state.roles, 'Contract Administrator'] } : state;
    return newState;
  }

  componentDidMount() {
    const { dispatch, person } = this.props;
    dispatch(loadTrackings(person._id));
  }

  render() {
    const { styles, mergeCardData } = this.props;
    const columns = mergeCardData ? '' : styles.columns;
    const column = mergeCardData ? '' : styles.column;

    return (
      <div className={columns}>
        <div className={column}>{this.trackingsCard()}</div>
        <div className={column}>{this.roleCard()}</div>
      </div>
    );
  }

  roleStringToKey(role) {
    const roleParts = role.split(' ');
    return `${roleParts[0].toLowerCase()}${roleParts.slice(1).join('')}`;
  }

  roleCard() {
    const { person, dispatch, mergeCardData, setProjectRoles } = this.props;
    const disabled = mergeCardData && mergeCardData.roles.primary === false ? true : false;
    const activeRoles = person.roles && person.roles.projects && Object.keys(person.roles.projects).length > 0;
    const showRoles = !mergeCardData || !mergeCardData?.roles?.isEmpty || activeRoles;

    return !showRoles ? (
      <></>
    ) : (
      <Card title="Roles" mergeCardData={mergeCardData?.roles}>
        {() => (
          <>
            {this.state.roles.map((role) => {
              const checked =
                person.roles && person.roles.projects && !!person.roles.projects[this.roleStringToKey(role)];
              if (checked && mergeCardData) {
                setProjectRoles(true);
              }
              return (
                <Checkbox
                  key={role}
                  checked={checked}
                  disabled={disabled}
                  text={role}
                  onChange={() => {
                    if (checked) {
                      delete person.roles.projects[this.roleStringToKey(role)];
                    } else {
                      if (!person.roles) {
                        person.roles = {};
                      }
                      if (!person.roles.projects) {
                        person.roles.projects = {};
                      }
                      person.roles.projects[this.roleStringToKey(role)] = {};
                    }
                    dispatch(updatePersonProfile(person._id, { roles: person.roles })).then(() => {
                      this.props.updatedProjectRole();
                    });
                  }}
                />
              );
            })}
          </>
        )}
      </Card>
    );
  }
  trackingsCard() {
    const { trackings, mergeCardData } = this.props;
    const activeTrackings = trackings.data && trackings.data.filter((tracking) => !tracking.archived);
    const showTracking = !mergeCardData || !mergeCardData?.tracking?.isEmpty || activeTrackings;

    return !showTracking ? (
      <></>
    ) : (
      <div>
        <Card title="Trackings" mergeCardData={mergeCardData?.tracking}>
          {() => (
            <div>
              {activeTrackings &&
                activeTrackings.map((tracking, index) => (
                  <a href={`${projectsUrl}/list/${tracking._id}`} key={index} target="_blank" rel="noopener noreferrer">
                    <Info name={tracking.name} />
                  </a>
                ))}
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const withStyles = styled({
  columns: {
    display: 'flex',
    height: '100%',
    'flex-flow': 'row wrap',
    justifyContent: 'space-between',
  },
  primaryText: { marginLeft: 10 },
  column: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
});

const withState = connect((state) => {
  const { trackings } = state.trackings;

  return { trackings };
});

export default withStyles(withState(Projects));
