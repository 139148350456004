import React from 'react';
import { Link } from 'react-router-dom';
import { Branding } from '@united-talent-agency/components';
import defaultUtaLogo from '../../assets/logo/UTA_logo_white_200x200.png';
import profilesSymbol from '../../assets/logo/profiles_symbol_200x200.png';
import { useLaunchDarklyCustomHook } from '../../support/hooks';

const linkedLogoStyle = {
  letterSpacing: 4,
  width: 38,
  height: 38,
  cursor: 'pointer',
};

const utaLogo = (
  <Link to="/" className="Link">
    <img alt="UTA Logo" style={linkedLogoStyle} src={defaultUtaLogo} />
  </Link>
);

export default function BrandingWrapped() {
  const [flags] = useLaunchDarklyCustomHook();

  return (
    <Branding
      text={flags.showContactsMergeFeatures ? 'CONTACTS' : 'PROFILES'}
      utaLogo={utaLogo}
      symbol={profilesSymbol}
      showBanner={flags.bannerText}
    />
  );
}
