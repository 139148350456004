import React from 'react';
import { helpers, styled } from 'react-free-style';

import { elements, icons } from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';

export const PublishButton = ({ styles = {}, onPublish, buttonText, isLoading }) => {
  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner size={40} />
      </div>
    );
  } else {
    return (
      <button onClick={onPublish} className={styles.publishButton}>
        <i className={styles.publishButtonIcon} />
        {buttonText}
      </button>
    );
  }
};

export function DirtyBlock({ styles = {}, onPublish, isLoading }) {
  return (
    <div className={styles.unpublished}>
      <span className={styles.private}>
        This Profile has been flagged dirty via changes. Changes can be viewed on the history page.
      </span>
      <PublishButton styles={styles} onPublish={onPublish} buttonText="Accept Changes" isLoading={isLoading} />
    </div>
  );
}

const withStyles = styled({
  publishButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#141414',
    width: '25%',
  }),
  publishButtonIcon: helpers.merge(
    {
      marginRight: 10,
    },
    icons.plus
  ),
  unpublished: {
    display: 'flex',
    padding: '5px 8px',
    fontSize: 10,
    alignItems: 'center',
    background: '#90E2D3',
    marginRight: 30,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    width: '25%',
  },
  private: {
    fontSize: 10,
    fontWeight: 100,
    marginRight: 10,
  },
});

export default withStyles(DirtyBlock);
