import { get } from '../api/index.js';

const getFilterString = (filtersState) => {
  const { includeIndustry, includeOutlook, includeClients, includeEmployee, includeShared, hasContacts } = filtersState;
  let typeFilterString = '';
  if ((includeIndustry, includeOutlook || includeClients || includeEmployee || includeShared)) {
    const typeFilters = [];
    if (includeClients) {
      typeFilters.push('Client');
    }
    if (includeEmployee) {
      typeFilters.push('Employee');
    }
    if (includeIndustry) {
      typeFilters.push('Industry Contact');
    }
    if (includeOutlook) {
      typeFilters.push('Outlook Contact');
    }
    if (includeShared) {
      typeFilters.push('Shared Contact');
    }
    typeFilterString = '&type=' + typeFilters.join(',');
  }

  let hasContactsFilterString = '';
  if (hasContacts) {
    hasContactsFilterString = '&hasContacts=true';
  }
  return typeFilterString + hasContactsFilterString;
};

async function searchCompanies({ query, filterString = '', hitsPerPage }) {
  const response = await get(`/profiles/searchCompaniesByName?count=${hitsPerPage}&name=${query}${filterString}`);
  return { hits: response.body.results };
}

async function searchVenues({ query, filterString = '', hitsPerPage }) {
  const response = await get(`/venues/searchVenuesByName?count=${hitsPerPage}&name=${query}${filterString}`);
  return { hits: response.body.results };
}

async function searchPeople({ query, filterString = '', hitsPerPage }) {
  const response = await get(`/profiles/searchPeopleByName?count=${hitsPerPage}&name=${query}${filterString}`);
  return { hits: response.body.results };
}

async function search({ query, filtersState = {}, searchIndex, contactEntityType, hitsPerPage = 50 }) {
  const filterString = getFilterString(filtersState);
  if (contactEntityType === 'people') {
    return searchPeople({ query, filterString, hitsPerPage });
  } else if (contactEntityType === 'companies') {
    return searchCompanies({ query, filterString, hitsPerPage });
  } else if (contactEntityType === 'venues') {
    return searchVenues({ query, filterString, hitsPerPage });
  } else {
    throw new Error(`Invalid search index: ${searchIndex}`);
  }
}

export default search;
