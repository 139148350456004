import React from 'react';
import { Info } from '@united-talent-agency/julius-frontend-components';

export default function TerritoriesReadView({ touringTerritories }) {
  const territoryList =
    touringTerritories.length &&
    touringTerritories.map((territoryObj, idx) => {
      const { territory = '', location = '', subsidiary = '', lineOfBusiness = '', department = '' } = territoryObj;
      return (
        <div key={idx} className="wrapper">
          <Info name="Territory" styles={{ info: 'field-row' }}>
            {territory}
          </Info>
          <Info name="Location" styles={{ info: 'field-row' }}>
            {location}
          </Info>
          <Info name="Subsidiary" styles={{ info: 'field-row' }}>
            {subsidiary}
          </Info>
          <Info name="Line of Business" styles={{ info: 'field-row' }}>
            {lineOfBusiness}
          </Info>
          <Info name="Department" styles={{ info: 'field-row' }}>
            {department}
          </Info>
        </div>
      );
    });

  return touringTerritories.length ? <>{territoryList}</> : null;
}
