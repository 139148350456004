import { isArray, cloneDeep } from 'lodash';

const isEmail = (str) => /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(str);

const transformResponse = (data) =>
  data && data.length
    ? data.map((obj) => {
        let copy = cloneDeep(obj);
        const { contacts, internalTeam, utaEmployeeInfo, representedByAgencies, territoryInfo } = copy?.delta;

        if (copy?.recordCreated) {
          const recordCreated_ = {
            changeType: 'Created',
            name: copy['delta']['name'],
          };
          copy['delta'] = {};
          copy['delta']['recordCreated'] = [recordCreated_];
        }

        if (territoryInfo?.touringAgents) {
          const { touringAgents } = territoryInfo;
          copy['delta']['territoryInfo']['touringAgents'] = [];
          const touringKeys = Object.keys(touringAgents);
          copy['delta']['territoryInfo']['touringAgents'].push(
            touringKeys
              .filter((ds) => ds !== '_t')
              .map((key) => {
                if (key === '_1') {
                  return {
                    changeType: 'Deleted',
                    agent: touringAgents[key][0],
                  };
                } else {
                  return {
                    changeType: 'Updated',
                    agent: touringAgents[key],
                  };
                }
              })
          );
        }
        if (contacts) {
          /**
           * We have to filter out keys other than '_t' to fetch the keys related to the delta. I do not know why this object has been
           * structured this way. This is the object we get from the history API.
           * */
          const deltaKeys = Object.keys(contacts).filter((x) => x !== '_t');
          copy['delta']['contacts'] = [];

          /**
           * The primary contact has been unset. It can be both an Email or Phone number.
           * We do not have clear-cut information about which contact has been unset.
           **/
          if (
            deltaKeys[0] &&
            Object.keys(contacts[deltaKeys[0]]).length === 1 &&
            Object.keys(contacts[deltaKeys[0]]).includes('primary') &&
            Object.keys(contacts[deltaKeys[0]]['primary']).length === 2 &&
            contacts[deltaKeys[0]]['primary'][0] === true
          ) {
            copy['delta']['contacts'] = [
              {
                changeType: 'Deleted',
                contactType: 'Primary',
                isPrimary: true,
              },
            ];
          } else if (
            /**
             * The primary contact has been set. It can be both an Email or Phone number.
             * We do not have clear-cut information about which contact has been unset.
             **/
            (deltaKeys[0] &&
              Object.keys(contacts[deltaKeys[0]]).length === 1 &&
              Object.keys(contacts[deltaKeys[0]]).includes('primary') &&
              Object.keys(contacts[deltaKeys[0]]['primary']).length === 1) ||
            (deltaKeys[0] &&
              Object.keys(contacts[deltaKeys[0]]).length === 1 &&
              Object.keys(contacts[deltaKeys[0]]).includes('primary') &&
              Object.keys(contacts[deltaKeys[0]]['primary']).length === 2 &&
              contacts[deltaKeys[0]]['primary'][0] === false)
          ) {
            //Primary contact has been unset
            copy['delta']['contacts'] = [
              {
                changeType: 'Added',
                contactType: 'Primary',
                isPrimary: true,
              },
            ];
          }

          for (let key of deltaKeys) {
            const value = contacts[key];
            if (isArray(value)) {
              // if type of 'value' is an array
              // As per the array structure if the array 'value' has 3 values then the Contact has been deleted. Otherwise a new contact has been added
              const changeType = value.length === 3 && value[1] === 0 && value[2] === 0 ? 'Deleted' : 'Added';

              if (value?.length || value[0]?.length) {
                copy['delta']['contacts'].push({
                  changeType,
                  contactType: value[0].contactType,
                  contact: value[0].contact,
                });
              }
            } else {
              //if type of value is 'object'
              // These are the different key values that hold values in the response of the history API
              if (key === '0' || key === '1' || key === '2' || key === '3') {
                const value = contacts[key];

                if (value && isArray(value.contact) && value['contact']) {
                  copy['delta']['contacts'].push({
                    changeType: 'Edited',
                    oldValue: value['contact'][0],
                    newValue: value['contact'][1],
                    contactType: isEmail(value['contact'][0]) ? 'Email' : 'Phone',
                  });
                }

                if (typeof value === 'object' && value.contactType && value.contact) {
                  copy['delta']['contacts'].push({
                    changeType: 'Added',
                    contactType: value.contactType,
                    contact: value.contact,
                    ...(value.primary && { isPrimary: value.primary }),
                  });
                }
              }
            }
          }
        }

        if (internalTeam) {
          if (typeof internalTeam === 'object') {
            if (internalTeam['_0'] && internalTeam['_0'][0].utaEmployee.name && internalTeam['_0'][0].type) {
              copy['delta']['internalTeam'] = {
                changeType: 'Deleted',
                name: internalTeam['_0'][0].utaEmployee.name,
                agentType: internalTeam['_0'][0].type,
              };
            }

            if (
              internalTeam['0'] &&
              internalTeam['0'][0] &&
              internalTeam['0'][0].utaEmployee.name &&
              internalTeam['0'][0].type
            ) {
              copy['delta']['internalTeam'] = {
                changeType: 'Added',
                name: internalTeam['0'][0].utaEmployee.name,
                agentType: internalTeam['0'][0].type,
              };
            }

            if (internalTeam.name && internalTeam.type) {
              copy['delta']['internalTeam'] = {
                changeType: 'Added',
                name: internalTeam.name,
                agentType: internalTeam.type,
              };
            }
          } else {
            const internalTeamKeys = Object.keys(internalTeam).filter((x) => x !== '_t');

            for (let internalTeamKey of internalTeamKeys) {
              const valueInternal = internalTeam[internalTeamKey];

              if (isArray(valueInternal)) {
                const changeType =
                  valueInternal.length === 3 && valueInternal[1] === 0 && valueInternal[2] === 0 ? 'Deleted' : 'Added';
                copy['delta']['internalTeam'] = {
                  changeType,
                  agentType: valueInternal['0']['type'],
                  name: valueInternal['0']['utaEmployee']['name'],
                };
              }
            }
          }
        }

        if (utaEmployeeInfo) {
          let groupRelationships, personRelationships;

          if (utaEmployeeInfo.internalTeam) {
            groupRelationships = utaEmployeeInfo.internalTeam.groupRelationships;
            personRelationships = utaEmployeeInfo.internalTeam.personRelationships;
          }

          let relationshipsObj;
          let keyName = '';

          if (groupRelationships) {
            relationshipsObj = groupRelationships;
            keyName = 'group';
          }

          if (personRelationships) {
            relationshipsObj = personRelationships;
            keyName = 'person';
          }

          const relationshipsObjKeys = relationshipsObj ? Object.keys(relationshipsObj).filter((x) => x !== '_t') : [];

          if (relationshipsObjKeys.length) {
            for (let relationshipKey of relationshipsObjKeys) {
              const objValue = relationshipsObj[relationshipKey];
              const changeTypeStr =
                objValue.length === 3 && objValue[1] === 0 && objValue[2] === 0 ? 'Deleted' : 'Added';

              const personOrCompanyObj = objValue[0];
              //Adding null checks as the data is inconsistent from the History API
              if (
                personOrCompanyObj &&
                personOrCompanyObj[`${keyName}`] &&
                personOrCompanyObj[`${keyName}`]['name'] &&
                personOrCompanyObj[`${keyName}`]['type']
              ) {
                if (changeTypeStr === 'Deleted') {
                  copy['delta']['utaEmployeeInfo'] = {
                    changeType: changeTypeStr,
                    for: objValue[0][`${keyName}`]['name'],
                    type: objValue[0][`${keyName}`]['type'],
                  };
                } else {
                  copy['delta']['utaEmployeeInfo'] = {
                    changeType: changeTypeStr,
                    inverse: `${objValue[0].inverse}`,
                    to: objValue[0][`${keyName}`]['name'],
                    type: objValue[0][`${keyName}`]['type'],
                  };
                }
              } else {
                copy['delta']['utaEmployeeInfo'] = {};
              }
            }
          } else {
            copy['delta']['utaEmployeeInfo'] = {};
          }
        }

        if (representedByAgencies) {
          const representedByAgenciesObjKeys = Object.keys(representedByAgencies).filter((x) => x !== '_t');

          if (representedByAgenciesObjKeys.length) {
            for (let objKey of representedByAgenciesObjKeys) {
              const objectValue = representedByAgencies[objKey];
              const changeTypeString =
                objectValue.length === 3 && objectValue[1] === 0 && objectValue[2] === 0 ? 'Deleted' : 'Added';

              //Adding null checks as the data is inconsistent from the History API
              if (objectValue[objKey] && objectValue[objKey]['company'] && objectValue[objKey]['company']['name']) {
                copy['delta']['representedByAgencies'] = {
                  changeType: changeTypeString,
                  representingAgency: objectValue[0]['company']['name'],
                };
              } else {
                copy['delta']['representedByAgencies'] = {};
              }
            }
          }
        }

        return copy;
      })
    : [];

export { transformResponse };
