import { get, patch } from './index.js';

export const getTouringTypes = async (url) => {
  const response = await get(url);

  return response.body.error ? [] : response.body;
};

export const getPersonToTouringAgentRelations = async (id) => {
  const response = await get(`/touring-teams/person?person=${id}`);
  return response?.body ?? [];
};

export const getCompanyToTouringAgentRelations = async (id) => {
  const response = await get(`/touring-teams/company?company=${id}`);
  return response?.body ?? [];
};

export const getVenueToTouringAgentRelations = async (id) => {
  const response = await get(`/touring-teams/venue?venue=${id}`);
  return response?.body ?? [];
};

export function updateEntityToTouringAgentRelationships({
  personId,
  companyId,
  venueId,
  creates = [],
  updates = [],
  deletes,
}) {
  const requiredParamsMessage = 'Must supply companyId, personId or venueId';

  if ((companyId && personId && venueId) || (!companyId && !personId && !venueId)) {
    return Promise.reject(requiredParamsMessage);
  }

  const payload = {
    touringTeam: creates.concat(updates),
    deletes,
  };

  if (personId) {
    payload.person = personId;
    return patch('/touring-teams/person', payload);
  } else if (companyId) {
    payload.company = companyId;
    return patch('/touring-teams/company', payload);
  } else {
    payload.venue = venueId;
    return patch('/touring-teams/venue', payload);
  }
}

export const getPersonToAccountingRepRelations = async (id) => {
  const response = await get(`/accounting-rep/person?person=${id}`);
  return response?.body ?? [];
};

export function updateEntityToAccountingRepRelationships({
  personId,
  companyId,
  venueId,
  creates = [],
  updates = [],
  deletes,
}) {
  const requiredParamsMessage = 'Must supply companyId, personId or venueId';

  if ((companyId && personId && venueId) || (!companyId && !personId && !venueId)) {
    return Promise.reject(requiredParamsMessage);
  }

  const payload = {
    accountingRep: creates.concat(updates),
    deletes,
  };

  if (personId) {
    payload.person = personId;
    return patch('/accounting-rep/person', payload);
  }
}
