const { ContactSorter, contactSortFormatter, getPrimaryPhone } = require('./contact-sorter');

const entitySortFormatter = (sortField) => {
  switch (sortField) {
    case 'Name':
      return (entity) => {
        return entity.name.trim().toUpperCase();
      };
    case 'Type':
      return (entity) => {
        return entity.type.toUpperCase();
      };
    case 'Phone':
      return (entity) => {
        const phone = getPrimaryPhone(entity.contacts);
        const phoneSanitizer = contactSortFormatter(sortField);
        return phone ? phoneSanitizer(phone) : '';
      };
    case 'Email':
      return (entity) => {
        if (entity.email) return entity.email && entity.email.contact ? entity.email.contact.trim() : '';
        const emails = ContactSorter(entity.contacts, sortField, 1);
        const email = emails.length > 0 && emails[0];
        const emailSanitizer = contactSortFormatter(sortField);
        return email ? emailSanitizer(email) : '';
      };
    case 'Title':
      return (entity) => {
        return entity.title ? entity.title.trim().toUpperCase() : '';
      };
    case 'Company':
      return () => {
        return '';
      };
    default:
      throw new Error(`Unsupported Sort Field: ${sortField}`);
  }
};
const EntitySorter = (entities, sortField, sortDirection) => {
  if (!sortDirection) return entities;

  const sortFunc = entitySortFormatter(sortField);
  const sortedEntities = entities.sort((a, b) => {
    const val1 = sortDirection === 1 ? sortFunc(a) : sortFunc(b);
    const val2 = sortDirection === 1 ? sortFunc(b) : sortFunc(a);
    return +(val1 > val2) || -(val1 < val2);
  });
  return sortedEntities;
};

module.exports = EntitySorter;
