import { searchContactPortal, personTypes } from '@united-talent-agency/julius-frontend-store';
import PersonResultTransformer from './person-result-transformer';
import { getPeopleByContacts } from '../api/people';

export const findPeople = ({
  dispatch,
  searchText,
  includeClients,
  includeIndustry,
  includeShared,
  includeEmployee,
  contactDao = searchContactPortal,
  _personResultTransformer = PersonResultTransformer,
  hasContacts = false,
}) => {
  const types = [];
  includeClients && types.push(personTypes.client);
  includeIndustry && types.push(personTypes.industryContact);
  includeShared && types.push(personTypes.shared);
  includeEmployee && types.push(personTypes.employee);
  if (types.length === 0) {
    return [];
  }
  const contactSearch = dispatch(contactDao(searchText, types, hasContacts))
    .then((results) => {
      const people = results ? results.body.data : [];
      const transformedPeople = people.map((person) => _personResultTransformer(person));
      return transformedPeople;
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
  return contactSearch;
};

export const findPeopleByContact = ({
  dispatch,
  searchText,
  includeClients,
  includeIndustry,
  includeShared,
  includeEmployee,
  contactDao = searchContactPortal,
  _personResultTransformer = PersonResultTransformer,
}) => {
  const types = [];
  includeClients && types.push(personTypes.client);
  includeIndustry && types.push(personTypes.industryContact);
  includeShared && types.push(personTypes.shared);
  includeEmployee && types.push(personTypes.employee);
  if (types.length === 0) {
    return [];
  }
  const contactSearch = getPeopleByContacts(searchText, types)
    .then((results) => {
      const people = results ? results.body : [];
      const transformedPeople = people.map((person) => _personResultTransformer(person));
      return transformedPeople;
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
  return contactSearch;
};

export default { findPeople, findPeopleByContact };
