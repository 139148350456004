import React from 'react';
import PropTypes from 'prop-types';
import { helpers, styled } from 'react-free-style';

import { colors, elements } from '@united-talent-agency/julius-frontend-components';
import { VerificationIcon, Icons } from '@united-talent-agency/components';

import { getPersonContacts } from '../../data/contact-sorter';
import { profileUrl, companyProfileUrl, venueProfileUrl } from '../../support/urls';
import TypeIcon from '../../components/type-icon/type-icon';
import cypressTags from '../../support/cypressTags';

const { SEARCH } = cypressTags;

const { EmailIcon, UserIcon, MobileOutlineIcon, OfficePhoneIcon, HomePhoneIcon, EnvironmentOutlinedIcon } = Icons;

const entityTypeOptions = new Map([
  ['person', { Icon: UserIcon, url: profileUrl }],
  ['group', { Icon: OfficePhoneIcon, url: companyProfileUrl }],
]);

export const ContactName = ({ person, styles }) => {
  const { _id, name, verifiedBy, verifiedOn, isVenue = false } = person;

  const isMasterData = !(person?.type === 'Outlook'); //more specifically, is not outlook
  const entityOptions = entityTypeOptions.get(person.entityType);
  const EntityTypeIcon = entityOptions && entityOptions.Icon;
  const entityTypeUrl = entityOptions && entityOptions.url;
  return isMasterData ? (
    <span className={styles.linkContainer}>
      <a
        href={`${isVenue ? venueProfileUrl : entityTypeUrl}/${_id}`}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name ?? ''}
      </a>
      <span className={styles.middot} />
      {isVenue ? (
        <EnvironmentOutlinedIcon style={{ fontSize: 12, color: '#141414', top: 4, position: 'relative' }} />
      ) : (
        <EntityTypeIcon style={{ fontSize: 12, color: '#141414', top: 4, position: 'relative' }} />
      )}
      {verifiedBy && verifiedOn && (
        <VerificationIcon
          verifiedBy={`${verifiedBy.first_name} ${verifiedBy.last_name}`}
          verifiedOn={verifiedOn}
          identifier={_id}
          includeExplanation
        />
      )}
    </span>
  ) : (
    name
  );
};

const ContactRow = ({ styles = {}, person, expanded = false, onExpandChanged = () => {} }) => {
  if (!person.contacts) person.contacts = [];
  const email = getPersonContacts(person.contacts).email;
  const phone = getPersonContacts(person.contacts).phone;

  const phoneDisplay = phone?.contact || '';
  return (
    <tr className={styles.tr}>
      <td width={1} style={{ textAlign: 'left', paddingLeft: 16, paddingRight: 4 }}>
        {person.isVenue ? (
          <TypeIcon entity={{ type: 'Industry Contact' }} customStyles={{ fontSize: 12, width: 20, height: 20 }} />
        ) : (
          <TypeIcon entity={person} customStyles={{ fontSize: 12, width: 20, height: 20 }} />
        )}
      </td>
      <td>
        <span className={styles.person}>
          <ContactName person={person} styles={styles} />
        </span>
      </td>
      <td>
        <span className={styles.person}>{person.isVenue ? '-' : person.title}</span>
      </td>
      <td>
        <span className={styles.person}>{person.isVenue ? '-' : person.company}</span>
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {phone && phone.contactType === 'Office Phone' && (
          <OfficePhoneIcon style={{ marginTop: -2, fontSize: 12, color: '#141414', marginRight: 2 }} />
        )}
        {phone && phone.contactType === 'Mobile Phone' && (
          <MobileOutlineIcon style={{ marginTop: -2, fontSize: 12, color: '#141414', marginRight: 2 }} />
        )}
        {phone && phone.contactType === 'Home Phone' && (
          <HomePhoneIcon style={{ marginTop: -2, fontSize: 12, color: '#141414', marginRight: 2 }} />
        )}
        <span className={styles.person}>
          {phoneDisplay.includes('+') ? (
            <a href={'tel:' + phoneDisplay}>{phoneDisplay}</a>
          ) : (
            <a href={'tel:+1' + phoneDisplay}>{phoneDisplay}</a>
          )}
        </span>
      </td>
      <td>
        <span className={styles.person}>
          {email && (
            <span>
              <EmailIcon style={{ marginTop: -2, fontSize: 12, color: '#141414', marginRight: 2 }} />
              <a href={'mailto:' + email.contact}>{email.contact}</a>
            </span>
          )}
        </span>
      </td>
      <td>
        <span
          className={styles.person}
          data-cy={SEARCH.DROPDOWN_EXPAND_CONTACT}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onExpandChanged && onExpandChanged(!expanded);
          }}
        >
          <i style={{ fontSize: 18, width: 18 }} className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
        </span>
      </td>
    </tr>
  );
};

const withStyles = styled({
  linkContainer: { display: 'flex' },
  link: {
    color: '#2187B9',
    fontSize: 12,
    '&:hover': {
      color: '#2187B9',
    },
  },
  title: {
    marginLeft: '10px',
    fontSize: '10px',
    fontWeight: 300,
    width: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tr: {
    '> td': {
      padding: '2px',
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: '11px',
      fontWeight: 300,
      color: colors.text,
      verticalAlign: 'middle',
    },
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
  },
  person: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    color: '#4A4A4A',
  },
  data: {
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '5px',
  }),
  defaultButton: {
    borderColor: '#000',
  },
  redButton: {
    borderColor: colors.invalidBorder,
  },
  greenButton: {
    borderColor: colors.green,
  },
  middot: {
    fontWeight: 700,
    borderRadius: '50%',
    backgroundColor: '#141414',
    display: 'inline-block',
    width: 3,
    height: 3,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 6,
    marginRight: 2,
  },
});

ContactName.propTypes = {
  person: PropTypes.object,
  styles: PropTypes.object,
};

ContactRow.propTypes = {
  person: PropTypes.object,
  styles: PropTypes.object,
  expanded: PropTypes.bool,
  onExpandChanged: PropTypes.func,
};

export default withStyles(ContactRow);
