import React, { useEffect, useState, useReducer } from 'react';
import { notify } from 'react-notify-toast';
import { getGroupMembership, updateGroupMembership } from '@united-talent-agency/julius-frontend-store';

import EmployeeEditor from './employee-editor';
import EmployeeLabel from './employee-label';
import Card from '../card/ProjectCard';

const cardDataKey = 'profileGroupMembers';

export const CardEmployees = ({ company, dispatch, readOnly, mergeCardData, refreshProfile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [members, setMembers] = useState([]);

  //to show render removing a created item when multiple created items exist
  const [, forceUpdate] = useReducer(() => new Date(), 0);

  const resetMembers = (force) => {
    setIsValid(true);
    // Use the up to date data in redux, unless we're editing
    if (!isEditing || force) {
      company._id &&
        dispatch(getGroupMembership({ groupId: company._id })).then((result) => {
          const response = Array.isArray((result || {}).body) ? result.body : [];
          setMembers(response);
        });
    }
  };

  useEffect(resetMembers, [company]);

  const onCreate = (props) => props.createBy(cardDataKey, undefined, { group: { name: '' } });

  const validateEntry = ({ creates, updates, deletes }) => {
    const invalidCreates = (creates[cardDataKey] || []).filter((create) => !(create.person && create.person._id));
    const invalidUpdates = Object.keys(updates[cardDataKey] || {})
      .map((memberId) => {
        const existingMember = members.find((member) => {
          return member._id === memberId;
        });
        const memberChanges = updates[cardDataKey][memberId];
        const mergedMember = { ...existingMember, ...memberChanges };
        return mergedMember;
      })
      .filter((update) => {
        //ignore updated objects that are being deleted
        return !(Object.keys(deletes[cardDataKey] || []) || []).some((deletedId) => deletedId === update._id);
      })
      .filter((update) => !(update.person && update.person._id));

    setIsValid(!invalidCreates.length && !invalidUpdates.length);
  };

  const saveChanges = (changes) => {
    const memberChanges = {};
    if (changes.creates && changes.creates[cardDataKey]) {
      memberChanges.creates = changes.creates[cardDataKey];
    }
    if (changes.deletes && changes.deletes[cardDataKey]) {
      const deletedIds = Object.keys(changes.deletes[cardDataKey])
        .map((memberId) => {
          return changes.deletes[cardDataKey][memberId] && memberId;
        })
        .filter((id) => id);
      if (deletedIds.length > 0) {
        memberChanges.deletes = deletedIds;
      }
    }

    if (changes.updates && changes.updates[cardDataKey]) {
      const updates = Object.keys(changes.updates[cardDataKey])
        .map((memberId) => {
          const existingMember = members.find((member) => {
            return member._id === memberId;
          });
          const memberChanges = changes.updates[cardDataKey][memberId];
          const mergedMember = { ...existingMember, ...memberChanges };
          return mergedMember;
        })
        .filter((update) => update.person)
        .filter((update) => {
          //ignore updated objects that are being deleted
          return !(memberChanges.deletes || []).some((deletedId) => deletedId === update._id);
        });
      if (updates.length > 0) {
        memberChanges.updates = updates;
      }
    }

    if (Object.keys(memberChanges).length === 0) {
      return;
    }

    return dispatch(updateGroupMembership({ groupId: company._id, members: memberChanges }))
      .then(() => {
        notify.show('Employees Saved', 'success');
      })
      .catch((error) => {
        const errorMessage = error.message;
        notify.show(`Error Saving: ${errorMessage}`, 'error');
      })
      .then(() => {
        resetMembers(true);
        refreshProfile && refreshProfile();
      });
  };

  const showEmployees = !mergeCardData || !mergeCardData.isEmpty || members.length > 0;

  return !showEmployees ? (
    <></>
  ) : (
    <Card
      title={`${company.name} Employees`}
      mergeCardData={mergeCardData}
      hasError={!isValid}
      saveChanges={saveChanges}
      onCreate={onCreate}
      onChange={validateEntry}
      onCancel={() => resetMembers(true)}
      onIsEditingChanged={setIsEditing}
      canEdit={!readOnly}
    >
      {({ creates, updates, deletes, createBy, updateBy, deleteBy, isEditing }) => {
        return (
          <div>
            {members.map((member, index) => {
              if (deletes[cardDataKey] && deletes[cardDataKey][member._id]) return null;
              if (!isEditing) {
                return <EmployeeLabel key={index} value={member} />;
              }

              const editedMember = updates[cardDataKey]
                ? {
                    ...member,
                    ...updates[cardDataKey][member._id],
                  }
                : member;

              return (
                <EmployeeEditor
                  key={`EmployeeEditor-Update-${index}`}
                  member={editedMember}
                  onDelete={() => {
                    deleteBy(cardDataKey, member._id);
                  }}
                  dispatch={dispatch}
                  onMemberChanged={(updatedMember) => {
                    updateBy(cardDataKey, member._id, updatedMember);
                  }}
                />
              );
            })}
            {(creates[cardDataKey] || []).map((member, index) => (
              <EmployeeEditor
                key={`EmployeeEditor-Create-${index}`}
                member={member}
                onDelete={() => {
                  creates[cardDataKey].splice(index, 1);
                  validateEntry({ creates, updates, deletes });
                  forceUpdate();
                }}
                dispatch={dispatch}
                onMemberChanged={(updatedMember) => {
                  createBy(cardDataKey, index, updatedMember);
                }}
              />
            ))}
          </div>
        );
      }}
    </Card>
  );
};

export default CardEmployees;
