import React from 'react';
// import { Prompt } from 'react-router';

export const CardContext = React.createContext({});

// Currently used to tell parent Company/Venue index that the data has changed

// This context could eventually:
//  - Prevent navigation if the user is currently editing a card
//  - Allow only one card to be in edit mode at a time

export default function CardPageContainer({ children, onSave }) {
  return (
    <CardContext.Provider value={{ onSave }}>
      {/*<Prompt message={'Are you sure you want to go away?'} />*/}
      {children}
    </CardContext.Provider>
  );
}
