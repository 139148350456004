import { get, patch, post } from './index.js';

export const createGroup = async (payload) => {
  const response = await post('/groups', payload);
  return response.body.data;
};

export const getGroup = async (id) => {
  const response = await get(`/groups/${id}`);
  return response.body.data;
};

export const updateGroup = async (id, payload) => {
  const response = await patch(`/groups/${id}`, payload);
  return response.body.data;
};

/**
 * Makes an API call to the GET /groups resource
 *    getGroups("asdf", ['Client', Industry Contact']) =>
 *      /groups?search="{searchText}"&type={types[0]}&type={types[1]} =>
 *      /groups?search="asdf"&type=Client&type=Industry%20Contact
 *
 * @param searchText: The text to search for in groups - exact search is automatically applied here by surrounding the search text with double-quotes
 * @param types: The types of groups to search for: Client, Industry Contact, Shared Contact
 * @param limit: The maximum number of results (default 10)
 *
 * @returns A list of groups from the response payload (no meta result yet)
 **/
export const getGroups = (searchText, types = [], limit = 10, _get = get) => {
  const query = [];
  if (searchText) {
    //wrap search text in double quotes to force an exact match search
    query.push({ search: `"${searchText}"` });
  }
  types.forEach((t) => {
    query.push({ type: t });
  });
  if (limit) {
    query.push({ limit });
  }

  const execPlan = _get(`/groups`, query).then((response) => {
    const { errors, data, meta } = (response || {}).body || {};
    if (errors) {
      //errors is an array, but v3 only returns a single error in the array
      //this is for api-handled errors,
      //  - unhandled errors shouldnt get this far (such as network outage)
      return Promise.reject(errors[0]);
    }
    const results = { data, meta };
    return results;
  });
  return execPlan;
};
