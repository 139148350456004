export const saveChanges = async (saveCardChangesProps) => {
  const { creates, deletes, updates, changes, person, dispatch, loadPerson } = saveCardChangesProps;
  let actions = [];

  Object.keys(changes.creates || {}).forEach((type) => {
    const action = creates[type];
    const payloads = changes.creates[type] || [];

    payloads.forEach((payload) => {
      actions.push(action(payload));
    });
  });

  Object.keys(changes.updates || {}).forEach((type) => {
    const action = updates[type];
    const entities = changes.updates[type] || {};

    Object.keys(entities).forEach((id) => {
      actions.push(action(id, entities[id]));
    });
  });

  Object.keys(changes.deletes || {}).forEach((type) => {
    const action = deletes[type];
    const entities = changes.deletes[type] || {};

    Object.keys(entities).forEach((id) => {
      actions.push(action(id));
    });
  });

  // Apply primary updates
  await actions.reduce((p, action) => {
    return p.then(() => dispatch(action));
  }, Promise.resolve());

  actions = [];

  actions.push(loadPerson(person._id));

  // Apply conditional updates and reload profile
  await actions.reduce((p, action) => {
    return p.then(() => dispatch(action));
  }, Promise.resolve());
};
