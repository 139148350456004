import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ProfileView from '../../../profile/views/profile/profile';

import { getUTACompany } from '@united-talent-agency/julius-frontend-store';

export default function Profile({ person = {}, user = {}, cardData = {}, refreshProfile, saveProfileChanges }) {
  const [utaCompany, setUTACompany] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUTACompany()).then((result) => {
      const utaCompany = result.body || {};
      setUTACompany({ utaCompany });
    });
  }, [dispatch]);

  return (
    <ProfileView
      person={person}
      utaCompany={utaCompany}
      user={user}
      mergeCardData={cardData}
      refreshProfile={() => refreshProfile()}
      saveChanges={(changes) => saveProfileChanges(changes)}
    />
  );
}
