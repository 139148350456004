import React from 'react';
import { Tooltip, Icons, colors } from '@united-talent-agency/components';

const LockedIcon = ({ deskConnected, onClick }) => {
  const tooltipText = deskConnected
    ? 'Unlock to make contact visible'
    : 'Please reach out to the internal team for contact details';
  const iconColor = deskConnected ? colors.utaBlack : colors.disabledGrey;
  const cursor = deskConnected ? 'pointer' : 'default';
  const canClick = deskConnected && onClick;
  const onClickAction = canClick ? () => onClick(false) : null;
  return (
    <Tooltip text={tooltipText}>
      <div style={{ cursor }} onClick={onClickAction}>
        <Icons.LockedIcon color={iconColor} />
      </div>
    </Tooltip>
  );
};

const UnlockedIcon = ({ deskConnected, onClick }) => {
  const tooltipText = 'Lock to make contact private';
  const iconColor = deskConnected ? colors.utaBlack : colors.disabledGrey;
  const cursor = deskConnected ? 'pointer' : 'default';
  const canClick = deskConnected && onClick;
  const onClickAction = canClick ? () => onClick(true) : null;
  return deskConnected ? (
    <Tooltip text={tooltipText}>
      <div style={{ cursor }} onClick={onClickAction}>
        <Icons.UnlockedIcon color={iconColor} />
      </div>
    </Tooltip>
  ) : (
    <div style={{ cursor }} onClick={onClickAction}>
      <Icons.UnlockedIcon color={iconColor} />
    </div>
  );
};
const PrimaryUnlockedIcon = () => {
  return (
    <Tooltip text="Primary contacts cannot be private">
      <Icons.UnlockedIcon color={colors.disabledGrey} />
    </Tooltip>
  );
};

export const LockIcon = ({ deskConnected, primaryContact, isPrivate, onClick }) => {
  if (primaryContact) {
    return PrimaryUnlockedIcon();
  }
  if (isPrivate) {
    return LockedIcon({ deskConnected, onClick });
  }
  return UnlockedIcon({ deskConnected, onClick });
};

export default LockIcon;
