import { searchOutlook, updateOutlookContact } from '@united-talent-agency/julius-frontend-store';
import OutlookResultTransformer from './outlook-result-transformer';

export const findContacts = ({
  dispatch,
  deskId,
  searchText,
  outlookDao = searchOutlook,
  _outlookResultTransformer = OutlookResultTransformer,
}) => {
  const outlookSearch = dispatch(outlookDao(searchText, deskId))
    .then((results) => {
      return results && results.body
        ? results.body.map((r) => {
            return _outlookResultTransformer(r);
          })
        : [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
  return outlookSearch;
};

export const updateOutlook = (dispatch, deskId, personId, update, _updateOutlookPerson = updateOutlookContact) => {
  return dispatch(_updateOutlookPerson(deskId, personId, update))
    .then((result) => {
      return OutlookResultTransformer(result.body || {});
    })
    .catch((err) => {
      console.log(err);
    });
};

export default { findContacts, updateOutlook };
