export const vocationOptions = [
  'Actor',
  'Author',
  'Comedy (Live)',
  'Director',
  'Journalist',
  'Musician',
  'Podcast',
  'Producer',
  'Production Arts',
  'Speaker',
  'Sports',
  'Unscripted',
  'Video Games',
  'Visual Artist',
  'Writer',
];

export const genreOptions = [
  'Afrobeats',
  'Adult Contemporary',
  'Alternative',
  'Blues',
  'Chefs',
  'Classical',
  'Christian',
  'Comedy',
  'Country',
  'Electronic',
  'Folk',
  'Gospel',
  'Hip Hop',
  'Indie',
  'Jazz',
  'Spanish Language',
  'Literary',
  'Mediums',
  'Metal',
  'Open-Format',
  'Other',
  'Podcasts',
  'Pop',
  'Presenters',
  'Rap',
  'Reggae',
  'R&B',
  'Rock',
  'Singer-Songwriter',
  'Speakers',
  'YouTube',
];
