import React from 'react';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { elements } from '@united-talent-agency/julius-frontend-components';

const InfoLine = ({ relationship, isClientComedy, styles = {} }) => {
  return (
    <>
      <div className={styles.container}>
        <div className={classnames(styles.typeColumn, relationship.inverse ? '' : styles.toRelationship)}>
          {isClientComedy && relationship.type === 'Papering Agent' ? 'Responsible Agent' : relationship.type}
        </div>
        <div className={styles.companyColumn}>
          <a href={`/profile/${relationship?.personId?._id}?view=team`} className={styles.link}>
            {(relationship.personId || {}).name}
          </a>
        </div>
      </div>
      <div className={styles.containerRow}>
        <div className={styles.typeRow}>
          <div className={classnames(styles.divider, relationship.inverse ? '' : styles.toRelationship)}>
            {relationship.regions[0]}
          </div>
        </div>
      </div>
    </>
  );
};

const withStyles = styled({
  container: { display: 'flex', flex: 1, flexDirection: 'row', fontWeight: 400, color: '#4A4A4A', fontSize: '12px' },
  containerRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: '12px',
  },
  typeRow: { flex: 0.5, paddingRight: 5, paddingBottom: 10, textAlign: 'right' },
  typeColumn: { flex: 0.5, paddingRight: 5, paddingBottom: 2 },
  toRelationship: { fontWeight: 300 },
  companyColumn: { flex: 0.5, paddingRight: 5, paddingBottom: 2, textAlign: 'right' },
  divider: { marginTop: 0, marginBottom: 10 },
  link: elements.link,
  department: { color: '#6c757d' },
});

export default withStyles(InfoLine);
