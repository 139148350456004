import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { colors } from '@united-talent-agency/julius-frontend-components';

import ContactRow from './contact-row';
import { SortableColumnHeader } from '@united-talent-agency/components';

class ContactTable extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: {}, editing: {} };
  }
  render() {
    const { styles, contacts, onSortChanged, sortColumn, sortDirection } = this.props;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th} style={{ width: 0, textAlign: 'center' }}></th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Name"
                  direction={sortColumn === 'Name' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Name', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Phone"
                  direction={sortColumn === 'Phone' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Phone', direction);
                  }}
                />
              </th>
              <th className={styles.th} colSpan="2">
                <SortableColumnHeader
                  text="Email"
                  direction={sortColumn === 'Email' && sortDirection}
                  onClick={(direction) => {
                    onSortChanged && onSortChanged('Email', direction);
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 && contacts.map((person, index) => <ContactRow key={index} person={person} />)}
          </tbody>
        </table>
      </div>
    );
  }
}

ContactTable.propTypes = {
  contacts: PropTypes.array,
  styles: PropTypes.object,
  onSortChanged: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.number,
};

const withStyles = styled({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    paddingTop: 20,
  },
  th: {
    padding: 0,
    paddingLeft: 2,
    color: '#464645',
    fontSize: '15px',
  },
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
    borderTopColor: colors.border,
    borderBottomColor: colors.border,
    border: `1px solid ${colors.background}`,
    overflow: 'visible',
  },
});

export default withStyles(ContactTable);
