import React, { useState } from 'react';
import styled from 'styled-components/macro';
// import { TerritoriesTable } from './TerritoriesTable';
import { buttonTypes } from '@united-talent-agency/julius-frontend-components';

import { ContractsTableEditor } from './ContractsTableEditor';
import { CardBody, CardButton, CardHeader, CardPane, CardTitle } from '../card/Card';
import { ContractSignerTable } from './ContractSignerTable';
import CommonTooltip from '../common/tooltip';

import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export const ContractSignerCard = ({
  territoriesData,
  title,
  individualItemTitle,
  textFieldTitle,
  saveData,
  id,
  includeNotes,
  numberValues,
  displayPercent,
  mergeCardData,
  contractSignerBlocks,
  isTouring,
  blockEdit,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing && !blockEdit ? (
    <div style={{ position: 'relative' }}>
      <ContractsTableEditor
        territoriesData={territoriesData}
        title={title}
        includeNotes={includeNotes}
        numberValues={numberValues}
        individualItemTitle={individualItemTitle}
        textFieldTitle={textFieldTitle}
        setReadMode={() => setIsEditing(false)}
        saveData={saveData}
        contractSignerBlocks={contractSignerBlocks}
        id={id}
        isTouring={isTouring}
      />
    </div>
  ) : (
    <div style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <ReadView
        id={id}
        mergeCardData={mergeCardData}
        territoriesData={territoriesData}
        title={title}
        textFieldTitle={textFieldTitle}
        setEditMode={() => setIsEditing(true)}
        includeNotes={includeNotes}
        displayPercent={displayPercent}
        isTouring={isTouring}
        blockEdit={blockEdit}
        contractSignerBlocks={contractSignerBlocks}
      />{' '}
    </div>
  );
};

const ReadView = ({ territoriesData, title, setEditMode, mergeCardData, blockEdit, contractSignerBlocks }) => {
  const style = mergeCardData ? { background: '#DFDFDB', height: '30px', padding: '15px' } : {};
  const cardTitle = (!mergeCardData || mergeCardData?.primary) && title;
  const canEdit = (!blockEdit && !mergeCardData) || mergeCardData?.primary;

  return (
    <CardPane name={mergeCardData?.name} mergeCardData={mergeCardData}>
      <CardHeader isEditing={false} style={style}>
        <CardTitle>{cardTitle}</CardTitle>
        {canEdit && <CardButton type={buttonTypes.edit} onClick={setEditMode} />}
      </CardHeader>
      <CardBody>
        {contractSignerBlocks && <ContractSignerTable territoriesData={territoriesData} textFieldTitle={title} />}
      </CardBody>
    </CardPane>
  );
};

export const HeaderButtonsContainer = styled.div`
  float: right;
`;

export const TerritoriesSectionContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
  padding: 20px 28px 28px 28px;
`;
