import React, { Fragment } from 'react';

import { sectionData, TerritoryBox, TerritoryHeader, InfoLine } from './ReadViewSupport';

export const BusinessNamesTable = ({ territoriesData }) => {
  const sectionedData = sectionData(territoriesData);

  return (
    <>
      {Object.values(sectionedData).map((territoryDataArr, territoryIdx) => (
        <TerritoryBox key={territoryIdx}>
          {territoryDataArr?.map((territoryData, idx) => {
            if (!territoryData.groupId) {
              return null;
            }
            let href = `/company/${territoryData.groupId._id}`;
            let name = territoryData.groupId.name;

            return (
              <Fragment key={idx}>
                {idx === 0 ? <TerritoryHeader>{territoryData.territory}</TerritoryHeader> : null}
                <InfoLine
                  label={territoryData.label}
                  isPrimary={territoryData.primary}
                  value={
                    <a target="_blank" rel="noopener noreferrer" href={href} style={{ color: 'black' }}>
                      {name}
                    </a>
                  }
                />
              </Fragment>
            );
          })}
        </TerritoryBox>
      ))}
    </>
  );
};
