import React from 'react';
import styled from 'styled-components';
import { Columns } from '../../../../styles/components/components';
import { NearbyVenuesList } from './NearbyVenuesList';

export default function VenueRadiusView({ venue }) {
  const address = venue && venue.addresses && venue.addresses[0];

  const embedApiKey = 'AIzaSyBvLw3RE1GlCtQ-lrxz6XGZit3Q8kjRb-Y';
  const query = address && `Arena, ${address.address}, ${address.city}, ${address.state} ${address.zip}`;

  return (
    <Container>
      <Columns>
        <ColumnLeft>
          {address && (
            <Address>
              {address.address}
              <br />
              {address.city}, {address.state} {address.zip}
            </Address>
          )}
        </ColumnLeft>

        <ColumnRight>
          <MapIFrame
            title="Venue Map"
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?key=${embedApiKey}&q=${query}`}
          />
        </ColumnRight>
      </Columns>
      <NearbyVenuesList venue={venue} />
    </Container>
  );
}

const Container = styled.div({
  background: 'white',
});

const Address = styled.div({ margin: 'auto', width: '80%', textAlign: 'center' });

const ColumnLeft = styled.div({
  flexGrow: 2,
  margin: 'auto',
});

const ColumnRight = styled.div({
  flexGrow: 3,
  margin: '20px',
});

const MapIFrame = styled.iframe({
  border: 0,
  width: '100%',
  height: '350px',
});
