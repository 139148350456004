import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import {
  colors,
  FormArray,
  FormArrayPrimaryRadioButton,
  FormContext,
  FormInput,
  Icons,
  TextButton,
  RHF,
  FormTextArea,
} from '@united-talent-agency/components';
import { buttonTypes } from '@united-talent-agency/julius-frontend-components';

import { sectionData } from './ReadViewSupport';
import { Territory } from './const';
import { CardButton, CardHeader, CardPane, CardTitle } from '../card/Card';
import { TerritoriesSelector } from './TerritoriesSelector';
import cypressTags from '../../support/cypressTags';

const { useForm } = RHF;
const { PlusIcon, XCloseIcon } = Icons;

const { COMMON } = cypressTags;

export const ContractsTableEditor = ({
  territoriesData,
  title,
  individualItemTitle,
  textFieldTitle,
  setReadMode,
  saveData,
  id,
  entityType,
}) => {
  const defaultTerritories = useExistingTerritories(territoriesData);
  const [territoriesCategory, setTerritoriesCategory] = useState(
    defaultTerritories.has(Territory.AllTerritories) ? 'All' : 'Itemized'
  );
  const [selectedTerritories, setSelectedTerritories] = useState(
    territoriesCategory === 'All' ? new Set([Territory.RestOfWorld]) : defaultTerritories
  );

  const [territoriesError, setTerritoriesError] = useState('');
  // Clear error when territories change
  useEffect(() => {
    setTerritoriesError('');
  }, [territoriesCategory, selectedTerritories]);

  const formProps = useForm({
    mode: 'onBlur',
    defaultValues: sectionData(territoriesData),
  });
  const { handleSubmit } = formProps;

  const showTerritories = territoriesCategory === 'All' ? new Set([Territory.AllTerritories]) : selectedTerritories;
  const orderedTerritoriesList = Object.values(Territory).filter((territory) => showTerritories.has(territory));

  const onSubmit = async (values) => {
    // Filter only selected territories and flatten the array
    const newData = [];
    orderedTerritoriesList.forEach((territory) => {
      // TODO: This sets the territory, it should have been set in data before this
      const territoryDataList = values[territory]?.map((territoryData) => ({ ...territoryData, territory }));
      if (territoryDataList) {
        newData.push(...territoryDataList);
      }
    });

    await saveData(newData);
    setReadMode();
  };

  function selectRow(territory) {
    let selectedRow = ContractSignerRow(entityType, territory);
    return selectedRow;
  }

  return (
    <FormContext {...formProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardPane id={id}>
          <CardHeader isEditing>
            <CardTitle>{title}</CardTitle>
            <div data-cy={COMMON.CANCEL_BUTTON}>
              <CardButton type={buttonTypes.cancel} onClick={setReadMode} />
            </div>
            <div data-cy={COMMON.SAVE_BUTTON}>
              <CardButton type={buttonTypes.save} onClick={handleSubmit(onSubmit)} />
            </div>
          </CardHeader>
          <TerritoryCardBody>
            <TerritoriesSelector
              territoriesCategory={territoriesCategory}
              setTerritoriesCategory={setTerritoriesCategory}
              selectedTerritories={selectedTerritories}
              setSelectedTerritories={setSelectedTerritories}
              territoriesError={territoriesError}
            />

            {orderedTerritoriesList.map((territory) => (
              <FormArray
                key={territory}
                name={territory}
                territory={territory}
                individualItemTitle={individualItemTitle}
                textFieldTitle={textFieldTitle}
                requireOne={false}
                itemForm={selectRow(territory)}
                wrapper={TerritoryWrapper}
              />
            ))}
          </TerritoryCardBody>
        </CardPane>
      </form>
    </FormContext>
  );
};

// Get a set of all the territories in the data
const useExistingTerritories = (territoriesData) => {
  return useMemo(() => {
    const territories = new Set(territoriesData.map(({ territory }) => territory));
    // If empty or AllTerritories, then ensure only AllTerritories
    if (!territories.size || territories.has(Territory.AllTerritories)) return new Set([Territory.AllTerritories]);

    // Ensure RestOfWorld
    return territories.add(Territory.RestOfWorld);
  }, [territoriesData]);
};

const TerritoryWrapper = ({ children, append, territory = Territory.AllTerritories, individualItemTitle }) => {
  return (
    <div>
      <TerritorySeparator />
      <TerritoryHeader>{territory}</TerritoryHeader>
      <div>
        <PrimaryColumn>Primary</PrimaryColumn>
      </div>
      <div>{children}</div>
      <div style={{ paddingLeft: '48px', marginTop: 10 }}>
        <TextButton
          text={`New`}
          icon={PlusIcon}
          fontSize={14}
          onMouseDown={() =>
            append({
              territory,
              label: '',
            })
          }
        />
      </div>
    </div>
  );
};

const IndividualContractSignerRow = ({ remove, entryKey, relTypes }) => {
  // const personSelector = useWatch({ name: `${entryKey}.personId` });
  return (
    <TerritoryRowContainer>
      <PrimaryColumn style={{ marginTop: 4 }}>
        <FormArrayPrimaryRadioButton />
      </PrimaryColumn>
      <span style={{ width: 200, display: 'flex' }}>
        <FormInput style={{ width: '100%' }} title="Label" name="label" required={true} />
      </span>
      <span style={{ width: 200, display: 'flex' }}>
        <FormTextArea title="Contract Signer Block" name="text" required={true} />
      </span>
      <XCloseIcon
        color={colors.cinnabar}
        width={18}
        height={18}
        onMouseDown={remove}
        style={{ cursor: 'pointer', marginTop: '10px' }}
      />
    </TerritoryRowContainer>
  );
};

const ContractSignerRow = (entityType, territory) => {
  // eslint-disable-next-line react/display-name
  return ({ remove, idx }) => {
    return <IndividualContractSignerRow remove={remove} entryKey={`${territory}.${idx}`} />;
  };
};

export const TerritoryCardBody = styled.div({
  padding: '5px 15px',
  display: 'flex',
  flexDirection: 'column',
});

const TerritoryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PrimaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  width: 48px;
  align-items: center;
`;

const TerritoryHeader = styled.div`
  margin-left: 48px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #141414;
`;
const TerritorySeparator = styled.hr`
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
`;
