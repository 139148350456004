import React, { useState, useEffect } from 'react';
import { styled } from 'react-free-style';
import TouringAgent from '../../../../components/touring-agent-card/uta-touring-agent-main';
import AccountingRep from '../../../../components/accounting-rep/uta-accounting-rep';
import { useDispatch } from 'react-redux';
import { ColumnsWrapper, ColumnWrapper } from '../../../../styles/components/components';
import {
  getGuaranteeNames,
  updateGuaranteeNames,
  getBusinessNames,
  updateBusinessNames,
  getTouringTerritories,
  updateContractSignerBlocks,
  getContractSignerBlocks,
} from '../../../../api/territory-data';
import TouringTerritoriesCard from '../../../../components/touring-territories-card/TouringTerritoriesCard';
import { TerritoriesCard } from '../../../../components/territories-card/TerritoriesCard';
import { TouringGuaranteeCard } from '../../../../components/touring-guarantee-card/TouringGuaranteeCard';
import TerritoriesCardWrapper from '../../../../components/territories-card/TerritoriesCardWrapper';
import TouringCardWrapper from '../../../../components/touring-guarantee-card/TouringCardWrapper';
import { ContractSignerCard } from '../../../../components/contract-signer-block/ContractSignerCard';
import ContractSignerWrapper from '../../../../components/contract-signer-block/ContractSignerWrapper';
import LaunchDarklyWrapper from '../../../../components/class-component-LD-wrapper/LaunchDarklyWrapper';
import { notify } from 'react-notify-toast';

import cypressTags from '../../../../support/cypressTags';

const TouringView = ({ person, blockEdit, refreshProfile, mergeCardData, user, onChange }) => {
  const dispatch = useDispatch();
  const personId = person?._id ?? null;
  const [businessNames, setBusinessNames] = useState(null);
  const [guaranteeNames, setGuaranteeNames] = useState(null);
  const [contractSigner, setContractSigner] = useState(null);
  const [touringTerritories, setTouringTerritories] = useState([]);
  const entityType = 'person';

  useEffect(() => {
    if (personId) {
      getBusinessNames(entityType, personId).then(setBusinessNames);
      getGuaranteeNames(entityType, personId).then(setGuaranteeNames);
      getTouringTerritories(entityType, personId).then(setTouringTerritories);
      getContractSignerBlocks(entityType, personId).then(setContractSigner);
    }
  }, [personId, entityType, user]);

  const businessNamesMerge = mergeCardData ? { ...mergeCardData.businessNames, primary: mergeCardData.primary } : null;
  const showBusinessNames = !mergeCardData || !mergeCardData.businessNames.isEmpty || !!businessNames?.length;

  const contractSignerMerge = mergeCardData
    ? { ...mergeCardData.contractSigner, primary: mergeCardData.primary }
    : null;
  const showContractSigner = !mergeCardData || !mergeCardData.contractSigner.isEmpty || !!contractSigner?.length;

  const guaranteeNamesMerge = mergeCardData
    ? { ...mergeCardData.guaranteeNames, primary: mergeCardData.primary }
    : null;
  const showGuaranteeNames = !mergeCardData || !mergeCardData.guaranteeNames.isEmpty || !!businessNames?.length;

  return (
    <LaunchDarklyWrapper
      render={(flags) => {
        return (
          <ColumnsWrapper ignoreCols={mergeCardData}>
            <ColumnWrapper ignoreCols={mergeCardData}>
              {!mergeCardData && (
                <TouringAgent
                  entity={person}
                  entityLabel={entityType}
                  mergeCardData={mergeCardData}
                  blockEdit={blockEdit}
                  onChange={onChange}
                  user={user}
                  personId={personId}
                  dispatch={dispatch}
                />
              )}
              {showBusinessNames && (
                <div data-cy={cypressTags.COMPANY.BUSINESS_NAME_CARD}>
                  <TerritoriesCardWrapper
                    conditional={businessNames}
                    title="Business Names"
                    mergeData={businessNamesMerge}
                  >
                    <TerritoriesCard
                      id="business-names"
                      mergeCardData={businessNamesMerge}
                      title="Business Names"
                      individualItemTitle="Business Name"
                      textFieldTitle="Business Name"
                      territoriesData={businessNames}
                      blockEdit={blockEdit}
                      businessNames
                      saveData={(data) => {
                        return updateBusinessNames(entityType, personId, data)
                          .then(() => {
                            notify.show('Business Names saved successfully', 'success');
                            setBusinessNames(data);
                            refreshProfile && refreshProfile();
                          })
                          .catch((error) => {
                            const errorMessage = error.message;
                            console.log(`Error Saving: ${errorMessage}`);
                            notify.show('Business Names did not save successfully. Please try again.', 'error');
                          });
                      }}
                    />
                  </TerritoriesCardWrapper>
                </div>
              )}
              {!mergeCardData && (
                <TouringTerritoriesCard
                  entity={person}
                  mergeCardData={mergeCardData}
                  blockEdit={blockEdit}
                  touringTerritories={touringTerritories}
                  setTouringTerritories={setTouringTerritories}
                  companyId={personId}
                  entityType={entityType}
                  cyTag={cypressTags.COMPANY.TERRITORIES_CARD}
                  refreshProfile={refreshProfile}
                />
              )}
              {showGuaranteeNames && (
                <div data-cy={cypressTags.PERSON.GUARANTEE_CARD}>
                  <TouringCardWrapper conditional={guaranteeNames} title="Guarantee" mergeData={guaranteeNamesMerge}>
                    <TouringGuaranteeCard
                      id="guarantee-names"
                      mergeCardData={guaranteeNamesMerge}
                      title="Guarantee"
                      individualItemTitle="Guarantee"
                      textFieldTitle="Guarantee"
                      territoriesData={guaranteeNames}
                      blockEdit={blockEdit}
                      guaranteeNames
                      saveData={(data) => {
                        return updateGuaranteeNames(entityType, personId, data)
                          .then(() => {
                            notify.show('Guarantee saved successfully', 'success');
                            setGuaranteeNames(data);
                            refreshProfile && refreshProfile();
                          })
                          .catch((error) => {
                            const errorMessage = error.message;
                            console.log(`Error Saving: ${errorMessage}`);
                            notify.show('Guarantee did not save successfully. Please try again.', 'error');
                          });
                      }}
                    />
                  </TouringCardWrapper>
                </div>
              )}
              {!mergeCardData && (
                <AccountingRep
                  entity={person}
                  entityLabel={entityType}
                  mergeCardData={mergeCardData}
                  blockEdit={blockEdit}
                  onChange={onChange}
                  user={user}
                  personId={personId}
                  dispatch={dispatch}
                />
              )}
              {showContractSigner && (
                <div data-cy={cypressTags.PERSON.CONTRACT_SIGNER_BLOCK}>
                  <ContractSignerWrapper
                    conditional={contractSigner}
                    title="Business Names"
                    mergeData={contractSignerMerge}
                  >
                    <ContractSignerCard
                      id="contract-signer"
                      mergeCardData={contractSignerMerge}
                      title="Contract Signer Block"
                      individualItemTitle="Contract Signer Block"
                      textFieldTitle="Contract Signer"
                      territoriesData={contractSigner}
                      blockEdit={blockEdit}
                      contractSignerBlocks={contractSigner}
                      saveData={(data) => {
                        return updateContractSignerBlocks(entityType, personId, data)
                          .then(() => {
                            setContractSigner(data);
                            notify.show('Contract Signer Block saved successfully', 'success');
                            refreshProfile && refreshProfile();
                          })
                          .catch((error) => {
                            const errorMessage = error.message;
                            console.log(`Error Saving: ${errorMessage}`);
                            notify.show('Contract Signer Block did not save successfully. Please try again.', 'error');
                          });
                      }}
                    />
                  </ContractSignerWrapper>
                </div>
              )}
            </ColumnWrapper>
          </ColumnsWrapper>
        );
      }}
    />
  );
};

const withStyles = styled({
  columns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginLeft: 20,
    },
  },
});

export default withStyles(TouringView);
