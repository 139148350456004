import { personTypes } from '@united-talent-agency/julius-frontend-store';
import CompanyResultTransformer from './company-result-transformer';
import { getGroups } from '../api/groups';
import { getCompaniesByContacts } from '../api/company';

export const findCompanies = ({
  searchText,
  includeClients,
  includeIndustry,
  includeShared,
  _getGroups = getGroups,
  _companyTransformer = CompanyResultTransformer,
}) => {
  //if no type is included, don't search
  // (consumer responsible for overarching logic in conjunction with other searches)
  if (!includeClients && !includeIndustry && !includeShared) {
    return [];
  }

  const types = [];
  includeClients && types.push(personTypes.client);
  includeIndustry && types.push(personTypes.industryContact);
  includeShared && types.push(personTypes.shared);

  const companySearch = _getGroups(searchText, types)
    .then((results) => {
      return (results.data || []).map(_companyTransformer);
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
  return companySearch;
};

export const findCompaniesByContact = ({
  searchText,
  includeClients,
  includeIndustry,
  includeShared,
  _getGroups = getCompaniesByContacts,
  _companyTransformer = CompanyResultTransformer,
}) => {
  //if no type is included, don't search
  // (consumer responsible for overarching logic in conjunction with other searches)
  if (!includeClients && !includeIndustry && !includeShared) {
    return [];
  }

  const types = [];
  includeClients && types.push(personTypes.client);
  includeIndustry && types.push(personTypes.industryContact);
  includeShared && types.push(personTypes.shared);

  const companySearch = _getGroups(searchText, types)
    .then((results) => {
      return (results.body || []).map(_companyTransformer);
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
  return companySearch;
};

export default { findCompanies, findCompaniesByContact };
