import React from 'react';
import { Col, Row } from 'reactstrap';

import CardAgentWebsite from '../../../../components/card-agent-website/card-agent-website';
import InfoArtist from '../../../../components/info-artist/info-artist';
import Card from '../../../../components/card/Card';
import _ from 'lodash';

const EmptyCard = ({ mergeCardData }) => (
  <Card title="Agent/Artist Info" mergeCardData={mergeCardData} data={[]} canEdit />
);

const CardArtist = ({ saveChanges, entity, mergeCardData }) => (
  <Card
    title="Artist Info"
    mergeCardData={mergeCardData}
    data={entity}
    canEdit
    onSave={saveChanges}
    readView={({ item }) => <InfoArtist entity={item} mergeCardData={mergeCardData} />}
    editView={({ item, setState }) => <InfoArtist entity={item} isEditing onChange={setState} />}
  />
);

const CardAgent = ({ saveChanges, entity, mergeCardData }) => (
  <Card
    title="Agent Info"
    mergeCardData={mergeCardData}
    data={entity}
    canEdit
    onSave={saveChanges}
    readView={({ item }) => <CardAgentWebsite entity={item} />}
    editView={({ item, setState }) => <CardAgentWebsite entity={item} isEditing onChange={setState} />}
  />
);

const PersonWebsiteView = (person, saveChanges, mergeCardData) => {
  switch (person.type) {
    case 'Employee':
      return (
        <Row>
          <Col>
            <CardAgent
              entity={person}
              entityFieldName={'person'}
              saveChanges={saveChanges}
              mergeCardData={mergeCardData}
            />
          </Col>
          {!mergeCardData && <Col></Col>}
        </Row>
      );
    case 'Client':
      return (
        <Row>
          <Col>
            <CardArtist
              entity={person}
              entityFieldName={'person'}
              saveChanges={saveChanges}
              mergeCardData={mergeCardData}
            />
          </Col>
          {!mergeCardData && <Col></Col>}
        </Row>
      );
    default:
      return mergeCardData ? <EmptyCard mergeCardData={mergeCardData} /> : null;
  }
};

const CompanyWebsiteView = (company, saveChanges, mergeCardData) => {
  if (company.type !== 'Client') {
    return mergeCardData ? <EmptyCard mergeCardData={mergeCardData} /> : null;
  }

  return (
    <Row>
      <Col>
        <CardArtist
          entity={company}
          entityFieldName={'company'}
          saveChanges={saveChanges}
          mergeCardData={mergeCardData}
        />
      </Col>
      {!mergeCardData && <Col></Col>}
    </Row>
  );
};

function checkShowWebsite(person, company, mergeCardData) {
  if (!mergeCardData || !mergeCardData.isEmpty) {
    return true;
  }

  if (company?.type === 'Client' || person?.type === 'Client') {
    return doesEntityHaveData(company !== null ? company : person);
  }

  if (person?.type === 'Employee') {
    const { representationDepartments, slug } = person;
    return representationDepartments.length > 0 || !!slug;
  }

  return false;
}

export function doesEntityHaveData(entity = {}) {
  const { artistName, representationDepartments = [], slug, territoriesOfRepresentation, bandsintown } = entity;
  return (
    (representationDepartments.length > 0 && !_.isEqual(representationDepartments, [''])) ||
    !!artistName ||
    !!slug ||
    !!territoriesOfRepresentation ||
    !!bandsintown
  );
}

const Website = ({ person, company, saveChanges, mergeCardData }) => {
  const showWebsite = checkShowWebsite(person, company, mergeCardData);
  if (!showWebsite) {
    return <></>;
  }

  if (person) {
    return PersonWebsiteView(person, saveChanges, mergeCardData);
  } else if (company) {
    return CompanyWebsiteView(company, saveChanges, mergeCardData);
  } else {
    return mergeCardData ? <EmptyCard mergeCardData={mergeCardData} /> : null;
  }
};

export default Website;
