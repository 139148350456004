import React, { useState } from 'react';
import { PersonProfilesInput } from '@united-talent-agency/components';
import { getPeople } from '../../api/people';
import { getGroups } from '../../api/groups';

export const MergeProfilesSearch = ({
  onSelect,
  selectedOptions,
  disabledOptions = [],
  onBlur,
  onFocus,
  profilesToMerge,
  setProfilesToMerge,
  showError = false,
  errorMessage = '',
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState('');

  /**
   * Searchbox for profiles shows both people and groups results
   * If a person profile is selected, only person profile results will show to select
   * If a group profile is selected, only group profile results will show to select
   * @param name - a string
   */
  const searchEntities = async (name) => {
    const types = ['Client', 'Industry Contact'];
    const searches = [];
    if (profilesToMerge.length === 0) {
      searches.push(getPeople(name, { types }), getGroups(name, types));
    } else {
      if (profilesToMerge[0].entityType === 'person') {
        searches.push(getPeople(name, { types }), Promise.resolve({}));
      }
      if (profilesToMerge[0].entityType === 'group') {
        searches.push(Promise.resolve({}), getGroups(name, types));
      }
    }

    await Promise.all(searches).then(([peopleResults, groupResults]) => {
      const filteredPeople =
        peopleResults?.data?.reduce((people, person) => {
          if (person._id && person.type && !profilesToMerge.map((each) => each._id).includes(person._id)) {
            people.push({ ...person, entityType: 'person' });
          }
          return people;
        }, []) || [];

      const filteredGroups =
        groupResults?.data?.reduce((groups, group) => {
          if (group._id && group.type && !profilesToMerge.map((each) => each._id).includes(group._id)) {
            groups.push({ ...group, entityType: 'group' });
          }
          return groups;
        }, []) || [];

      setSearchResults(filteredPeople.concat(filteredGroups));
    });
  };

  /**
   * Select a person profile to merge
   * @param person - a string
   */
  const onSelectPerson = (person) => {
    const text = profilesToMerge.length === 3 ? '' : searchText;
    setSearchText(text);

    const people = new Set(profilesToMerge).add(person);
    setProfilesToMerge(Array.from(people));
  };

  return (
    <div style={{ flex: '.34', width: 419 }}>
      <div
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
        onFocus={(e) => {
          onFocus && onFocus(e);
        }}
      >
        <PersonProfilesInput
          results={searchResults}
          value={searchText}
          onFocusChanged={() => searchEntities(searchText)}
          onChange={(value) => {
            setSearchText(value);
            value && searchEntities(value);
          }}
          onSelect={(person) => {
            onSelectPerson(person);
          }}
          disabled={profilesToMerge.length === 4}
          focus={!profilesToMerge.length === 4}
        />
        {showError && <div className="text-danger">{errorMessage}</div>}
      </div>
      <div style={{ marginTop: 8 }}>
        {(selectedOptions || []).map(({ value, label }, idx) => {
          if (disabledOptions.length > 0 && disabledOptions.indexOf(value) !== -1) {
            const newItems = selectedOptions.filter((selectedOption) => selectedOption.value !== value);
            onSelect(newItems);
            return null;
          }
          return (
            <div key={idx} style={{ display: 'inline-block', marginRight: 5 }}>
              <input
                checked
                readOnly
                type="checkbox"
                style={{ marginRight: 3 }}
                onClick={() => {
                  const newItems = selectedOptions.filter((selectedOption) => selectedOption.value !== value);
                  onSelect(newItems);
                }}
              />
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
