import React from 'react';
import { styled } from 'react-free-style';

import { Input, Select, TypeIcon } from '@united-talent-agency/julius-frontend-components';
import { MultiSelectDropdown, colors, Icons } from '@united-talent-agency/components';

import { vocationOptions, genreOptions } from './options';
import cypressTags from '../../support/cypressTags';
import ProfilePic from '../profile-pic/profile-pic';
import Info from '../info/info';

const { XCloseIcon } = Icons;
const { COMMON } = cypressTags;

const personTypes = [
  { key: 'Industry Contact', content: 'Industry Contact' },
  { key: 'Client', content: 'Client' },
  { key: 'Shared Contact', content: 'Shared Contact' },
];

const BasicInfo = (props) => {
  const {
    styles,
    person = {},
    onChange,
    isEditing,
    isRequired,
    errors = {},
    mergeCardData,
    isDataStewardsMember,
  } = props;
  const { type, vocations = [], genres = [], legalName, name } = person;
  const isClientOrIndustryContact = type === 'Client' || type === 'Industry Contact';
  const isMusician = isClientOrIndustryContact && vocations.includes('Musician');
  const isComedy = isClientOrIndustryContact && vocations.includes('Comedy (Live)');

  let personTypeLabel = personTypes.find((personType) => personType.key === type);
  personTypeLabel = (personTypeLabel && personTypeLabel.content) || type;
  const vocationsLabel = vocations.join(', ');
  const genresLabel = genres.join(', ');

  const showFullName = !mergeCardData || legalName;
  const showVocations = !mergeCardData || vocationsLabel;
  const showGenres = !mergeCardData || genresLabel;

  if (!isEditing) {
    return (
      <div className={styles.infoContainer} data-cy={COMMON.BASIC_INFO_CARD.VIEW_MODE_CONTAINER}>
        <Info cyTag={COMMON.BASIC_INFO_CARD.NAME_TITLE} name="Name">
          {name}
        </Info>
        {showFullName && (
          <Info cyTag={COMMON.BASIC_INFO_CARD.FULLNAME_TITLE} name="Full Legal Name">
            {legalName}
          </Info>
        )}
        <Info name="Type" cyTag={COMMON.BASIC_INFO_CARD.TYPE_TITLE}>
          <TypeIcon split entity={person} /> {personTypeLabel}
        </Info>
        {isClientOrIndustryContact && showVocations && (
          <Info name="Vocations" cyTag={COMMON.BASIC_INFO_CARD.VOCATIONS_TITLE}>
            {vocationsLabel}
          </Info>
        )}
        {(isMusician || isComedy) && showGenres && (
          <Info cyTag={COMMON.BASIC_INFO_CARD.GENRE_TITLE} name="Genres">
            {genresLabel}
          </Info>
        )}
      </div>
    );
  }

  const types = personTypes.map((type) => ({
    key: type.key,
    content: type.content,
    active: type.key === person.type,
    className: styles.menuItem,
    onClick: () => onChange({ type: type.key }),
  }));

  return (
    <div className={styles.infoContainer} data-cy={COMMON.BASIC_INFO_CARD.EDIT_MODE_CONTAINER}>
      <div style={{ position: 'relative' }}>
        <ProfilePic
          {...props}
          onChange={(src) => onChange({ profile_pic: src })}
          cyTag={COMMON.BASIC_INFO_CARD.PROFILE_HEADSHOT}
        />
        {person.profile_pic && person.profile_pic.length && (
          <span data-cy={COMMON.BASIC_INFO_CARD.PROFILE_REMOVE_PICTURE_BTN}>
            <XCloseIcon
              onClick={() => onChange({ profile_pic: '' })}
              color={colors.red}
              width={24}
              height={24}
              style={{ position: 'absolute', top: 68, right: 0, cursor: 'pointer' }}
            />
          </span>
        )}
      </div>
      <Input
        type="text"
        title="Name"
        cyTag={cypressTags.PERSON.NAME_INPUT}
        value={person.name || ''}
        className={styles.input}
        onChange={(name) => onChange({ name })}
        isRequired={isRequired}
      />
      {errors.name && <Error msg={errors.name} style={{ marginTop: '-10px', marginBottom: '10px' }} />}
      <Input
        type="text"
        title="Full Legal Name"
        cyTag={cypressTags.COMMON.BASIC_INFO_CARD.LEGAL_NAME_INPUT}
        value={person.legalName || ''}
        className={styles.input}
        onChange={(legalName) => onChange({ legalName })}
      />
      {personTypeLabel === 'Employee' ? (
        <Info name="Type">
          <TypeIcon split entity={person} /> {personTypeLabel}
        </Info>
      ) : (
        <div data-cy={cypressTags.PERSON.TYPE_INPUT}>
          <Select
            items={isDataStewardsMember ? types : types.filter((type) => type.content !== 'Client')}
            isRequired={isRequired}
          >
            {personTypeLabel}
          </Select>
        </div>
      )}
      {errors.type && <Error msg={errors.type} />}
      {isClientOrIndustryContact && (
        <div className={styles.multiselectContainer} data-cy={cypressTags.COMMON.BASIC_INFO_CARD.VOCATIONS_SELECT}>
          <MultiSelectDropdown
            title="Vocations"
            defaultValues={(person.vocations || []).map((vocation) => ({
              label: vocation,
              value: vocation,
            }))}
            options={vocationOptions.map((vocation) => ({
              label: vocation,
              value: vocation,
            }))}
            onChange={(vocations) => {
              const vocationSelections = vocations.map((vocation) => vocation.value);
              onChange({ vocations: vocationSelections });
            }}
          />
        </div>
      )}
      {(isMusician || isComedy) && (
        <div className={styles.multiselectContainer}>
          <MultiSelectDropdown
            title="Genres"
            defaultValues={(person.genres || []).map((genre) => ({
              label: genre,
              value: genre,
            }))}
            options={genreOptions.sort().map((genre) => ({
              label: genre,
              value: genre,
            }))}
            onChange={(selectedGenres) => {
              const genres = selectedGenres.map(({ value }) => value);
              onChange({ genres });
            }}
            required
          />
        </div>
      )}
    </div>
  );
};

const Error = ({ msg, style }) => <div style={{ color: 'red', fontSize: '12px', ...style }}>{msg}</div>;

const withStyles = styled({
  infoContainer: {
    marginBottom: 10,
  },
  input: {
    marginBottom: 10,
    fontWeight: 100,
  },
  menuItem: {
    padding: 10,
  },
  multiselectContainer: {
    marginTop: 10,
  },
});

export default withStyles(BasicInfo);
