import React from 'react';
import PropTypes from 'prop-types';
import TextInputInline from './text-input-inline';

const FormGroupAddress = (props) => {
  const { styles = {}, labelGroup, address, onChanged } = props;
  return (
    <div className="form-group">
      <label className={styles.formGroupLabel}>{labelGroup}</label>
      <div className="col-sm-11 col-sm-offset-1">
        <TextInputInline
          label="Street"
          value={address.street}
          onChange={(value) => {
            address.street = value;
            onChanged && onChanged(address);
          }}
          styles={styles}
        />
        <TextInputInline
          label="City"
          value={address.city}
          onChange={(value) => {
            address.city = value;
            onChanged && onChanged(address);
          }}
          styles={styles}
        />
        <TextInputInline
          label="State"
          value={address.state}
          onChange={(value) => {
            address.state = value;
            onChanged && onChanged(address);
          }}
          styles={styles}
        />
        <TextInputInline
          label="Postal Code"
          value={address.postalCode}
          onChange={(value) => {
            address.postalCode = value;
            onChanged && onChanged(address);
          }}
          styles={styles}
        />
        <TextInputInline
          label="Country"
          value={address.countryOrRegion}
          onChange={(value) => {
            address.countryOrRegion = value;
            onChanged && onChanged(address);
          }}
          styles={styles}
        />
      </div>
    </div>
  );
};
FormGroupAddress.propTypes = {
  address: PropTypes.object.isRequired,
  styles: PropTypes.object,
  onChanged: PropTypes.func,
  labelGroup: PropTypes.string,
};

export default FormGroupAddress;
