import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePerson } from '@united-talent-agency/julius-frontend-store';
import { Tooltip, Icons } from '@united-talent-agency/components';
import Card from '../card/Card';
import { primaryFirstSort } from '../../support/primary-sort';
import AddressInfo from '../address-info/AddressInfo';
import { updateGroup } from '../../api/groups';
import CommonTooltip from '../common/tooltip';
import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export default function AddressesCard({
  entity = {},
  addresses,
  onChange,
  firstIsPrimary,
  requirePrimary,
  validateAddresses,
  noRequirements,
  apiError,
  onSave,
  isPerson,
  mergeCardData,
  blockEdit,
  blockCreateMultiple,
  blockDefaultEmptyItem,
  cyTag = '',
}) {
  const dispatch = useDispatch();
  // set loading to false when the venue is loaded
  const [isLoading, setIsLoading] = useState(false);
  const _onSave = async (saveInfo) => {
    for (let data of Object.values(saveInfo.mergedData)) {
      delete data.errors;
    }

    // remove _id from mergedData entries as addresses schema do not support  _id
    for (let data of Object.values(saveInfo.mergedData)) {
      delete data._id;
    }

    return isPerson
      ? dispatch(updatePerson({ _id: entity._id, addresses: Object.values(saveInfo.mergedData) }))
      : await updateGroup(entity._id, { addresses: Object.values(saveInfo.mergedData) });
  };
  let entityAddresses = (addresses || entity.addresses || []).sort(primaryFirstSort);

  if (entityAddresses.length) {
    entityAddresses = entityAddresses.map((entity, idx) => ({ ...entity, _id: String(idx) }));
  }

  const showAddresses = !mergeCardData || entityAddresses.length > 0 || !mergeCardData.isEmpty ? true : false;

  const isEmployee = (entity || {}).type === 'Employee';
  return !showAddresses ? (
    <></>
  ) : (
    <div style={{ position: 'relative' }} data-cy={cyTag}>
      {isEmployee && (
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <Tooltip place="left" position="left" text="Please contact HR for changes to UTA Employee Information">
            <Icons.LockedIcon />
          </Tooltip>
        </div>
      )}
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <Card
        title="Addresses"
        data={entityAddresses}
        isLoading={isLoading}
        readView={readView}
        editView={!isEmployee && !blockEdit && ((props) => editView(noRequirements, props))}
        canSetPrimary
        canDelete
        firstIsPrimary={firstIsPrimary}
        onSave={async (saveInfo) => {
          setIsLoading(true);
          let result;
          if (onSave) {
            result = await onSave(saveInfo);
          } else {
            result = await _onSave(saveInfo);
          }
          setIsLoading(false);
          return result;
        }}
        onChange={onChange}
        createNew={() => ({ address: '' })}
        onValidateItem={(address) => !validateAddresses || onValidateItem(address)}
        itemOptions={requirePrimary && requirePrimaryItemOptions}
        apiError={apiError}
        mergeCardData={mergeCardData}
        blockCreateMultiple={blockCreateMultiple}
        blockDefaultEmptyItem={blockDefaultEmptyItem}
      />
    </div>
  );
}

function readView({ item: address }) {
  return <AddressInfo address={address} />;
}

function editView(noRequirements, { item: address, setState }) {
  return (
    <AddressInfo
      addressTypes={['Home', 'Work']}
      address={address}
      isEditing
      onChange={(data) => setState(data)}
      noRequirements={noRequirements}
    />
  );
}

function onValidateItem(address) {
  return !address.errors;
}

// Would like to refactor this
// The Card could be extended to require one item and make sure it is primary
function requirePrimaryItemOptions({ idx }) {
  return {
    deleteDisabled: idx === 0,
  };
}
