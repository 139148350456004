import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { buttonTypes } from '@united-talent-agency/julius-frontend-components';

import { LoanoutsTable } from './LoanoutsTable';
import { TerritoriesTableEditor } from './TerritoriesTableEditor';
import { CardBody, CardButton, CardHeader, CardPane, CardTitle } from '../card/Card';
import CommonTooltip from '../common/tooltip';

import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export const LoanoutsCard = ({
  territoriesData,
  title,
  individualItemTitle,
  textFieldTitle,
  saveData,
  id,
  entityType,
  mergeCardData,
  blockEdit,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <TerritoriesTableEditor
      territoriesData={territoriesData}
      title={title}
      individualItemTitle={individualItemTitle}
      textFieldTitle={textFieldTitle}
      setReadMode={() => setIsEditing(false)}
      saveData={saveData}
      id={id}
      loanout
      entityType={entityType}
    />
  ) : (
    <div style={{ position: 'relative' }}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}
      <ReadView
        id={id}
        blockEdit={blockEdit}
        mergeCardData={mergeCardData}
        territoriesData={territoriesData}
        title={title}
        textFieldTitle={textFieldTitle}
        setEditMode={() => setIsEditing(true)}
      />{' '}
    </div>
  );
};

const ReadView = ({ territoriesData, title, setEditMode, mergeCardData, blockEdit }) => {
  const style = mergeCardData ? { background: '#DFDFDB', height: '30px', padding: '15px' } : {};
  const cardTitle = (!mergeCardData || mergeCardData?.primary) && title;
  const canEdit = !blockEdit && (!mergeCardData || mergeCardData?.primary);

  return (
    <CardPane name={mergeCardData?.name} mergeCardData={mergeCardData}>
      <CardHeader isEditing={false} style={style}>
        <CardTitle>{cardTitle}</CardTitle>
        {canEdit && <CardButton type={buttonTypes.edit} onClick={setEditMode} />}
      </CardHeader>
      <CardBody>
        <LoanoutsTable territoriesData={territoriesData} textFieldTitle={title} />
      </CardBody>
    </CardPane>
  );
};

export const HeaderButtonsContainer = styled.div`
  float: right;
`;

export const TerritoriesSectionContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
  padding: 20px 28px 28px 28px;
`;
