import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { omit } from 'lodash';
import { createGroup } from '../../api/groups';
import { BasicInfoCard } from '../company/views/profile/BasicInfoCard';
import RolesCard from '../../components/company-roles-card/CompanyRolesCard';
import AddressesCard from '../../components/addresses-card/AddressesCard';
import ContactsCard from '../../components/company-contacts-card/CompanyContactsCard';
import TeamView from '../company/views/team';
import { SaveButton } from '../../components/save-button/SaveButton';

import { phoneContactFilter, emailContactFilter, otherContactFilter } from '../../support/contacts';

import {
  PageBody,
  Columns,
  Column,
  PageHeader,
  TitleContainer,
  Name,
  PageContainer,
  PageContent,
} from '../../styles/components/components';
import cypressTags from '../../support/cypressTags';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped';
import { FormValidationContainer } from '../../support/FormContext';

const CreateCompanyView = (props) => {
  const { history, dispatch } = props;
  const [newCompany, setNewCompany] = useState({
    roles: { projects: {} },
    addresses: [],
    contacts: [],
    internalTeam: [],
    name: '',
    type: '',
    distribution: '',
    vocations: [],
  });
  const [validCards, setValidCards] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showAllValidationErrors, setShowAllValidationErrors] = useState(false);
  const hasFormErrors = Object.keys(formErrors).some((key) => formErrors[key]);

  const hasErrors = Object.values(validCards).some((valid) => !valid) || hasFormErrors;

  const saveCompany = async () => {
    if (hasErrors) {
      setShowAllValidationErrors(true);
    }
    if (!newCompany.name && !newCompany.type) {
      setFormErrors({ name: 'Name is required', type: 'Type is required' });
    } else if (!newCompany.name) {
      setFormErrors({ name: 'Name is required' });
    } else if (!newCompany.type) {
      setFormErrors({ type: 'Type is required' });
    } else if (hasErrors) {
      setFormErrors({});
    } else {
      const { emailContacts = [], phoneContacts = [], otherContacts = [] } = newCompany;
      newCompany.contacts = [...emailContacts, ...phoneContacts, ...otherContacts];
      newCompany.internalTeam = newCompany.internalTeam.map((member) => {
        return { type: member?.type, utaEmployee: member?.utaEmployee._id };
      });
      delete newCompany.emailContacts;
      delete newCompany.phoneContacts;
      delete newCompany.otherContacts;
      const response = await createGroup(newCompany);
      const newlyCreatedId = response && response._id;
      if (newlyCreatedId) {
        notify.show('Company created', 'success');
        history.push(`/company/${newlyCreatedId}`);
      } else {
        const errorMsg = (response.body && response.body.message) || '';
        notify.show(`Error saving company: ${errorMsg}`, 'error');
      }
    }
  };

  useEffect(() => {
    if (!hasErrors && showAllValidationErrors) {
      // User cleared up existing errors, we can clear this flag
      setShowAllValidationErrors(false);
    }
  }, [hasErrors, showAllValidationErrors]);

  return (
    <PageContainer>
      <Helmet>
        <title>CREATE COMPANY</title>
      </Helmet>
      <BrandingWrapped />
      <PageHeader>
        <TitleContainer>
          <Name>Create Company</Name>
          <SaveButton onSave={saveCompany} hasErrors={hasErrors} />
          <div style={{ padding: '28px 0px' }}></div>
        </TitleContainer>
      </PageHeader>
      <PageBody>
        <PageContent>
          <FormValidationContainer showAllValidationErrors={showAllValidationErrors}>
            <Columns>
              <Column>
                <BasicInfoCard
                  company={newCompany}
                  isRequired
                  cyTag={cypressTags.COMPANY.BASIC_INFO_CARD}
                  errors={formErrors}
                  onChange={(saveInfo) => {
                    const newData = saveInfo.mergedData[0];
                    if (formErrors.name && newData.name && newData.name !== '') {
                      setFormErrors(omit(formErrors, 'name'));
                    }
                    if (formErrors.type && newData.type && newData.type !== '') {
                      setFormErrors(omit(formErrors, 'type'));
                    }

                    setNewCompany({
                      ...newCompany,
                      ...{
                        name: newData.name,
                        type: newData.type,
                        distribution: newData.distribution,
                        vocations: newData.vocations,
                      },
                    });
                  }}
                />
                <RolesCard
                  company={newCompany}
                  noDisabled
                  onChange={(saveInfo) => {
                    const newRoles = saveInfo.mergedData[0];
                    setNewCompany({ ...newCompany, ...{ roles: { projects: newRoles } } });
                  }}
                />

                <TeamView
                  cyTag={cypressTags.COMPANY.UTA_RELATIONSHIP_CARD}
                  entity={newCompany}
                  dispatch={dispatch}
                  onChange={(saveInfo) => {
                    setNewCompany({ ...newCompany, internalTeam: saveInfo.mergedData });
                    setValidCards({ ...validCards, internalTeam: !saveInfo.hasError });
                  }}
                />
              </Column>
              <Column>
                <ContactsCard
                  contacts={newCompany.contacts}
                  title="Phone Contacts"
                  contactTypeFilter={phoneContactFilter}
                  canSetPrimary
                  onChange={(saveInfo) => {
                    setNewCompany({ ...newCompany, ...{ phoneContacts: saveInfo.mergedData } });
                    setValidCards({ ...validCards, 'phone-contacts': !saveInfo.hasError });
                  }}
                />
                <ContactsCard
                  contacts={newCompany.contacts}
                  title="Email Contacts"
                  contactTypeFilter={emailContactFilter}
                  canSetPrimary
                  onChange={(saveInfo = {}) => {
                    setNewCompany({ ...newCompany, ...{ emailContacts: saveInfo.mergedData } });
                    setValidCards({ ...validCards, 'email-contacts': !saveInfo.hasError });
                  }}
                />
                <ContactsCard
                  contacts={newCompany.contacts}
                  title="Other Contacts"
                  contactTypeFilter={otherContactFilter}
                  onChange={(saveInfo = {}) => {
                    setNewCompany({ ...newCompany, ...{ otherContacts: saveInfo.mergedData } });
                    setValidCards({ ...validCards, 'other-contacts': !saveInfo.hasError });
                  }}
                />
                <AddressesCard
                  addresses={newCompany.addresses}
                  validateAddresses
                  onChange={(saveInfo) => {
                    for (let data of Object.values(saveInfo.mergedData)) {
                      delete data.errors;
                    }
                    setNewCompany({ ...newCompany, ...{ addresses: saveInfo.mergedData } });
                    setValidCards({ ...validCards, addresses: !saveInfo.hasError });
                  }}
                  blockDefaultEmptyItem={true}
                />
              </Column>
            </Columns>
          </FormValidationContainer>
        </PageContent>
      </PageBody>
    </PageContainer>
  );
};

export default withRouter(CreateCompanyView);
