export default {
  COMPANY: {
    NAME_INPUT: 'name-input',
    TYPE_DROPDOWN: 'type-dropdown',
    TYPE_DROPDOWN_OPTION: 'type-dropdown-option',
    CREATE_SAVE_BUTTON: 'create-save-button',
    UTA_RELATIONSHIP_CARD: 'uta-relationship-card',
    BASIC_INFO_CARD: 'basic-info-card',
    ROLES_CARD: 'roles-card',
    PHONE_CONTACTS_CARD: 'phone-contacts-card',
    EMAIL_CONTACTS_CARD: 'email-contacts-card',
    OTHER_CONTACTS_CARD: 'other-contacts-card',
    ADDRESSES_CARD: 'addresses-card',
    SOCIAL_CARD: 'social-media-card',
    COMPANY_NAME_TITLE: 'company-name-title',
    TOURING_AGENT_CARD: 'touring-agent-card',
    BUSINESS_NAME_CARD: 'business-name-card',
    TERRITORIES_CARD: 'territories-card',
    GUARANTEE_CARD: 'guarantee-card',
    PROFILE_VIEW: 'company-profile-view',
  },
  HISTORY: {
    AUDIT_TABLE: 'audit-table',
  },
  VENUE: {
    NAME_INPUT: 'name-input',
    CAPACITY_INPUT: 'capacity-input',
    TYPE_DROPDOWN: 'type-dropdown',
    TYPE_DROPDOWN_OPTION: 'type-dropdown-option',
    CREATE_SAVE_BUTTON: 'create-save-button',
    UTA_RELATIONSHIP_CARD: 'uta-relationship-card',
    BASIC_INFO_CARD: 'basic-info-card',
    CAPACITY_CARD: 'capacity-card',
    WEBSITE_CARD: 'website-card',
    VENUE_NAME_TITLE: 'venue-name-title',
    TOURING_AGENT_CARD: 'touring-agent-card',
    BUSINESS_NAME_CARD: 'business-name-card',
    TERRITORIES_CARD: 'territories-card',
    GUARANTEE_CARD: 'guarantee-card',
    PROFILE_VIEW: 'venue-profile-view',
  },
  PERSON: {
    NAME_INPUT: 'name-input',
    TYPE_INPUT: 'type-input',
    TYPE_DROPDOWN: 'type-dropdown',
    TYPE_DROPDOWN_OPTION: 'type-dropdown-option',
    CREATE_SAVE_BUTTON: 'create-save-button',
    UTA_RELATIONSHIP_CARD: 'uta-relationship-card',
    BASIC_INFO_CARD: 'basic-info-card',
    NAME_TITLE: 'name-title',
    TOURING_AGENT_CARD: 'touring-agent-card',
    BUSINESS_NAME_CARD: 'business-name-card',
    TERRITORIES_CARD: 'territories-card',
    GUARANTEE_CARD: 'guarantee-card',
    CONTRACT_SIGNER_BLOCK: 'contract-signer-block',
    PROFILE_HEADER: 'profile-header',
    PROFILE_PAGE_CONTAINER: 'profile-page-container',
    UTA_RELATIONSHIP: {
      EDIT_MODE_CONTAINER: 'uta-relationship-card-edit-mode-container',
      VIEW_MODE_CONTAINER: 'uta-relationship-card-view-mode-container',
    },
    TOURING_AGENT: {
      EDIT_MODE_CONTAINER: 'touring-agent-card-edit-mode-container',
      VIEW_MODE_CONTAINER: 'touring-agent-card-view-mode-container',
    },
    EMPLOYER_CARD: {
      CONTAINER: 'employer-card-container',
      LOCK_BUTTON: 'employer-card-lock-button',
      READ_VIEW_CONTAINER: 'employer-card-read-view-container',
      EDIT_VIEW_CONTAINER: 'employer-card-edit-view-container',
      COMPANY_DROPDOWN_WRAPPER: 'employer-card-company-dropdown-wrapper',
    },
    PRIMARY_TAGS: {
      MAX_AGE_INPUT: 'max-age-input',
      CONTAINER: 'primary-tags-container',
      READ_VIEW_CONTAINER: 'primary-tags-read-view-container',
      EDIT_VIEW_CONTAINER: 'primary-tags-edit-view-container',
      BITHDAY_DATE_PICKER: 'birthday-date-picker',
      MIN_AGE_INPUT: 'min-age-input',
      ETHNICITY_SELECT: 'ethnicity-select',
      NATIONALITY_SELECT: 'nationality-select',
      GENDER_SELECT: 'gender-select',
      PRIMARY_LANGUAGE_SELECT: 'primary-language-select',
      SECONDARY_LANGUAGE_SELECT: 'secondary-language-select',
      DISABILITY_SELECT: 'disability-select',
      LGBTQ_SELECT: 'lgbtq-select',
    },
    REPRESENTED_BY_CARD: {
      CONTAINER: 'represented-by-card-container',
      LOCK_BUTTON: 'represented-by-card-lock-button',
      READ_VIEW_CONTAINER: 'represented-by-card-read-view-container',
      EDIT_VIEW_CONTAINER: 'represented-by-card-edit-view-container',
      AGENCY_INPUT_FIELD: 'represented-by-card-agency-input-field',
      AGENCY_DROPDOWN_WRAPPER: 'represented-by-card-agency-dropdown-wrapper',
      DROPDOWN_OPTIONS: 'represented-by-card-dropdown-options',
      PRIMARY_CHECKBOX: 'represented-by-card-primary-checkbox',
      NEW_AGENCY_BUTTON: 'represented-by-card-new-agency-button',
      REMOVE_ROW_ICON: 'represented-by-card-remove-row-icon',
    },
    CONTACTS_CARDS: {
      PHONE_CONTACTS_CARD_CONTAINER: 'phone-contacts-card-container',
      EMAIL_CONTACTS_CARD_CONTAINER: 'email-contacts-card-container',
      OTHER_CONTACTS_CARD_CONTAINER: 'other-contacts-card-container',
      READ_VIEW_CONTAINER: 'contacts-card-read-view-container',
      EDIT_VIEW_CONTAINER: 'contacts-card-edit-view-container',
      PHONE_INPUT: 'phone-input',
    },
  },
  COMMON: {
    PERSON_INPUT: 'person-input',
    ROW_DELETE_CROSS_BUTTON: 'row-delete-cross-button',
    SIDENAV_TAB: 'sidenav_tab',
    CREATE_SAVE_BUTTON: 'create-save-button',
    SAVE_BUTTON: 'save-button',
    CANCEL_BUTTON: 'cancel-button',
    LOCK_BUTTON: 'lock-button',
    TAB_CONTAINER: 'tab-container',
    TOURING_TEAM_CARD: 'touring-team-card',
    GUARANTEE_TEAM_CARD: 'guarantee-team-card',
    AGENT_TYPE_SELECT: 'agent-type-select',
    AGENT_NAME_INPUT: 'agent-name-input',
    CARD_NEW_BUTTON: 'common-card-new-button',
    BASIC_INFO_CARD: {
      EDIT_BUTTON: 'common-basic-info-card-edit-button',
      SAVE_BUTTON: 'common-basic-info-card-save-button',
      CANCEL_BUTTON: 'common-basic-info-card-cancel-button',
      IMAGE_PICKER: 'common-basic-info-card-image-picker',
      IMAGE_PICKER_CLOSE: 'common-basic-info-card-image-picker-close',
      IMAGE_PLACEHOLDER: 'common-basic-info-card-image-placeholder',
      NAME_INPUT: 'common-basic-info-card-name-input',
      LEGAL_NAME_INPUT: 'common-basic-info-card-legal-name-input',
      TYPE_SELECT: 'common-basic-info-card-type-select',
      VOCATIONS_SELECT: 'common-basic-info-card-vocations-select',
      VOCATIONS_SELECT_OPTION: 'common-basic-info-card-vocations-select-option',
      VIEW_MODE_CONTAINER: 'common-basic-info-card-view-mode-container',
      EDIT_MODE_CONTAINER: 'common-basic-info-card-edit-mode-container',
      NAME_TITLE: 'common-basic-info-card-name-title',
      FULLNAME_TITLE: 'common-basic-info-card-fullname-title',
      TYPE_TITLE: 'common-basic-info-card-type-title',
      VOCATIONS_TITLE: 'common-basic-info-card-vocations-title',
      GENRE_TITLE: 'common-basic-info-card-genre-title',
      PROFILE_HEADSHOT: 'common-basic-info-card-profile-headshot',
      PROFILE_IMAGE_FETCH: 'profile-image-cy-tag',
      PROFILE_REMOVE_PICTURE_BTN: 'common-basic-info-card-profile-remove-picture-btn',
    },
  },
  SEARCH: {
    DROPBOX_CHECK_CLIENT: 'dropbox-check-client',
    DROPBOX_CHECK_INDUSTRY_CONTACT: 'dropbox-check-industry-contact',
    DROPBOX_CHECK_SHARED_CONTACT: 'dropbox-check-shared-contact',
    DROPBOX_CHECK_EMPLOYEE: 'dropbox-check-employee',
    DROPBOX_CHECK_OUTLOOK: 'dropbox-check-outlook',
    DROPBOX_CHECK_COMPANY: 'dropbox-check-company',
    DROPBOX_CHECK_PERSON: 'dropbox-check-person',
    DROPBOX_CHECK_HAS_PHONE_OR_EMAIL: 'dropbox-check-has-phone-or-email',
    MAIN_SEARCH_INPUT: 'main-search-input',
    DROPDOWN_EXPAND_CONTACT: 'dropdown-expand-contact',
    PROFILE_HEADSHOT: 'profile-headshot',
  },
};
