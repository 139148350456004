import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { colors, elements } from '@united-talent-agency/julius-frontend-components';
import { setDesk as setD } from '@united-talent-agency/julius-frontend-store';
import { Icons, Spinner } from '@united-talent-agency/components';

import ContactTable from './contact-table';
import Header from './header';
import DropCapCheckBox from '../../components/dropcap-checkbox';
import entitySorter from '../../data/entity-sorter';
import CountLabel from '../../components/count-label';
import MergeProfilesModal from '../../components/merge-profiles-modal/MergeProfilesModal';
import { datadogRum } from '@datadog/browser-rum';
import cypressTags from '../../support/cypressTags';
import BrandingWrapped from '../../components/branding-wrapped/BrandingWrapped';
import LaunchDarklyWrapper from '../../components/class-component-LD-wrapper/LaunchDarklyWrapper';
import algoliaSearch from '../../support/algolia';
import mongoAtlasSearch from '../../support/atlas-search';
import { findContacts, updateOutlook } from '../../data/outlook-dao';

const { useLaunchDarklyCustomHook } = require('../../support/hooks');
const { SEARCH } = cypressTags;

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const { REACT_APP_ALGOLIA_PEOPLE_INDEX, REACT_APP_ALGOLIA_COMPANIES_INDEX, REACT_APP_ALGOLIA_VENUE_INDEX } =
  process.env;

const {
  ChevronRightIcon,
  ChevronLeftIcon,
  UserIcon,
  OfficeBuildingIcon,
  MergeIcon,
  AddressBookSolidIcon,
  EnvironmentOutlinedIcon,
} = Icons;

const Search = (props) => {
  const { styles = {}, desk, user, desks, dispatch } = props;
  const [includeClients, setIncludeClients] = useState(localStorage.getItem('includeClients') === true.toString());
  const [includeOutlook, setIncludeOutlook] = useState(localStorage.getItem('includeOutlook') === true.toString());
  const [includeIndustry, setIncludeIndustry] = useState(localStorage.getItem('includeIndustry') === true.toString());
  const [includeShared, setIncludeShared] = useState(localStorage.getItem('includeShared') === true.toString());
  const [includeEmployee, setIncludeEmployee] = useState(localStorage.getItem('includeEmployee') === true.toString());
  const [includeCompanies, setIncludeCompanies] = useState(
    localStorage.getItem('includeCompanies') === true.toString()
  );
  const [includeVenues, setIncludeVenues] = useState(localStorage.getItem('includeVenues') === true.toString());
  const [includePeople, setIncludePeople] = useState(localStorage.getItem('includePeople') === true.toString());
  const [hasContacts, setHasContacts] = useState(localStorage.getItem('hasContacts') === true.toString());

  // LOCAL STATE
  const [state, setState] = useState({
    isSearching: false,
    notesIsOpen: false,
    entities: [],
    sortColumn: 'Name',
    sortDirection: 0,
    myClients: false,
    myClientsPoint: false,
    myClientsAgent: false,
    filtersExpanded: true,
    showMergeProfilesModal: false,
  });

  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const { sortColumn, sortDirection, myClients, myClientsPoint, filtersExpanded, showMergeProfilesModal } = state;

  const [flags] = useLaunchDarklyCustomHook();

  // function to return filters
  const getFilters = useCallback(() => {
    return { includeClients, includeOutlook, includeIndustry, includeShared, includeEmployee, hasContacts };
  }, [includeClients, includeOutlook, includeIndustry, includeShared, includeEmployee, hasContacts]);

  useEffect(() => {
    if (searchText && searchText.trim().length >= 3) {
      onSearchTextChanged({
        searchText,
        getFilters,
        setIsSearching,
        setSearchResults,
        includeCompanies,
        includeVenues,
        includePeople,
        sortColumn,
        sortDirection,
        flags,
        desk,
        dispatch,
      });
    }
  }, [
    searchText,
    includeCompanies,
    includeVenues,
    includePeople,
    includeClients,
    includeOutlook,
    includeIndustry,
    includeShared,
    includeEmployee,
    hasContacts,
    getFilters,
    sortColumn,
    sortDirection,
    flags,
    desk,
    dispatch,
  ]);

  /**
   * @description Sorts the entities based on the given column and direction and updates the state with the sorted entities, sort column and direction.
   * @param {('Type' | 'Name' | 'Email' | 'Phone' | 'Company')} column - The column to sort the entities by.
   * @param {number} direction - The sort direction, 1 for ascending order and -1 for descending order.
   * @returns {void}
   */
  const onSortChanged = (column, direction) => {
    const sortedPeople = entitySorter(searchResults, column, direction);
    setState((previousState) => {
      return {
        ...previousState,
        sortColumn: column,
        sortDirection: direction,
        entities: sortedPeople,
      };
    });
    setSearchResults(sortedPeople);
  };

  const onClickReset = () => {
    localStorage.setItem('includeClients', false);
    localStorage.setItem('includeCompanies', false);
    localStorage.setItem('includeVenues', false);
    localStorage.setItem('includePeople', false);
    localStorage.setItem('includeOutlook', false);
    localStorage.setItem('includeIndustry', false);
    localStorage.setItem('includeShared', false);
    localStorage.setItem('includeEmployee', false);
    localStorage.setItem('hasContacts', false);
    setIncludeClients(false);
    setIncludeCompanies(false);
    setIncludeVenues(false);
    setIncludePeople(false);
    setIncludeOutlook(false);
    setIncludeIndustry(false);
    setIncludeShared(false);
    setIncludeEmployee(false);
    setHasContacts(false);
    setSearchText('');
    setSearchResults([]);
  };

  const setDesk = async (desk) => {
    const _desk = desk || {};
    const resetQuickSearch = { myClients: false, myClientsPoint: false, myClientsAgent: false };
    if (myClients) {
      resetQuickSearch.entities = [];
    }
    setState((previousState) => {
      return { ...previousState, ...resetQuickSearch };
    });
    return dispatch(setD(_desk));
  };

  useEffect(() => {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }

    const windowpath = window.location.href;
    const isSearch = windowpath.split('/')[3].split('?')[0].toLowerCase() === 'search';
    if (isSearch) {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const query = params.get('q');
      if (query) {
        setSearchText(query);
      }
    }
  }, []);

  useEffect(() => {
    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }, [user]);

  return (
    <LaunchDarklyWrapper
      render={(flags) => {
        return (
          <div className={styles.container}>
            <BrandingWrapped />
            <Header
              noDebounce={false}
              cyTag={SEARCH.MAIN_SEARCH_INPUT}
              searchText={searchText}
              onSearchTextChanged={async (value) => {
                setSearchText(value);
              }}
              desk={desk}
              desks={desks}
              setDesk={(desk) => setDesk(desk)}
              showReleaseNotes={() => {
                setState((previousState) => {
                  return { ...previousState, notesIsOpen: true };
                });
              }}
            />
            <div className={styles.content}>
              {!filtersExpanded && (
                <ChevronRightIcon
                  onClick={() =>
                    setState((previousState) => {
                      return { ...previousState, filtersExpanded: true };
                    })
                  }
                  style={{
                    cursor: 'pointer',
                    fontSize: 12,
                    fontWeight: 700,
                    color: '#000',
                    marginTop: 22,
                    marginLeft: 10,
                  }}
                />
              )}
              {filtersExpanded && (
                <div className={styles.filterColumn}>
                  <span>
                    <span style={{ float: 'right' }}>
                      <ChevronLeftIcon
                        onClick={() =>
                          setState((previousState) => {
                            return { ...previousState, filtersExpanded: false };
                          })
                        }
                        style={{ cursor: 'pointer', fontSize: 12, fontWeight: 700, color: '#000' }}
                      />
                    </span>
                    <h5 style={{ fontSize: '18px', marginBottom: 20 }}>Filters</h5>
                  </span>
                  <div style={{ marginLeft: 15 }}>
                    <DropCapCheckBox
                      cyTag={SEARCH.DROPBOX_CHECK_CLIENT}
                      text="Client"
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includeClients', checked);
                        return setIncludeClients(checked);
                      }}
                      checked={includeClients}
                      capBackgroundColor={elements.clientMarker.background}
                      capTextColor={elements.clientMarker.color}
                    />
                    <DropCapCheckBox
                      text="Industry"
                      cyTag={SEARCH.DROPBOX_CHECK_INDUSTRY_CONTACT}
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includeIndustry', checked);
                        return setIncludeIndustry(checked);
                      }}
                      checked={includeIndustry}
                      capBackgroundColor={elements.industryMarker.background}
                      capTextColor={elements.industryMarker.color}
                    />
                    <DropCapCheckBox
                      text="Shared"
                      cyTag={SEARCH.DROPBOX_CHECK_SHARED_CONTACT}
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includeShared', checked);
                        return setIncludeShared(checked);
                      }}
                      checked={includeShared}
                      capBackgroundColor={elements.sharedMarker.background}
                      capTextColor={elements.sharedMarker.color}
                    />
                    <DropCapCheckBox
                      text="Employee"
                      cyTag={SEARCH.DROPBOX_CHECK_EMPLOYEE}
                      letter="U"
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includeEmployee', checked);
                        return setIncludeEmployee(checked);
                      }}
                      checked={includeEmployee}
                      capBackgroundColor={elements.employeeMarker.background}
                      capTextColor={elements.employeeMarker.color}
                    />
                    <DropCapCheckBox
                      text="Outlook"
                      cyTag={SEARCH.DROPBOX_CHECK_OUTLOOK}
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includeOutlook', checked);
                        return setIncludeOutlook(checked);
                      }}
                      checked={includeOutlook}
                      capBackgroundColor={elements.outlookMarker.background}
                      capTextColor={elements.outlookMarker.color}
                      enabled={desks.length > 0}
                    />
                  </div>
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                      marginTop: 20,
                      paddingTop: 20,
                      paddingLeft: 16,
                    }}
                  >
                    <DropCapCheckBox
                      text="Company"
                      cyTag={SEARCH.DROPBOX_CHECK_COMPANY}
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includeCompanies', checked);
                        return setIncludeCompanies(checked);
                      }}
                      checked={includeCompanies}
                      IconComponent={OfficeBuildingIcon}
                    />
                    <DropCapCheckBox
                      text="Venue"
                      cyTag={SEARCH.DROPBOX_CHECK_COMPANY}
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includeVenues', checked);
                        return setIncludeVenues(checked);
                      }}
                      checked={includeVenues}
                      IconComponent={EnvironmentOutlinedIcon}
                    />
                    <DropCapCheckBox
                      text="Person"
                      cyTag={SEARCH.DROPBOX_CHECK_PERSON}
                      onCheckChanged={(checked) => {
                        localStorage.setItem('includePeople', checked);
                        return setIncludePeople(checked);
                      }}
                      checked={includePeople}
                      IconComponent={UserIcon}
                    />
                  </div>
                  <div
                    style={{
                      borderTop: '1px solid #ccc',
                      marginTop: 20,
                      paddingTop: 20,
                      paddingLeft: 16,
                    }}
                  >
                    <DropCapCheckBox
                      text="Has Phone or Email"
                      cyTag={SEARCH.DROPBOX_CHECK_HAS_PHONE_OR_EMAIL}
                      onCheckChanged={(checked) => {
                        localStorage.setItem('hasContacts', checked);
                        return setHasContacts(checked);
                      }}
                      checked={hasContacts}
                      IconComponent={AddressBookSolidIcon}
                    />
                  </div>
                </div>
              )}
              <div className={styles.tableColumn}>
                {isSearching ? (
                  <div className={styles.spinner}>
                    <Spinner size={60} style={{ paddingTop: 30 }} />
                  </div>
                ) : (
                  <div className={styles.gridContainer}>
                    {searchResults.length > 0 && (
                      <>
                        <CountLabel count={searchResults.length} label="Result" className={styles.resultCount} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <button label="reset" className={styles.resetButton} onClick={onClickReset}>
                            Reset
                          </button>
                          {flags.showMergeButtons ? (
                            <div>
                              <MergeIcon rotate={1} style={{ marginRight: 5 }} />
                              <button
                                label="merge"
                                className={styles.mergeButton}
                                onClick={() => {
                                  setState((previousState) => {
                                    return {
                                      ...previousState,
                                      showMergeProfilesModal: true,
                                    };
                                  });
                                }}
                              >
                                Merge
                              </button>
                              {showMergeProfilesModal ? (
                                <MergeProfilesModal
                                  isOpen={true}
                                  desk={desk}
                                  onCancel={() => {
                                    setState((previousState) => {
                                      return { ...previousState, showMergeProfilesModal: false };
                                    });
                                  }}
                                ></MergeProfilesModal>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                        <ContactTable
                          contacts={searchResults}
                          user={user}
                          sortColumn={sortColumn}
                          sortDirection={sortDirection}
                          onSortChanged={onSortChanged}
                          onOutlookContactUpdated={(personId, updates) => {
                            return updateOutlook(dispatch, desk._id, personId, updates).then((result) => {
                              const personIndex = searchResults
                                .map((p) => {
                                  return p._id;
                                })
                                .indexOf(personId);
                              searchResults[personIndex] = result;
                              onSortChanged(sortColumn, sortDirection);
                            });
                          }}
                        />
                      </>
                    )}
                    {searchResults.length === 0 && (
                      <div className={styles.searchResults}>
                        {myClients
                          ? `No ${myClientsPoint ? 'Point' : ''} Agent Relationships`
                          : !searchText || searchText.trim().length < 3
                          ? 'Searches Must Include 3 or More Characters'
                          : 'No Search Results Found'}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

const onSearchTextChanged = async ({
  searchText,
  getFilters,
  setIsSearching,
  setSearchResults,
  includeCompanies,
  includeVenues,
  includePeople,
  sortColumn,
  sortDirection,
  desk,
  dispatch,
  flags,
}) => {
  setIsSearching(true);
  const search = flags.enableMongoSearch ? mongoAtlasSearch : algoliaSearch;
  const filters = getFilters();
  const { includeClients, includeOutlook, includeIndustry, includeShared, includeEmployee } = filters;

  let peopleResults = [];

  const checkedCategories = includeCompanies + includeVenues + includePeople;
  let hitsPerPage = parseInt(100 / (checkedCategories !== 0 ? checkedCategories : 1));

  if ((!includeCompanies && !includeVenues) || includePeople) {
    peopleResults = await search({
      query: searchText,
      filtersState: filters,
      searchIndex: REACT_APP_ALGOLIA_PEOPLE_INDEX,
      contactEntityType: 'people',
      hitsPerPage,
    });
  }
  let companiesResults = [];
  if (!includePeople & !includeVenues || includeCompanies) {
    companiesResults = await search({
      query: searchText,
      filtersState: filters,
      searchIndex: REACT_APP_ALGOLIA_COMPANIES_INDEX,
      contactEntityType: 'companies',
      hitsPerPage,
    });
  }
  let venuesResults = [];
  if (!includePeople & !includeCompanies || includeVenues) {
    const filter_map = {
      includeClients: includeClients,
      includeOutlook: includeOutlook,
      includeIndustry: false,
      includeShared: includeShared,
      includeEmployee: includeEmployee,
    };
    venuesResults = await search({
      query: searchText,
      filtersState: filter_map,
      searchIndex: REACT_APP_ALGOLIA_VENUE_INDEX,
      contactEntityType: 'venues',
      hitsPerPage,
    });

    venuesResults.hits = venuesResults.hits?.map((venue) => {
      return {
        isVenue: true,
        ...venue,
      };
    });
  }

  const allOtherFilterUnset = !includeClients && !includeIndustry && !includeShared && !includeEmployee;
  let outlookResults = [];
  if (desk && desk._id && (includeOutlook || allOtherFilterUnset)) {
    outlookResults = await findContacts({
      dispatch,
      searchText,
      deskId: desk._id,
    });
  }

  const outLookCheck = includePeople || (!includePeople && !includeCompanies && !includeVenues);

  let personHits = peopleResults.hits || [];
  let companiesHits = companiesResults.hits || [];
  let venuesHits = venuesResults.hits || [];

  personHits = resultsMerger({ algoliaResults: personHits, outlookResults: outLookCheck ? outlookResults : [] });
  personHits.map((record) => {
    // set person title and company
    let title = '';
    let personEmployerCompany = '';
    if (record.groupMembership && record.groupMembership.length > 0) {
      // find primary groupMembership and use that as the title
      const primaryGroupMembership = record.groupMembership.find((groupMembership) => groupMembership.primary);
      if (primaryGroupMembership) {
        title = primaryGroupMembership.title;
        personEmployerCompany = primaryGroupMembership?.group?.name;
      }
    }
    record.entityType = 'person';
    record.title = title;
    record.company = personEmployerCompany;
    return record;
  });
  companiesHits.map((record) => {
    record.entityType = 'group';
    return record;
  });
  const combinedResults = [...personHits, ...companiesHits, ...venuesHits];
  const sortedResults = entitySorter(combinedResults, sortColumn, sortDirection);
  setSearchResults([...sortedResults]);
  setIsSearching(false);
  return sortedResults;
};

const resultsMerger = function ({ algoliaResults, outlookResults }) {
  let mergedArray = [];

  let maxLength = Math.max(algoliaResults.length, outlookResults.length);

  for (var i = 0; i < maxLength; i++) {
    if (i < algoliaResults.length) {
      mergedArray.push(algoliaResults[i]);
    }
    if (i < outlookResults.length) {
      mergedArray.push(outlookResults[i]);
    }
  }

  return mergedArray;
};

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.background,
    fontFamily: 'aktiv-grotesk, sans-serif',
    height: '100%',
  },
  gridContainer: {
    width: '100%',
    padding: '0 10px',
    paddingRight: 20,
    alignItems: 'left',
  },
  searchResults: {
    textAlign: 'center',
    fontWeight: '700',
    paddingTop: 100,
    fontSize: 20,
  },
  spinner: {
    display: 'flex',
    paddingTop: 100,
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  filterColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    padding: 20,
    height: '100%',
    marginTop: -10,
    paddingTop: 27,
    paddingRight: 0,
  },
  tableColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    overflow: 'visible',
  },
  manageMyClients: {
    '&:hover': {
      textDecoration: 'underline',
      color: '#2187B9',
    },
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 500,
    color: '#2187B9',
    textAlign: 'right',
    marginTop: '5px',
  },
  manageMyClientsDisabled: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#AAA',
    textAlign: 'right',
    marginTop: '5px',
  },
  resultCount: {
    color: '#141414',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  resetButton: {
    marginLeft: 0,
    marginBottom: 10,
    paddingLeft: 0,
    fontSize: 12,
    color: '#141414',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none !important',
  },
  mergeButton: {
    marginLeft: 0,
    paddingLeft: 0,
    fontSize: 12,
    marginBottom: 10,
    color: '#141414',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none !important',
  },
});

Search.propTypes = {
  dispatch: PropTypes.func,
  styles: PropTypes.object,
  contacts: PropTypes.object,
  desk: PropTypes.object,
  desks: PropTypes.array,
  user: PropTypes.object,
  outlook: PropTypes.object,
};

const withState = connect((store) => {
  const { user, desk } = store;
  const desks = desk.available;
  return { user, desk: desk.current, desks };
});

export default withState(withStyles(Search));
