import React from 'react';
import { styled } from 'react-free-style';
import { link } from '../../styles/elements';

const AgencyLabel = (props) => {
  const { value, styles } = props;

  return value.company ? (
    <div style={{ fontSize: '12px' }}>
      <div style={{ fontWeight: 300, color: '#4A4A4A' }}>
        <a href={`/company/${value.company._id}`} className={styles.contactLink}>
          {value.company && value.company.name}
        </a>
        {value.primary && ' (Primary)'}
      </div>

      <hr style={{ marginTop: 10, marginBottom: 10 }} />
    </div>
  ) : null;
};
const withStyles = styled({ contactLink: link });
export default withStyles(AgencyLabel);
