import React from 'react';
import Select from 'react-select';

const selectFieldStyle = {
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  indicatorSeparator: () => ({ hidden: true }),
};

const generateSelectValues = (items) => items.map((item) => ({ value: item, label: item }));

export const SearchableSelect = ({ value, onChange, options }) => (
  <Select
    value={value}
    styles={selectFieldStyle}
    onChange={onChange}
    options={generateSelectValues(options)}
    isClearable
  />
);
