import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { parse } from 'querystring';
import { useEffect } from 'react';
import { getAccessGroup } from '../api/access-groups';
import { loadInternalTeamEntities, loadEmployeeAssistants } from './clientTeamAccessCheckerHelpers';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Functions similarly to useState
 * Uses a key to store values as JSON in local storage
 * value is cached after getting from storage for performance
 *
 * ex.
 *  const [menuIsOpen, setMenuIsOpen] = useState('menuIsOpen', true);
 *
 * @param key {string} The key to use in local storage
 * @param defaultValue
 * @returns [value, setValue] similar to setState
 */
export const useLocalStorage = (key, defaultValue) => {
  let [cachedValue, setCachedValue] = useState(() => {
    try {
      const json = window.localStorage.getItem(key);
      if (json !== null) {
        return JSON.parse(json);
      }
    } catch (error) {
      console.error(`Error getting ${key} from local storage`, error);
    }
    return defaultValue;
  });

  const setValue = (value) => {
    setCachedValue(value);

    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      console.error(`Error setting ${key} to local storage`, error);
    }
  };

  return [cachedValue, setValue];
};

/**
 * Previous URLs used the query string ?view=history
 * New code uses react-router paths /history
 * Users could have bookmarks set that we don't want to disturb
 * This component redirects from the query string form to a path form
 *
 * @param baseUrl URL to be prepended to new path.
 *  ex. http://localhost:3003/venue/6046a22ca377fa1c95ed734f
 * @param redirects object Key/value pairs of old view -> new path
 *  ex. { history: 'history' }
 */
export const useQueryStringViewRedirect = (baseUrl, redirects) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { view } = parse(location.search.substr(1));

    if (view && redirects && redirects[view]) {
      history.push(`${baseUrl}/${redirects[view]}`);
    }
  }, [baseUrl, redirects, history, location]);
};

export const useClientTeamAccessChecker = ({ entity, user, type, dispatch }) => {
  const [blockVerify, setBlockVerify] = useState(true);
  const [blockEdit, setBlockEdit] = useState(true);
  const [relations, setRelations] = useState([]);

  // hook to load client relations
  useEffect(() => {
    // load uta relations
    if (!entity?._id) {
      return;
    }
    if (entity?.type === 'Client') {
      loadInternalTeamEntities(dispatch, entity?._id, type).then((relationships) => {
        setRelations(relationships);
      });
    } else {
      setBlockEdit(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, type, setRelations, dispatch]);
  // hook to check users membership in client team or data steward team
  useEffect(() => {
    if (entity && user && entity.type === 'Client') {
      // see if user is one of the clients internal team member
      let userExistsInRelations = relations.some(
        (relation) =>
          relation?.utaEmployee?._id && user?.personId?._id && relation?.utaEmployee?._id === user?.personId?._id
      );
      setBlockEdit(!userExistsInRelations);
      setBlockVerify(!userExistsInRelations);
      // Get assistants of the internal team members
      relations.forEach((relation) => {
        if (!userExistsInRelations) {
          loadEmployeeAssistants(dispatch, relation?.utaEmployee?._id).then((assistants) => {
            // check if user is one of the assistants
            if (assistants.includes(user?.personId?._id)) {
              userExistsInRelations = true;
              setBlockEdit(!userExistsInRelations);
              setBlockVerify(!userExistsInRelations);
            }
          });
        }
      });

      // see if user is one of the data stewards access group members
      getAccessGroup({
        params: {
          name: 'DATA_STEWARD',
        },
      }).then((members) => {
        if (members.includes(user?.personId?._id)) {
          setBlockEdit(false);
          setBlockVerify(false);
        }
      });
    } else {
      if (entity && entity.type) {
        setBlockEdit(false);
        setBlockVerify(false);
      }
    }
  }, [entity, user, relations, setBlockVerify, dispatch]);

  return [relations, blockEdit, blockVerify];
};

export const useIndustryContactAccessChecker = ({ entity, user, type, dispatch }) => {
  const [blockVerify, setBlockVerify] = useState(true);
  const [blockEdit, setBlockEdit] = useState(true);
  const [relations, setRelations] = useState([]);

  // hook to load client relations
  useEffect(() => {
    // load uta relations
    if (!entity?._id) {
      return;
    }
    if (entity?.type === 'Industry Contact') {
      loadInternalTeamEntities(dispatch, entity?._id, type).then((relationships) => {
        setRelations(relationships);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, type, setRelations, dispatch]);
  // hook to check users membership in client team or data steward team
  useEffect(() => {
    if (entity && user && entity?.type === 'Industry Contact') {
      // see if user is one of the clients internal team member
      let userExistsInRelations = relations.some(
        (relation) =>
          relation?.utaEmployee?._id && user?.personId?._id && relation?.utaEmployee?._id === user?.personId?._id
      );
      setBlockEdit(!userExistsInRelations);
      setBlockVerify(!userExistsInRelations);
      // Get assistants of the internal team members
      relations.forEach((relation) => {
        if (!userExistsInRelations) {
          loadEmployeeAssistants(dispatch, relation?.utaEmployee?._id).then((assistants) => {
            // check if user is one of the assistants
            if (assistants.includes(user?.personId?._id)) {
              userExistsInRelations = true;
              setBlockEdit(!userExistsInRelations);
              setBlockVerify(!userExistsInRelations);
            }
          });
        }
      });

      // see if user is one of the data stewards access group members
      getAccessGroup({
        params: {
          name: 'DATA_STEWARD',
        },
      }).then((members) => {
        if (members.includes(user?.personId?._id)) {
          setBlockEdit(false);
          setBlockVerify(false);
        }
      });
    } else {
      if (entity && entity.type) {
        setBlockEdit(false);
        setBlockVerify(false);
      }
    }
  }, [entity, user, relations, setBlockVerify, dispatch]);

  return [relations, blockEdit, blockVerify];
};

export const useOnlyDataStewartTeamAccessChecker = ({ entity, user, dispatch }) => {
  const [blockVerify, setBlockVerify] = useState(true);
  const [blockEdit, setBlockEdit] = useState(true);

  // hook to check users membership in client team or data steward team
  useEffect(() => {
    if (entity && user && entity.type === 'Client') {
      // see if user is one of the data stewards access group members
      getAccessGroup({
        params: {
          name: 'DATA_STEWARD',
        },
      }).then((members) => {
        if (members.includes(user?.personId?._id)) {
          setBlockEdit(false);
          setBlockVerify(false);
        }
      });
    } else {
      if (entity && entity.type) {
        setBlockEdit(false);
        setBlockVerify(false);
      }
    }
  }, [entity, user, setBlockVerify, dispatch]);

  return [blockEdit, blockVerify];
};
export const useLaunchDarklyCustomHook = (user) => {
  const ldClient = useLDClient();
  const flags = useFlags();
  useEffect(() => {
    if (user && user._id && ldClient) {
      ldClient.identify({ key: user._id, email: user.email });
      return;
    }
  }, [user, ldClient]);

  return [flags];
};
