import React from 'react';

import { styled } from 'react-free-style';
import { TypeIcon } from '@united-talent-agency/julius-frontend-components';
import { VerificationIcon } from '../../styles/components/components';
import moment from 'moment';

const Title = ({ entity, type, styles }) => {
  const isVerified = entity.verifiedBy && entity.verifiedOn;
  const lastVerifiedLabel = isVerified
    ? `Last Verified by: ${entity.verifiedBy.first_name} ${entity.verifiedBy.last_name} on ${moment(
        entity.verifiedOn
      ).format('M/D/YYYY')}`
    : '';

  return (
    <div className={styles.container}>
      <div className={styles.verificationContainer}>
        <div className={styles.flexRow}>
          <TypeIcon split entity={{ type }} customStyles={{ height: '32px', width: '32px', fontSize: '24px' }} />
          <div className={styles.name}>{entity.name}</div>
          {isVerified && <VerificationIcon color="verifiedPersonIcon" />}
        </div>
        {<span className={styles.verificationText}>{isVerified ? lastVerifiedLabel : ''}</span>}
      </div>
    </div>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 30px',
    marginTop: '6px',
  },
  name: {
    fontSize: 24,
    fontWeight: 300,
    marginLeft: 15,
  },
  verificationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  verificationText: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 200,
    color: '#A29992',
    lineHeight: '14px',
    margin: '0 50px',
    height: '14px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default withStyles(Title);
