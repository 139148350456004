import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notify } from 'react-notify-toast';
import { loadPerson } from '@united-talent-agency/julius-frontend-store';

import { BasicInfoCard } from './BasicInfoCard';
import InfoCard from './InfoCard';

import TeamView from '../../views/team';
import { CardEmployers } from '../../../../components/card-employers';
import { phoneContactFilter, emailContactFilter, otherContactFilter } from '../../../../support/contacts';
import CardSocial from '../../../../components/card-social/card-social';
import RepresentedByInfo from './RepresentedByInfo';
import { ColumnWrapper, ColumnsWrapper } from '../../../../styles/components/components';
import AddressesCard from '../../../../components/addresses-card/AddressesCard';
import ContactsCard from '../../../../components/person-contacts-card/PersonContactsCard';
import PrimaryTags from '../../../../components/primary-tags/PrimaryTags';
import { getPersonPrivateContactsPermission } from '../../../../support/private-contact-permission';
import { getGroups } from '../../../../api/groups';

import LaunchDarklyWrapper from '../../../../components/class-component-LD-wrapper/LaunchDarklyWrapper';

import { getAccessGroup } from '../../../../api/access-groups';
import cypressTags from '../../../../support/cypressTags';

const { PERSON } = cypressTags;
const { CONTACTS_CARDS } = PERSON;

export default function Profile({
  person = {},
  utaCompany = {},
  saveChanges,
  user = {},
  refreshProfile,
  mergeCardData,
  blockEdit,
  blockTouringEdit,
  relations,
  onChange,
  _getGroups = getGroups,
}) {
  const [privateContactPermission, setPrivateContactPermission] = useState(false);
  const [isDataStewardsMember, setIsDataStewardsMember] = useState(false);

  const isClientOrIndustryContact = person.type === 'Client' || person.type === 'Industry Contact';
  const isNotEmployee = person.type !== 'Employee';

  const dispatch = useDispatch();
  useEffect(() => {
    getPersonPrivateContactsPermission(dispatch, user.deskIds, person._id).then((hasPermission) =>
      setPrivateContactPermission(hasPermission)
    );
  }, [person, user, dispatch]);

  useEffect(() => {
    if (user) {
      getAccessGroup({
        params: {
          name: 'DATA_STEWARD',
        },
      })
        .then((members) => {
          if (members.includes(user?.personId?._id)) {
            setIsDataStewardsMember(true);
          }
        })
        .catch((error) => {
          notify.show('Error getting territory data', 'error');
          throw error;
        });
    }
  }, [user]);

  const basicMerge = mergeCardData ? { ...mergeCardData.basic, primary: mergeCardData.primary } : null;
  const phoneMerge = mergeCardData ? { ...mergeCardData.phone, primary: mergeCardData.primary } : null;
  const emailMerge = mergeCardData ? { ...mergeCardData.email, primary: mergeCardData.primary } : null;
  const otherMerge = mergeCardData ? { ...mergeCardData.other, primary: mergeCardData.primary } : null;
  const addressesMerge = mergeCardData ? { ...mergeCardData.addresses, primary: mergeCardData.primary } : null;
  const employersMerge = mergeCardData ? { ...mergeCardData.employers, primary: mergeCardData.primary } : null;
  const representedMerge = mergeCardData ? { ...mergeCardData.represented, primary: mergeCardData.primary } : null;
  const primaryTagsMerge = mergeCardData ? { ...mergeCardData.primaryTags, primary: mergeCardData.primary } : null;

  const CONTACTS_CARD_DATA = [
    {
      person,
      canSetPrimary: true,
      title: 'Phone Contacts',
      blockEdit,
      contactTypeFilter: phoneContactFilter,
      privateContactPermission,
      mergeCardData: phoneMerge,
      cyTag: CONTACTS_CARDS.PHONE_CONTACTS_CARD_CONTAINER,
    },
    {
      person,
      blockEdit,
      privateContactPermission,
      canSetPrimary: true,
      title: 'Email Contacts',
      contactTypeFilter: emailContactFilter,
      mergeCardData: emailMerge,
      cyTag: CONTACTS_CARDS.EMAIL_CONTACTS_CARD_CONTAINER,
    },
    {
      person,
      blockEdit,
      privateContactPermission,
      canSetPrimary: true,
      title: 'Other Contacts',
      contactTypeFilter: otherContactFilter,
      mergeCardData: otherMerge,
      cyTag: CONTACTS_CARDS.OTHER_CONTACTS_CARD_CONTAINER,
    },
  ];

  return (
    <LaunchDarklyWrapper
      render={(flags) => {
        return (
          <ColumnsWrapper ignoreCols={mergeCardData}>
            <ColumnWrapper ignoreCols={mergeCardData}>
              <BasicInfoCard
                person={person}
                isRequired
                mergeCardData={basicMerge}
                blockEdit={blockEdit}
                isDataStewardsMember={isDataStewardsMember}
                cyTag={cypressTags.PERSON.BASIC_INFO_CARD}
              />
              {blockEdit && !relations?.length && (
                <InfoCard>
                  <p style={{ textAlign: 'center' }}>
                    This client profile is not editable since it does not have an assigned agent or point agent. Please
                    email <a href="mailto:onyxdatarequests@unitedtalent.com">onyxdatarequests@unitedtalent.com</a> to
                    add one
                  </p>
                </InfoCard>
              )}
              {isNotEmployee && !mergeCardData && (
                <TeamView
                  entity={person}
                  dispatch={dispatch}
                  blockEdit={blockEdit}
                  refreshProfile={refreshProfile}
                  cyTag={cypressTags.PERSON.UTA_RELATIONSHIP_CARD}
                  onSave={() => {
                    dispatch(loadPerson(person._id)).then((updatedPerson) => {
                      const p = updatedPerson.body;
                      person.verifiedOn = p.verifiedOn;
                      person.verifiedBy = p.verifiedBy;
                      getPersonPrivateContactsPermission(dispatch, user.deskIds, person._id).then((hasPermission) =>
                        setPrivateContactPermission(hasPermission)
                      );
                      saveChanges();
                      refreshProfile();
                    });
                  }}
                />
              )}

              <CardEmployers
                dispatch={dispatch}
                person={person}
                blockEdit={blockEdit}
                searchGroups={async (searchText) => {
                  const response = await _getGroups(searchText);
                  const searchResults = (response && response.data) || [];
                  return searchResults.filter((searchResult) => searchResult._id !== utaCompany._id);
                }}
                mergeCardData={employersMerge}
              />
              {isClientOrIndustryContact && (
                <RepresentedByInfo
                  person={person}
                  searchGroups={_getGroups}
                  dispatch={dispatch}
                  blockEdit={blockEdit}
                  mergeCardData={representedMerge}
                />
              )}
              {isClientOrIndustryContact && (
                <PrimaryTags
                  person={person}
                  saveChanges={saveChanges}
                  mergeCardData={primaryTagsMerge}
                  blockEdit={blockEdit}
                />
              )}
            </ColumnWrapper>
            <ColumnWrapper ignoreCols={mergeCardData}>
              {CONTACTS_CARD_DATA.map((contactData, index) => (
                <ContactsCard key={index} {...contactData} />
              ))}
              <AddressesCard
                isPerson
                entity={person}
                validateAddresses
                mergeCardData={addressesMerge}
                blockEdit={blockEdit}
              />
              {isClientOrIndustryContact && !mergeCardData && (
                <CardSocial
                  entity={person}
                  entityFieldName="person"
                  refreshProfile={refreshProfile}
                  blockEdit={blockEdit}
                />
              )}
            </ColumnWrapper>
          </ColumnsWrapper>
        );
      }}
    />
  );
}
