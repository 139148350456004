import OutlookContactTransformer from './outlook-contact-transformer';

const OutlookContactPersonChanges = (person, outlookContact) => {
  const changes = {};
  const personOutlookContact = OutlookContactTransformer(person);

  const keys = Object.keys(outlookContact);
  keys.forEach((key) => {
    if (JSON.stringify(outlookContact[key]) !== JSON.stringify(personOutlookContact[key])) {
      changes[key] = outlookContact[key];
    }
  });

  return changes;
};
export default OutlookContactPersonChanges;
