import React, { useState } from 'react';
import { styled, helpers } from 'react-free-style';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { TextArea, colors } from '@united-talent-agency/julius-frontend-components';

import { trashcan, pencil, plus, crossBlack } from '../../../styles/icons';
import { contentColor, contentBackground, text } from '../../../styles/colors';

const Header = ({ styles, saveNotes, notes, user, editingNote = {}, setEditingNote }) => {
  const [note, setNote] = useState(null);
  const [error, setError] = useState(null);

  const isEditing = !isEmpty(editingNote);
  const defaultNoteValue = editingNote.note || '';

  const save = () => {
    if (!note) {
      setError('Required.');
    } else {
      const now = new Date();
      const newNote = { note, createdBy: user._id, createdAt: now, updatedAt: now };
      saveNotes(notes.concat(newNote));
      clear();
    }
  };

  const update = () => {
    if (!note) {
      setError('Required.');
    } else {
      const now = new Date();
      const updatedNoteId = editingNote._id;
      const updatedNotes = notes.map((curNote) => {
        if (curNote._id === updatedNoteId) {
          return { ...curNote, ...{ note, updatedBy: user._id, updatedAt: now } };
        }
        return curNote;
      });
      saveNotes(updatedNotes);
      clear();
    }
  };

  const clear = () => {
    setNote(null);
    setError(null);
    setEditingNote({});
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerNoteEntry}>
          <TextArea
            focus
            cols={10}
            title="Note"
            onChange={(value) => {
              if (value && error) {
                setError(null);
              }
              setNote(value);
            }}
            value={note === null ? defaultNoteValue : note}
          />
        </div>

        <div>
          <div style={{ position: 'absolute' }}>
            {(note || isEditing) && <i className={styles.clearIcon} onClick={clear} />}
          </div>
          <button className={styles.actionButton} onClick={isEditing ? update : save}>
            <span className={styles.buttonText}>
              {isEditing ? <i className={styles.editIcon} /> : <i className={styles.addIcon} />}
              {isEditing ? 'Update' : 'Add'}
            </span>
          </button>
        </div>
      </div>
      {error && <div className={styles.errorText}>{error}</div>}
    </>
  );
};

const stripNotes = (notes) => {
  const stripNote = (note) => {
    if (note._id) {
      return {
        createdBy: note.createdBy._id,
        note: note.note,
        createdAt: note.createdAt,
        updatedAt: note.updatedAt,
        updatedBy: (note.updatedBy && note.updatedBy._id) || null,
      };
    }
    return note;
  };

  return notes.map(stripNote);
};

const NotesView = ({ styles, entity, saveNotes, user }) => {
  const [editingNote, setEditingNote] = useState({});
  const notes = entity.notes || [];

  const saveStrippedNotes = (updatedNotes) => saveNotes(stripNotes(updatedNotes));

  const deleteNote = (noteId) => saveStrippedNotes(notes.filter((note) => note._id !== noteId));
  const selectedCellId = !isEmpty(editingNote) && editingNote._id;
  return (
    <>
      <Header
        styles={styles}
        saveNotes={saveStrippedNotes}
        notes={notes}
        user={user}
        editingNote={editingNote}
        setEditingNote={setEditingNote}
      />
      <table className={styles.table}>
        <thead>
          <tr className={styles.th}>
            <th style={{ width: '15%' }}>Author</th>
            <th style={{ width: '15%' }}>Date</th>
            <th style={{ width: '60%' }}>Note</th>
            <th style={{ width: '10%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(notes || [])
            .sort((a, b) => {
              const aCreated = a.createdAt ? 1 : 0;
              const bCreated = b.createdAt ? 1 : 0;
              if (aCreated === bCreated) {
                return moment(b.createdAt) - moment(a.createdAt);
              } else {
                return bCreated - aCreated;
              }
            })
            .map((note, index) => {
              const { createdBy = {} } = note;
              const createdByAuthor =
                (createdBy.first_name && createdBy.last_name && `${createdBy.first_name} ${createdBy.last_name}`) || '';
              const createdAt = (note.createdAt && moment(note.createdAt).format('YYYY-MM-DD')) || '';
              const isSelectedCell = selectedCellId && selectedCellId === note._id;
              return (
                <tr key={index} className={isSelectedCell ? styles.selectedCell : ''}>
                  <td className={isSelectedCell ? styles.selectedTd : styles.td}>{createdByAuthor}</td>
                  <td className={isSelectedCell ? styles.selectedTd : styles.td}>{createdAt}</td>
                  <td className={isSelectedCell ? styles.selectedTd : styles.td}>{note.note}</td>
                  <td className={isSelectedCell ? styles.selectedTd : styles.td}>
                    <i className={styles.pencilIcon} onClick={() => setEditingNote(note)} />
                    <i className={styles.trashIcon} onClick={() => deleteNote(note._id)} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

const withStyles = styled({
  headerContainer: { display: 'flex', justifyContent: 'space-between' },
  headerNoteEntry: { flex: 0.95 },
  errorText: { color: 'red', marginTop: '-5px', fontSize: '14px' },
  trashIcon: helpers.merge({ cursor: 'pointer', margin: '5px 10px' }, trashcan),
  pencilIcon: helpers.merge({ cursor: 'pointer', margin: '5px 10px' }, pencil),
  addIcon: helpers.merge({ marginRight: '10px' }, plus),
  editIcon: helpers.merge({ marginRight: '10px' }, pencil),
  clearIcon: helpers.merge(crossBlack, {
    height: 18,
    width: 18,
    right: '4rem',
    top: 11,
    position: 'absolute',
    cursor: 'pointer',
  }),
  selectedCell: { borderColor: '#141414', border: `1px solid ${colors.border}` },
  selectedTd: {
    borderTop: '1px solid #141414',
    borderBottom: '1px solid #141414',
    fontSize: 12,
    fontWeight: 300,
    color: text,
    padding: '15px 10px',
    backgroundColor: contentBackground,
  },
  buttonText: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actionButton: {
    display: 'inline-block',
    fontSize: '0.85em',
    padding: '0.9em 0.8em',
    background: colors.contentBackground,
    color: colors.darkText,
    border: `1px solid ${colors.border}`,
    outline: 0,
    height: 40,
    lineHeight: 1,
    textAlign: 'center',
    textDecoration: 'none',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#141414',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
    '>th': {
      textAlign: 'left',
      padding: 10,
    },
  },
  td: {
    fontSize: 12,
    fontWeight: 300,
    color: text,
    padding: '15px 10px',
    backgroundColor: contentBackground,
  },
});

export default withStyles(NotesView);
