export const Territory = {
  AllTerritories: 'All Territories',
  RestOfWorld: 'Rest of World',
  Canada: 'Canada',
  LatinAmerica: 'Latin America',
  Scandinavia: 'Scandinavia',
  UnitedStates: 'United States',
};

export const AgentType = {
  PaperingAgent: 'Papering Agent',
  ResponsibleAgent: 'Responsible Agent',
  ContractAdministrator: 'Contract Administrator',
};

export const ErrorMsg = {
  PaperingAgent: 'One Papering Agent is required per territory.',
  MaxOnePaperingAgent: 'Papering Agent can only be added once per territory.',
  ResponsibleAgent: 'One Responsible Agent is required per territory.',
  ContractAdmin: 'Contract Administrator can only be added once per territory.',
  Agent: 'An agent must be associated to the selected Agent Type.',
  AgentType: 'An Agent Type must be associated to the selected agent.',
};
