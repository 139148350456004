import React, { Fragment } from 'react';

import { sectionData, TerritoryBox, TerritoryHeader, InfoLine } from './ReadViewSupport';

export const ContractSignerTable = ({ territoriesData }) => {
  const sectionedData = sectionData(territoriesData);

  return (
    <>
      {Object.values(sectionedData).map((territoryDataArr, territoryIdx) => (
        <TerritoryBox key={territoryIdx}>
          {territoryDataArr?.map((territoryData, idx) => {
            return (
              <Fragment key={idx}>
                {idx === 0 ? <TerritoryHeader>{territoryData.territory}</TerritoryHeader> : null}
                <div style={{ marginTop: idx !== 0 ? 8 : 'auto' }}>
                  <InfoLine label={territoryData.label} isPrimary={territoryData.primary} value={territoryData.text} />
                </div>
              </Fragment>
            );
          })}
        </TerritoryBox>
      ))}
    </>
  );
};
