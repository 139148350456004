import React, { useState } from 'react';
import { notify } from 'react-notify-toast';

import Card from '../card/Card';
import EditView from './EditView';
import TerritoriesReadView from './TerritoriesReadView';
import { updateTouringTerritories } from '../../api/territory-data';
import CommonTooltip from '../common/tooltip';

import './styles.css';
import { TOOLTIP_MESSAGES } from '../common/messages/tooltip-messages';

export default function TouringTerritoriesCard({
  entity,
  mergeCardData,
  blockEdit,
  entityType,
  companyId,
  refreshProfile,
  touringTerritories,
  setTouringTerritories,
  cyTag,
}) {
  const [errors, setErrors] = useState([]);

  const onSave = async (saveInfo) => {
    if (!!saveInfo.updates[entity._id] && !!saveInfo.updates[entity._id]['touringTerritories']) {
      let requestPayload = saveInfo.updates[entity._id]['touringTerritories'];
      function removeNullAndEmptyValues(obj) {
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          if (value !== null && value !== '') {
            newObj[key] = value;
          }
        });
        return newObj;
      }

      const filteredArray = requestPayload.map(removeNullAndEmptyValues);

      return updateTouringTerritories(entityType, companyId, filteredArray)
        .then((data) => {
          notify.show('Territories saved successfully', 'success');
          setTouringTerritories(data);
          refreshProfile && refreshProfile();
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log(`Error Saving: ${errorMessage}`);
          notify.show('Territories did not save successfully. Please try again', 'error');
        });
    } else {
      notify.show('Territories saved successfully', 'success');
    }
  };

  const onValidateItem = () => {
    function keyExistsInAll(array, key) {
      return array.every((obj) => key in obj);
    }

    function keyExistsAndTrue(array, key) {
      return array.some((obj) => obj[key] === true);
    }

    if (errors && errors.length === 0) {
      return false;
    } else if (
      errors &&
      errors.length > 0 &&
      (!keyExistsInAll(errors, 'territory') ||
        !keyExistsInAll(errors, 'location') ||
        !keyExistsInAll(errors, 'subsidiary') ||
        !keyExistsInAll(errors, 'lineOfBusiness') ||
        !keyExistsInAll(errors, 'department'))
    ) {
      return false;
    } else if (
      errors &&
      errors.length > 0 &&
      (keyExistsAndTrue(errors, 'territory') ||
        keyExistsAndTrue(errors, 'location') ||
        keyExistsAndTrue(errors, 'subsidiary') ||
        keyExistsAndTrue(errors, 'lineOfBusiness') ||
        keyExistsAndTrue(errors, 'department'))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const territoryInitErrors = (errCopy) => {
    setErrors(errCopy);
  };

  const restErrors = (idx, item, label) => {
    let territoryKeys = ['territory', 'location', 'subsidiary', 'lineOfBusiness', 'department'];
    let arrayMap = {};
    territoryKeys
      .filter((ds) => ds !== label)
      .forEach((data) => {
        arrayMap = { ...arrayMap, [data]: true };
      });

    const errorCopy =
      errors.length > 0 && !!errors.find((ds, index) => idx === index)
        ? errors.map((ds, index) => {
            if (idx === index) {
              return { ...ds, [label]: item === null || item === '' ? true : false };
            }
            return ds;
          })
        : [...errors, { ...arrayMap, [label]: item === null || item === '' ? true : false }];

    setErrors(errorCopy);
  };

  const clearErrorsOnDelete = (idx) => {
    const errorCopy = errors.filter((ds, index) => index !== idx);
    setErrors(errorCopy);
  };

  const clearErrorsOnSelectAll = (idx, item, label) => {
    const errorCopy = errors
      .filter((ds, index) => index === idx)
      .map((ds) => {
        return { ...ds, [label]: item === null || item === '' ? true : false };
      });
    if (errorCopy.length > 0) {
      setErrors(errorCopy);
    } else if (errorCopy.length === 0) {
      let territoryKeys = ['territory', 'location', 'subsidiary', 'lineOfBusiness', 'department'];
      let arrayMap = {};
      territoryKeys
        .filter((ds) => ds !== label)
        .forEach((data) => {
          arrayMap = { ...arrayMap, [data]: true };
        });
      const newError = [{ ...arrayMap, [label]: item === null || item === '' ? true : false }];
      setErrors(newError);
    }
  };

  return (
    <div style={{ position: 'relative' }} data-cy={cyTag}>
      {blockEdit && <CommonTooltip text={TOOLTIP_MESSAGES.CONTACT_CLIENT_TEAM_MEMBER} />}{' '}
      <Card
        title="Territories"
        mergeCardData={mergeCardData}
        data={entity}
        readView={() => <TerritoriesReadView touringTerritories={touringTerritories} />}
        editView={
          !blockEdit &&
          ((props) => (
            <EditView
              territoryProps={props}
              territoryInitErrors={territoryInitErrors}
              restErrors={restErrors}
              clearErrorsOnDelete={clearErrorsOnDelete}
              clearErrorsOnSelectAll={clearErrorsOnSelectAll}
              errors={errors}
            />
          ))
        }
        onSave={onSave}
        onValidateItem={() => onValidateItem()}
      />{' '}
    </div>
  );
}
